import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Route, HashRouter as Router, Switch, useRouteMatch } from 'react-router-dom';

import { useAPI } from '../userContext';

const DebugText = ({ title, children }) => {
  const [open, set_open] = useState(false);
  return (
    <div>
      <div onClick={() => set_open(!open)}>{title}</div>
      {open && <pre>{children}</pre>}
    </div>
  );
};

const OneHopPath = ({ path }) => {
  const { Name, text_list, analysis_list, rank } = path;
  const text = Array.from(new Set(text_list)).join(' | ');
  const analysis = Array.from(new Set(analysis_list)).join(' | ');
  return (
    <div>
      <h4>
        Aleph's {Name} - rank = {rank} ( {text} )
      </h4>
      <p>{analysis}</p>
      <DebugText title="[see full path info]">{JSON.stringify(path, null, 2)}</DebugText>
    </div>
  );
};

const TwoHopPath = ({ path }) => {
  const { Name, rank, text_list } = path;
  const text = Array.from(new Set(text_list)).join(' | ');
  return (
    <div>
      <h4>
        Via {Name} - rank = {rank} ( {text} )
      </h4>
      <div style={{ padding: '1em', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
        {path.path.length ? (
          path.path.map((path) => <OneHopPath key={path.contact_id} path={path} />)
        ) : (
          <span>No direct paths found</span>
        )}
      </div>
      <DebugText title="[see full path info]">{JSON.stringify(path, null, 2)}</DebugText>
    </div>
  );
};

export const PathToContact = ({ contact_id, contact_name }) => {
  const api = useAPI();
  const [path, set_path] = useState({});
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState('');
  useEffect(() => {
    const fetch_path = async () => {
      set_loading(true);
      try {
        const res = await api(`pathfinder/contact/${contact_id}`);
        set_path(res);
      } catch (e) {
        set_error(e.message);
      } finally {
        set_loading(false);
      }
    };
    fetch_path();
  }, [api, contact_id]);
  const contact_label = contact_name || contact_id;
  return loading ? (
    <div>Finding path to {contact_label}...</div>
  ) : error ? (
    <div style={{ color: 'red' }}>Error: {error}</div>
  ) : (
    <div>
      <h3 style={{ marginTop: '0px' }}>
        {path.one_hop_paths.length} direct paths to <b>{contact_label}</b> and {path.two_hop_paths.length} potential
        connectors:
      </h3>
      <div style={{ padding: '1em', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
        {path.one_hop_paths.length ? (
          path.one_hop_paths.map((path) => <OneHopPath key={path.id} path={path} />)
        ) : (
          <span>No direct paths found</span>
        )}
        {path.two_hop_paths.length && path.two_hop_paths.map((path) => <TwoHopPath key={path.id} path={path} />)}
      </div>
      <DebugText title={'See full path info (debug)'}>{JSON.stringify(path, null, 2)}</DebugText>
    </div>
  );
};

export const ContactPathFinder = () => {
  const { params } = useRouteMatch();

  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }}>
        <h1 style={{ marginTop: '1em' }}>Contact Path Finder</h1>
        <h2>contact id: {params.id}</h2>
        <PathToContact contact_id={params.id} />
      </div>
    </>
  );
};

export const AccountPathFinder = () => {
  const { params } = useRouteMatch();
  return (
    <div>
      <h1>Account Path Finder</h1>
      <h2>account id: {params.id}</h2>
    </div>
  );
};

const prioritize_contact = ({ contact_kind }) => {
  if (contact_kind === 'exact_title_match') return 0;
  if (contact_kind === 'executives') return 1;
  if (contact_kind === 'board_members') return 2;
  if (contact_kind === 'managers') return 3;
  if (contact_kind === 'vc_partners') return 4;
  if (contact_kind === 'employees') return 5;
};

const sort_paths = (paths) => _.sortBy(paths, [prioritize_contact, (x) => -x.score]);

export const IntroPathFinder = () => {
  const { params } = useRouteMatch();
  const api = useAPI();
  const [path, set_path] = useState({});
  const [intro, set_intro] = useState({});
  const [loading, set_loading] = useState(true);
  const [error, set_error] = useState('');
  useEffect(() => {
    const fetch_path = async () => {
      set_loading(true);
      try {
        const { paths, intro } = await api(`pathfinder/intro/${params.id}`);
        set_path(sort_paths(paths));
        set_intro(intro);
      } catch (e) {
        set_error(e.message);
      } finally {
        set_loading(false);
      }
    };
    fetch_path();
  }, [api, params.id]);
  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }}>
        <h1 style={{ marginTop: '1em' }}>Intro Path Finder</h1>
        <h2>
          <b>Intro:</b> {intro.Name || params.id}
        </h2>
        {loading ? (
          <div style={{ textAlign: 'center' }}>Loading...</div>
        ) : error ? (
          <div style={{ textAlign: 'center' }}>Error: {error}</div>
        ) : (
          <>
            <div>
              <DebugText title="[see raw path (debug mode)]">{JSON.stringify(path, null, 2)}</DebugText>
            </div>
            <div>
              {path.map(({ id, name, title, contact_kind, score_details }, idx) => (
                <div key={id}>
                  <h3 style={{ marginTop: '0px' }}>
                    <b>{idx}.</b> {name} - {title} ({contact_kind}){' '}
                    <span
                      style={{ fontSize: '12px', fontWeight: 100, fontStyle: 'normal', color: 'rgba(0, 0, 0, 0.3)' }}
                    >
                      {score_details}
                    </span>
                  </h3>
                  <div style={{ padding: '1em', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
                    <PathToContact contact_id={id} contact_name={name} />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const PathFinderMain = () => {
  const { path } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route path={`${path}/contact/:id`}>
          <ContactPathFinder />
        </Route>
        <Route path={`${path}/account/:id`}>
          <AccountPathFinder />
        </Route>
        <Route path={`${path}/intro/:id`}>
          <IntroPathFinder />
        </Route>
      </Switch>
    </Router>
  );
};
