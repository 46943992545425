const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:17337/' : 'https://api-connect.aleph.vc/';

class NotLoggedInError extends Error {
  constructor(response) {
    super('Not logged in');
    this.response = response;
  }
}

export const get_api_caller = (token, userStateDispatcher) =>
  token
    ? async (url, options = {}) => {
        try {
          const response = await apiCall(url, token, options);
          return response;
        } catch (err) {
          if (err instanceof NotLoggedInError) {
            userStateDispatcher({
              type: 'logoutUser',
            });
          }
          throw err;
        }
      }
    : async (url, options = {}) => {};

export async function apiCall(url, token, options = {}) {
  options.headers = {
    'x-aleph-token': token,
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  };
  const response = await fetch(`${baseUrl}${url}`, options);
  if (!response.ok && response.status !== 304) {
    let errorBody;
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.includes('application/json')) {
      errorBody = await response.json();
    } else {
      errorBody = await response.text();
    }

    if (response.status === 401) {
      throw new NotLoggedInError('Unauthorized: ' + JSON.stringify(errorBody));
    }
    throw new Error(`Bad response (status ${response.status}): ${JSON.stringify(errorBody)}`);
  }
  return response.json();
}

export async function test_login(api) {
  const { user } = await api('user');
  return user;
}

export async function login(google_token) {
  const options = {
    headers: {
      'X-Google-Token': google_token,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    },
  };
  const response = await fetch(`${baseUrl}login`, options);
  if (!response.ok) {
    return null;
  }
  return response.json();
}
