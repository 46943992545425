import {
  AppBar,
  Avatar,
  ClickAwayListener,
  Divider,
  Drawer,
  FormControlLabel,
  Grow,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon,
  Switch,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Adjust,
  Dashboard,
  ExitToApp,
  LibraryBooks,
  MenuTwoTone,
  PeopleAlt,
  PlaylistPlay,
  School,
  Search,
  ThumbUpAltOutlined,
  TrackChanges,
  Update,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import large_logo from '../static/ampliphy_white.svg';
import { ReactComponent as salesOpportunitiesSvg } from '../static/sales-opportunities-icon.svg';
import { ReactComponent as tamnuniSvg } from '../static/tamnuni.svg';
import { is_admin, is_fake, is_talpod, killFake, LogoutController } from '../userContext';

export const EmptyAppBar = ({ classes }) => (
  <AppBar elevation={0} position="fixed" className={classes.appBar}>
    <Toolbar>
      <RouterLink to="/">
        <IconButton edge="start">
          <img height="32px" src={large_logo} alt="Aleph Connect" />
        </IconButton>
      </RouterLink>
      <span style={{ marginLeft: 'auto' }} />
    </Toolbar>
  </AppBar>
);

const UserMenu = ({ user, adminOff, toggleAdmin }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <IconButton onClick={handleToggle}>
        <Avatar src={_.get(user, 'payload.picture')} />
      </IconButton>
      <IconButton onClick={handleToggle} ref={anchorRef}>
        <MenuTwoTone />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {is_talpod(user) && (
                    <>
                      <RouterLink to="/talent/dashboard">
                        <MenuItem key="talent-dashboard">
                          <ListItemIcon>
                            <Adjust fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Talpod KRs" />
                        </MenuItem>
                      </RouterLink>
                      <RouterLink to="/talent/focus/list">
                        <MenuItem key="focus-list">
                          <ListItemIcon>
                            <PlaylistPlay fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Focus List" />
                        </MenuItem>
                      </RouterLink>
                      <RouterLink to="/talent/open-requests">
                        <MenuItem key="open-requests">
                          <ListItemIcon>
                            <Dashboard fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Talpod Dashboard" />
                        </MenuItem>
                      </RouterLink>
                      <RouterLink to="/talent/open-positions">
                        <MenuItem key="open-positions">
                          <ListItemIcon>
                            <Dashboard fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Open Positions" />
                        </MenuItem>
                      </RouterLink>
                      <RouterLink to="/talent/key-nodes-reachouts">
                        <MenuItem key="key-nodes-reachouts">
                          <SvgIcon
                            component={tamnuniSvg}
                            style={{ width: 20, height: 17, marginRight: '8px' }}
                            viewBox={'0 0 36 31'}
                          />
                          <ListItemText primary="Key Nodes Reachouts" />
                        </MenuItem>
                      </RouterLink>
                      <RouterLink to="/csv-scrape">
                        <MenuItem key="csv-scrape">
                          <ListItemIcon>
                            <Update fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Scrape from CSV" />
                        </MenuItem>
                      </RouterLink>
                    </>
                  )}
                  <RouterLink to="/">
                    <MenuItem key="search-network">
                      <ListItemIcon>
                        <Search fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Search Aleph Network" />
                    </MenuItem>
                  </RouterLink>
                  <RouterLink to="/link-account">
                    <MenuItem key="logout">
                      <ListItemIcon>
                        <PeopleAlt fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="My Network" />
                    </MenuItem>
                  </RouterLink>
                  <RouterLink to="/intro/dashboard">
                    <MenuItem key="my-intros">
                      <ListItemIcon>
                        <LibraryBooks fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="My Requests" />
                    </MenuItem>
                  </RouterLink>
                  {
                    /* we need to first update Aleph_Connect_Role__c for all users and then uncomment this line  https://alephvc.slack.com/archives/C01A9LDNMK9/p1685453831058949?thread_ts=1685447654.526759&cid=C01A9LDNMK9
                                    (_.includes(getUserAlephConnectRole(user),'HR') || _.includes(getUserAlephConnectRole(user),'Founder') || is_admin(user))  &&*/
                    <RouterLink to="/talent/candidates">
                      <MenuItem key="my-candidates">
                        <ListItemIcon>
                          <TrackChanges fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="My Candidates" />
                      </MenuItem>
                    </RouterLink>
                  }
                  {!location.pathname.includes('/talent') && (
                    <>
                      <RouterLink to="/sales/opportunities">
                        <MenuItem key="sales-opportunities">
                          <ListItemIcon>
                            <SvgIcon component={salesOpportunitiesSvg} viewBox={'0 0 22 18'} />
                          </ListItemIcon>
                          <ListItemText primary="My Opportunities" />
                        </MenuItem>
                      </RouterLink>
                      <RouterLink to="/sales/tutorial">
                        <MenuItem key="sales-tutorial">
                          <ListItemIcon>
                            <School fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Tutorial" />
                        </MenuItem>
                      </RouterLink>
                    </>
                  )}
                  {!is_fake(user) && (
                    <RouterLink to="/logout">
                      <MenuItem key="logout">
                        <ListItemIcon>
                          <ExitToApp fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </MenuItem>
                    </RouterLink>
                  )}
                  {is_admin(user) && (
                    <>
                      <RouterLink to="/sales/recommended">
                        <MenuItem key="sales-recs">
                          <ListItemIcon>
                            <ThumbUpAltOutlined fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Sales Recommendations" />
                        </MenuItem>
                      </RouterLink>
                      <RouterLink to="/login-as">
                        <MenuItem key="login-as">
                          <ListItemIcon>
                            <Icon className="fa fa-user-secret" fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Login As..." />
                        </MenuItem>
                      </RouterLink>
                      <MenuItem key="is-admin">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!adminOff}
                              onChange={() => {
                                toggleAdmin(!adminOff);
                              }}
                              name="Is Admin"
                              color="primary"
                            />
                          }
                          label="Is Admin"
                        />
                      </MenuItem>
                      <RouterLink to="/get-app-token">
                        <MenuItem key="get-app-token">
                          <ListItemIcon>
                            <Icon className="fa fa-key" fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary="Get App Token" />
                        </MenuItem>
                      </RouterLink>
                    </>
                  )}
                  {is_fake(user) && (
                    <MenuItem key="remove-fake-user">
                      <ListItemIcon>
                        <Icon className="fa fa-user-secret" fontSize="small" /> &nbsp; &nbsp;{' '}
                        <Link onClick={killFake}>Remove Fake User</Link>
                      </ListItemIcon>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const ConnectAppBar = ({ classes, user, toggleAdmin, adminOff }) => (
  <AppBar elevation={0} position="fixed" className={classes.appBar}>
    <>
      {is_fake(user) && (
        <div
          style={{ height: '11px', width: '100%', backgroundColor: '#FF0000', position: 'absolute', zIndex: '-1' }}
        ></div>
      )}
      <Toolbar>
        <RouterLink to="/">
          <IconButton edge="start">
            <img height="32px" src={large_logo} alt="Aleph Connect" />
          </IconButton>
        </RouterLink>
        <span style={{ marginLeft: 'auto' }} />
        <Typography variant="h6" noWrap>
          Hey, {_.get(user, 'payload.given_name')} <br />
        </Typography>
        <UserMenu user={user} toggleAdmin={toggleAdmin} adminOff={adminOff} />
      </Toolbar>
    </>
  </AppBar>
);

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

function SideNav() {
  return (
    <>
      <Divider />
      <List>
        <ListItemLink to="/talent" primary="Talent" />
        <ListItemLink to="/intro" primary="Intro" />
        <ListItemLink to="/reference-check" primary="Refernce Check" />
        <LogoutController />
      </List>
    </>
  );
}

export const ConnectSideBar = ({ classes }) => (
  <nav>
    <Drawer
      variant="permanent"
      open
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <SideNav />
    </Drawer>
  </nav>
);
