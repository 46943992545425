import { FormControlLabel, Grid, Slider, Switch, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { IndustryAutocomplete } from './industry-autocomplete';
import { ModeSelector } from './modeselect';
import { TitleSearch } from './search-by-titles';

export const SEARCH_ROUTES = {
  'By Company': '/',
  'By Title': '/advanced',
  'By Industry': '/industry',
};

export const INV_SEARCH_ROUTES = {
  '/': 'By Company',
  '/advanced': 'By Title',
  '/industry': 'By Industry',
};

const HEADCOUNT_RANGE = [1, 10, 50, 200, 500, 1000, 5000, 10000, Infinity];

function HeadCountSelect({ headCountFrom, setHeadCountFrom, headCountTo, setHeadCountTo }) {
  const handleChange = (event, newValue) => {
    const [min, max] = newValue;
    setHeadCountFrom(HEADCOUNT_RANGE[min] + 1);
    setHeadCountTo(HEADCOUNT_RANGE[max]);
  };
  return (
    <div style={{ marginTop: '0.5em' }}>
      <Typography id="range-slider" gutterBottom>
        Company Size (# of Employees)
      </Typography>
      <Slider
        value={[HEADCOUNT_RANGE.indexOf(headCountFrom - 1), HEADCOUNT_RANGE.indexOf(headCountTo)]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={null}
        scale={(x) => Math.min(HEADCOUNT_RANGE[x], 1000000)}
        marks={HEADCOUNT_RANGE.map((x, i) => ({ label: `${x}`, value: i }))}
        min={0}
        max={HEADCOUNT_RANGE.length - 1}
      />
    </div>
  );
}

export function ContactQueryBuilder({
  onTitleLikeChange,
  onTitleNotLikeChange,
  headCountFrom,
  setHeadCountFrom,
  headCountTo,
  setHeadCountTo,
  useTitleNotLike,
  setUseTitleNotLike,
}) {
  const theme = useTheme();
  const small_screen = useMediaQuery(theme.breakpoints.down('xs'));
  const handleShowNotLikeSwitch = (e) => {
    setUseTitleNotLike(e.target.checked);
  };

  const titleLikeLabel = !small_screen ? (
    <em>Type title keywords to search for (e.g. - CEO)</em>
  ) : (
    <em>Title keywords</em>
  );
  const titleNotLikeLabel = !small_screen ? (
    <em>Exclude these keywords (e.g. - Assistant)</em>
  ) : (
    <em>Exclude keywords</em>
  );

  const history = useHistory();
  const location = useLocation();
  const modeSelectorValue = INV_SEARCH_ROUTES[location.pathname];

  const handleSelected = (e, new_value) => {
    console.log(new_value, 'selected');
    if (new_value !== modeSelectorValue) {
      history.push(SEARCH_ROUTES[new_value]);
    }
  };

  return (
    <Grid>
      <div className="searchbox-outer">
        <ModeSelector options={_.keys(SEARCH_ROUTES)} handleSelected={handleSelected} value={modeSelectorValue} />
        <TitleSearch onSelected={onTitleLikeChange} label={titleLikeLabel} />
      </div>
      <FormControlLabel
        value="show-not-lk"
        control={<Switch color="primary" checked={useTitleNotLike} onChange={handleShowNotLikeSwitch} />}
        label="Exclude some keywords"
        labelPlacement="end"
      />
      {useTitleNotLike && (
        <div className="searchbox-outer" style={{ marginTop: '0.5em' }}>
          <TitleSearch variant="fullWidth" onSelected={onTitleNotLikeChange} label={titleNotLikeLabel} />
        </div>
      )}
      <HeadCountSelect
        headCountFrom={headCountFrom}
        setHeadCountFrom={setHeadCountFrom}
        headCountTo={headCountTo}
        setHeadCountTo={setHeadCountTo}
      />
    </Grid>
  );
}

export function IndustryQueryBuilder({
  onIndustriesChange,
  headCountFrom,
  setHeadCountFrom,
  headCountTo,
  setHeadCountTo,
}) {
  const theme = useTheme();
  const small_screen = useMediaQuery(theme.breakpoints.down('xs'));

  const industriesLabel = !small_screen ? <em>Type industries to search for (e.g. - Tourism)</em> : <em>Industries</em>;

  const history = useHistory();
  const location = useLocation();
  const modeSelectorValue = INV_SEARCH_ROUTES[location.pathname];

  const handleSelected = (e, new_value) => {
    if (new_value !== modeSelectorValue) {
      history.push(SEARCH_ROUTES[new_value]);
    }
  };

  return (
    <Grid>
      <div className="searchbox-outer">
        <ModeSelector options={_.keys(SEARCH_ROUTES)} handleSelected={handleSelected} value={modeSelectorValue} />
        <IndustryAutocomplete onSelected={onIndustriesChange} label={industriesLabel} />
      </div>
      <HeadCountSelect
        headCountFrom={headCountFrom}
        setHeadCountFrom={setHeadCountFrom}
        headCountTo={headCountTo}
        setHeadCountTo={setHeadCountTo}
      />
    </Grid>
  );
}
