import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

import ampliphy_logo from './static/ampliphy_dark.svg';
import { LoginController } from './userContext';

function resizeIframe(obj) {
  obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
}

export const Home = ({ authenticated }) => {
  const ref = React.useRef();
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (ref.current) {
        resizeIframe(ref.current);
        console.log('hi');
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]); // Empty array ensures that effect is only run on mount

  return (
    <Grid container className="homepage">
      <Grid item xs={12} sm={6}>
        <img src={ampliphy_logo} style={{ height: '4em' }} loading="lazy" alt="Aleph Connect" className="image-7" />
      </Grid>
      <iframe
        src="/walkthrough/connect_large _800.html"
        title="How it Works"
        width="100%"
        style={{ border: 'none' }}
        ref={ref}
        onLoad={() => resizeIframe(ref.current)}
      />
      <div className="text-block-6">
        Aleph Connect is an exclusive network visibility tool that enables Aleph portfolio companies to ask for
        introductions to potential customers, business partners, investors and talent.
      </div>
      {authenticated === 'pending' && (
        <p className="paragraph-3">
          <Alert severity="info">
            Your request for access was sent to Aleph for review, you will be notified once approved.
            <br /> Thank you for your patience!
          </Alert>
        </p>
      )}
      <LoginController />
      <div className="text-block-8">
        For more info see our{' '}
        <a href="/#/privacy-policy" className="link">
          Privacy Policy
        </a>
      </div>
    </Grid>
  );
};
