import { Grid } from '@material-ui/core';
import React from 'react';

export const PrivacyPolicy = () => (
  <Grid container justifyContent="center">
    <Grid item xs={8}>
      <h1>Privacy Policy</h1>
      <p>
        Thank you for using Aleph Connect! We take your privacy extremely seriously and would like to describe how we
        collect, use and protect your information when you access our products, services and applications (collectively,
        the “Services”).
      </p>
      <h2>Overview</h2>
      <p>
        Aleph Connect is a private application used exclusively by Aleph Personell and Aleph Portfolio Companies. Access
        is by invite only. We collect - your usage history (in order to improve the service), and (strictly optional) -
        names and email addresses of people you interacted lately with on connected gmail accounts. The latter is used
        solely in order to contact your about potential introductions to some of your contacts, if necessary. We do not
        share or sell your information.
      </p>

      <h2>What Does This Privacy Policy Cover?</h2>
      <p>
        This Privacy Policy covers our treatment of personally identifiable information (“Personal Information”) that we
        gather when you are accessing or using our Services. We gather various types of Personal Information from our
        users, as explained in more detail below, and we use this Personal Information in order to make our Services
        available to you, to contact you, and to better understand how you use the Services. In certain cases, we may
        also share some Personal Information with third parties, but only as described below.
      </p>
      <p>
        We do not knowingly collect or solicit personal information from anyone under the age of 16. If you are under
        16, please do not attempt to use the Services or send any personal information about yourself to us. If we learn
        that we have collected personal information from a child under age 16, we will delete that information as
        quickly as possible. If you believe that a child under 16 may have provided us personal information, please
        contact us at [contact@aleph.vc].
      </p>

      <h2>Will This Privacy Policy Ever Change?</h2>
      <p>
        We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time
        as well, but if we do, we will bring it to your attention by placing a notice in the app on{' '}
        <a href="https://connect.aleph.vc">https://connect.aleph.vc</a>, and/or by sending you an email, and/or by some
        other means. Your use of the Services after any changes to the Privacy Policy have been posted will be
        considered to indicate your acceptance of the changes.
      </p>

      <h2>What Information Do We Collect?</h2>
      <h3>Information You Provide to Us</h3>
      <p>
        We receive and store any information you knowingly provide to us. When you create a new account to use the
        Services we will solicit your consent to connect your Google account to your Aleph Connect account (using
        Google’s OAuth authentication method), thereby providing us with limited access to your Google account,
        including your name, email, and the headers of your gmail communications. Although this OAuth authentication
        provides access to all your gmail headers and metadata, Aleph Connect accesses and stores only a subset of the
        data, namely - names, email addresses, and timestamps - to provide you with our Services. Specifically - we do
        NOT have access to the contents of your emails, and while we do have theoretical access to the email subject
        lines - we do NOT process nor store them at any point.
      </p>
      <h3>Information Collected Automatically</h3>
      <p>
        Whenever you interact with our Services, we automatically receive and record information on our server logs from
        your browser or device, which may include your IP address, “cookie” information, the type of browser and/or
        device you’re using to access our Services, and the page or feature you requested. “Cookies” are identifiers we
        transfer to your browser or device that allow us to recognize your browser or device and tell us how and when
        pages and features in our Services are visited and by how many people. You may be able to change the preferences
        on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from
        taking advantage of some of our features.
      </p>
      <h3>Information Collected From Other Websites and Do Not Track Policy</h3>
      <p>
        We do NOT collect information about your online activity on other websites, via cookies nor any other method. We
      </p>
      <h3>Third Party Analytics Services</h3>
      <p>
        We use third party analytics services in order to better understand user engagement with the Services. When a
        user browses or uses the Services, these third party analytics services may collect the user’s IP address,
        browser type, and approximate location (based on the IP address). They may also use web logs or web beacons and
        may set and access cookies on your computer or other device.
      </p>

      <h2>Do We Share Any Personal Information?</h2>
      <p>
        We neither rent nor sell your Personal Information to anyone. However, we may share your Personal Information
        with third parties as described in this section:
      </p>
      <p>
        <b>Agents and vendors:</b> We employ other companies and people to perform tasks on our behalf and need to share
        your information with them, including in some cases the Personal Information you have provided to us, in order
        to make the Services available to you. For example, we may use third party hosting providers or software
        developers to help implement and maintain the Services. However, our agents do not have any right to use the
        Personal Information we share with them beyond what is necessary to assist us.
      </p>
      <p>
        <b>Protection of Aleph:</b> We reserve the right to access, read, preserve, and disclose any information that we
        believe is necessary to comply with law or court order; enforce or apply our applicable terms of use and other
        agreements; or protect the rights, property, or safety of Aleph, our employees, our users, or others.
      </p>

      <h2>Is Personal Information About Me Secure?</h2>
      <p>
        We will use very diligent efforts to protect the privacy of your account and other Personal Information we hold
        in our records, but unfortunately, we cannot guarantee complete security. Unauthorized entry or use, hardware or
        software failure, and other factors, may compromise the security of user information at any time.
      </p>

      <h2>Transfer to the U.S. or Other Countries</h2>
      <p>
        We currently use datacenter facilities located exclusively in the United States. Your information will be stored
        and processed in the United States or other countries where we decide to maintain datacenter facilities in the
        future. By using the Services, you consent to the transfer of information outside of your country, even if your
        country has more rigorous data protection standards. For any Personal Information that may be transferred from
        the European Economic Area, please see the EU Residents section below.
      </p>

      <h2>EU Residents</h2>
      <p>
        If you are a resident of the European Union (“EU”), United Kingdom, Lichtenstein, Norway, or Iceland, you may
        have additional rights under the EU General Data Protection Regulation (the “GDPR”).
      </p>

      <h2>What if I Have Questions About This Policy?</h2>
      <p>
        If you have any questions or concerns regarding our privacy policies, please don’t hesitate to contact us at
        [contact@aleph.vc], and we will try to resolve your concerns.
      </p>
    </Grid>
  </Grid>
);
