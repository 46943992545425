import { Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

// Common styles function
const useCommonStyles = (color, custom) =>
  makeStyles((theme) => ({
    arrow: {
      color: color,
    },
    tooltip: {
      backgroundColor: color,
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(12),
      border: `1px solid ${color}`,
      padding: '12px 16px',
      maxWidth: '130px',
      fontFamily: 'Inter',
    },
    custom: custom, // Custom styles
  }));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#ffcf64',
  },
  tooltip: {
    backgroundColor: '#ffcf64',
    color: 'black',
  },
}));

const useStylesAlertTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.info.main,
  },
  tooltip: {
    backgroundColor: theme.palette.info.main,
    maxWidth: '50vw',
  },
}));

const useStylesConnectorTooltip = makeStyles((theme) => ({
  arrow: {
    color: '#3D3C3C',
  },
  tooltip: {
    backgroundColor: '#3D3C3C',
    color: '#fff',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #3D3C3C',
    padding: '12px 16px 12px 16px', // add your desired padding here
    maxWidth: '130px',
  },
}));

const useStylesIntroRequestTooltip = makeStyles((theme) => ({
  arrow: {
    color: '#3D3C3C',
  },
  tooltip: {
    backgroundColor: '#3D3C3C',
    color: '#fff',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #3D3C3C',
    padding: '0px 14px',
    height: '57px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    textAlign: 'center',
  },
}));

const useStylesConnectorButtonTooltip = makeStyles((theme) => ({
  arrow: {
    color: '#3D3C3C',
  },
  tooltip: {
    backgroundColor: '#3D3C3C',
    color: '#fff',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #3D3C3C',
    padding: '12px 16px 12px 16px',
    maxWidth: '150px',
  },
}));

const useSendTamnuniTooltipStyles = (custom) => useCommonStyles('#3D3C3C', custom);

export const BootstrapTooltip = (props) => {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
};

export const AlertTooltip = (props) => {
  const classes = useStylesAlertTooltip();
  return <Tooltip arrow interactive classes={classes} {...props} />;
};

export const TalentTooltip = (props) => {
  const classes = useStylesAlertTooltip();
  return <Tooltip interactive classes={classes} {...props} />;
};

export const ConnectorTooltip = (props) => {
  const classes = useStylesConnectorTooltip();
  return <Tooltip arrow interactive classes={classes} {...props} />;
};

export const ConnectorButtonTooltip = (props) => {
  const classes = useStylesConnectorButtonTooltip();
  return <Tooltip arrow interactive classes={classes} {...props} />;
};

export const SendTamnuniTooltip = ({ className, ...props }) => {
  const classes = useSendTamnuniTooltipStyles(className)();
  return <Tooltip arrow interactive classes={{ ...classes, custom: className }} {...props} />;
};

export const IntroRequestTooltip = (props) => {
  const classes = useStylesIntroRequestTooltip();
  return <Tooltip arrow interactive classes={classes} {...props} />;
};

export const WhiteCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
