import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  ping_aleph_facilitator,
  update_portco_interest,
  update_poscan_next_feedback_request,
  update_poscan_priority,
  update_poscan_rejection,
  update_poscan_status,
} from '../talent/api';
import { is_admin, useAPI, withUserState } from '../userContext';
import { getFilteredIntrosInternal, introStatusApiNameToLabel } from './intro-utils';
import {
  POISTION_CANDIDATE_PRIORITIES,
  POSCAN_STATUSES,
  REJECTION_REASONS_POSCAN_BY_CANDIDATE,
  REJECTION_REASONS_POSCAN_BY_PORTCO,
} from './sf-consts';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  fontFamily: 'Merriweather Sans',
  fontSize: '12px',
  lineHeight: '22px',
  color: '#00A394',
  '&.Mui-checked': {
    color: '#00A394',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '400px',
    },
  },
};

export const ACTIVE_POSCAN_REQUEST_STATUSES = ['Intro request', 'Ref check request'];

export function shouldShowPriority(status) {
  return _.includes(ACTIVE_POSCAN_REQUEST_STATUSES, status);
}

export function isPortcoRequest(requestType) {
  return _.includes(['Intro request', 'Ref check request', 'Interview with Aleph personnel'], requestType);
}

export const FOLLOWUPS_POSCANS_STATUSES = [
  'To send to account',
  'Sent to account',
  'Accepted by account',
  'To send to candidate',
  'Sent to candidate',
  'To be introduced',
  'Introduced',
  'Interview with Aleph personnel',
];

export const OLD_POSCANS_STATUSES = [
  'Rejected by account',
  'Rejected by Candidate',
  'Not relevant / No longer needed',
  "Candidate didn't answer",
  'Rejected by account after interview',
  'Rejected by candidate after interview',
  'Interview with Aleph personnel',
  'Is/Was Already in Process',
  'Hired',
  'candidate was hired by another company',
  'position was closed',
  'position candidate was closed after no feedback from account',
  'Someone else was hired',
  'Couldn’t find a referencer',
  'Ref check provided',
  'Talpod found irrelevant',
  "Candidate didn't answer",
  'On Hold',
];

export const ACTIVE_ALEPH_POSCAN_STATUSES = [
  'Intro request',
  'Ref check request',
  'To be introduced',
  'Sent to candidate',
  'Interview with Aleph personnel',
];

export const ACTIVE_PORTCO_POSCAN_STATUSES = ['Introduced'];

export const ICE_POSCAN_STATUSES = ["Couldn't find connection", 'On Hold'];

export const FAILED_REQUEST_STATUSES = ["Couldn't find connection"];

export const POSCAN_INTRO_STATUSES = ['To be introduced', 'Introduced'];

export const POSCAN_REJECTED_BY_CANDIDATE = [
  'Rejected by Candidate',
  "Candidate didn't answer",
  'Is/Was Already in Process',
  'candidate was hired by another company',
];

export const POSCAN_REJECTED_BY_ACCOUNT = [
  'Rejected by account',
  'Is/Was Already in Process',
  'position was closed',
  'Someone else was hired',
];

export const POSCAN_SEND_TO_ACCOUNT = ['To send to account', 'Sent to account'];

export const POSCAN_SEND_TO_CANDIDATE = ['To send to candidate', 'Sent to candidate'];

function getAdminPotentialStatuses({ Status__c, Request_Type__c }) {
  if (
    _.includes(ACTIVE_ALEPH_POSCAN_STATUSES, Status__c) &&
    _.includes(['Intro request', 'Ref check request'], Request_Type__c)
  ) {
    return _.compact([
      "Couldn't find connection",
      'On Hold',
      'Talpod found irrelevant',
      Request_Type__c === 'Ref check request' ? 'Ref check provided' : false,
      'Position candidate was closed after no feedback from account',
    ]);
  } else if (Status__c === 'Introduced') {
    return [
      'To be introduced',
      'Rejected by account after interview',
      'Position candidate was closed after no feedback from account',
    ];
  }
}

const PRE_INTRO_STATUSES_FOR_PORTCOS = [
  'Not relevant / No longer needed',
  'position was closed',
  'Someone else was hired',
];

const POST_INTRO_STATUSES_FOR_PORTCOS = [
  'Hired',
  'candidate was hired by another company',
  'position was closed',
  'Someone else was hired',
  'Rejected by Candidate After interview',
  "Candidate didn't answer",
];

export function getPotentialPoscanStatuses(
  { Portco_Interested__c, Status__c, Candidate_Interested__c, Request_Type__c },
  isAdmin = false,
) {
  if (
    _.includes(ACTIVE_ALEPH_POSCAN_STATUSES, Status__c) &&
    _.includes(['Intro request', 'Ref check request'], Request_Type__c)
  ) {
    return _.compact(
      _.concat(
        PRE_INTRO_STATUSES_FOR_PORTCOS,
        isAdmin && getAdminPotentialStatuses({ Status__c, Request_Type__c }),
        Status__c,
      ),
    );
  } else if (Status__c === 'Introduced') {
    return _.compact(
      _.concat(
        POST_INTRO_STATUSES_FOR_PORTCOS,
        Status__c,
        !isAdmin && 'Rejected by us after interview',
        isAdmin && getAdminPotentialStatuses({ Status__c, Request_Type__c }),
      ),
    );
  } else if (Status__c === 'Sent to candidate') {
    return _.concat(
      Status__c,
      POSCAN_REJECTED_BY_CANDIDATE,
      Portco_Interested__c ? POSCAN_INTRO_STATUSES : POSCAN_SEND_TO_ACCOUNT,
    );
  } else if (Status__c === 'Sent to account') {
    return _.concat(
      Status__c,
      POSCAN_REJECTED_BY_ACCOUNT,
      Candidate_Interested__c ? POSCAN_INTRO_STATUSES : POSCAN_SEND_TO_CANDIDATE,
    );
  } else {
    return isAdmin ? POSCAN_STATUSES : [];
  }
}

export function assignPoscanGroup(p) {
  const { Status__c } = p;
  if (ACTIVE_ALEPH_POSCAN_STATUSES.includes(Status__c)) {
    return {
      ...p,
      poscanScore: 3,
      poscanStatusPortco: 'In Play - Aleph',
    };
  } else if (ACTIVE_PORTCO_POSCAN_STATUSES.includes(Status__c)) {
    return {
      ...p,
      poscanScore: 2,
      poscanStatusPortco: 'Awaiting Feedback',
    };
  } else {
    /*else if(ICE_POSCAN_STATUSES.includes(Status__c)){
        return{
            ...p,
            poscanScore: 1,
            poscanStatusPortco: `On hold - ${Status__c}`
        }
    }*/
    // TODO: fix
    return {
      ...p,
      poscanScore: 0,
      poscanStatusPortco: 'Old Process',
    };
  }
}

export const canCancelRequest = (poscanStatus) =>
  _.includes(_.concat(ACTIVE_ALEPH_POSCAN_STATUSES, ICE_POSCAN_STATUSES), poscanStatus);

export const PoscanFieldGenericInput = ({
  poscan,
  fieldName,
  fieldApiName,
  options,
  updateFunc,
  inputType,
  width = '200px',
}) => {
  const api = useAPI();
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState(_.get(poscan, fieldApiName));

  const [lastReq, setLastReq] = useState('');
  const hide_message = () => setLastReq('');

  const update = async (value) => {
    setDisabled(true);
    setValue(value);
    try {
      await updateFunc({ poscanId: poscan.Id, [fieldApiName]: value }, api);
      setLastReq(`${fieldName} was updated successfully`);
    } catch (e) {
      setLastReq(`Ooops, looks like there was an error - ${e.message}`);
    }
    setDisabled(false);
  };

  return (
    <>
      <FormControl style={{ width }}>
        {inputType === 'select' && (
          <>
            <InputLabel id={'poscan-select-label-' + _.get(poscan, 'Id') + '-' + fieldApiName}>{fieldName}</InputLabel>
            <Select
              labelId={'poscan-select-label1-' + _.get(poscan, 'Id') + '-' + fieldApiName}
              id={'poscan-select-' + fieldName}
              value={value}
              onChange={(event) => update(event.target.value)}
              input={<Input />}
              MenuProps={MenuProps}
              disabled={disabled}
            >
              {options.map(({ fullName }) => (
                <MenuItem key={fullName} value={fullName}>
                  {fullName}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        {inputType === 'textarea' && (
          <>
            <TextField
              label={fieldName}
              id={'poscan-textarea-' + fieldName}
              multiline
              value={value || ''}
              onChange={(event) => setValue(event.target.value)}
              onBlur={(event) => update(event.target.value)}
              variant="standard"
            />
          </>
        )}
      </FormControl>
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
    </>
  );
};

export const PortcoInterest = ({ poscanId, Portco_Interested__c }) => {
  const api = useAPI();
  const [interested, setInterested] = useState(Portco_Interested__c);
  const handleChange = (event) => {
    updatePortcoInterest(event.target.value);
  };

  const updatePortcoInterest = async (value) => {
    setInterested(value);
    await update_portco_interest({ poscanId, Portco_Interested__c: value }, api);
  };

  return (
    <FormControl>
      <RadioGroup aria-labelledby="poscan-interest" value={interested} name="poscan-interest" onChange={handleChange}>
        <FormControlLabel value={true} control={<Radio />} label="Interesting" />
        <FormControlLabel value={false} control={<Radio />} label="Not interesting" />
      </RadioGroup>
    </FormControl>
  );
};

export const RequestPriorityRadios = ({ poscanId, Priority__c }) => {
  const api = useAPI();
  const [priority, setPriority] = useState(Priority__c);

  const updatePoscanPriority = async (value) => {
    setPriority(value);
    await update_poscan_priority({ poscanId, Priority__c: value }, api);
  };
  const handleChange = (event) => {
    updatePoscanPriority(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup aria-labelledby="poscan-priority" value={priority} name="poscan-priority" onChange={handleChange}>
        {POISTION_CANDIDATE_PRIORITIES.map((p) => (
          <FormControlLabel
            value={p}
            key={poscanId + p}
            control={
              <Radio
                size={'small'}
                style={{
                  fontFamily: 'Merriweather Sans',
                  fontSize: '12px',
                  lineHeight: '22px',
                  color: '##262626',
                }}
              />
            }
            label={p}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const RequestPriorityCheckbox = ({ poscanId, Priority__c, extra }) => {
  const api = useAPI();
  const [priority, setPriority] = useState(Priority__c);
  const [isHigh, setIsHigh] = useState(priority === 'High'); //quick and dirty as we plan to kill the checkbox anyway
  const [updated, setUpdated] = useState(false);
  const updatePoscanPriority = async (value) => {
    setPriority(value);
    await update_poscan_priority(
      {
        poscanId,
        Priority__c: value,
        extra,
      },
      api,
    );
  };

  useEffect(() => {
    if (updated) {
      updatePoscanPriority(priority);
    }
    setUpdated(false);
  }, [priority, updatePoscanPriority, updated]);

  useEffect(() => {
    isHigh ? setPriority('High') : setPriority('Best Effort');
  }, [isHigh]);
  const handleChange = () => {
    setUpdated(true);
    setIsHigh(!isHigh);
  };

  return (
    <Tooltip title={'We will review your high priority requests before the rest'} placement={'bottom-start'}>
      <div>
        <StyledCheckbox size={'small'} checked={isHigh} onChange={handleChange} />
        <span>High priority</span>
      </div>
    </Tooltip>
  );
};

const _StatusSelect = ({ poscan, userState, width = '150px', status, setStatus }) => {
  const api = useAPI();
  const [disableStatus, setDisableStatus] = useState(false);
  const [potentialStatuses, setPotentials] = useState(
    getPotentialPoscanStatuses(
      _.pick(poscan, ['Status__c', 'Request_Type__c', 'Portco_Interested__c', 'Candidate_Interested__c']),
      is_admin(userState),
    ),
  );

  useEffect(() => {
    setPotentials(
      getPotentialPoscanStatuses(
        _.pick(poscan, ['Status__c', 'Portco_Interested__c', 'Request_Type__c', 'Candidate_Interested__c']),
        is_admin(userState),
      ),
    );
  }, [poscan, status, userState]);

  const updatePoscanStatus = async (value) => {
    setDisableStatus(true);
    setStatus(value);
    await update_poscan_status(
      {
        poscanId: poscan.Id,
        Status__c: value,
        extra: {
          Status__c: status,
          ..._.pick(poscan, ['Candidate_LinkedIn__c', 'Id', 'Candidate_Name__c', 'Position_Title__c']),
        },
      },
      api,
    );
    setDisableStatus(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menu_props = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  return (
    <FormControl style={{ width }}>
      <InputLabel id={'poscan-status-selector-input-label' + poscan.Id}>Status</InputLabel>
      <Select
        labelId={'poscan-status-selector-label' + poscan.Id}
        id={'poscan-status-selector' + poscan.Id}
        value={status}
        onChange={(event) => updatePoscanStatus(event.target.value)}
        input={<Input />}
        disabled={disableStatus}
        menu_props={menu_props}
      >
        {potentialStatuses.map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const StatusSelect = withUserState(_StatusSelect);

export function isActivePoscan({ Active__c }) {
  return Active__c === true;
}

export function getOptionalFiltersInternal({ intros, poscans }) {
  const counters = {
    Portco: _.countBy(poscans.map(({ Account__c }) => Account__c)),
    'Portco Owner': _.countBy(
      poscans
        .filter(({ Portco_Owner__r }) => !_.isEmpty(Portco_Owner__r))
        .map(({ Portco_Owner__r }) => _.get(Portco_Owner__r, 'Name')),
    ),
    'Current Company': _.countBy(poscans.map(({ Candidate_Account__c }) => Candidate_Account__c || 'None')),
  };
  return _.concat(
    _(poscans)
      .map(({ Account__c }) => ({ type: 'Portco', value: Account__c, count: _.get(counters, 'Portco')[Account__c] }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(poscans)
      .filter(({ Portco_Owner__r }) => !_.isEmpty(Portco_Owner__r))
      .map(({ Portco_Owner__r }) => ({
        type: 'Portco Owner',
        value: _.get(Portco_Owner__r, 'Name'),
        count: _.get(counters, 'Portco Owner')[_.get(Portco_Owner__r, 'Name')],
      }))
      .uniqBy('value')
      .orderBy(['count'], ['desc'])
      .value(),
    _(poscans)
      .map(({ Candidate_Account__c }) => ({
        type: 'Current Company',
        value: Candidate_Account__c || 'None',
        count: _.get(counters, 'Current Company')[Candidate_Account__c || 'None'],
      }))
      .uniqBy('value')
      .orderBy(['count'], ['desc'])
      .value(),
    _(intros)
      .filter(({ Status__c }) => !_.isEmpty(Status__c))
      .map(({ Status__c }) => ({ type: 'Intro Status', value: introStatusApiNameToLabel(Status__c) }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(poscans)
      .filter(({ Status__c }) => !_.isEmpty(Status__c))
      .map(({ Status__c }) => ({ type: 'Poscan Status', value: Status__c }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(intros)
      .filter(({ Aleph_Facilitator__r }) => !_.isEmpty(_.get(Aleph_Facilitator__r, 'Name')))
      .map(({ Aleph_Facilitator__r }) => ({ type: 'Aleph Facilitator', value: _.get(Aleph_Facilitator__r, 'Name') }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Ramzor_Score__c }) => ({ type: 'Ramzor', value: Ramzor_Score__c }))
      .uniqBy('value')
      .filter(({ value }) => !_.isNull(value))
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Request_Type__c }) => ({ type: 'Request Type', value: Request_Type__c }))
      .uniqBy('value')
      .filter(({ value }) => !_.isNull(value))
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Aleph_Owner__r }) => ({ type: 'Aleph Owner', value: _.get(Aleph_Owner__r, 'FirstName') || 'None' }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Candidate_Name__c }) => ({ type: 'Candidate', value: Candidate_Name__c }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Position_Title__c }) => ({ type: 'Position', value: Position_Title__c }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(intros)
      .filter(({ Connector__r }) => !_.isEmpty(_.get(Connector__r, 'Name')))
      .map(({ Connector__r }) => ({ type: 'Connector', value: _.get(Connector__r, 'Name') }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
  );
}

export function getOptionalCandidateFiltersPortcos({ poscans }) {
  return _.concat(
    _(poscans)
      .filter(
        ({ introStatusPortco, poscanStatusPortco }) => !_.isEmpty(introStatusPortco) || !_.isEmpty(poscanStatusPortco),
      )
      .map(({ introStatusPortco, poscanStatusPortco }) => ({
        type: 'Status',
        value: introStatusPortco || poscanStatusPortco,
      }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Ramzor_Score__c }) => ({ type: 'Likelihood To Connect', value: Ramzor_Score__c }))
      .uniqBy('value')
      .filter(({ value }) => !_.isNull(value))
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Request_Type__c }) => ({ type: 'Request Type', value: Request_Type__c }))
      .uniqBy('value')
      .filter(({ value }) => !_.isNull(value))
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Position_Title__c }) => ({ type: 'Position', value: Position_Title__c }))
      .uniqBy('value')
      .sortBy('value')
      .value(),

    _(poscans)
      .map(({ Candidate_Account__c }) => ({ type: 'Current Company', value: Candidate_Account__c || 'None' }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
    _(poscans)
      .map(({ Candidate_Name__c }) => ({ type: 'Candidate', value: Candidate_Name__c }))
      .uniqBy('value')
      .sortBy('value')
      .value(),
  );
}

export function assignRamzorRank(score) {
  if (score === 'We know the target') {
    return 1;
  } else if (score === 'High likelihood') {
    return 2;
  } else {
    return 3;
  }
}

export const getRequesetType = (poscanRequests) =>
  _(poscanRequests)
    .map(({ request }) => request)
    .uniq()
    .value();

export const getFilteredPoscansInternal = ({ poscans, filteredIntros, filters, intros: allIntros }) => {
  const candidateNameFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Candidate')
    .map(({ value }) => value);
  const positionFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Position')
    .map(({ value }) => value);
  const portcoFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Portco')
    .map(({ value }) => value);
  const currentCompanyFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Current Company')
    .map(({ value }) => value);
  const poscanStatusFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Poscan Status')
    .map(({ value }) => value);
  const alephOwnerFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Aleph Owner')
    .map(({ value }) => value);
  const ramzorFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Ramzor')
    .map(({ value }) => value);
  const requestTypeFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Request Type')
    .map(({ value }) => value);
  const portcoOwnerFilters = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Portco Owner')
    .map(({ value }) => value);

  const groupedByPoscan = _.groupBy(filteredIntros, 'Position_Candidate__r.Id');
  const groupedByPoscanNoFilters = _.groupBy(allIntros, 'Position_Candidate__r.Id');

  return (
    _(poscans)
      // some poscans don't have intros yet
      .map((p) => {
        const earliestIntro = _(_.get(groupedByPoscan, p.Id, []))
          .filter(({ Is_In_Play__c }) => !!Is_In_Play__c)
          .orderBy(['Next_Update_Request__c', 'asc'])
          .head();
        return {
          ...p,
          intros: getFilteredIntrosInternal({ intros: _.get(groupedByPoscan, p.Id, []), filters }),
          earliestDate: _.get(earliestIntro, 'Next_Update_Request__c'),
          ramzorRank: assignRamzorRank(_.get(p, 'Ramzor_Score__c')),
          earliestIntroScore: _.get(earliestIntro, 'talpodRank'),
        };
      })
      .filter(
        ({
          Candidate_Name__c,
          Position_Title__c,
          Account__c,
          Status__c,
          Candidate_Account__c,
          intros,
          Aleph_Owner__r,
          Candidate__r,
          Ramzor_Score__c,
          Request_Type__c,
          Portco_Owner__r,
        }) => {
          const introFilters = _.get(filters, 'search', [])
            .filter(({ type }) => _.includes(['Intro Status', 'Aleph Facilitator', 'Connector'], type))
            .map(({ value }) => value);

          return (
            (_.isEmpty(candidateNameFilters) || _.includes(candidateNameFilters, Candidate_Name__c)) &&
            (_.isEmpty(positionFilters) || _.includes(positionFilters, Position_Title__c)) &&
            (_.isEmpty(portcoFilters) || _.includes(portcoFilters, Account__c)) &&
            (_.isEmpty(currentCompanyFilters) || _.includes(currentCompanyFilters, Candidate_Account__c)) &&
            (_.isEmpty(poscanStatusFilters) || _.includes(poscanStatusFilters, Status__c)) &&
            (_.isEmpty(alephOwnerFilters) ||
              _.includes(alephOwnerFilters, _.get(Aleph_Owner__r, 'FirstName') || 'None')) &&
            (_.isEmpty(ramzorFilters) || _.includes(ramzorFilters, Ramzor_Score__c)) &&
            (_.isEmpty(requestTypeFilters) || _.includes(requestTypeFilters, Request_Type__c)) &&
            (_.isEmpty(portcoOwnerFilters) || _.includes(portcoOwnerFilters, _.get(Portco_Owner__r, 'Name'))) &&
            (_.isEmpty(introFilters) || !_.isEmpty(intros)) &&
            (_.isEmpty(_.get(Candidate__r, 'Main_Role__r.Start_Date__c')) ||
              (moment().diff(_.get(Candidate__r, 'Main_Role__r.Start_Date__c'), 'months') <= filters.monthsMax &&
                moment().diff(_.get(Candidate__r, 'Main_Role__r.Start_Date__c'), 'months') >= filters.monthsMin))
          );
        },
      ) //filter by intros by show all intros related to each relevant poscan
      .map((p) => ({
        ..._.omit(p, ['intros']),
        intros: _.get(groupedByPoscanNoFilters, p.Id, []),
      }))
      .value()
  );
};

export const MonthsSlider = ({ filters, setFilters, optionalRequestFilters }) => {
  const handleSliderChange = (event, newValue) => {
    const [monthsMin, monthsMax] = newValue;
    setFilters({
      ..._.omit(filters, ['monthsMin', 'monthsMax']),
      monthsMin,
      monthsMax,
    });
  };
  return (
    <FormControl style={{ width: '50%' }}>
      <InputLabel id="years-selector">Months Current Role</InputLabel>
      <Slider
        value={[filters.monthsMin, filters.monthsMax]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={1}
        min={optionalRequestFilters.monthsMin}
        max={optionalRequestFilters.monthsMax}
      />
    </FormControl>
  );
};

export const NextUpdateBefore = ({ filters, setFilters }) => {
  const handleNewDate = (event) => {
    const { value } = event.target;
    setFilters({
      ..._.omit(filters, ['nextUpdateBefore']),
      nextUpdateBefore: value,
    });
  };
  return (
    <FormControl>
      <InputLabel id="next-update-before-label"></InputLabel>
      <TextField
        id="datetime-next-update-before"
        label="Latest Next Update"
        type="datetime-local"
        value={filters.nextUpdateBefore || ''}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleNewDate}
      />
    </FormControl>
  );
};

export const NextUpdateAfter = ({ filters, setFilters }) => {
  const handleNewDate = (event) => {
    const { value } = event.target;
    setFilters({
      ..._.omit(filters, ['nextUpdateAfter']),
      nextUpdateAfter: value,
    });
  };
  return (
    <FormControl>
      <InputLabel id="next-update-after-label"></InputLabel>
      <TextField
        id="next-update-after"
        label="Earliest Next Update"
        type="datetime-local"
        value={filters.nextUpdateAfter || ''}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleNewDate}
      />
    </FormControl>
  );
};

export const CreatedDateAfter = ({ filters, setFilters }) => {
  const handleNewDate = (event) => {
    const { value } = event.target;
    setFilters({
      ..._.omit(filters, ['createdDateAfter']),
      createdDateAfter: value,
    });
  };
  return (
    <FormControl>
      <InputLabel id="next-update-after-label"></InputLabel>
      <TextField
        id="created-after"
        label="Created after (old requests)"
        type="datetime-local"
        value={filters.createdDateAfter || ''}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleNewDate}
      />
    </FormControl>
  );
};

export const NextPortcoFeedbackRequest = ({ poscanId, date }) => {
  const api = useAPI();
  const [newDate, setNewDate] = useState(date);
  const handleNewDate = (event) => {
    const { value } = event.target;
    setNewDate(value);
  };
  const [lastReq, setLastReq] = useState('');
  const hide_message = () => setLastReq('');

  const updateNewDate = async () => {
    if (!_.isEmpty(newDate)) {
      try {
        await update_poscan_next_feedback_request({ poscanId, date: newDate }, api);
        setLastReq('Next feedback reqest date was updated successfully');
      } catch (e) {
        setLastReq(`Ooops, looks like there was an error - ${e.message}`);
      }
    }
  };
  return (
    <>
      <FormControl style={{ width: '180px' }}>
        <InputLabel id="next-feedback-request-label"></InputLabel>
        <TextField
          id="next-feedback-request"
          label="Next Feedback Request"
          type="datetime-local"
          value={newDate}
          InputLabelProps={{
            shrink: true,
          }}
          size={'small'}
          onChange={handleNewDate}
          onBlur={updateNewDate}
        />
      </FormControl>
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
    </>
  );
};

export const RejectionReasonPoscan = ({ width, poscan, potentialRejectionReasons }) => {
  const options = potentialRejectionReasons;
  return (
    <PoscanFieldGenericInput
      fieldName={'Rejection Reason'}
      fieldApiName={'Rejection_Reason__c'}
      poscan={poscan}
      options={options}
      updateFunc={update_poscan_rejection}
      inputType={'select'}
      width={width}
    />
  );
};

export const OUTBOUND_REQUEST_TYPES = ['Ref check request', 'Interview with Aleph personnel', 'Intro request'];
export const INBOUND_REQUEST_TYPES = ['Referral'];
