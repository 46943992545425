import { Divider, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Immutable from 'immutable';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useLocalStorage } from '../misc/first_time_popup';
import { useAPI } from '../userContext';
import { get_aleph_users, get_potential_introducers } from './api';
import { BottomMenu } from './talent-connect-page/bottom-menu';
import { Target } from './talent-connect-page/targets';

export const TalentMain = () => {
  const api = useAPI();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const linkedinIdFromUrl = urlParams.get('linkedin_id');
  const requestSource = urlParams.get('request_source');
  const selectedPositionId = urlParams.get('position_id');
  const requestTypeUrl = urlParams.get('request_type') || 'Intro Request';
  const available = urlParams.get('available');
  const recCount = urlParams.get('rec-count');
  const fullNameFromUrl = decodeURIComponent(urlParams.get('fullName'));

  const [loadingTarget, setLoadingTarget] = useState(false);
  const [potentialTarget, setPotentialTarget] = useState([]);

  const [connectorsMap, setConnectorsMap] = useState({});
  const [selectedReferrersSet, setSelectedReferrersSet] = useState(Immutable.Set([]));
  const [selectedReferrers, setSelectedReferrers] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(undefined);

  const [reqCount, setReqCount] = useState(0);

  const [first_request_bottom_menu_tooltip_never_open, set_first_request_bottom_menu_tooltip_never_open] =
    useLocalStorage('aleph-connect-talent-bottom-menu-hide-tooltip', false);
  const [first_request_bottom_menu_tooltip_open, set_first_request_bottom_menu_tooltip_open] = useState(
    first_request_bottom_menu_tooltip_never_open ? false : true,
  );
  const handle_never_show_bottom_menu_toggle = (e) => {
    set_first_request_bottom_menu_tooltip_never_open(e.target.checked);
  };
  const close_bottom_menu_tooltip = () => set_first_request_bottom_menu_tooltip_open(false);

  useEffect(() => {
    const tempConnectorsMap = {};
    _.get(potentialTarget, 'pastColleagues', []).forEach((c) => (tempConnectorsMap[c.Linkedin_Profile__c] = c));
    setConnectorsMap(tempConnectorsMap);
  }, [potentialTarget]);

  const [selected_poscan_requests, set_selected_poscan_requests] = useState(Immutable.Set([]));
  const toggle_poscan_request = useCallback(
    ({ targetPublicId, ask }) =>
      () => {
        const targetString = JSON.stringify({ targetPublicId, ask });
        if (selected_poscan_requests.has(targetString)) {
          set_selected_poscan_requests(selected_poscan_requests.remove(targetString));
        } else {
          set_selected_poscan_requests(selected_poscan_requests.add(targetString));
        }
      },
    [],
  );

  useEffect(() => {
    if (requestTypeUrl) {
      toggle_poscan_request({ targetPublicId: linkedinIdFromUrl, ask: requestTypeUrl })();
    }
  }, [linkedinIdFromUrl, requestTypeUrl, toggle_poscan_request]);

  const [refCheckDetails, setRefCheckDetails] = useState('');
  const [personalizedMsg, setPersonalizedMsg] = useState('');
  const [comments, setComments] = useState('');
  const handleRefcheckDetails = (e) => setRefCheckDetails(e.target.value);
  const handlePersonalizedMsg = (e) => setPersonalizedMsg(e.target.value);
  const handle_comments = (e) => setComments(e.target.value);

  const [lastReq, setLastReq] = useState('');
  const hide_message = () => setLastReq('');

  const cleanRequestInfo = () => {
    set_selected_poscan_requests(Immutable.Set([]));
    setSelectedReferrersSet(Immutable.Set([]));
    setRefCheckDetails('');
    setPersonalizedMsg('');
    setComments('');
    setPoscanRequests([]);
  };

  const on_request = (e) => {
    setReqCount(reqCount + 1);
    if (_.isUndefined(e)) {
      setLastReq('Request Sent Successfully');
    } else {
      setLastReq('There was an error sending the request, please try again...');
    }
    // setShowSearchBox(true)
    cleanRequestInfo();
  };

  const [poscanRequests, setPoscanRequests] = useState([]);
  useEffect(() => {
    const tempRequests = [];
    selected_poscan_requests.forEach((targetString) => {
      const { targetPublicId, ask } = JSON.parse(targetString);
      const referrers = selectedReferrers.filter(
        ({ targetPublicId: refTarget, ask: refAsk }) => refTarget === targetPublicId && ask === refAsk,
      );
      tempRequests.push({
        ..._.pick(potentialTarget, [
          'Id',
          'name',
          'public_identifier',
          'headline',
          'Relationship_Category_Components__c',
          'chanceToConnect',
        ]),
        request: ask,
        referrers,
      });
    });
    setPoscanRequests(tempRequests);
  }, [selectedReferrers, selected_poscan_requests, potentialTarget]);

  const [alephUsers, setAlephUsers] = useState([]);
  useEffect(() => {
    async function fetch() {
      try {
        get_aleph_users(api).then(({ users }) => setAlephUsers(users));
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [api]);

  useEffect(() => {
    const contactToSearch = { public_identifier: linkedinIdFromUrl, source: 'ramzor', name: fullNameFromUrl };

    const fetchResults = async () => {
      if (_.isEmpty(contactToSearch)) {
        return;
      }
      setLoadingTarget(true);
      try {
        const res = await get_potential_introducers(
          { contactToSearch, source: 'ramzor', requestType: requestTypeUrl },
          api,
        );
        const t = res.target;
        setPotentialTarget({
          ...t,
          linkedin_profile: `https://www.linkedin.com/in/${contactToSearch.public_identifier}`,
          name: _.get(t, 'name', _.get(contactToSearch, 'name')),
        });
      } finally {
        setLoadingTarget(false);
      }
    };
    fetchResults();
  }, [api, fullNameFromUrl, linkedinIdFromUrl, requestTypeUrl]);

  return (
    <>
      <Grid container justifyContent="center">
        <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
          <Alert onClose={hide_message} severity={lastReq.includes('Success') ? 'success' : 'error'}>
            {lastReq}
          </Alert>
        </Snackbar>
        <Grid container justifyContent="center">
          {loadingTarget && <Typography>Searching...</Typography>}
          {
            <>
              {!_.isEmpty(potentialTarget) && (
                <>
                  <Grid container justifyContent="center" className="contact-list-area">
                    <Grid item md={9} sm={9} xs={12}>
                      <div
                        style={{
                          width: '100%',
                          fontFamily: 'Merriweather Sans',
                          fontWeight: 700,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#747474',
                        }}
                      >
                        {requestTypeUrl === 'Reference Check' ? 'Ask for Reference' : 'Request Intro'}
                      </div>
                      <Divider style={{ marginBottom: '38px', marginTop: '5px' }} />
                      <Grid>
                        <Target
                          target={potentialTarget}
                          alephUsers={alephUsers}
                          key={'target-' + potentialTarget.public_identifier}
                          selected_poscan_requests={selected_poscan_requests}
                          connectorsMap={connectorsMap}
                          setSelectedReferrersSet={setSelectedReferrersSet}
                          setSelectedReferrers={setSelectedReferrers}
                          toggle_poscan_request={toggle_poscan_request}
                          selectedReferrersSet={selectedReferrersSet}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <BottomMenu
                    poscanRequests={poscanRequests}
                    close_bottom_menu_tooltip={close_bottom_menu_tooltip}
                    first_request_bottom_menu_tooltip_never_open={first_request_bottom_menu_tooltip_never_open}
                    first_request_bottom_menu_tooltip_open={first_request_bottom_menu_tooltip_open}
                    handle_never_show_bottom_menu_toggle={handle_never_show_bottom_menu_toggle}
                    selectedPosition={selectedPosition}
                    refCheckDetails={refCheckDetails}
                    handleRefcheckDetails={handleRefcheckDetails}
                    personalizedMsg={personalizedMsg}
                    handlePersonalizedMsg={handlePersonalizedMsg}
                    setSelectedPosition={setSelectedPosition}
                    potentialTarget={potentialTarget}
                    on_request={on_request}
                    comments={comments}
                    handle_comments={handle_comments}
                    requestSource={requestSource}
                    selectedPositionId={selectedPositionId}
                    available={available}
                    recCount={recCount}
                  />
                </>
              )}

              {lastReq === 'Request Sent Successfully' && (
                <Redirect
                  to={{
                    pathname: '/talent/candidates',
                  }}
                  push={true}
                />
              )}
            </>
          }
        </Grid>
      </Grid>
    </>
  );
};
