import { Button, Checkbox, FormControlLabel, Grid, Snackbar, TextField } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { scrape_contacts } from '../talent/api';
import { useAPI } from '../userContext';

export const ScrapingPanel = ({ contacts, default_slack_text = '' }) => {
  const api = useAPI();
  const [minimum_freshness_in_days, setFreshness] = useState(31);
  const [disableScrape, setDisableScrape] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [save_to_ampliphy, setSaveToAmpliphy] = useState(false);
  const getUrlsByFreshness = () =>
    contacts
      .filter(({ last_scraper_enrichment_date__c = false }) => {
        return (
          !last_scraper_enrichment_date__c ||
          moment(last_scraper_enrichment_date__c).isBefore(moment().subtract(minimum_freshness_in_days, 'days'))
        );
      })
      .map((c) => c.linkedin_url);

  const [linkedinUrls, setLinkedinUrls] = useState(getUrlsByFreshness());

  const [slack_text, setSlackText] = useState(default_slack_text);

  const hide_message = () => setShowMessage(false);

  useEffect(() => setDisableScrape(false), [linkedinUrls]);
  useEffect(() => setLinkedinUrls(getUrlsByFreshness()), [minimum_freshness_in_days]);

  async function scrapeLeads() {
    setDisableScrape(true);
    setShowMessage(true);
    return scrape_contacts({ linkedinUrls, save_to_ampliphy, minimum_freshness_in_days, slack_text }, api);
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={save_to_ampliphy}
            onChange={(event) => setSaveToAmpliphy(event.target.checked)}
            name="save to ampliphy"
          />
        }
        label="save to ampliphy"
        style={{ marginRight: '40px' }}
      />
      <TextField
        type="number"
        id="freshness"
        label="Freshness in days"
        onChange={(e) => setFreshness(e.target.value)}
        style={{ width: '100px', marginRight: '20px' }}
        value={minimum_freshness_in_days}
      />
      <span style={{ marginRight: '20px' }}> {linkedinUrls.length} will be scraped</span>
      <Button onClick={scrapeLeads} color="primary" startIcon={<Send />} disabled={disableScrape}>
        {' '}
        get fresh profiles
      </Button>
      <Snackbar open={showMessage} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={'success'}>
          {`Scraping ${linkedinUrls.length} profiles...`}
        </Alert>
      </Snackbar>
    </div>
  );
};
