import { TextField, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-lodash-debounce';

import { useAPI, withUserState } from '../userContext';
import { get_positions_by_account_id } from './api';

const _PositionsSearchBox = ({
  onSelected,
  label,
  labelMin,
  accountId,
  userState,
  selectedPositionId,
  backgroundColor = 'none',
}) => {
  const api = useAPI();
  const [allOptions, setAllOptions] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 100);

  const [value, setValue] = useState('');
  const theme = useTheme();
  const small_screen = useMediaQuery(theme.breakpoints.down('xs'));

  const aleph_user_id = _.get(userState, 'aleph_user.Id');

  useEffect(() => {
    async function getPosition() {
      try {
        setLoading(true);
        if (accountId) {
          const res = await get_positions_by_account_id(accountId, api);
          setAllOptions(
            _(res.positions)
              .map((p) => ({
                ...p,
                isOwner: p.Owner__c === aleph_user_id ? '1 - Your Positions' : '2 - All',
              }))
              .orderBy(['isOwner', 'Name'], ['Asc', 'Asc'])
              .value(),
          );
        }
      } finally {
        setLoading(false);
      }
    }
    getPosition();
  }, [api, accountId, aleph_user_id]);

  useEffect(() => {
    setSearchOptions(allOptions);
    if (!!selectedPositionId) {
      const selected = allOptions.find((option) => option.Id === selectedPositionId);
      if (selected) {
        setInputValue(selected.Name);
        setValue(selected);
        onSelected(selected);
      }
    }
  }, [allOptions]);

  useEffect(() => {
    setLoading(true);
    if (debouncedInputValue === '') {
      setSearchOptions(allOptions);
    } else {
      setSearchOptions(
        _.isEmpty(debouncedInputValue)
          ? allOptions
          : allOptions.filter(({ Name }) => _.includes(Name.toLowerCase(), debouncedInputValue.toLowerCase())),
      );
    }
    setLoading(false);
  }, [debouncedInputValue, allOptions]);

  const handleChange = (event) => {
    const option = event.target.value;
    setInputValue(option.Name || option);
  };

  const handleSelected = (event, option) => {
    setInputValue(option ? option.Name : '');
    setValue(option);
    onSelected(option);
  };

  return (
    <Autocomplete
      style={{ width: '100%', backgroundColor }}
      getOptionLabel={(option) => (option.Name ? option.Name : '')}
      renderOption={(option) => <React.Fragment>{option.Name}</React.Fragment>}
      filterOptions={(x) => x}
      options={searchOptions}
      groupBy={(option) => option.isOwner}
      value={value}
      inputValue={inputValue}
      autoComplete
      onChange={handleSelected}
      selectOnFocus={false}
      loading={loading}
      freeSolo={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!small_screen ? <em>{label}</em> : <em>{labelMin}</em>}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
        />
      )}
    />
  );
};

export const PositionsSearchBox = withUserState(_PositionsSearchBox);
