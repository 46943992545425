import _ from 'lodash';

import { isAlephOrPortco } from './contacts';

export function showSharedWorkText(connector) {
  return _.get(connector, 'roles', []).length > 0;
}

export const isConnectorOwner = ({ owner_name, Contact_Name__c }) => {
  return Contact_Name__c === owner_name;
};

export function getRelationshipFromSignals(signals) {
  return (
    _.get(
      signals.find(({ Feedback_on_relationship__c }) => !_.isEmpty(Feedback_on_relationship__c)),
      'Feedback_on_relationship__c',
    ) || 'Unknown rel'
  );
}

export const getConnectorEmail = ({ email, private_email }) => email || private_email;

/*
with connector or with target when connector is from aleph
 */
export function getRelevantRelationship({ relationshipWithOwner, owner_name, Contact_Name__c, signals = [] }) {
  return isConnectorOwner({ owner_name, Contact_Name__c })
    ? getRelationshipFromSignals(signals)
    : relationshipWithOwner;
}

//assign referrer group
export const assignGroup = ({ c, selectedPoscan, userState }) => {
  const isSameAccount = _.includes(
    [_.get(selectedPoscan, 'Account__c'), _.get(userState, 'aleph_user.Account.Name')],
    _.get(c, 'Currrent_account_of_contact_role__c'),
  );
  if (isSameAccount) {
    return 'Same Account';
  } else if (isAlephOrPortco(c)) {
    return 'Portcos';
  } else {
    return 'Network';
  }
};
