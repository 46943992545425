import { Grid, Snackbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import { useQuery } from '../utils/misc';
import { MultiAccountSearchBox } from '../widgets/account-searchbox';
// import { FirstTimePopup } from '../misc/first_time_popup'
import { INV_SEARCH_ROUTES, SEARCH_ROUTES } from '../widgets/contact-query-builder';
import { ModeSelector } from '../widgets/modeselect';
import { BatchContactFinder } from './contacts';

export const BatchIntroRequestsMain = () => {
  const q = useQuery().get('q');
  const [accountList, setAccountList] = useState(q ? q.split('\n') : []);
  const [matchedAccountList, setMatchedAccountList] = useState([]);
  const [lastReq, setLastReq] = useState('');
  const [reqCount, setReqCount] = useState(0);
  const [exactMatch, setExactMatch] = useState(_.isEmpty(q) ? false : true);
  const theme = useTheme();
  const xs_screen = useMediaQuery(theme.breakpoints.down('xs'));
  const on_request = (e) => {
    if (_.isUndefined(e)) {
      setLastReq('Request Sent Successfully');
      setAccountList([]);
      setMatchedAccountList([]);
      setReqCount(reqCount + 1);
    } else {
      setLastReq('There was an error sending the request, please try again...');
    }
  };
  const onAccountListChange = (lst) => {
    setMatchedAccountList(lst.filter((x) => !!x.id));
    setAccountList(lst.filter((x) => !x.id).map((x) => x.trim()));
    setLastReq('');
    setExactMatch(false);
  };
  const searchAccountList = useDebounce(accountList, 1000);
  const hide_message = () => setLastReq('');

  const history = useHistory();
  const location = useLocation();
  const modeSelectorValue = INV_SEARCH_ROUTES[location.pathname];

  const handleSelected = (e, new_value) => {
    if (new_value !== modeSelectorValue) {
      history.push(SEARCH_ROUTES[new_value]);
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h1">Search Aleph Network</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Grid item xs={12} sm={12} md={10} lg={8} xl={6} style={{ textAlign: 'center' }}>
          <div className="searchbox-outer">
            {!xs_screen && (
              <ModeSelector options={_.keys(SEARCH_ROUTES)} handleSelected={handleSelected} value={modeSelectorValue} />
            )}
            <MultiAccountSearchBox
              onSelected={onAccountListChange}
              key={reqCount}
              initial_search={q}
              label={'Type or paste company names separated by line breaks'}
              labelMin={'Search Accounts'}
            />
          </div>
        </Grid>
      </Grid>
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('Success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
      <Grid container justifyContent="center">
        {(!_.isEmpty(searchAccountList) || !_.isEmpty(matchedAccountList)) && (
          <BatchContactFinder
            unmatchedAccounts={searchAccountList}
            matchedAccounts={matchedAccountList}
            on_request={on_request}
            exactMatch={exactMatch}
          />
        )}
      </Grid>
    </>
  );
};
