import React, { useContext, useEffect, useReducer, useState } from 'react';

import './App.css';
import './static/fonts/Gerstner-ProgrammMedium.woff2';

import { Toolbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';

import { AdvancedSearch, IndustrySearch } from './advanced-search';
import { get_api_caller, test_login } from './api';
import { DefpodLinkedin } from './dealflow';
import { Home } from './home';
import { BatchIntroRequestsMain } from './intro';
import { list_user_subscriptions } from './intro/api';
import { SalesDashboard } from './intro/dashboard';
import { CsvScrape } from './misc/csv-scrape';
import { PrivacyPolicy } from './misc/privacy_policy';
import { NotFound } from './notfound';
import { PathFinderMain } from './path-finder';
import { NoRamzorForYou, Ramzor } from './ramzor';
import { RelationshipGame } from './relationship-game';
import { SalesOpportunities } from './sales/opportunities';
import { SalesRecommendations } from './sales/recommendations';
import { Tutorial } from './sales/tutorial';
import { TalentMain } from './talent';
import { Candidate } from './talent/candidate';
import { TalpodDashboard } from './talent/dashboard/admin-panel';
import { OpenPositions } from './talent/dashboard/open-positions';
import { OpenRequests } from './talent/dashboard/open-requests';
import { ConnectorFeedback } from './talent/focus/feedback';
import { FocusFull } from './talent/focus/full';
import { FocusList } from './talent/focus/list';
import { FocusMini, NoFocusForYou } from './talent/focus/mini';
import { ConnectorRelatedTargets } from './talent/get-related-targets';
import { JobSeekers } from './talent/job-seekers';
import { KeyNodesReachouts } from './talent/key-nodes-reachouts';
import { Nemp } from './talent/nemp';
import { PortcoDashboard } from './talent/portco-dashboard/portco-dashboard';
import { ImpersonateController, is_defpod, LogoutController, userContext, userReducer } from './userContext';
import { AccountLinkManagement } from './users/accountlink';
import { GetAppToken } from './users/get-app-token';
import { WhatsappConnectedIndicator, WhatsappLogin } from './whatsapp';
import { WidgetsGrid } from './widgets';
import { ConnectAppBar, EmptyAppBar } from './widgets/bars';

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
  },
  appBar: {
    background: 'linear-gradient(90deg, #025750 0%, #00A394 75%);',
  },
}));

const theme = createTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        'min-width': '30px',
      },
    },
    MuiToolbar: {
      gutters: {
        '@media (min-width: 600px)': {},
      },
    },
  },
  typography: {
    h5: {
      fontFamily: 'Gerstner-Programm',
    },
    h4: {
      fontFamily: 'Gerstner-Programm',
    },
    h3: {
      fontFamily: 'Gerstner-Programm',
    },
    h2: {
      fontFamily: 'Gerstner-Programm',
    },
    h1: {
      fontFamily: 'Gerstner-Programm',
    },
    fontFamily: "'Merriweather Sans', sans-serif",
  },
  palette: {
    primary: {
      main: '#00A394',
    },
    secondary: {
      main: '#025750',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

function Main({ authenticated, user, toggleAdmin, adminOff }) {
  const classes = useStyles();
  return authenticated === true ? (
    <Router>
      <Switch>
        <Route path="/ramzor">
          <Ramzor />
        </Route>
        <Route path="/whatsapp/login">
          <WhatsappConnectedIndicator />
        </Route>
        <Route path="/talent/portco-connectors-mini">
          {is_defpod(user) && <DefpodLinkedin />}
          {!is_defpod(user) && <FocusMini />}
        </Route>
        <Route path="/friend-or-foe">
          <div className="App">
            <ConnectAppBar classes={classes} user={user} toggleAdmin={toggleAdmin} adminOff={adminOff} />
            <Toolbar classes={classes} />
            <main className={classes.content}>
              <RelationshipGame />
            </main>
          </div>
        </Route>
        <Route path="/talent/focus/connector-feedback-redirect">
          <ConnectorFeedback />
        </Route>
        <Route path="*">
          <div className={_.get(user, 'fake', false) ? 'App FakeUser' : 'App'}>
            <ConnectAppBar classes={classes} user={user} toggleAdmin={toggleAdmin} adminOff={adminOff} />
            <Toolbar />
            <main className={classes.content}>
              <WidgetsGrid />
              <Switch>
                <Route path="/intro/dashboard">
                  <SalesDashboard />
                </Route>
                <Route path="/intro">
                  <BatchIntroRequestsMain />
                </Route>
                <Route path="/batch-intro">
                  <BatchIntroRequestsMain />
                </Route>
                <Route path="/talent/open-requests">
                  <OpenRequests />
                </Route>
                <Route path="/talent/candidates">
                  <PortcoDashboard />
                </Route>
                <Route path="/talent/key-nodes-reachouts">
                  <KeyNodesReachouts />
                </Route>
                <Route path="/talent/match-positions">
                  <Candidate />
                </Route>
                <Route path="/talent/open-positions">
                  <OpenPositions />
                </Route>
                <Route path="/talent/job-seekers">
                  <JobSeekers />
                </Route>
                <Route path="/talent/related-targets">
                  <ConnectorRelatedTargets />
                </Route>
                <Route path="/talent/dashboard">
                  <TalpodDashboard />
                </Route>
                <Route exact path="/talent/focus">
                  <FocusFull />
                </Route>
                <Route path="/talent/focus/list">
                  <FocusList />
                </Route>
                <Route path="/talent">
                  <TalentMain />
                </Route>
                <Route path="/sales/recommended">
                  <SalesRecommendations />
                </Route>
                <Route path="/sales/opportunities">
                  <SalesOpportunities />
                </Route>
                <Route path="/sales/tutorial">
                  <Tutorial />
                </Route>
                <Route path="/advanced">
                  <AdvancedSearch />
                </Route>
                <Route path="/industry">
                  <IndustrySearch />
                </Route>
                <Route path="/link-account">
                  <AccountLinkManagement />
                </Route>
                <Route path="/logout">
                  <LogoutController />
                </Route>
                <Route path="/login-as">
                  <ImpersonateController />
                </Route>
                <Route path="/get-app-token">
                  <GetAppToken />
                </Route>
                <Route path="/nemp">
                  <Nemp />
                </Route>
                <Route path="/csv-scrape">
                  <CsvScrape />
                </Route>
                <Route path="/path-finder">
                  <PathFinderMain />
                </Route>
                <Route path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route path="/">
                  <BatchIntroRequestsMain />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </main>
          </div>
        </Route>
      </Switch>
    </Router>
  ) : (
    <Router>
      <Switch>
        <Route path="/ramzor">
          <NoRamzorForYou />
        </Route>
        <Route path="/whatsapp/login">
          <WhatsappLogin />
        </Route>
        <Route path="/talent/portco-connectors-mini">
          <NoFocusForYou />
        </Route>
        <Route path="/talent/focus/connector-feedback-redirect">
          <ConnectorFeedback />
        </Route>
        <Route path="*">
          <div className="App">
            <main className={classes.content}>
              <Switch>
                <Route path="/privacy-policy">
                  <EmptyAppBar classes={classes} />
                  <Toolbar />
                  <PrivacyPolicy />
                </Route>
                <Route path="*">
                  <Home authenticated={authenticated} />
                </Route>
              </Switch>
            </main>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

function App() {
  const [userState, userStateDispatcher] = useReducer(userReducer, useContext(userContext));
  const token = _.get(userState, 'alephToken');
  const [adminOff, toggleAdmin] = useState(false);
  useEffect(() => {
    const check_login = async (token) => {
      const api = get_api_caller(token, userStateDispatcher);
      const user = await test_login(api);
      userStateDispatcher({
        type: 'setUser',
        payload: {
          user,
        },
      });
      const { subscribed_accounts } = await list_user_subscriptions(api);
      userStateDispatcher({
        type: 'setAccountSubscriptionList',
        payload: {
          subscribed_accounts: subscribed_accounts,
        },
      });
      const { sales_target } = await api('sales-targets/default', { method: 'GET' });
      userStateDispatcher({
        type: 'setDefaultSalesTarget',
        payload: {
          default_sales_target: sales_target,
        },
      });
    };
    if (token) {
      check_login(token);
    }
  }, [token]);

  return (
    <userContext.Provider value={{ userState, adminOff, userStateDispatcher }}>
      <userContext.Consumer>
        {({ userState }) => {
          const { authenticated, aleph_user } = userState;
          return (
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Main authenticated={authenticated} user={aleph_user} toggleAdmin={toggleAdmin} adminOff={adminOff} />
            </ThemeProvider>
          );
        }}
      </userContext.Consumer>
    </userContext.Provider>
  );
}

export default App;
