import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, { useState } from 'react';

const title_keywords = require('./title-keywords.json');

export const TitleSearch = ({ onSelected, label, variant, initialValue = [] }) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    const text = event.target.value;
    const lines = text.split('\n');
    const search = lines.pop(-1);
    const updated_value = _.concat(
      value,
      lines.map((x) => (_.isString(x) ? x.trim() : x)).filter((x) => x),
    );
    setValue(updated_value);
    setInputValue(search);
    if (!search.trim()) {
      onSelected(updated_value);
    }
    return false;
  };
  const handleSelected = (event, value) => {
    const filtered_value = value.map((x) => x.trim());
    onSelected(filtered_value);
    setValue(filtered_value);
    setInputValue('');
  };

  return (
    <Autocomplete
      className="searchbox"
      style={
        variant === 'fullWidth'
          ? { width: '100%' }
          : { width: 'calc(80% - 1em)', display: 'inline-flex', marginLeft: '1em' }
      }
      value={value}
      inputValue={inputValue}
      options={title_keywords.keywords}
      multiple
      freeSolo
      autoComplete
      openOnFocus={false}
      onChange={handleSelected}
      selectOnFocus={false}
      disableCloseOnSelect
      blurOnSelect={true}
      disablePortal={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          onChange={handleChange}
          inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
          multiline
          lines={1}
          InputLabelProps={{ disableAnimation: true, shrink: !(_.isEmpty(value) && _.isEmpty(inputValue)) }}
        />
      )}
    />
  );
};
