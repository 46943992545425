import { Grid } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useAPI } from '../userContext';
import { SKILLS } from '../utils/sf-consts';
import { get_all_job_seekers } from './api';

export const JobSeekers = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);

  const [jobSeekers, setJobSeekers] = useState(undefined);
  const [filteredJobSeekers, setFilteredJobSeekers] = useState(undefined);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        await get_all_job_seekers(api).then(({ jobSeekers: j }) => setJobSeekers(j));
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [api]);

  useEffect(() => {
    console.log(jobSeekers);
  }, [jobSeekers]);

  return <div>]ob seekers</div>;
};
