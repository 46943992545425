import { Button } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useAPI } from '../../userContext';
import { getRequesetType } from '../../utils/poscan-utils';
import { send_poscan_request, send_slack_or_email } from '../../widgets/api';

export function SubmitPoscanButton({
  poscanRequests,
  personalizedMsg,
  comments,
  refCheckDetails,
  selectedPosition,
  on_request,
  requestSource,
  recCount,
  available,
}) {
  const api = useAPI();

  const [requestTypes, SetRequestTypes] = useState(getRequesetType(poscanRequests));
  const [submittingRequest, setSubmittingRequest] = useState(false);
  const isDisabled =
    submittingRequest ||
    _.isEmpty(poscanRequests) ||
    _.isEmpty(selectedPosition) ||
    (getRequesetType(poscanRequests).includes('Reference Check') && _.isEmpty(refCheckDetails)) ||
    (getRequesetType(poscanRequests).includes('Intro Request') && _.isEmpty(personalizedMsg));

  useEffect(() => {
    SetRequestTypes(getRequesetType(poscanRequests));
  }, [poscanRequests, selectedPosition]);
  const createPoscanRequest = async () => {
    try {
      setSubmittingRequest(true);
      await send_poscan_request(
        {
          selectedPosition,
          target:
            poscanRequests.length === 1
              ? _.head(poscanRequests)
              : _.find(poscanRequests, ({ request }) => request !== 'Reference Check'),
          Reference_Check_Details__c: refCheckDetails,
          personalized_message_for_candidate__c: personalizedMsg,
          comments,
          requestSource,
          available,
          recCount,
        },
        api,
      );
      on_request();
      setSubmittingRequest(true);
    } catch (e) {
      console.log('ERROR', e);
      on_request(e);
    }
  };
  return (
    <>
      <Button
        onClick={createPoscanRequest}
        disabled={isDisabled}
        size="small"
        color="primary"
        variant="contained"
        startIcon={<Send />}
      >
        Submit
      </Button>
    </>
  );
}

export function AlephRequestButton({
  target,
  connector,
  comments,
  on_request,
  channel,
  selectedSource,
  selectedUser,
  isDisabled,
  introGoal,
  selectedPoscan,
  setIsDisabled,
}) {
  const api = useAPI();

  const slackOrEmail = async () => {
    try {
      setIsDisabled(true);
      await send_slack_or_email(
        { target, comments, connector, channel, selectedUser, selectedSource, introGoal, poscan: selectedPoscan },
        api,
      );
      on_request();
    } catch (e) {
      console.log('ERROR', e);
      on_request(e);
    }
  };
  return (
    <>
      <Button
        onClick={slackOrEmail}
        size="large"
        color="primary"
        variant="contained"
        disabled={isDisabled}
        startIcon={<Send />}
      ></Button>
    </>
  );
}
