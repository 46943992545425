import { Collapse, IconButton, Link } from '@material-ui/core';
import { ArrowDropDown, ArrowRight, KeyboardArrowDown, KeyboardArrowUp, LinkedIn } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { salesforce_contact_link, salesforce_signal_link } from './misc';

export function getRelationBySignalType(type) {
  if (['Linkedin Connection', 'Introduction', 'Gmail Contacts', 'Facebook Friend'].includes(type)) {
    return 'of';
  } else if (['Relationship'].includes(type)) {
    return 'with';
  } else {
    return 'by';
  }
}

export function getDirectionTextBySignal({ Direction__c, Type__c }) {
  return Direction__c === 'Positive' ? (
    <KeyboardArrowUp style={{ color: 'green' }} />
  ) : Direction__c === 'Negative' ? (
    <KeyboardArrowDown style={{ color: 'red' }} />
  ) : (
    ''
  );
}

export function getSignalTypeText(type) {
  return type === 'Gmail Contacts' ? 'Gmail Contact' : type;
}

export function connectorSignal({ Signalling_Contact_Name__c, Direction__c, Type__c, Feedback_on_relationship__c }) {
  return _.compact([
    Direction__c,
    getSignalTypeText(Type__c),
    getRelationBySignalType(Type__c),
    Signalling_Contact_Name__c,
    Feedback_on_relationship__c ? `(${Feedback_on_relationship__c})` : '',
  ]).join(' ');
}

export const SignalItem = ({ s, showSignallingContact, showLinks, onlyFirstName }) => {
  const signalingContactName = onlyFirstName
    ? _.head(_.get(s, 'Signalling_Contact_Name__c').split(' '))
    : _.get(s, 'Signalling_Contact_Name__c');
  return (
    <span key={'signal-span' + _.get(s, 'Id')}>
      {getDirectionTextBySignal(s)}
      {getSignalTypeText(_.get(s, 'Type__c'))}
      {!_.isNull(_.get(s, 'Feedback_on_relationship__c')) && (
        <>
          <span style={{ marginRight: '5px', marginLeft: '5px', fontWeight: 300, color: '#7E7E7E', fontSize: '11px' }}>
            {' '}
            &#x25CF;{' '}
          </span>
          {_.get(s, 'Feedback_on_relationship__c')}
        </>
      )}
      {showSignallingContact && (
        <>
          {` ${getRelationBySignalType(_.get(s, 'Type__c'))}`}
          {showLinks ? (
            <>
              <Link
                href={salesforce_contact_link(_.get(s, 'Signalling_Contact__c'))}
                target="_blank"
                style={{ marginLeft: '12px' }}
              >
                {signalingContactName}
              </Link>
              <Link
                href={_.get(s, 'Linkedin_Profile_Signalling_Contact__c')}
                target="_blank"
                style={{ marginLeft: '12px' }}
              >
                <LinkedIn fontSize="small" />
              </Link>
            </>
          ) : (
            <span> {signalingContactName} </span>
          )}
        </>
      )}
      {_.get(s, 'Type__c') === 'Linkedin Connection' && !_.isEmpty(_.get(s, 'Signal_Date__c')) && (
        <>
          <span style={{ marginRight: '5px', marginLeft: '5px', fontWeight: 300, color: '#7E7E7E', fontSize: '11px' }}>
            {' '}
            &#x25CF;{' '}
          </span>
          since {moment(_.get(s, 'Signal_Date__c')).format('MMM YYYY')}
        </>
      )}
      {_.get(s, 'Details__c')}
    </span>
  );
};

export const SignalsListRows = ({ signals, title }) => {
  const [showSignals, SetShowSignals] = useState(signals.length === 1);
  return (
    <>
      <IconButton aria-label="expand row" size="small" onClick={() => SetShowSignals(!showSignals)}>
        {showSignals ? (
          <ArrowDropDown style={{ verticalAlign: 'middle' }} />
        ) : (
          <ArrowRight style={{ verticalAlign: 'middle' }} />
        )}
      </IconButton>{' '}
      {title || 'Show Signals'} ({signals.length})
      <Collapse in={showSignals} timeout="auto" unmountOnExit>
        <div>
          {signals.map((s) => (
            <div key={_.get(s, 'Id')}>
              {getDirectionTextBySignal(s)}
              <b>
                <Link href={salesforce_signal_link(_.get(s, 'Id'))} target="_blank">
                  {_.get(s, 'Type__c')}
                </Link>
              </b>
              <br />
              {getRelationBySignalType(_.get(s, 'Type__c'))}{' '}
              <Link
                href={salesforce_contact_link(_.get(s, 'Signalling_Contact__r.Id'))}
                target="_blank"
                style={{ marginLeft: '12px' }}
              >
                {_.get(s, 'Signalling_Contact__r.Name')}
              </Link>
              <Link
                href={_.get(s, 'Signalling_Contact__r.Linkedin_Profile__c')}
                target="_blank"
                style={{ marginLeft: '12px' }}
              >
                <LinkedIn fontSize="small" />
              </Link>
              <br />
              {_.get(s, 'Details__c')}
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
