import _ from 'lodash';
import React, { useEffect } from 'react';

import aleph_png from '../static/aleph.png';
import { LoginController, useAPI, withUserState } from '../userContext';
import { sync_whatsapp_groups } from './api';

export const WhatsappConnectedIndicator = withUserState(({ userStateDispatcher }) => {
  const api = useAPI();
  useEffect(() => {
    const handleMessage = async (event) => {
      console.log('Message received:', event.data);
      // Check the origin of the sender to ensure it's trusted
      if (event.origin === 'https://web.whatsapp.com') {
        if (event.data.type === 'group_sync') {
          // Update state based on the received message
          const { data } = event.data;
          const { groups, contacts } = data;
          const groups_meta = groups.map((group) => ({ id: group.id, name: group.name }));
          const group_participants = _.flatten(
            groups.map((group) =>
              group.full_participants.map((participant) => ({
                phone_number: participant.phone_number,
                group_id: group.id,
              })),
            ),
          );
          const users_meta = _.toPairs(contacts).map(([phone_number, contact]) => ({
            phone_number,
            name: contact.name,
            pushname: contact.pushname,
          }));
          await sync_whatsapp_groups({ groups_meta, group_participants, users_meta }, api);
        }
      }
    };

    // Add message listener
    window.addEventListener('message', handleMessage);

    // Clean up listener
    return () => window.removeEventListener('message', handleMessage);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const logout = () => {
    const confirm = window.confirm('Are you sure you want to disconnect from Ampliphy?');
    if (confirm) {
      userStateDispatcher({ type: 'logoutUser' });
    }
  };
  return (
    <div onClick={logout} style={{ display: 'flex', alignItems: 'center' }}>
      <img height="40px" width="40px" src={aleph_png} alt={'ampliphy'} style={{ marginRight: '2px' }} />
      <div style={{ marginLeft: '2px', width: '90px', lineHeight: '13px', fontSize: '10px' }}>Ampliphy Connected</div>
    </div>
  );
});

export const WhatsappLogin = () => {
  return (
    <div style={{ backgroundColor: 'transparent' }}>
      <div style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
        <LoginController theme={'outline'} type={'icon'} shape={'square'} size={'medium'} text="signin" width="70" />
        <div style={{ marginLeft: '2px', width: '90px', lineHeight: '13px' }}>Connect to Ampliphy</div>
      </div>
    </div>
  );
};
