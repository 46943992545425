import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Snackbar,
  TextField,
  Toolbar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import parse from 'html-react-parser';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { send_intro_request } from '../intro/api';
import { useAPI, useUserState } from '../userContext';
import { IntroRequestTooltip } from '../widgets/tooltips';

const StyledButton = withStyles((theme) => ({
  root: {
    borderRadius: '18px',
    textTransform: 'none',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    lineHeight: 'normal',
  },
}))(Button);

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    padding: 'unset',
  },
}))((props) => <DialogTitle disableTypography {...props} />);

const MergeField = ({ label, value, rows, onChange }) => {
  const [editMode, setEditMode] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  const handleSave = () => {
    onChange(tempValue);
    setEditMode(false);
  };

  return (
    <>
      <span className="merge-field" onClick={() => setEditMode(true)}>
        {value}
      </span>
      <IntroRequestTooltip title={label} placement={'top'}>
        <i className="edit-icon" onClick={() => setEditMode(true)}></i>
      </IntroRequestTooltip>
      <Dialog open={editMode} onClose={handleSave} maxWidth="md" fullWidth>
        <DialogContent>
          <StyledDialogTitle>{label}</StyledDialogTitle>
          <TextField
            autoFocus
            margin="dense"
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            multiline={rows > 1}
            minRows={rows}
            fullWidth
            InputProps={{ disableUnderline: true }}
            inputProps={{
              style: {
                lineHeight: '1.5em',
                border: '1px solid rgba(0, 0, 0, 0.20)',
                borderRadius: '6px',
                display: 'inline-flex',
                padding: '4px 104px 5px 10px',
                alignItems: 'center',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
              },
            }}
          />
        </DialogContent>
        <DialogActions style={{ paddingBottom: '26px', paddingLeft: '24px', paddingRight: '24px' }}>
          <StyledButton color="primary" variant="contained" onClick={handleSave} disableElevation>
            Save
          </StyledButton>
          <StyledButton color="primary" variant="outlined" onClick={() => setEditMode(false)} disableElevation>
            Cancel
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const IntroRequestDialog = ({
  open,
  sales_target,
  path,
  account_id,
  account_name,
  requesting_contact_id,
  requesting_account_id,
  sales_recommendation_id,
  source,
  on_submit,
  on_cancel,
}) => {
  const api = useAPI();
  const [step, set_step] = useState(0);
  const [sending, set_sending] = useState(false);
  const [error, set_error] = useState(null);
  const [templateFields, setTemplateFields] = useState({
    One_Liner__c: _.get(sales_target, 'One_Liner__c', ''),
    Value_Proposition__c: _.get(sales_target, 'Value_Proposition__c', ''),
    Decision_Makers__c: _.get(sales_target, 'Decision_Makers__c', ''),
    Ask__c: _.get(sales_target, 'Ask__c', ''),
    Intro_Text__c: _.get(sales_target, 'Intro_Text__c', ''),
    Case_Studies__c: _.get(sales_target, 'Case_Studies__c', ''),
  });
  useEffect(() => {
    setTemplateFields({
      One_Liner__c: _.get(sales_target, 'One_Liner__c', ''),
      Value_Proposition__c: _.get(sales_target, 'Value_Proposition__c', ''),
      Decision_Makers__c: _.get(sales_target, 'Decision_Makers__c', ''),
      Ask__c: _.get(sales_target, 'Ask__c', ''),
      Intro_Text__c: _.get(sales_target, 'Intro_Text__c', ''),
      Case_Studies__c: _.get(sales_target, 'Case_Studies__c', ''),
    });
  }, [sales_target]);

  const handleTemplateFieldChange = useCallback(
    (field, value) => {
      setTemplateFields({
        ...templateFields,
        [field]: value,
      });
    },
    [templateFields],
  );
  const email_template = _.get(sales_target, 'Sample_Email_Draft__c', '');
  const TEMPLATE_FIELDS = useMemo(
    () => ({
      One_Liner__c: {
        label: "Your company's expertise/one-liner",
        defaultValue: _.get(sales_target, 'One_Liner__c', undefined),
      },
      Value_Proposition__c: {
        label: 'What is the value prop to the target company? Why now?',
        defaultValue: _.get(sales_target, 'Value_Proposition__c', undefined),
        rows: 4,
      },
      Ask__c: {
        label: 'What would you like to discuss with the target contact?',
        defaultValue: _.get(sales_target, 'Ask__c', undefined),
        rows: 4,
      },
      Decision_Makers__c: {
        label: 'Who is the decision maker(s) (Name/Role/Linkedin URL)?',
        defaultValue: _.get(sales_target, 'Decision_Makers__c', undefined),
      },
      Case_Studies__c: {
        label: 'Any specific case studies you’d like to share?',
        defaultValue: _.get(sales_target, 'Case_Studies__c', undefined),
        rows: 4,
      },
      Intro_Text__c: {
        label: 'Relevant blurb about your company',
        defaultValue: _.get(sales_target, 'Intro_Text__c', undefined),
        rows: 6,
      },
    }),
    [sales_target],
  );

  const preprocessed_email_template = (email_template || '').replace(/{{(.*?)}}/g, (match, field) => {
    if (_.has(TEMPLATE_FIELDS, field)) {
      const id = `merge-field-${field}`;
      return `<span id="${id}" class="merge-field"></span>`;
    }
    if (field === 'account_name') {
      return account_name;
    }
  });
  const fields_found_in_template = preprocessed_email_template.match(/merge-field-(\w+)/g) || [];
  // make sure each fields in TEMPLATE_FIELDS is found in the template
  const all_fields_found = _.every(_.keys(TEMPLATE_FIELDS), (field) =>
    fields_found_in_template.includes(`merge-field-${field}`),
  );
  const missing_fields_in_template = _.difference(
    _.keys(TEMPLATE_FIELDS),
    _.map(fields_found_in_template, (field) => field.replace('merge-field-', '')),
  );
  if (missing_fields_in_template.length > 0) {
    console.warn('Missing fields in email template:', missing_fields_in_template);
  }
  const use_email_template = !_.isEmpty(email_template) && all_fields_found;

  const request_intro = async (event) => {
    try {
      set_sending(true);
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries(formData.entries());
      const { prior_interaction, customer_tier, who_to_introduce, extra_comments } = formJson;
      const {
        One_Liner__c: oneliner,
        Value_Proposition__c: value_proposition,
        Ask__c: ask,
        Decision_Makers__c: decision_maker,
        Case_Studies__c: case_studies,
        Intro_Text__c: blurb,
      } = templateFields;
      console.log('requesting intro', {
        account_id,
        account_name,
        requesting_contact_id,
        requesting_account_id,
        path,
        source,
        ...formJson,
      });
      const comments_field = `
Value prop? Why now?
${value_proposition}
---
What would you like to discuss with the target?
${ask}
---
Relevant blurb?
${blurb}
---
One-liner?
${oneliner}
---
Decision maker(s)?
${decision_maker}
---
Case studies?
${case_studies}
---
Any prior interactions?
${prior_interaction}
---
Customer tier & deal size?
${customer_tier}
---
Who to introduce?
${who_to_introduce}
---
Any other comments?
${extra_comments}
            `;
      const why = `${ask}
            ${value_proposition}
            `;
      const sales_target_id = _.get(sales_target, 'Id');
      await send_intro_request(api, account_id, {
        account_name,
        requesting_contact_id,
        requesting_account_id,
        comments: comments_field,
        path,
        why,
        sales_recommendation_id,
        sales_target_id,
        source,
      });

      on_submit();
      set_sending(false);
    } catch (error) {
      console.error('Error requesting intro', error);
      set_error(error.message);
      set_sending(false);
    }
  };
  const on_close = () => {
    set_sending(false);
    set_error(null);
    on_cancel();
  };
  return (
    <Drawer
      style={{ zIndex: 1000 }}
      anchor="right"
      open={open}
      onClose={on_close}
      PaperProps={{
        component: 'form',
        onSubmit: request_intro,
        style: { width: '42%' },
      }}
      ModalProps={{
        disablePortal: false,
      }}
    >
      <Toolbar />
      <div
        style={{
          padding: '31px 48px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          flexShrink: '0',
          textAlign: 'justify',
          height: 'calc(100vh - 64px)',
        }}
      >
        <div
          style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '20px', flexShrink: '0' }}
        >
          <div>
            <h2 style={{ marginBottom: '10px' }}>Request a Warm Introduction to {account_name}</h2>
            {step === 0 && use_email_template ? (
              <p
                style={{
                  marginTop: '0',
                  fontWeight: '500',
                  color: 'rgba(0, 0, 0, 0.40)',
                  width: '90%',
                  lineHeight: '150%',
                }}
              >
                <p>
                  <b> Please edit the highlighted sections of this draft:</b>
                </p>
                <p>
                  Focus on your product's relevance and offerings, aligning them with {account_name}'s interests and
                  needs. Emphasize the benefits of connecting.
                </p>
              </p>
            ) : (
              <p style={{ marginTop: '0', fontWeight: '500', color: 'rgba(0, 0, 0, 0.40)', width: '88%' }}>
                Please fill in the details below.
                <br />
                This will serve to identify the ideal approach to address them.
              </p>
            )}
          </div>
          {error && <Alert severity="error">Error: {error}</Alert>}
          {step === 0 && use_email_template && (
            <>
              <div
                className="email-template-box"
                style={{
                  borderRadius: '10px',
                  background: '',
                  paddingRight: '1rem',
                  paddingLeft: '1rem',
                  paddingTop: 0,
                  paddingBottom: 0,
                  textAlign: 'left',
                }}
              >
                {parse(preprocessed_email_template, {
                  replace: (domNode) => {
                    if (domNode.type === 'tag' && domNode.name === 'span' && domNode.attribs.id) {
                      const field = domNode.attribs.id.replace('merge-field-', '');
                      return (
                        <MergeField
                          key={domNode.attribs.id}
                          label={TEMPLATE_FIELDS[field].label}
                          value={templateFields[field]}
                          rows={TEMPLATE_FIELDS[field].rows}
                          onChange={(value) => handleTemplateFieldChange(field, value)}
                        />
                      );
                    }
                  },
                })}
              </div>
            </>
          )}
          {step === 0 &&
            !use_email_template &&
            _.keys(TEMPLATE_FIELDS).map((field) => (
              <TextField
                key={field}
                name={field}
                label={TEMPLATE_FIELDS[field].label}
                value={templateFields[field]}
                fullWidth
                required
                multiline={TEMPLATE_FIELDS[field].rows > 1}
                maxRows={TEMPLATE_FIELDS[field].rows}
                onChange={(e) => handleTemplateFieldChange(field, e.target.value)}
              />
            ))}
          {step === 1 && (
            <>
              <TextField
                name="prior_interaction"
                label="List prior interactions with the company (if any)"
                multiline
                rows={2}
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                name="customer_tier"
                label="Enter costumer tier and average expected deal size"
                multiline
                rows={2}
                fullWidth
                required
                variant="outlined"
              />
              <TextField
                name="who_to_introduce"
                label="Who to introduce in case of a succesful reachout (Name & Email)?"
                multiline
                rows={2}
                fullWidth
                variant="outlined"
              />
              <TextField
                name="extra_comments"
                label="Any other relevant info you'd like to share?"
                multiline
                rows={2}
                fullWidth
                variant="outlined"
              />
            </>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px', height: '50px' }}>
          {step === 1 ? (
            <>
              <StyledButton color="primary" variant="contained" type="submit" disabled={sending} disableElevation>
                {sending ? 'Sending...' : 'Submit Request'}
              </StyledButton>
              <StyledButton color="primary" variant="outlined" onClick={() => set_step(0)} disableElevation>
                Previous
              </StyledButton>
              <StyledButton color="primary" variant="outlined" onClick={on_close} disabled={sending} disableElevation>
                Cancel
              </StyledButton>
            </>
          ) : (
            <>
              <StyledButton color="primary" variant="contained" onClick={() => set_step(1)} disableElevation>
                Next Step
              </StyledButton>
              <StyledButton color="primary" variant="outlined" onClick={on_close} disabled={sending} disableElevation>
                Cancel
              </StyledButton>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export const IntroRequestButton = ({
  account_id,
  account_name,
  sales_target,
  path,
  requesting_contact_id,
  requesting_account_id,
  sales_recommendation_id,
  source,
  on_submit,
  style,
  button_text = 'Get Intro',
}) => {
  const [open_snackbar, set_open_snackbar] = React.useState(false);
  const [show_intro, set_show_intro] = React.useState(false);
  const { userState } = useUserState();
  const { default_sales_target } = userState;

  const inner_sales_target = useMemo(
    () => (_.isEmpty(sales_target) ? default_sales_target : sales_target),
    [sales_target, default_sales_target],
  );
  const onclick_intro = async () => {
    set_show_intro(true);
  };
  const handle_close_intro = () => set_show_intro(false);
  const handle_intro_requested = () => {
    set_open_snackbar(true);
    on_submit();
  };
  const hide_snackbar = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    set_open_snackbar(false);
  };

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={onclick_intro}
        color="primary"
        aria-label="request intro"
        size="small"
        style={style}
      >
        {button_text}
      </Button>
      <IntroRequestDialog
        open={show_intro}
        account_id={account_id}
        account_name={account_name}
        sales_target={inner_sales_target}
        path={path}
        requesting_contact_id={requesting_contact_id}
        requesting_account_id={requesting_account_id}
        source={source}
        on_submit={handle_intro_requested}
        on_cancel={handle_close_intro}
        sales_recommendation_id={sales_recommendation_id}
      />
      <Snackbar
        open={open_snackbar}
        autoHideDuration={10000}
        onClose={hide_snackbar}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <Alert onClose={hide_snackbar} severity={'success'}>
          {`Introduction requested to ${account_name}!`}
        </Alert>
      </Snackbar>
    </>
  );
};
