import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { CsvToArray } from '../../misc/csv-array';
import { useAPI } from '../../userContext';
import { POTENTIAL_DATE_RANGES } from '../../utils/sf-consts';
import { get_missing_hires, get_talpod_krs } from '../api';

const StyledTableCell = styled(TableCell)(() => ({
  maxWidth: '200px',
}));

const KrsTable = ({ krs, showAllKrs }) => {
  const sortedKrs = _.orderBy(
    krs,
    ['isCurrentKr', 'rank'], // Order by 'isCurrentKr' first, then by 'metric'
    ['desc', 'asc'], // 'desc' for 'isCurrentKr' to get truthy values first, 'asc' for 'kr'
  );
  const displayedKrs = showAllKrs ? sortedKrs : sortedKrs.filter(({ isCurrentKr }) => isCurrentKr);

  return (
    <Table aria-label="customized table" style={{ width: '100%', marginBottom: '20px' }}>
      <TableHead>
        <TableRow>
          <StyledTableCell>Metric</StyledTableCell>
          <StyledTableCell>Results</StyledTableCell>
          <StyledTableCell>Type</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displayedKrs.map(({ metric, rank, result, percentage, type, isCurrentKr }) => (
          <TableRow key={metric} style={{ marginBottom: '5px', backgroundColor: isCurrentKr ? 'white' : '#f0f0f0' }}>
            <TableCell>
              {rank} {metric}
            </TableCell>
            <TableCell>
              {result}
              {percentage ? `%` : ''}
            </TableCell>
            <TableCell>{type}</TableCell> {/* Display KR Type */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const RangeSelector = ({ dateRange, rangeNumber, setDateRange, setRangeNumber }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '400px',
      },
    },
  };
  const [newRangeNumber, setNewRangeNum] = useState(rangeNumber);
  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ display: 'inline-flex' }}>
        <FormControl>
          <InputLabel id="krs-date-range-select-label">Date Range</InputLabel>
          <Select
            labelId="krs-date-range-select-label1"
            id="krs-date-range-select"
            value={dateRange}
            onChange={(event) => setDateRange(event.target.value)}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {POTENTIAL_DATE_RANGES.map((pr) => (
              <MenuItem key={pr} value={pr}>
                {pr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {_.includes(dateRange, ':n') && (
          <FormControl style={{ width: '40px', marginLeft: '15px' }}>
            <TextField
              id="number"
              value={newRangeNumber}
              inputMode={'numeric'}
              onChange={(event) => setNewRangeNum(event.target.value)}
              onBlur={() => setRangeNumber(newRangeNumber)}
              label={'Number'}
            />
          </FormControl>
        )}
      </div>
    </div>
  );
};

export const Krs = ({ stats, showAllKrs }) => {
  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        {!_.isEmpty(stats) && (
          <div style={{ display: 'inline-flex' }}>
            <KrsTable krs={stats.krs} showAllKrs={showAllKrs} />
          </div>
        )}
      </div>
    </>
  );
};

export const TalpodDashboard = () => {
  const api = useAPI();
  const [stats, setStats] = useState();
  const [dateRange, setDateRange] = useState('THIS_QUARTER');
  const [rangeNumber, setRangeNumber] = useState(7);
  const [loadingKrs, setLoadingKrs] = useState(false);
  const [showAllKrs, setShowAllKrs] = useState(false); // New state to track visibility
  const toggleShowAllKrs = () => setShowAllKrs(!showAllKrs); // Function to toggle KR visibility
  const [leads, setLeads] = useState([]);
  const [missingHires, setMissingHires] = useState(undefined);

  useEffect(() => {
    setLoadingKrs(true);
    async function fetchKrs() {
      await get_talpod_krs({ dateRange, rangeNumber }, api).then(({ krs, referralPoscans }) => {
        setStats({
          krs,
          referralPoscans,
        });
        setLoadingKrs(false);
      });
    }
    fetchKrs();
  }, [dateRange, rangeNumber]);

  async function getMissingHires() {
    await get_missing_hires({ leads }, api).then(({ missingHires: res }) => {
      console.log(res);
      setMissingHires(res);
    });
  }

  return (
    <>
      <div style={{ width: '60%', margin: 'auto', marginBottom: '20px' }}>
        <RangeSelector
          dateRange={dateRange}
          rangeNumber={rangeNumber}
          setDateRange={setDateRange}
          setRangeNumber={setRangeNumber}
        />
        <Button variant="contained" color="primary" onClick={toggleShowAllKrs} style={{ marginBottom: '20px' }}>
          {showAllKrs ? 'Hide Non-Current KRs' : 'Show All Metrics'}
        </Button>
        {loadingKrs && <Typography style={{ marginBottom: '20px' }}>Loading...</Typography>}
        {!loadingKrs && <Krs stats={stats} showAllKrs={showAllKrs} />}
        <CsvToArray header={'Missing Hires - Import leads'} array={leads} setArray={setLeads} />
        {!_.isEmpty(leads) && (
          <Button variant="contained" color="primary" onClick={getMissingHires} style={{ marginTop: '20px' }}>
            Check Missing Hires
          </Button>
        )}
        {_.isEmpty(missingHires) && !_.isUndefined(missingHires) && <h5> No missing hires were found</h5>}
        {!_.isEmpty(missingHires) && (
          <div>
            <h5> Missing Hires</h5>
            {/*a table with all missing hires where the columns are the attirbutes of the first missing hires*/}
            <Table headers={Object.keys(missingHires[0])}>
              <TableHead>
                <TableRow>
                  {Object.keys(missingHires[0]).map((key) => (
                    <TableCell key={key}>{key}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {missingHires.map((missingHire) => (
                  <TableRow key={missingHire.id}>
                    {Object.keys(missingHire).map((key) => (
                      <TableCell key={key}>{missingHire[key]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};
