import _ from 'lodash';
import { useLocation } from 'react-router';

export const shouldShowMyCandidates = (user) => {
  return _.includes(['Admin', 'HR'], _.get(user, 'Aleph_Connect_Role__c'));
};
export const salesforce_contact_link = (contact_id) =>
  `https://aleph.lightning.force.com/lightning/r/Contact/${encodeURIComponent(contact_id)}/view`;
export const salesforce_signal_link = (signal_id) =>
  `https://aleph.lightning.force.com/lightning/r/Contact_Signal__c/${encodeURIComponent(signal_id)}/view`;
export const salesforce_task_link = (task_id) =>
  `https://aleph.lightning.force.com/lightning/r/Task/${encodeURIComponent(task_id)}/view`;
export const unaccent = (s) => s.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
export const clean_account_name = (account) =>
  account
    .replace(/,? inc\.$/i, '')
    .replace(/,? llc\.$/i, '')
    .replace(/,? ltd\.$/i, '')
    .trim();

export function useQuery() {
  const loc = useLocation();
  return new URLSearchParams(loc.search);
}

export function addQueryParamIfNeeded(key, value) {
  let currentURL = new URL(window.location.href);
  if (!currentURL.searchParams.has(key)) {
    currentURL.searchParams.append(key, value);
    window.location.href = currentURL.href;
    window.location.reload();
  }
}
