import {
  Button,
  Drawer,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useAPI } from '../userContext';
import { IntroRequestButton } from './intro_request_form';

const StyledTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '17px',
    color: 'black',
    borderRadius: '10px',
    border: '0px solid rgba(0, 0, 0, 0.10)',
    opacity: 0.3,
    minHeight: 'unset',
    minWidth: 'unset',
    padding: '10px 16px',
    '&$selected': {
      color: 'black',
    },
    transition: 'background ease-out 0.2s, opacity ease-out 0.2s',
    '&:hover': {
      background: '#F4F4F4',
      opacity: 1,
    },
  },
  selected: {
    color: 'black',
    background: '#F4F4F4',
    opacity: 1,
  },
}))(Tab);

const StyledTabs = withStyles(() => ({
  flexContainer: {
    gap: '7px',
    alignItems: 'flex-start',
  },
  indicator: {
    display: 'none',
  },
}))(Tabs);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: '14px',
    fontSize: 14,
    fontFamily: 'Inter',
    color: 'black',
    border: 'none',
  },
  head: {
    backgroundColor: theme.palette.common.white,
    fontWeight: 500,
    opacity: 0.7,
  },
  body: {
    fontWeight: 400,
    lineHeight: '150%',
    color: 'black',
    verticalAlign: 'top',
  },
}))(TableCell);

const StyledActionsCell = withStyles(() => ({
  body: {
    verticalAlign: 'middle',
  },
}))(StyledTableCell);

const StyledTextCell = withStyles(() => ({
  body: {
    opacity: 0.4,
    color: 'black',
    fontSize: 14,
  },
}))(StyledTableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
  },
}))(TableRow);

const StyledButton = withStyles((theme) => ({
  root: {
    borderRadius: '18px',
    textTransform: 'none',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    lineHeight: 'normal',
  },
}))(Button);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  preCell: {
    fontSize: 14,
    fontFamily: 'Inter',
    color: 'black',
    fontWeight: 400,
    lineHeight: '150%',
    border: 'none',
    margin: 0, // To remove default margin of <pre> tag
    whiteSpace: 'pre-wrap', // To wrap the text
    wordBreak: 'break-word', // To break long words
  },
  buttonContainer: {
    display: 'flex',
    gap: '14px', // Controls the spacing between buttons
    flexWrap: 'wrap', // Ensures buttons wrap if the cell is too small
    alignItems: 'center', // Aligns buttons vertically
    justifyContent: 'center', // Aligns buttons horizontally
  },
});

const SkeletonRow = () => (
  <>
    {_.range(6).map((i) => (
      <StyledTableRow key={i}>
        {_.range(6).map((j) => (
          <StyledTableCell key={`${i}-${j}`}>
            <Skeleton />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ))}
  </>
);

const DismissButton = ({ status_id, account_id, account_name, account_website, sales_target_id, update_status }) => {
  const [show_dismiss, set_show_dismiss] = React.useState(false);
  const onclick_dismiss = () => set_show_dismiss(true);
  const handle_close_dismiss = () => set_show_dismiss(false);

  return (
    <>
      <StyledButton variant="outlined" color="primary" onClick={onclick_dismiss}>
        Dismiss
      </StyledButton>
      <Drawer
        style={{ zIndex: 1000 }}
        anchor="right"
        open={show_dismiss}
        onClose={handle_close_dismiss}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const comments = formJson.comments;
            const dismiss_category = formJson.dismiss_category;
            update_status({
              status_id,
              account_id: account_id,
              sales_target_id: sales_target_id,
              status: 'Dismissed by Portfolio',
              comments,
              dismiss_category,
            });
            handle_close_dismiss();
          },
          style: { width: '42%' },
        }}
        ModalProps={{
          disablePortal: true,
        }}
      >
        <Toolbar />
        <div
          style={{
            padding: '31px 48px',
            display: 'flex',
            flexDirection: 'column',
            gap: '52px',
            flexShrink: '0',
            textAlign: 'justify',
          }}
        >
          <div>
            <h2 style={{ marginBottom: '10px' }}>
              Why is{' '}
              <a href={account_website} style={{ fontWeight: '500' }} target="_blank" rel="noopener noreferrer">
                {account_name}
              </a>{' '}
              not relevant?
            </h2>
            <p style={{ marginTop: '0', fontWeight: '500', color: 'rgba(0, 0, 0, 0.40)', width: '88%' }}>
              This will help provide better recommendations in the future
            </p>
          </div>
          <TextField
            id="dismiss_category"
            name="dismiss_category"
            label="Select Reason"
            fullWidth
            variant="outlined"
            select
            className="sales-opportunities-dismiss-category"
          >
            <MenuItem value="Company not a good match">Company not a good match</MenuItem>
            <MenuItem value="Already in touch">Already in touch</MenuItem>
            <MenuItem value="Existing customer">Existing customer</MenuItem>
            <MenuItem value="Churned customer">Churned customer</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
          <TextField
            autoFocus
            required
            id="comments"
            name="comments"
            label="Other Comments"
            fullWidth
            variant="outlined"
          />
          <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
            <StyledButton variant="contained" color="primary" type="submit" disableElevation>
              Submit
            </StyledButton>
            <StyledButton variant="outlined" color="primary" onClick={handle_close_dismiss}>
              Cancel
            </StyledButton>
          </div>
        </div>
      </Drawer>
    </>
  );
};

const OpportunitiesTable = ({ opportunities, loading, update_status }) => {
  const classes = useStyles();
  const format_date = (datetime) => {
    //format as 22 Apr 24
    return new Date(datetime).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' });
  };
  const format_reasons = (reasons) => {
    // replace &amp; with &. replace gt and lt with > and <
    return (reasons || '').replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&amp;/g, '&');
  };
  const render_location = ({ city, country }) => {
    if (city && country) {
      return `${city}, ${country}`;
    }
    if (country) {
      return country;
    }
    return '';
  };
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" style={{ width: '5%', minWidth: '98px', maxWidth: '98px' }}>
              Date
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '10%' }}>
              Target Profile
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '17%', minWidth: '140px' }}>
              Company
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '25%' }}>
              Relevancy
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '25%' }}>
              Comments
            </StyledTableCell>
            <StyledTableCell style={{ width: '18%', minWidth: '110px' }}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <SkeletonRow />
          ) : (
            opportunities.map((row) => (
              <StyledTableRow key={row.status_id}>
                <StyledTextCell component="th" scope="row">
                  {format_date(row.created_date)}
                </StyledTextCell>
                <StyledTableCell align="left">{row.sales_target.Name}</StyledTableCell>
                <StyledTableCell align="left">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '6px',
                      flexShrink: '0',
                    }}
                  >
                    <span>
                      <span style={{ fontWeight: '600' }}>{row.target_account.name}</span>
                      <br />
                      <span style={{ opacity: 0.4 }}>{render_location(row.target_account)}</span>
                    </span>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: '4px',
                        flexShrink: '0',
                      }}
                    >
                      <a href={row.target_account.website} target="_blank" rel="noopener noreferrer">
                        <i className="website-icon" />
                      </a>
                      <a href={row.target_account.linkedin} target="_blank" rel="noopener noreferrer">
                        <i className="linkedin-icon" />
                      </a>
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTextCell align="left">
                  <pre className={classes.preCell}>{format_reasons(row.account_reasons)}</pre>
                </StyledTextCell>
                <StyledTextCell align="left">{row.comments}</StyledTextCell>
                <StyledActionsCell align="center">
                  <div className={classes.buttonContainer}>
                    {row.status === 'Suggested to Portfolio' && (
                      <>
                        <IntroRequestButton
                          style={{ borderRadius: '18px', textTransform: 'none' }}
                          button_text="Request Intro"
                          account_id={row.account_id}
                          account_name={row.target_account.name}
                          sales_target={row.sales_target}
                          sales_recommendation_id={row.status_id}
                          source="Sales Recs"
                          on_submit={() =>
                            update_status({
                              status_id: row.status_id,
                              sales_target_id: row.sales_target_id,
                              account_id: row.account_id,
                              status: 'Introduction Requested',
                            })
                          }
                        />
                        <DismissButton
                          status_id={row.status_id}
                          update_status={update_status}
                          account_id={row.account_id}
                          account_name={row.target_account.name}
                          account_website={row.target_account.website}
                          sales_target_id={row.sales_target_id}
                        />
                      </>
                    )}
                    {row.status === 'Introduction Requested' && (
                      <RouterLink to={`/intro/dashboard?q=${encodeURIComponent(row.target_account.name)}`}>
                        See status in dashboard
                      </RouterLink>
                    )}
                  </div>
                </StyledActionsCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SalesOpportunities = () => {
  const api = useAPI();
  const [sales_opportunities, set_sales_opportunities] = React.useState([]);
  const [loading, set_loading] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const read_sales_opportunities = async () => {
      set_loading(true);
      const opportunities = await api('sales-targets/all/suggestions', { method: 'GET' });
      set_sales_opportunities(opportunities);
      set_loading(false);
    };
    read_sales_opportunities();
  }, [api]);

  const filter_recs = (recs, selectedTab) => {
    const not_snoozed = (rec) => !rec.snooze_until || new Date(rec.snooze_until) < new Date();
    switch (selectedTab) {
      case 0:
        return recs.filter((rec) => not_snoozed(rec) && rec.status === 'Suggested to Portfolio');
      case 1:
        return recs.filter((rec) => not_snoozed(rec) && rec.status === 'Introduction Requested');
      case 2:
        return recs.filter((rec) => !not_snoozed(rec));
      default:
        return recs;
    }
  };
  const filtered_recs = React.useMemo(() => {
    return _.sortBy(filter_recs(sales_opportunities, selectedTab), 'created_date').reverse();
  }, [sales_opportunities, selectedTab]);

  const update_status = useCallback(
    async ({
      status_id,
      sales_target_id,
      account_id,
      status,
      comments,
      snooze_until,
      dismiss_category,
      account_reasons,
    }) => {
      await api(`sales-targets/${sales_target_id}/statuses/${account_id}`, {
        method: 'POST',
        body: JSON.stringify({ status_id, status, comments, snooze_until, dismiss_category, account_reasons }),
      });
      set_loading(true);
      const opportunities = await api('sales-targets/all/suggestions', { method: 'GET' });
      set_sales_opportunities(opportunities);
      set_loading(false);
    },
    [api],
  );

  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }}>
        <h1 style={{ marginTop: '1em' }}>Sales Opportunities</h1>
        <StyledTabs
          value={selectedTab}
          textColor="primary"
          style={{ width: '100%', margin: 'auto', marginBottom: '20px' }}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          aria-label="tabs"
        >
          <StyledTab
            label={
              <span style={{ display: 'flex', gap: '8px' }}>
                <span>Pending Review</span>
                <span style={{ opacity: 0.4 }}>{filter_recs(sales_opportunities, 0).length}</span>
              </span>
            }
          />
          <StyledTab
            label={
              <span style={{ display: 'flex', gap: '8px' }}>
                <span>Requested</span>
                <span style={{ opacity: 0.4 }}>{filter_recs(sales_opportunities, 1).length}</span>
              </span>
            }
          />
        </StyledTabs>
        <OpportunitiesTable loading={loading} opportunities={filtered_recs} update_status={update_status} />
      </div>
    </>
  );
};
