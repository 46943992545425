export async function search_accounts_autocomplete(text, api) {
  const res = await api(`account/search?query=${encodeURIComponent(text)}`);
  return res.result;
}

export async function search_accounts_batch(text_list, api) {
  if (text_list.length === 0) {
    return [];
  }
  const body = JSON.stringify({ names: text_list });
  const res = await api('account/batch-search', { method: 'POST', body: body });
  return res.accounts;
}

export async function search_pg_contacts_autocomplete(text, api) {
  const res = await api(`contact/search_pg_by_name?query=${encodeURIComponent(text)}`);
  return res.result;
}

export async function search_sf_contacts_autocomplete(text, api) {
  const res = await api(`contact/search_sf_by_name?query=${encodeURIComponent(text)}`);
  return res.result;
}

export function send_poscan_request(params, api) {
  return api('contact-intro/poscan-request/', { method: 'POST', body: JSON.stringify(params) });
}

export function send_slack_or_email(params, api) {
  return api('contact-intro/create-slack-email/', { method: 'POST', body: JSON.stringify(params) });
}

export function get_positions_by_account_id(accountId, api) {
  return api('position/by_account_id', { method: 'POST', body: JSON.stringify({ accountId }) });
}

export function create_contact_signal(params, api) {
  return api('signal/create', { method: 'POST', body: JSON.stringify(params) });
}

export function get_signal_sources(api) {
  return api('signal/metadata/source', { method: 'GET' });
}

export function get_signal_types(api) {
  return api('signal/metadata/type', { method: 'GET' });
}
