import {
  Badge,
  Button,
  Chip,
  Link,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
// import { Send } from '@material-ui/icons'
import { Autocomplete, Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ReactComponent as LinkedIn } from '../static/linkedin.svg';
// import { ReactComponent as checking_internally_icon } from '../static/aleph-review.svg'
import { ReactComponent as salesforce } from '../static/salesforce-white.svg';
import { useAdmin, useAPI, useUserState } from '../userContext';
import { useQuery } from '../utils/misc';
import { archive_intro_from_dashboard, list_my_intros } from './api';

/*
const INTRO_STATUSES_TO_ICON = {
    'To review': null,
    'Checking Internally': CheckingInternallyIcon,
    'Checking Internally - Pinged': CheckingInternallyIcon,
    'Facilitator Agreed': CheckingInternallyIcon,
    'Declined by Facilitator': null,
    'Weak relationship with connector': null,
    'No relationship with connector': null,
    'Sent Email to Facilitator': CheckingInternallyIcon,
    'Awaiting Info from Portfolio': null,
    'Can\'t Reach Out': null,
    'Deferred': null,
    'Asked': AskingConnectorIcon,
    'Asked Connector - Pinged': AskingConnectorIcon,
    'Didn\'t Respond': null,
    'Declined_by_Connector': null,
    'Agreed': ConnectorAgreedIcon,
    'Connector Agreed - Pinged': ConnectorAgreedIcon,
    'Sent': TargetCheckingIcon,
    'Request Sent to Target - Pinged': TargetCheckingIcon,
    'Target Company is Checking': TargetCheckingIcon,
    'No Response': null,
    'Declined by Target': null,
    'Introduced': IntroducedIcon,
    'Feedback Requested': null,
    'Feedback Provided': null,
    'No Feedback': null,
    'On Hold': null,
    'Declined by Requestor': null,
}

const Legend = () => (
    <div style={{ 
        display: 'flex', 
        flexDirection: 'row',  
        fontFamily: 'Merriweather Sans',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '13px',
        lineHeight: '27px',
        alignItems: 'center',
        paddingLeft: '12px',
        paddingBottom: '14px',
    }}>
        <CheckingInternallyIcon />
        Checking Internally at Aleph
        <ArrowRightAlt fontSize="small" />
        <AskingConnectorIcon />
        Asking a Potential Connector
        <ArrowRightAlt fontSize="small" />
        <TargetCheckingIcon />
        Request Sent to Target Company
        <ArrowRightAlt fontSize="small" />
        <IntroducedIcon />
        Introduction Made
    </div>
)
*/

const INTRO_STATUSES_TO_HUMAN_READABLE = (i) =>
  ({
    'To review': '',
    'Checking Internally': 'Aleph is checking',
    'Checking Internally - Pinged': 'Aleph is checking',
    'Facilitator Agreed': 'Aleph is checking',
    'Declined by Facilitator': 'Insufficient relationship for this ask',
    'Weak relationship with connector': 'Insufficient relationship for this ask',
    'No relationship with connector': 'Insufficient relationship for this ask',
    'Sent Email to Facilitator': 'Aleph is checking',
    'Awaiting Info from Portfolio': `Instructions were sent to ${i.Requestor_Email}`,
    "Can't Reach Out": 'Insufficient relationship for this ask',
    Deferred: '',
    Asked: 'Sent to potential connector',
    'Asked Connector - Pinged': 'Sent to potential connector',
    "Didn't Respond": "Connector didn't respond",
    Declined_by_Connector: "Connector couldn't introduce",
    Agreed: 'Awaiting company response',
    'Connector Agreed - Pinged': 'Awaiting company response',
    Sent: 'Awaiting company response',
    'Request Sent to Target - Pinged': 'Awaiting company response',
    'Target Company is Checking': 'Awaiting company response',
    'No Response': "Target didn't respond",
    'Declined by Target': 'Target declined',
    Introduced: `Feedback required: instructions were sent to ${i.Requestor_Email}`,
    'Feedback Requested': `Feedback required: instructions were sent to ${i.Requestor_Email}`,
    'Feedback Provided': '',
    'No Feedback': '',
    'On Hold': 'On Hold',
    'Declined by Requestor': 'Canceled',
  })[i.Status__c];

const INTRO_STATUSES_TO_INDICATOR = (i) =>
  ({
    'To review': 'queued',
    'Checking Internally': 'in_progress',
    'Checking Internally - Pinged': 'in_progress',
    'Facilitator Agreed': 'in_progress',
    'Declined by Facilitator': 'declined',
    'Weak relationship with connector': 'declined',
    'No relationship with connector': 'declined',
    'Sent Email to Facilitator': 'in_progress',
    'Awaiting Info from Portfolio': 'action_required',
    "Can't Reach Out": 'declined',
    Deferred: i.Reject_Reason__c === 'Alternative Path Chosen' ? 'alternative_path_chosen' : 'declined',
    Asked: 'in_progress',
    'Asked Connector - Pinged': 'in_progress',
    "Didn't Respond": 'declined',
    Declined_by_Connector: 'declined',
    Agreed: 'in_progress',
    'Connector Agreed - Pinged': 'in_progress',
    Sent: 'in_progress',
    'Request Sent to Target - Pinged': 'in_progress',
    'Target Company is Checking': 'in_progress',
    'No Response': 'declined',
    'Declined by Target': 'declined',
    Introduced: 'feedback_required',
    'Feedback Requested': 'feedback_required',
    'Feedback Provided': 'done',
    'No Feedback': 'done',
    'On Hold': 'queued',
    'Declined by Requestor': 'declined',
  })[i.Status__c];

const INTRO_STATUSES_TO_BUCKET = {
  'To review': 'queue',
  'Checking Internally': 'in_progress',
  'Checking Internally - Pinged': 'in_progress',
  'Facilitator Agreed': 'in_progress',
  'Declined by Facilitator': 'archive',
  'Weak relationship with connector': 'archive',
  'No relationship with connector': 'archive',
  'Sent Email to Facilitator': 'in_progress',
  'Awaiting Info from Portfolio': 'in_progress',
  "Can't Reach Out": 'archive',
  Deferred: 'archive',
  Asked: 'in_progress',
  'Asked Connector - Pinged': 'in_progress',
  "Didn't Respond": 'archive',
  Declined_by_Connector: 'archive',
  Agreed: 'in_progress',
  'Connector Agreed - Pinged': 'in_progress',
  Sent: 'in_progress',
  'Request Sent to Target - Pinged': 'in_progress',
  'Target Company is Checking': 'in_progress',
  'No Response': 'archive',
  'Declined by Target': 'archive',
  Introduced: 'in_progress',
  'Feedback Requested': 'in_progress',
  'Feedback Provided': 'archive',
  'No Feedback': 'archive',
  'On Hold': 'queue',
  'Declined by Requestor': 'archive',
};

export const INTRO_STATUSES_TO_HIGH_LEVEL_STATUS = {
  'To review': 'Queued',
  'Checking Internally': 'Checking Internally at Aleph',
  'Checking Internally - Pinged': 'Checking Internally at Aleph',
  'Facilitator Agreed': 'Checking Internally at Aleph',
  'Declined by Facilitator': "Can't Introduce",
  'Weak relationship with connector': "Can't Introduce",
  'No relationship with connector': "Can't Introduce",
  'Sent Email to Facilitator': 'Checking Internally at Aleph',
  'Awaiting Info from Portfolio': 'On Hold - Action Required',
  "Can't Reach Out": "Can't Introduce",
  Deferred: 'Alternative Path Chosen',
  Asked: 'Checking With Potential Connector',
  'Asked Connector - Pinged': 'Checking With Potential Connector',
  "Didn't Respond": "Can't Introduce",
  Declined_by_Connector: "Can't Introduce",
  Agreed: 'Checking With Potential Connector',
  'Connector Agreed - Pinged': 'Checking With Potential Connector',
  Sent: 'Checking With Target Company',
  'Request Sent to Target - Pinged': 'Checking With Target Company',
  'Target Company is Checking': 'Checking With Target Company',
  'No Response': "Can't Introduce",
  'Declined by Target': "Can't Introduce",
  Introduced: 'Success',
  'Feedback Requested': 'Success',
  'Feedback Provided': 'Success',
  'No Feedback': 'Success',
  'On Hold': 'On Hold',
  'Declined by Requestor': 'Canceled',
};

const should_request_feedback = (i) => {
  if (i.Status__c === 'Introduced' || i.Status__c === 'Feedback Requested') {
    return true;
  }
  if (i.Status__c === 'Feedback Provided') {
    if (i.Goal__c === 'Sales Opportunity' && i.Sales_Status__c === 'In Play') {
      return true;
    }
  }
  return false;
};

const is_action_required = (i) => {
  if (i.Status__c === 'Awaiting Info from Portfolio') {
    return true;
  }
  if (
    should_request_feedback(i) &&
    (_.isEmpty(i.Next_Update_Request__c) || moment(i.Next_Update_Request__c) < moment())
  ) {
    return true;
  }
  return false;
};

const StatusChip = ({ indicator }) => {
  const customstyle = {
    fontFamily: 'Merriweather Sans',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '32px',
  };
  switch (indicator) {
    case 'queued': {
      return <Chip size="medium" label="Queued" style={{ backgroundColor: '#F4DF94', ...customstyle }} />;
    }
    case 'in_progress': {
      return <Chip size="medium" label="In Progress" style={{ backgroundColor: '#AAF0EA', ...customstyle }} />;
    }
    case 'declined': {
      return <Chip size="medium" label="Couldn't Facilitate" style={{ backgroundColor: '#E0E0E0', ...customstyle }} />;
    }
    case 'action_required': {
      return (
        <Chip
          size="medium"
          label="Action Required"
          style={{ backgroundColor: 'rgba(176, 170, 240, 0.65)', ...customstyle }}
        />
      );
    }
    case 'alternative_path_chosen': {
      return (
        <Chip size="medium" label="Alternative Path Chosen" style={{ backgroundColor: '#E0E0E0', ...customstyle }} />
      );
    }
    case 'feedback_required': {
      return <Chip size="medium" label="Successful Intro" style={{ backgroundColor: '#AAF0AD', ...customstyle }} />;
    }
    case 'done': {
      return <Chip size="medium" label="Done" style={{ backgroundColor: '#C2C2C2', ...customstyle }} />;
    }
    default: {
      return <Chip size="medium" label={indicator} style={customstyle} />;
    }
  }
};

const preprocess_intros = (intros) => {
  const date_by_account = {};
  intros.forEach(({ Target_Account__c, CreatedDate, LastActivityDate }) => {
    const date = LastActivityDate || CreatedDate.slice(0, 10);
    const current = date_by_account[Target_Account__c];
    if (_.isUndefined(current) || date > current) {
      date_by_account[Target_Account__c] = date;
    }
  });
  const processed = intros.map(
    ({
      Id,
      Name,
      Target_Account__c,
      Target_Account__r,
      To_Who__r,
      Target_Contact_Role__r,
      Connector__r,
      Requestor__c,
      Requestor__r,
      Status__c,
      Sales_Status__c,
      Most_Advanced_Sales_Status__c,
      Feedback_Details__c,
      Feedback__c,
      Comments__c,
      CreatedDate,
      LastActivityDate,
      Archived_In_Dashboard__c,
      Next_Update_Request__c,
      Goal__c,
      Aleph_Facilitator_r,
      ...rest
    }) => ({
      id: Id,
      Name,
      Target_Account__c,
      Target_Account_Name: _.get(Target_Account__r, 'Name', '-'),
      To_Who_Name: _.get(To_Who__r, 'Name', '-'),
      Title: _.get(Target_Contact_Role__r, 'Name', _.get(To_Who__r, 'Title', '-')),
      Connector_Name: _.get(Connector__r, 'Name', _.get(Aleph_Facilitator_r, 'Name')),
      Requestor__c,
      Requestor_Name: _.get(Requestor__r, 'Name', '-'),
      Requestor_Email: _.get(Requestor__r, 'Email', `${_.get(Requestor__r, 'Name', '')}'s email`),
      Status__c,
      Sales_Status__c,
      Most_Advanced_Sales_Status__c,
      Feedback_Details__c,
      Feedback__c,
      Comments__c,
      CreatedDate,
      LastActivityDate,
      Goal__c,
      date: LastActivityDate || CreatedDate.slice(0, 10),
      account_date: date_by_account[Target_Account__c],
      // status_icon: INTRO_STATUSES_TO_ICON[Status__c],
      bucket: INTRO_STATUSES_TO_BUCKET[Status__c],
      archived: Archived_In_Dashboard__c,
      To_Who__r,
      ...rest,
    }),
  );
  const processed2 = processed.map((i) => ({
    action_required: is_action_required(i),
    request_feedback: should_request_feedback(i),
    show_archive_button: i.bucket === 'archive' && !i.archived && !should_request_feedback(i),
    indicator: should_request_feedback(i) ? 'feedback_required' : INTRO_STATUSES_TO_INDICATOR(i),
    full_status: INTRO_STATUSES_TO_HUMAN_READABLE(i),
    ...i,
  }));
  const sorted = _.reverse(_.sortBy(processed2, ['account_date', 'Account__c']));
  return sorted;
};

const sort_by_date = (intros) => _.reverse(_.sortBy(intros, ['account_date', 'Account__c', 'date']));

const useSalesIntros = () => {
  const api = useAPI();
  const [loading, set_loading] = useState(true);
  const [my_intros, set_my_intros] = useState([]);
  const [all_intros, set_all_intros] = useState([]);
  const [error, set_error] = useState('');
  const [cnt, set_cnt] = useState(0);

  const dismiss_error = () => set_error('');
  useEffect(() => {
    async function fetch() {
      try {
        // set_loading(true)
        const { my_intros, all_intros } = await list_my_intros(api);
        set_my_intros(preprocess_intros(my_intros));
        set_all_intros(preprocess_intros(all_intros));
      } catch (e) {
        console.log(e);
        set_error(e.message);
      } finally {
        set_loading(false);
      }
    }
    fetch();
  }, [api, cnt]);
  const refresh = () => set_cnt(cnt + 1);

  const archive = async (id) => {
    for (const i of all_intros) {
      if (i.id === id) {
        i.archived = true;
        set_all_intros(_.clone(all_intros));
      }
    }
    for (const i of my_intros) {
      if (i.id === id) {
        i.archived = true;
        set_my_intros(_.clone(my_intros));
      }
    }
    await archive_intro_from_dashboard(id, api);
    refresh();
  };
  return [loading, my_intros, all_intros, error, dismiss_error, refresh, archive];
};

const FilterBox = ({ filter, set_filter, optional_filters }) => {
  const handleOptionSelection = (event, option) => {
    set_filter({
      ...filter,
      search: option,
    });
  };

  return (
    <Autocomplete
      disabled={_.isEmpty(optional_filters) || _.isUndefined(filter)}
      getOptionLabel={(option) => option.value || ''}
      options={optional_filters}
      groupBy={(option) => option.type}
      value={filter.search}
      autoComplete
      multiple
      onChange={handleOptionSelection}
      selectOnFocus={false}
      freeSolo={false}
      renderInput={(params) => (
        <TextField
          className={'searchbox'}
          variant="outlined"
          style={{ borderRadius: '32px', marginBottom: '40px' }}
          placeholder={_.isEmpty(filter.search) ? 'Search' : undefined}
          {...params}
        />
      )}
    />
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: '0',
}));

const LinksTableCell = styled(TableCell)(({ theme }) => ({
  width: '70px',
  verticalAlign: 'top',
  paddingTop: '15px',
}));

const cell_styles = {
  padding: '19px 18px 17px 0px',
  border: '0',
  borderBottom: '0.4px solid #939393',
  verticalAlign: 'top',
};

const TargetTableCell = styled(TableCell)(({ theme }) => ({
  ...cell_styles,
  padding: '19px 20px 17px 18px',
}));

const PathTableCell = styled(TableCell)(({ theme }) => ({
  ...cell_styles,
  padding: '19px 20px 17px 0px',
}));

const StatusTableCell = styled(TableCell)(({ theme }) => ({
  ...cell_styles,
}));

const MostRightTableCell = styled(TableCell)(({ theme }) => ({
  ...cell_styles,
}));

const InnerCellDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '95px',
  justifyContent: 'space-between',
}));

const TargetCell = ({ i }) => {
  const { userState } = useUserState();
  return (
    <InnerCellDiv>
      <div>
        <div
          style={{
            fontFamily: 'Merriweather Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '18px',
            lineHeight: '20px',
            marginBottom: '1px',
          }}
        >
          {i.Target_Account_Name}
        </div>
        <div
          style={{
            fontFamily: 'Merriweather Sans',
            fontStyle: 'normal',
            lineHeight: '20px',
            fontWeight: '300',
            fontSize: '13px',
            color: '#7E7E7E',
          }}
        >
          Requested By:{' '}
          <span style={{ color: '#000000' }}>
            {userState.aleph_user.Id === i.Requestor__c ? 'You' : i.Requestor_Name}
          </span>
        </div>
      </div>
      <div
        style={{
          fontFamily: 'Merriweather Sans',
          fontStyle: 'normal',
          lineHeight: '14px',
        }}
      >
        <div style={{ marginBottom: '2px' }}>
          <span style={{ marginRight: '12px', fontSize: '12px', fontWeight: '300', color: 'rgb(126, 126, 126)' }}>
            Updated
          </span>
          <span style={{ marginRight: '12px', fontSize: '11px', fontWeight: '400', color: '#000000' }}>
            {_.toUpper(moment(i.date).format('DD  MMM  YY'))}
          </span>
        </div>
        <div>
          <span style={{ marginRight: '12px', fontSize: '12px', fontWeight: '300', color: 'rgb(126, 126, 126)' }}>
            Created
          </span>
          <span
            style={{ marginRight: '12px', fontSize: '11px', fontWeight: '400', color: '#000000', marginLeft: '4px' }}
          >
            {_.toUpper(moment(i.CreatedDate).format('DD  MMM  YY'))}
          </span>
          {!_.isEmpty(i.Goal__c) && (
            <>
              <span style={{ marginRight: '12px', fontSize: '12px', color: '#C4C4C4' }}> &#x25CF; </span>
              <span style={{ fontSize: '12px', color: '#000000' }}> {i.Goal__c}</span>
            </>
          )}
        </div>
      </div>
    </InnerCellDiv>
  );
};

const PathCell = ({ i }) => {
  return (
    <InnerCellDiv>
      <div>
        <div
          style={{
            fontFamily: 'Merriweather Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '1px',
          }}
        >
          {i.To_Who_Name}
        </div>
        <div
          style={{
            fontFamily: 'Merriweather Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '13px',
            lineHeight: '20px',
            color: '#7E7E7E',
          }}
        >
          {i.Title}
        </div>
      </div>
      {i.Connector_Name && (
        <div
          style={{
            fontFamily: 'Merriweather Sans',
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '13px',
            lineHeight: '14px',
            color: '#818181',
          }}
        >
          Via: <span style={{ color: '#000000' }}>{i.Connector_Name}</span>
        </div>
      )}
    </InnerCellDiv>
  );
};

const RequestStatus = ({ i }) => {
  return (
    <InnerCellDiv>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <StatusChip indicator={i.indicator} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'Merriweather Sans',
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '13px',
            lineHeight: '27px',
            alignItems: 'center',
            paddingLeft: '1em',
          }}
        >
          {false && i.status_icon && i.status_icon()}
          <span>{i.full_status} </span>
        </div>
      </div>
    </InnerCellDiv>
  );
};

const ActionButtons = ({ i, refresh, archive }) => {
  const archive_intro = () => archive(i.id);
  return (
    <InnerCellDiv>
      {i.show_archive_button && (
        <Button
          className="dashboard-hover-action-btn"
          variant="contained"
          disableElevation
          color="primary"
          onClick={archive_intro}
          size="small"
        >
          Archive
        </Button>
      )}
    </InnerCellDiv>
  );
};

const IntroRow = ({ i, refresh, archive }) => {
  const is_admin = useAdmin();
  return (
    <StyledTableRow className="dashboard-row">
      <TargetTableCell>
        <TargetCell i={i} />
      </TargetTableCell>
      <LinksTableCell>
        <div>
          {is_admin && (
            <Link href={`https://aleph.lightning.force.com/lightning/r/Contact/${i.To_Who__c}/view`} target="_blank">
              <SvgIcon
                component={salesforce}
                style={{ width: 17.5, height: 14, marginRight: '3px' }}
                viewBox={'0 0 8 10'}
              />
            </Link>
          )}
          {i.To_Who__c && (
            <Link href={i.To_Who__r.Linkedin_Profile__c} target="_blank">
              <SvgIcon component={LinkedIn} style={{ width: 14, height: 17.5 }} viewBox={'0 0 10 8'} />
            </Link>
          )}
        </div>
      </LinksTableCell>
      <PathTableCell>
        <PathCell i={i} />
      </PathTableCell>
      <StatusTableCell>
        <RequestStatus i={i} />
      </StatusTableCell>
      <MostRightTableCell>
        <ActionButtons i={i} refresh={refresh} archive={archive} />
      </MostRightTableCell>
    </StyledTableRow>
  );
};

const query_matches = (intro, query) => {
  if (query.type === 'Company') {
    return intro.Target_Account_Name === query.value;
  }
  if (query.type === 'Person') {
    return intro.To_Who_Name === query.value;
  }
  console.error('unsupported query', query);
  return false;
};

const does_intro_match_filter = (intro, filter) => {
  return _.some(filter.map((f) => query_matches(intro, f)));
};

const get_intros_to_show = (intros, selectedTab, filter) => {
  if (!_.isEmpty(filter)) {
    return intros.filter((i) => does_intro_match_filter(i, filter));
  }
  if (selectedTab === 0) {
    // queue
    return sort_by_date(intros.filter((i) => i.bucket === 'queue'));
  }
  if (selectedTab === 1) {
    // in progress
    const in_progress = intros.filter((i) => i.bucket !== 'queue' && i.archived !== true);
    const action_required = sort_by_date(in_progress.filter((i) => i.action_required));
    const to_archive = sort_by_date(in_progress.filter((i) => i.show_archive_button));
    const the_rest = sort_by_date(in_progress.filter((i) => !i.action_required && !i.show_archive_button));

    return _.concat(action_required, the_rest, to_archive);
  }
  if (selectedTab === 2) {
    // archive
    return intros.filter((i) => i.archived === true);
  }
};

const IntrosTable = ({ intros, selectedTab, filter, refresh, archive }) => {
  const intros_to_show = get_intros_to_show(intros, selectedTab, filter);
  return (
    <TableContainer component={'div'}>
      <Table sx={{ overflowX: 'auto', tableLayout: 'fixed' }} aria-label="customized table">
        <TableBody>
          {_(intros_to_show)
            .orderBy(['Last_Status_Update__c'], ['desc'])
            .map((i) => <IntroRow i={i} key={`intro-row-${i.id}`} refresh={refresh} archive={archive} />)
            .value()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SalesDashboard = () => {
  const q = useQuery().get('q');
  const theme = useTheme();
  const xlScreen = useMediaQuery(theme.breakpoints.up(2000));
  const smScreen = useMediaQuery(theme.breakpoints.down(1500));
  const [selectedTab, setSelectedTab] = useState(1);
  const [filter, set_filter] = useState({ search: q ? q.split('\n').map((a) => ({ type: 'Company', value: a })) : [] });
  const [loading, my_intros, all_intros, error, dismiss_error, refresh, archive] = useSalesIntros();
  const action_required = all_intros.filter((i) => i.action_required);

  const gridWidth = xlScreen ? '80%' : smScreen ? '90%' : '90%';

  const target_companies = _.uniq(all_intros.map((i) => i.Target_Account_Name));
  const target_people = _.uniq(all_intros.map((i) => i.To_Who_Name));
  const filter_options = _.concat(
    target_companies.map((c) => ({ type: 'Company', value: c })),
    target_people.map((p) => ({ type: 'Person', value: p })),
  );

  return (
    <>
      <Tabs
        value={selectedTab}
        textColor="primary"
        style={{ width: gridWidth, margin: 'auto', marginBottom: '20px' }}
        onChange={(a, newValue) => setSelectedTab(newValue)}
        aria-label="tabs"
      >
        <Tab label={'Queued'} />
        <Tab
          label={
            <Badge badgeContent={action_required.length > 0} variant="dot" color="primary" className="redbadge">
              Active
            </Badge>
          }
        />
        <Tab label={'Archived'} />
      </Tabs>

      <div style={{ width: gridWidth, margin: 'auto' }}>
        <>
          <div style={{ width: '50%' }}>
            <FilterBox filter={filter} set_filter={set_filter} optional_filters={filter_options} />
          </div>
          {loading ? (
            <>
              <Skeleton
                variant="rect"
                animation="wave"
                height={45}
                width="90%"
                style={{ marginBottom: '10px', marginLeft: '18px' }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height={45}
                width="90%"
                style={{ marginBottom: '10px', marginLeft: '18px' }}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                height={45}
                width="90%"
                style={{ marginBottom: '10px', marginLeft: '18px' }}
              />
            </>
          ) : (
            <IntrosTable
              intros={all_intros}
              selectedTab={selectedTab}
              filter={filter.search}
              refresh={refresh}
              archive={archive}
            />
          )}
        </>
      </div>
    </>
  );
};
