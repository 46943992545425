export async function search_contacts_by_title(params, api) {
  const res = await api('contact/advanced_search', { method: 'POST', body: JSON.stringify(params) });
  return res;
}

export async function search_contacts_by_account_list(account_ids, api) {
  const body = JSON.stringify({ account_ids });
  const res = await api('intro/accounts/contacts/search', { method: 'POST', body: body });
  return res;
}

export async function search_accounts_by_industries({ industries, headCountFrom, headCountTo }, api) {
  const body = JSON.stringify({ industries, headCountFrom, headCountTo });
  const res = await api('account/industry-search', { method: 'POST', body: body });
  return res.accounts;
}

export async function send_intro_request(api, account_id, data) {
  const res = await api(`intro/account/${account_id}/request`, { method: 'POST', body: JSON.stringify(data) });
  return res.result;
}

export async function send_intro_request_batch(targets, comments, api) {
  const data = { targets, comments };
  const res = await api('intro/request', { method: 'POST', body: JSON.stringify(data) });
  return res.result;
}

export async function archive_intro_from_dashboard(intro_id, api) {
  const res = await api(`intro/${intro_id}/archive`, { method: 'POST' });
  return res.result;
}

export async function list_user_subscriptions(api) {
  const res = await api('user/subscriptions/account/', { method: 'GET' });
  return res;
}

export async function subscribe_user_to_account(account_id, api) {
  const res = await api(`user/subscriptions/account/${account_id}`, { method: 'PUT' });
  return res;
}

export async function unsubscribe_user_from_account(account_id, api) {
  const res = await api(`user/subscriptions/account/${account_id}`, { method: 'DELETE' });
  return res;
}

export async function list_my_intros(api) {
  const res = await api('user/intros', { method: 'GET' });
  return res;
}
