import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { ScrapingPanel } from '../widgets/scraper';
import { CsvToArray } from './csv-array';

export function CsvScrape() {
  const [array, setArray] = useState([]);
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls(array.map(({ url }) => url));
  }, [array]);
  return (
    <div style={{ width: '50%', margin: 'auto' }}>
      <CsvToArray
        header={'Upload file with urls to scrape'}
        subHeader={'currently only a column called "url" will be considered'}
        array={array}
        setArray={setArray}
      />
      {!_.isEmpty(urls) && (
        <div style={{ marginTop: '20px' }}>
          <ScrapingPanel contacts={urls.map((url) => ({ linkedin_url: url }))} />
        </div>
      )}
    </div>
  );
}
