import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Snackbar,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useState } from 'react';

import { ReactComponent as gmailLogo } from '../../static/gmail.svg';
import { ReactComponent as home } from '../../static/home.svg';
import { ReactComponent as LinkedIn } from '../../static/linkedin-blue.svg';
import { ReactComponent as relationship } from '../../static/relationship.svg';
import { ReactComponent as salesforceIcon } from '../../static/salesforce-white.svg';
import { ReactComponent as signal } from '../../static/signal.svg';
import { ReactComponent as slackLogo } from '../../static/slack.svg';
import { ReactComponent as suitcase } from '../../static/suitcase.svg';
import { ReactComponent as tamnuniSvg } from '../../static/tamnuni.svg';
import { is_admin, is_talpod, useUserState, withUserState } from '../../userContext';
import { assignGroup, showSharedWorkText } from '../../utils/connectors';
import { getPublicId } from '../../utils/contacts';
import { getPeriodText } from '../../utils/dates';
import { connectorSignal, SignalItem } from '../../utils/signals';
import { AlephIcon } from '../../widgets';
import { SingleSfContactSearchBox } from '../../widgets/contact-searchbox';
import { ScoreIconWithoutTooltip } from '../../widgets/contacts';
import {
  AlephMemberSelector,
  getOnGoingProcesses,
  Interactions,
  OctopusActions,
  ReferrerActions,
} from './referrer-actions';

const _ReferrerIcons = ({ linkedin_profile, userState, sfid }) => {
  return (
    <span style={{ marginLeft: '5px', marginRight: '5px' }}>
      {is_admin(userState) && !!sfid && (
        <Link href={`https://aleph.lightning.force.com/lightning/r/Contact/${sfid}/view`} target="_blank">
          <SvgIcon component={salesforceIcon} style={{ width: 17.5, height: 14 }} viewBox={'0 0 8 10'} />
        </Link>
      )}
      {linkedin_profile && (
        <Link href={linkedin_profile + `${is_admin(userState) ? '?ampliphy_update=true' : ''}`} target="_blank">
          <SvgIcon component={LinkedIn} style={{ width: 16, height: 16, marginRight: '10px' }} viewBox={'0 0 16 16'} />
        </Link>
      )}
    </span>
  );
};

export const ReferrerIcons = withUserState(_ReferrerIcons);

const getIsraeliEmployeesCount = (r) => {
  const isRootCompany = !!_.get(r, 'is_root_company');
  return {
    employeeCount: _.toNumber(
      _.get(r, isRootCompany ? 'root_company_employee_count' : 'individual_company_employee_count', 0),
    ),
    rootCompanyCount: _.toNumber(_.get(r, 'root_company_employee_count', 0)),
    isRootCompany,
  };
};

const SharedRole = ({ role, connector, target, admin }) => {
  const SHORT_DATE = true;
  const {
    Account_Name__c,
    NumberOfEmployees,
    Account_Employees_Count__c,
    overlappingMonths,
    endYearTogether,
    role_title,
    targetTitlesSharedAccount,
  } = role;
  const { employeeCount: israeliEmployeesCount, rootCompanyCount, isRootCompany } = getIsraeliEmployeesCount(role);

  let employeesCountText = `${israeliEmployeesCount || 'unknown # of'} Israelis`;
  if (!isRootCompany && rootCompanyCount && admin) {
    employeesCountText += `, Parent Company: ${rootCompanyCount}`;
  }
  const employeesCount = `(${NumberOfEmployees || Account_Employees_Count__c} employees, ${employeesCountText})`;

  return (
    <div style={{ marginTop: '10px', fontWeight: 300 }}>
      <SvgIcon component={suitcase} style={{ width: 15, height: 12, marginRight: '12px' }} viewBox={'0 0 15 12'} />
      <span>
        {_.get(connector, 'FirstName')} was {role_title}, {_.get(target, 'FirstName')} was {targetTitlesSharedAccount} @
        {Account_Name__c} {employeesCount} {getPeriodText(overlappingMonths, SHORT_DATE)} Until {endYearTogether}
      </span>
    </div>
  );
};

const IntroPath = ({ connector, alephUsers, setSelectedUser, selectedUser }) => {
  return (
    <div style={{ marginTop: '8px', width: '350px' }}>
      <SvgIcon component={home} style={{ width: 15, height: 14, marginRight: '20px' }} viewBox={'0 0 15 14'} />
      <span
        style={{
          marginRight: '20px',
          fontFamily: 'Merriweather Sans',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
          color: '#747474',
        }}
      >
        {' '}
        Path
      </span>
      <AlephMemberSelector
        alephUsers={alephUsers}
        isAleph={_.get(connector, 'Currrent_account_of_contact_role__c') === 'Aleph'}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        signals={_.get(connector, 'alephSignals', []).filter(
          ({ Feedback_on_relationship__c }) => !_.isNull(Feedback_on_relationship__c),
        )}
      />
    </div>
  );
};

const SignalsList = ({ signals, title, showSignallingContact, showLinks, onlyFirstName = false }) => {
  const [showSignals, SetShowSignals] = useState(false);
  return (
    <>
      {
        <>
          <span
            style={{
              marginRight: '20px',
              fontFamily: 'Merriweather Sans',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#747474',
            }}
          >
            {title || 'Show Signals'}
          </span>
          {signals.length}
          <Link onClick={() => SetShowSignals(!showSignals)} style={{ marginLeft: '10px' }}>
            {showSignals ? <ArrowDropDown fontSize="small" /> : <ArrowRight fontSize="small" />}
          </Link>
        </>
      }
      <Collapse in={showSignals} timeout="auto" unmountOnExit>
        <div>
          {signals.map((s) => (
            <div style={{ marginTop: '10px' }} key={'signal-wrapper' + _.get(s, 'Id')}>
              <SignalItem
                s={s}
                showSignallingContact={showSignallingContact}
                showLinks={showLinks}
                onlyFirstName={onlyFirstName}
                key={'signal-item' + _.get(s, 'Id')}
              />
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};

const AlephSignals = ({ connector }) => {
  return (
    <div style={{ marginTop: '8px' }}>
      <SvgIcon component={signal} style={{ width: 15, height: 12, marginRight: '20px' }} viewBox={'0 0 15 12'} />
      <SignalsList
        signals={_.get(connector, 'alephSignals', [])}
        title={'Aleph Signals'}
        showSignallingContact={true}
        showLinks={false}
        onlyFirstName={true}
      />
    </div>
  );
};

const RelationshipWithTarget = ({ connector, targetFirstName }) => {
  return (
    <div style={{ marginTop: '8px' }}>
      <SvgIcon component={relationship} style={{ width: 15, height: 14, marginRight: '20px' }} viewBox={'0 0 15 12'} />
      <SignalsList
        signals={_.get(connector, 'signals', [])}
        title={'Relationship with ' + targetFirstName}
        showSignallingContact={false}
        showLinks={false}
        onlyFirstName={true}
      />
    </div>
  );
};

const _ConnectorPrimary = ({ connector, target, alephUsers, userState, setSelectedUser, selectedUser }) => {
  const { Contact__c: sfid, Linkedin_Profile__c: linkedin_profile } = connector;
  const showCurrentAccount =
    connector.group !== 'Same Account' &&
    _.get(connector, 'Title') &&
    _.get(connector, 'Currrent_account_of_contact_role__c');
  return (
    <>
      <div>
        <b>{_.get(connector, 'Contact_Name__c')}</b>
        <ReferrerIcons linkedin_profile={linkedin_profile} sfid={sfid} />

        {showCurrentAccount && (
          <>
            <span style={{ marginRight: '5px', fontWeight: 300, color: '#7E7E7E', fontSize: '11px' }}> &#x25CF; </span>
            <span style={{ fontWeight: 300 }}> {_.get(connector, 'Title')} </span>
            <span
              style={{ marginRight: '5px', marginLeft: '5px', fontWeight: 300, color: '#7E7E7E', fontSize: '11px' }}
            >
              {' '}
              &#x25CF;{' '}
            </span>
            <span style={{ fontWeight: 300 }}> @{_.get(connector, 'Currrent_account_of_contact_role__c')} </span>
          </>
        )}
      </div>
      {showSharedWorkText(connector) && (
        <div>
          {_.get(connector, 'roles', []).map((role) => (
            <SharedRole
              key={_.get(role, 'Id')}
              role={role}
              connector={connector}
              target={target}
              admin={is_admin(userState)}
            />
          ))}
        </div>
      )}
      {is_admin(userState) && (
        <>
          {connector.connectionOnLinkedin && (
            <div style={{ marginTop: '10px', fontWeight: '300' }}>
              Connected on Linkedin to {_.get(target, 'FirstName')}{' '}
            </div>
          )}
          <Interactions connector={connector} />
          {!!_.get(connector, 'poscan_count') && (
            <div style={{ marginTop: '10px' }}>{_.get(connector, 'poscan_count')} Poscans</div>
          )}
          {!!_.get(connector, 'connector_count') && (
            <div style={{ marginTop: '10px' }}>{_.get(connector, 'connector_count')} Intros</div>
          )}
          <IntroPath
            connector={connector}
            alephUsers={alephUsers}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
          {!_.isEmpty(_.get(connector, 'alephSignals')) && <AlephSignals connector={connector} />}
          {!_.isEmpty(_.get(connector, 'signals')) && (
            <RelationshipWithTarget connector={connector} targetFirstName={_.get(target, 'FirstName')} />
          )}
        </>
      )}
    </>
  );
};

const ConnectorPrimary = withUserState(_ConnectorPrimary);

const _ConnectorSecondary = ({ connector }) => {
  return (
    <div style={{ color: 'grey', marginTop: '5px' }}>
      <>
        <div style={{ marginTop: '8px' }}>
          We are aware of {_.get(connector, 'connections_count', 0)} Linkedin connections.
          {!!_.get(connector, 'oxy_connections_count') && _.get(connector, 'oxy_connections_count') !== 65535 && (
            <span> From {_.get(connector, 'oxy_connections_count', 0)} in total</span>
          )}
        </div>
      </>
    </div>
  );
};
const ConnectorSecondary = withUserState(_ConnectorSecondary);

const _TamnuniActions = ({ isCheckedByType, performActionByType, c, userState }) => {
  return (
    <div style={{ float: 'right', marginLeft: '10px' }}>
      <SvgIcon component={tamnuniSvg} style={{ width: 36, height: 31, marginRight: '20px' }} viewBox={'0 0 36 31'} />

      <Tooltip title="Slack from tamnuni">
        <span>
          <Checkbox
            checked={isCheckedByType(c, 'Slack')}
            disableRipple
            inputProps={{ 'aria-labelledby': _.get(c, 'Linkedin_Profile__c') }}
            onClick={performActionByType(c, 'Slack')}
            style={{
              color: '#00A394',
              padding: '0px',
              marginRight: '12px',
              fontSize: '15px',
            }}
          />
          <SvgIcon component={slackLogo} viewBox={'0 0 2447.6 2452.5'} style={{ marginRight: '8px' }} />
          <span
            style={{
              fontWeight: 400,
              fontSize: '14px',
            }}
          >
            Slack
          </span>
        </span>
      </Tooltip>
      {is_talpod(userState) && (
        <>
          <Tooltip title="Create Email Draft">
            <span>
              <Checkbox
                checked={isCheckedByType(c, 'Gmail')}
                disableRipple
                inputProps={{ 'aria-labelledby': _.get(c, 'Linkedin_Profile__c') }}
                onClick={performActionByType(c, 'Gmail')}
                style={{
                  color: '#00A394',
                  padding: '0px',
                  marginRight: '12px',
                  fontSize: '15px',
                  marginLeft: '20px',
                }}
              />
              <SvgIcon component={gmailLogo} viewBox={'7.086 7.087 1277.149 924.008'} style={{ marginRight: '8px' }} />
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                }}
              >
                Gmail
              </span>
            </span>
          </Tooltip>
        </>
      )}
    </div>
  );
};
const TamnuniActions = withUserState(_TamnuniActions);

const _Referrer = ({
  c,
  isCheckedByType,
  target,
  userState,
  alephUsers,
  introGoal,
  hasRequestByType,
  performActionByType,
  selectedPoscan,
  referrerFromSf,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [lastReq, setLastReq] = useState('');
  const [reqCount, setReqCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState(
    alephUsers.find(({ Slack_Id__c }) => Slack_Id__c === _.get(c, 'owner_slack_id')),
  );
  const hide_message = () => setLastReq('');

  const onSlackGmailRequest = (e) => {
    setIsDisabled(true);
    setReqCount(reqCount + 1);
    if (_.isUndefined(e)) {
      setLastReq('Sent a slack message successfully');
    } else {
      setLastReq('There was an error sending a slack msg. plz contact Tal...');
      console.error(e);
    }
  };

  const getChannel = () => (isCheckedByType(c, 'Slack') ? 'Slack' : isCheckedByType(c, 'Gmail') ? 'Gmail' : undefined);
  const [comments, set_comments] = useState('');
  const handle_comments = (e) => set_comments(e.target.value);

  return (
    <ListItem
      key={'referrerListItem-' + _.get(c, 'Contact__c') + '-' + _.get(c, 'Account__c')}
      style={{
        paddingBottom: '16px',
        paddingTop: '16px',
        fontFamily: 'Merriweather Sans',
      }}
    >
      <div style={{ width: '100%' }}>
        <div style={{ display: 'inline-flex', width: '100%' }}>
          {is_talpod(userState) && (
            <Tooltip title={c.scoreExplaination || _.get(c, 'signals', []).map(connectorSignal).join(', ')}>
              <span style={{ marginRight: '5px' }}>{c.talpodScore}</span>
            </Tooltip>
          )}
          {is_admin(userState) && (
            <>
              {assignGroup({ c, selectedPoscan, userState }) === 'Network' && (
                <ScoreIconWithoutTooltip score={_.get(c, 'score')} />
              )}
              {assignGroup({ c, selectedPoscan, userState }) === 'Portcos' && (
                <ListItemIcon>
                  <AlephIcon fontSize="small" />
                </ListItemIcon>
              )}
            </>
          )}
          {!is_admin(userState) && (
            <ReferrerActions
              hasRequestByType={hasRequestByType}
              isCheckedByType={isCheckedByType}
              performActionByType={performActionByType}
              c={c}
            />
          )}
          <div style={{ width: '100%' }}>
            <div style={{ maxWidth: is_admin(userState) ? '75%' : '100%' }}>
              <ConnectorPrimary
                connector={c}
                target={_.pick(target, ['FirstName', 'name'])}
                alephUsers={alephUsers}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
              {is_admin(userState) && (
                <>
                  <ConnectorSecondary connector={c} />
                  <TamnuniActions isCheckedByType={isCheckedByType} performActionByType={performActionByType} c={c} />
                </>
              )}
            </div>
          </div>
        </div>
        {is_admin(userState) &&
          (_.has(c, 'fromSearch') || isCheckedByType(c, 'Slack') || isCheckedByType(c, 'Gmail')) && (
            <>
              <div>
                <OctopusActions
                  comments={comments}
                  handle_comments={handle_comments}
                  target={target}
                  connector={c}
                  channel={getChannel()}
                  on_request={onSlackGmailRequest}
                  alephUsers={alephUsers}
                  reqCount={reqCount}
                  isDisabled={isDisabled}
                  referrerFromSf={referrerFromSf}
                  introGoal={introGoal}
                  setIsDisabled={setIsDisabled}
                  selectedPoscan={selectedPoscan}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              </div>
              <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
                <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
                  {lastReq}
                </Alert>
              </Snackbar>
            </>
          )}
      </div>
    </ListItem>
  );
};

export const Referrer = withUserState(_Referrer);

const ReferrersSection = ({
  header,
  array,
  hasRequestByType,
  isCheckedByType,
  performActionByType,
  loadMore = false,
  target,
  ongoingProcesses,
  setOngoing,
  alephUsers,
  introGoal,
  selectedPoscan,
}) => {
  const [numberOfrReferrersToShow, setNumberOfrReferrersToShow] = useState(20);

  return (
    <>
      <Divider component="li" style={{ marginBottom: '10px' }} />
      <li style={{ marginBottom: '16px', fontWeight: 300, color: '#7E7E7E', fontFamily: 'Merriweather Sans' }}>
        <Typography display="block" variant="caption">
          {header}
        </Typography>
      </li>
      {loadMore && (
        <>
          {_.take(array, numberOfrReferrersToShow).map((c) => (
            <Referrer
              isCheckedByType={isCheckedByType}
              c={c}
              target={target}
              key={'referrer-' + _.get(c, 'Contact__c') + '-' + _.get(c, 'Account__c')}
              ongoingProcesses={ongoingProcesses}
              setOngoing={setOngoing}
              alephUsers={alephUsers}
              introGoal={introGoal}
              hasRequestByType={hasRequestByType}
              performActionByType={performActionByType}
              selectedPoscan={selectedPoscan}
            />
          ))}
          {numberOfrReferrersToShow < array.length && (
            <Button onClick={() => setNumberOfrReferrersToShow(numberOfrReferrersToShow + 20)}>Show More</Button>
          )}
        </>
      )}
      {!loadMore &&
        array.map((c) => (
          <Referrer
            isCheckedByType={isCheckedByType}
            c={c}
            target={target}
            key={'referrer-' + _.get(c, 'Contact__c') + '-' + _.get(c, 'Account__c')}
            ongoingProcesses={ongoingProcesses}
            setOngoing={setOngoing}
            alephUsers={alephUsers}
            introGoal={introGoal}
            hasRequestByType={hasRequestByType}
            performActionByType={performActionByType}
            selectedPoscan={selectedPoscan}
          />
        ))}
    </>
  );
};

const ReferrerFromSearch = ({
  onConnectorFoundFromSf,
  referrerFromSf,
  target,
  alephUsers,
  setOngoing,
  ongoingProcesses,
  introGoal,
  hasRequestByType,
  isCheckedByType,
  performActionByType,
  selectedPoscan,
}) => {
  const header = "Found someone who doesn't appear on the list? ask Tamnuni to reach out:";

  return (
    <>
      <Divider component="li" style={{ marginTop: '18px', marginBottom: '18px' }} />
      <Typography color="textSecondary" display="block" variant="caption">
        {header}
      </Typography>
      <SingleSfContactSearchBox
        onSelected={onConnectorFoundFromSf}
        key={'contact-search-sf'}
        label={'contact name from salesforce'}
        labelMin={'Search contact'}
      />
      {!_.isEmpty(referrerFromSf) && (
        <>
          {/*
            TODO: change isCheckedByType when adding gmail for tamnuni
            */}
          <Referrer
            isCheckedByType={isCheckedByType}
            c={referrerFromSf}
            target={target}
            referrerFromSf={true}
            ongoingProcesses={ongoingProcesses}
            setOngoing={setOngoing}
            alephUsers={alephUsers}
            introGoal={introGoal}
            hasRequestByType={hasRequestByType}
            performActionByType={performActionByType}
            selectedPoscan={selectedPoscan}
          />
        </>
      )}
    </>
  );
};

// TODO: a bit ugly, we use only the fields Tamnuni needs here and use the format from colleagues which is based on contact roles
const contactToConnectorFormat = (contact) => ({
  Contact__c: contact.Id,
  Contact_Name__c: contact.name,
  Currrent_account_of_contact_role__c: contact.account_name,
  fromSearch: true,
  signals: [],
  ..._.omit(contact, ['Id', 'name', 'account_name']),
});

export function Referrers({
  filteredReferrers,
  selectedReferrersSet,
  toggleReferrer,
  target,
  selected_poscan_requests,
  open,
  alephUsers,
  introGoal,
  selectedPoscan,
}) {
  const { userState } = useUserState();
  const hasRequestByType = (requestType) =>
    selected_poscan_requests.has(
      JSON.stringify({
        targetPublicId: target.public_identifier,
        ask: requestType,
      }),
    );

  const isCheckedByType = (c, requestType) =>
    selectedReferrersSet.has(
      JSON.stringify({
        referrerUrl: _.get(c, 'Linkedin_Profile__c'),
        targetPublicId: getPublicId(target),
        ask: requestType,
      }),
    );

  const performActionByType = (c, requestType) =>
    toggleReferrer({
      referrerUrl: _.get(c, 'Linkedin_Profile__c'),
      targetPublicId: getPublicId(target),
      ask: requestType,
    });

  const [sameAccountReferrers, otherReferrers] = _.partition(
    filteredReferrers,
    (c) => assignGroup({ c, selectedPoscan, userState }) === 'Same Account',
  );

  const [ongoingProcesses, setOngoing] = useState(getOnGoingProcesses());

  const [referrerFromSf, setReferrerFromSf] = useState(undefined);
  const onConnectorFoundFromSf = (contact) => {
    setReferrerFromSf(!!contact ? contactToConnectorFormat(contact) : undefined);
  };

  const sameAccountHeaderText = `Potential contacts from ${_.get(userState, 'aleph_user.Account.Name')} ${is_admin(userState) && !_.isEmpty(selectedPoscan) ? `& ${_.get(selectedPoscan, 'Account__c')}` : ''}:`;
  const networkHeaderText = is_admin(userState)
    ? 'Potential Connectors from the Aleph network'
    : "Additionally, you can mark specific contacts from announced Aleph portfolio companies that we'll check with regarding a reference:";

  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {!is_admin(userState) && (
            <div style={{ fontSize: '16px', color: '#747474', fontWeight: 700 }}>
              <Checkbox edge="start" checked={true} disabled={true} />
              Aleph Talent Team will search for people who can recommend
            </div>
          )}
          {is_admin(userState) && (
            <ReferrerFromSearch
              onConnectorFoundFromSf={onConnectorFoundFromSf}
              referrerFromSf={referrerFromSf}
              hasRequestByType={hasRequestByType}
              isCheckedByType={isCheckedByType}
              performActionByType={performActionByType}
              target={target}
              introGoal={introGoal}
              ongoingProcesses={ongoingProcesses}
              setOngoing={setOngoing}
              alephUsers={alephUsers}
              selectedPoscan={selectedPoscan}
            />
          )}
          {!_.isEmpty(sameAccountReferrers) && is_admin(userState) && (
            <ReferrersSection
              header={sameAccountHeaderText}
              alephUsers={alephUsers}
              array={sameAccountReferrers}
              hasRequestByType={hasRequestByType}
              isCheckedByType={isCheckedByType}
              performActionByType={performActionByType}
              target={target}
              ongoingProcesses={ongoingProcesses}
              setOngoing={setOngoing}
              introGoal={introGoal}
              selectedPoscan={selectedPoscan}
            />
          )}
          {!_.isEmpty(otherReferrers) && (
            <ReferrersSection
              header={networkHeaderText}
              alephUsers={alephUsers}
              loadMore={is_admin(userState)}
              array={otherReferrers}
              hasRequestByType={hasRequestByType}
              isCheckedByType={isCheckedByType}
              performActionByType={performActionByType}
              selectedPoscan={selectedPoscan}
              target={target}
              ongoingProcesses={ongoingProcesses}
              setOngoing={setOngoing}
              introGoal={introGoal}
            />
          )}
        </List>
      </Collapse>
    </>
  );
}
