import { TextField, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-lodash-debounce';

import { useAPI } from '../userContext';
import { search_pg_contacts_autocomplete, search_sf_contacts_autocomplete } from './api';

export const MultiPgContactSearchBox = ({ onSelected, label, labelMin }) => {
  const api = useAPI();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 200);
  const theme = useTheme();
  const small_screen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (inputValue.length < 5) {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    let current_value = debouncedInputValue;
    if (debouncedInputValue === '' || debouncedInputValue.length < 5) {
      return;
    }

    const search = async (val) => {
      setLoading(true);
      try {
        const contacts = await search_pg_contacts_autocomplete(val, api);
        if (val === current_value) {
          setOptions(contacts);
        }
      } finally {
        setLoading(false);
      }
    };
    search(current_value);
    return () => {
      current_value = debouncedInputValue;
    };
  }, [debouncedInputValue, api]);

  const handleChange = (event) => {
    const text = event.target.value;
    const lines = text.split('\n');
    const search = lines.pop(-1);
    const updated_value = _.concat(
      value,
      lines.map((x) => (_.isString(x) ? x.trim() : x)).filter((x) => x),
    );
    setValue(updated_value);
    setInputValue(search);
    if (!search.trim()) {
      onSelected(updated_value);
    }
    return false;
  };
  const handleSelected = (event, value) => {
    const filtered_value = value.map((x) => (_.isString(x) ? x.trim() : x)).filter((x) => x);
    onSelected(filtered_value);
    setValue(filtered_value);
    setInputValue('');
  };

  return (
    <Autocomplete
      style={{ width: '100%', marginTop: '2em' }}
      getOptionLabel={(option) => (option.id ? option.name : option)}
      renderOption={(option) => (
        <React.Fragment>{option.id ? `${option.name} (${option.headline})` : option}</React.Fragment>
      )}
      filterOptions={(x) => x}
      options={options}
      value={value}
      inputValue={inputValue}
      freeSolo
      autoComplete
      loading={loading}
      onChange={handleSelected}
      selectOnFocus={false}
      blurOnSelect={true}
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          label={!small_screen ? <em>{label}</em> : <em>{labelMin}</em>}
          variant="outlined"
          fullWidth
          onChange={handleChange}
          inputProps={{ ...params.inputProps, autoComplete: 'multi-sg-contact' }}
        />
      )}
    />
  );
};

export const SingleSfContactSearchBox = ({ onSelected, label, labelMin }) => {
  const api = useAPI();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 200);
  const theme = useTheme();
  const small_screen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (!inputValue || inputValue.length < 5) {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    let current_value = debouncedInputValue;
    if (debouncedInputValue === '' || debouncedInputValue.length < 5) {
      return;
    }

    const search = async (val) => {
      setLoading(true);
      try {
        const contacts = await search_sf_contacts_autocomplete(val, api);
        if (val === current_value) {
          setOptions(contacts);
        }
      } finally {
        setLoading(false);
      }
    };
    search(current_value);
    return () => {
      current_value = debouncedInputValue;
    };
  }, [debouncedInputValue, api]);

  const handleChange = (event) => {
    const contact = _.get(event, 'target.value', '');
    setInputValue(contact);
  };
  const handleSelected = (event, value) => {
    onSelected(value);
    setValue(value || '');
    setInputValue(_.get(value, 'name', ''));
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => _.get(option, 'name', '')}
      renderOption={(option) => (
        <React.Fragment>{`${option.name} (${option.sf_title} @ ${option.account_name})`}</React.Fragment>
      )}
      filterOptions={(x) => x}
      options={options}
      value={value}
      inputValue={inputValue}
      freeSolo
      autoComplete
      loading={loading}
      onChange={handleSelected}
      selectOnFocus={false}
      blurOnSelect={true}
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          label={!small_screen ? <em>{label}</em> : <em>{labelMin}</em>}
          variant="outlined"
          onChange={handleChange}
          size={'small'}
          inputProps={{ ...params.inputProps, autoComplete: 'single-sf-contact' }}
        />
      )}
    />
  );
};
