import { Checkbox, Chip, CircularProgress, Grid, Link, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import React, { useState } from 'react';

import { useAPI, withUserState } from '../../userContext';
import { ContactSfTasks } from '../../utils/contacts';
import { IntroDetails } from '../../widgets';
import { InteractionSourceSelector } from '../../widgets/contacts';
import { show_contact_history } from '../api';
import { AlephRequestButton } from './intro-request';

const _ReferrerActions = ({ isCheckedByType, performActionByType, hasRequestByType, c }) => {
  return (
    <>
      <div>
        {hasRequestByType('Reference Check') && (
          <div>
            <Tooltip title="Reference Check">
              <Checkbox
                edge="start"
                checked={isCheckedByType(c, 'Reference Check')}
                disableRipple
                inputProps={{ 'aria-labelledby': _.get(c, 'Linkedin_Profile__c') }}
                onClick={performActionByType(c, 'Reference Check')}
                style={{
                  color: '#00A394',
                  padding: '0px',
                  marginRight: '29px',
                  fontSize: '15px',
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};
export const ReferrerActions = withUserState(_ReferrerActions);

export const Interactions = ({ connector }) => {
  const [askedForTasks, SetAsked] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [showTasks, toggleTasks] = useState(false);
  const [tasks, setTasks] = useState([]);

  const api = useAPI();
  const toggleHistory = async () => {
    toggleTasks(!showTasks);
    if (_.isEmpty(tasks)) {
      try {
        setLoadingTasks(true);
        SetAsked(true);
        await show_contact_history({ contactId: connector.Contact__c }, api).then((res) => {
          setTasks(res.tasks);
          setLoadingTasks(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Link onClick={toggleHistory}>{showTasks ? 'Hide interactions' : 'See interactions'}</Link>
      {showTasks && (
        <ContactSfTasks
          tasks={tasks}
          askedForTasks={askedForTasks}
          loadingTasks={loadingTasks}
          style={{ marginTop: '12px' }}
        />
      )}
      {loadingTasks && <CircularProgress color="secondary" />}
    </div>
  );
};

export const AlephMemberSelector = ({ alephUsers, selectedUser, setSelectedUser, signals, isAleph = false }) => {
  const getBackgroundColor = (user) => {
    const feedback = _.find(signals, { Signalling_Contact__c: user.Contact_Id__c });
    const relationship = _.get(feedback, 'Feedback_on_relationship__c');
    return isAleph
      ? '#E4E4E4'
      : relationship === 'Familiar'
        ? '#F0FFF0'
        : relationship === 'Familiar - Weak Relationship'
          ? '#FFF8DC'
          : relationship === 'Not Familiar'
            ? '#fcbcb5'
            : '#E4E4E4';
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedUser(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '250px',
        backgroundColor: 'transparent',
        background: '#F9FFFE',
        border: '0.5px solid rgba(0, 163, 148, 0.76)',
        borderRadius: '10px',
      },
    },
  };

  return (
    <FormControl>
      <Select
        labelId="demo-mutiple-name-label"
        id="demo-mutiple-name"
        value={selectedUser}
        onChange={handleChange}
        input={<Input />}
        disableUnderline
        renderValue={(selectedUser) => (
          <Chip
            key={selectedUser.Id}
            label={selectedUser.Name}
            style={{ backgroundColor: getBackgroundColor(selectedUser), fontSize: '12px', height: '16px' }}
          />
        )}
        style={{ padding: 'opx' }}
        MenuProps={MenuProps}
      >
        {alephUsers.map((user) => (
          <MenuItem key={user.Id} value={user}>
            <Chip label={user.Name} style={{ backgroundColor: getBackgroundColor(user) }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const _OctopusActions = ({
  comments,
  handle_comments,
  target = false,
  connector,
  selectedPoscan = false,
  referrerFromSf = false,
  channel,
  on_request,
  isDisabled,
  introGoal,
  setIsDisabled,
  selectedUser,
}) => {
  const [selectedSource, setSelectedSource] = useState('Connect');
  const optionalSources = ['Connect', 'Facebook', 'Linkedin', 'Twitter'];

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {channel === 'Slack' && (
        <Grid item md={6} sm={6} xs={6}>
          <IntroDetails
            comments={comments}
            handle_comments={handle_comments}
            placeholder={''}
            label={`Intro text for ${channel === 'Slack' ? channel : 'Email to ' + _.get(connector, 'Contact_Name__c')}`}
            fullWidth
          />
        </Grid>
      )}
      <Grid item md={2} sm={2} xs={2}>
        {referrerFromSf && (
          <InteractionSourceSelector
            optionalSources={optionalSources}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />
        )}
      </Grid>
      <Grid item md={2} sm={2} xs={2}>
        <AlephRequestButton
          target={target}
          connector={connector}
          comments={comments}
          on_request={on_request}
          setIsDisabled={setIsDisabled}
          selectedPoscan={selectedPoscan}
          selectedSource={selectedSource}
          introGoal={introGoal}
          channel={channel}
          selectedUser={selectedUser}
          isDisabled={isDisabled || _.get(selectedUser, 'Name', '') === 'Ampliphy Ampliphius' || _.isEmpty(channel)}
        />
      </Grid>
    </Grid>
  );
};

export const OctopusActions = withUserState(_OctopusActions);

export const getOnGoingProcesses = () => {
  const item = localStorage.getItem('potential-referencers');
  return item ? JSON.parse(item) : [];
};
