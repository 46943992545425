import { Button, Grid, Paper, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Route, HashRouter as Router, Switch, useRouteMatch } from 'react-router-dom';

import { useAPI } from '../userContext';

export const LeaderBoard = () => (
  <div>
    <h1>LeaderBoard</h1>
    <ul>
      <li>#1 - Sagiv Malihi</li>
      <li>#2 - Uri Ar</li>
    </ul>
  </div>
);

export const GameView = () => {
  const api = useAPI();
  const [connections, setConnections] = useState([]);
  const [loading, set_loading] = useState(true);
  const [c, set_c] = useState({});
  useEffect(() => {
    const fetch_data = async () => {
      set_loading(true);
      try {
        const conns = await api('user/contacts');
        setConnections(conns.contacts);
        set_c(_.sample(conns.contacts));
      } finally {
        set_loading(false);
      }
    };
    fetch_data();
  }, []);

  const choose_new = () => {
    const new_conns = connections.filter((con) => con.id !== c.id);
    const new_c = _.sample(new_conns) || {};
    setConnections(new_conns);
    set_c(new_c);
  };

  const update_relationship = (feedback) => {
    api(`user/relationship/${c.id}`, { method: 'POST', body: JSON.stringify({ feedback }) });
    choose_new();
  };

  const dont_know = () => update_relationship('Not Familiar');
  const know_each_other = () => update_relationship('Familiar');
  const weak_relationship = () => update_relationship('Familiar - Weak Relationship');

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.defaultPrevented) {
        return;
      }
      switch (event.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          console.log('down press');
          weak_relationship();
          break;

        case 'Left': // IE/Edge specific value
        case 'ArrowLeft':
          console.log('left press');
          dont_know();
          break;
        case 'Right': // IE/Edge specific value
        case 'ArrowRight':
          console.log('right press');
          know_each_other();
          break;
        default:
          return; // Quit when this doesn't handle the key event.
      }
      // Cancel the default action to avoid it being handled twice
    };
    // subscribe event
    console.log('subscribe');
    window.addEventListener('keydown', handleKeydown);
    return () => {
      // unsubscribe event
      console.log('unsubscribe');
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [dont_know, know_each_other, weak_relationship]);

  const start_date = c.Start_Date__c ? moment(c.Start_Date__c).format('YYYY') : '';
  const end_date = c.End_Date__c ? moment(c.End_Date__c).format('YYYY') : 'today';
  const rand = Math.round((0.8 + (Math.random() * 20) / 100) * 100);
  return (
    <Grid container alignItems="center" justifyContent="center">
      {loading ? (
        <Skeleton />
      ) : _.isEmpty(connections) ? (
        <Grid item sm={8} lg={6} xs={12}>
          <Paper elevation={1} style={{ padding: '3rem' }}>
            <Typography variant="h4" style={{ marginBottom: '0.5em' }}>
              All Done! check back later to rank more contacts
            </Typography>
          </Paper>
        </Grid>
      ) : (
        <>
          <Grid item sm={8} lg={6} xs={12}>
            <Paper elevation={1} style={{ padding: '3rem' }}>
              <Typography variant="h4" style={{ marginBottom: '0.3em' }}>
                Do you know --{' '}
                <b>
                  <a target="_blank" rel="noopener noreferrer" href={c.Linkedin_Profile__c}>
                    {c.Name}
                  </a>
                </b>
                ?
              </Typography>
              <Typography variant="h5" style={{ marginBottom: '0.5em', fontWeight: 'normal', fontSize: '1rem' }}>
                Ampliphy AI estimates an <b>{rand}%</b> chance that you do
              </Typography>
              <div style={{ display: 'flex' }}>
                <iframe
                  height="640px"
                  style={{
                    transform: 'scale(0.4)',
                    marginTop: '-180px',
                    marginBottom: '-160px',
                    marginRight: '-50px',
                    marginLeft: '-50px',
                  }}
                  src={`${c.Linkedin_Profile__c}/overlay/photo/`}
                  title="linkedin profile"
                />
                <div>
                  {
                    !_.isEmpty(c.Account_Name__c) && !_.isEmpty(start_date) && !_.isEmpty(end_date) && (
                      <p>
                        You may know each other from {c.Account_Name__c} (between {start_date} and {end_date})
                      </p>
                    ) //
                  }
                  <p>Their title: {c.title}</p>
                  {c.connected_on_linkedin && <p>You are connected on linkedin</p>}
                </div>
              </div>
              <Grid container justifyContent="space-around">
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<ArrowBackIcon />}
                  onClick={dont_know}
                  size="large"
                >
                  Nope
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={weak_relationship}
                  endIcon={<ArrowDownwardIcon />}
                  size="large"
                >
                  Weak connection
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  endIcon={<ArrowForwardIcon />}
                  onClick={know_each_other}
                  size="large"
                >
                  Yes!
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const RelationshipGame = () => {
  const { path } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <GameView />
        </Route>
        <Route path={`${path}/leaderboard`}>
          <LeaderBoard />
        </Route>
      </Switch>
    </Router>
  );
};
