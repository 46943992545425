import { Button } from '@material-ui/core';
import React, { useState } from 'react';

import { useAPI, withUserState } from '../userContext';

const _GetAppToken = ({ userState }) => {
  const api = useAPI();
  const [token, set_token] = useState('');
  const [error, set_error] = useState('');

  const handle_submit = async (e) => {
    e.preventDefault();
    const res = await api('admin/get-app-token', { method: 'GET' });
    if (res.success) {
      await navigator.clipboard.writeText(res.token);
      set_token(res.token);
    } else {
      set_error(res.error);
    }
  };
  return (
    <div style={{ textAlign: 'center' }}>
      {!token && !error && (
        <Button
          type="submit"
          form="login-as-form"
          color="primary"
          variant="contained"
          style={{ marginTop: '1em' }}
          onClick={handle_submit}
        >
          Get App Token
        </Button>
      )}
      {!!token && <h5>your token was copied to clipboard</h5>}
      {!!error && <h5>error: {error}</h5>}
    </div>
  );
};
export const GetAppToken = withUserState(_GetAppToken);
