import { Button, FormControlLabel, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';

import { getUserAccountId, is_admin, withUserState } from '../../userContext';
import { getRequesetType } from '../../utils/poscan-utils';
import { IntroDetails } from '../../widgets';
import { PositionsSearchBox } from '../../widgets/positions-searchbox';
import { TalentTooltip, WhiteCheckbox } from '../../widgets/tooltips';
import { SubmitPoscanButton } from '../utils/intro-request';

const _BottomMenu = ({
  poscanRequests,
  close_bottom_menu_tooltip,
  first_request_bottom_menu_tooltip_never_open,
  first_request_bottom_menu_tooltip_open,
  handle_never_show_bottom_menu_toggle,
  selectedPosition,
  requestSource,
  selectedPositionId,
  userState,
  setSelectedPosition,
  potentialTarget,
  comments,
  handle_comments,
  on_request,
  refCheckDetails,
  handleRefcheckDetails,
  personalizedMsg,
  handlePersonalizedMsg,
  available,
  recCount,
}) => {
  const accountId = getUserAccountId(userState);

  return (
    <>
      {!is_admin(userState) && (
        <Grid
          container
          justifyContent="center"
          className={
            _.isEmpty(poscanRequests)
              ? 'intro-req-area intro-req-area-talent'
              : 'intro-req-area intro-req-area-talent intro-req-area-active'
          }
          style={{ backgroundColor: '#f4f4f4' }}
        >
          <TalentTooltip
            title={
              <>
                <Alert
                  style={{ alignItems: 'center' }}
                  severity="info"
                  action={
                    <Button
                      style={{ border: '1px solid white' }}
                      color="inherit"
                      size="small"
                      onClick={close_bottom_menu_tooltip}
                    >
                      Got it!
                    </Button>
                  }
                >
                  Please choose the relevant position from the list below. add any additional details that can help us
                  find the right contact in comments
                </Alert>
                <FormControlLabel
                  style={{ marginBottom: '15px', marginTop: '5px', height: '0' }}
                  control={
                    <WhiteCheckbox
                      size="small"
                      checked={first_request_bottom_menu_tooltip_never_open}
                      onChange={handle_never_show_bottom_menu_toggle}
                    />
                  }
                  label={<span style={{ fontSize: '0.8em' }}>{"Don't show this again"}</span>}
                />
              </>
            }
            open={first_request_bottom_menu_tooltip_open && !_.isEmpty(poscanRequests) && _.isEmpty(selectedPosition)}
            placement="top"
            PopperProps={{ disablePortal: true }}
          >
            <Grid container spacing={2} justifyContent="center" alignItems="center" className="intro-req-area-inner">
              <Grid item md={2} sm={2} xs={2} xl={2} lg={2}>
                <PositionsSearchBox
                  onSelected={(value) => setSelectedPosition(value)}
                  label={'Choose a position'}
                  labelMin={'Position'}
                  selectedPositionId={selectedPositionId}
                  accountId={accountId}
                  backgroundColor={'white'}
                />
              </Grid>
              {getRequesetType(poscanRequests).includes('Reference Check') && (
                <Grid item md={6} sm={6} xs={6} xl={6} lg={6}>
                  <IntroDetails
                    comments={refCheckDetails}
                    handle_comments={handleRefcheckDetails}
                    label={''}
                    maxRows={3}
                    placeholder={'Please specify what you’d like to check'}
                    backgroundColor={'white'}
                  />
                </Grid>
              )}
              {getRequesetType(poscanRequests).includes('Intro Request') && (
                <Grid item md={6} sm={6} xs={6} xl={6} lg={6}>
                  <IntroDetails
                    comments={personalizedMsg}
                    handle_comments={handlePersonalizedMsg}
                    maxRows={3}
                    label={''}
                    placeholder={'What would you say to convince the candidate it’s the right job for them?'}
                    backgroundColor={'white'}
                  />
                </Grid>
              )}
              <Grid item md={2} sm={2} xs={2} xl={2} lg={2}>
                <IntroDetails
                  showPositions={!_.isEmpty(potentialTarget)}
                  comments={comments}
                  handle_comments={handle_comments}
                  placeholder={'Additional Comments'}
                  backgroundColor={'white'}
                  maxRows={3}
                  label={''}
                />
              </Grid>
              <Grid item md={1} sm={1} xs={1} xl={1} lg={1}>
                <SubmitPoscanButton
                  on_request={on_request}
                  personalizedMsg={personalizedMsg}
                  refCheckDetails={refCheckDetails}
                  comments={comments}
                  poscanRequests={poscanRequests}
                  selectedPosition={selectedPosition}
                  requestSource={requestSource}
                  available={available}
                  recCount={recCount}
                />
              </Grid>
            </Grid>
          </TalentTooltip>
        </Grid>
      )}
    </>
  );
};

export const BottomMenu = withUserState(_BottomMenu);
