import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useAPI, withUserState } from '../userContext';
import { SIGNAL_DIRECTIONS, SKILLS } from '../utils/sf-consts';
import { get_signal_types } from '../widgets/api';
import { create_contact_signal } from './api';
import { SingleSfContactSearchBox } from './contact-searchbox';

export const _SignalDialog = ({
  showDialog,
  toggleDialog,
  contact,
  source,
  type,
  subType,
  direction,
  handleCreatedSignal,
  setLastReq,
  byContact = undefined,
  userState,
}) => {
  const api = useAPI();
  const [signalDetails, setSignalDetails] = useState({
    Sub_Type__c: subType || '',
    Type__c: type || '',
    Direction__c: direction || 'Neutral',
    Source__c: source || '',
    Details__c: '',
    userChange: false,
    Signalling_Contact__c: _.get(byContact, 'Id') || '',
    Contact__c: contact.Id || '',
    Skill__c: undefined,
  });
  const [creatingSignal, setCreatingSignal] = useState(false);
  const [signalTypes, setSignalTypes] = useState([]);

  useEffect(() => {
    async function fetchTypes() {
      return get_signal_types(api).then(({ types }) => setSignalTypes(types));
    }
    fetchTypes();
  }, [api]);

  const selectByContact = (contact) => {
    setSignalDetails({
      Signalling_Contact__c: _.get(contact, 'Id', ''),
      userChange: true,
      ..._.omit(signalDetails, ['Signalling_Contact__c', 'userChange']),
    });
  };

  const handleTypeChange = (event) => {
    const { value } = event.target;
    setSignalDetails({
      Type__c: value,
      userChange: true,
      ..._.omit(signalDetails, ['Type__c', 'userChange']),
    });
  };

  const isOpinion = () => _.get(signalDetails, 'Type__c') === 'Opinion';

  const handleDirectionChange = (event) => {
    const { value } = event.target;
    setSignalDetails({
      Direction__c: value,
      userChange: true,
      ..._.omit(signalDetails, ['Direction__c', 'userChange']),
    });
  };

  const handleSkillChange = (event, option) => {
    const Skill__c = option.map(({ apiName }) => apiName).join(';');
    setSignalDetails({
      Skill__c,
      userChange: true,
      ..._.omit(signalDetails, ['Skill__c', 'userChange']),
    });
  };

  const createSignal = async () => {
    setCreatingSignal(true);
    try {
      const result = await create_contact_signal(_.omit(signalDetails, ['userChange']), api);
      handleCreatedSignal(result.signal);
      setCreatingSignal(false);
      setLastReq('Woohoo! the signal was added successfully');
    } catch (e) {
      setLastReq('Oops, looks like there was an error creating the signal. Please try again or contact an EIR');
    }
    toggleDialog();
  };
  const handleDetailsChange = (event) => {
    setSignalDetails({
      Details__c: event.target.value,
      userChange: true,
      ..._.omit(signalDetails, ['Details__c', 'userChange']),
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menu_props = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };

  const disableSave =
    _.isEmpty(_.get(signalDetails, 'Type__c')) ||
    _.isEmpty(_.get(signalDetails, 'Signalling_Contact__c')) ||
    _.isEmpty(_.get(signalDetails, 'Contact__c')) ||
    (_.get(signalDetails, 'Type__c') === 'Opinion' && _.isEmpty(_.get(signalDetails, 'Direction__c'))) ||
    creatingSignal;

  return (
    <Dialog
      open={showDialog}
      onClose={toggleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="add-signal-dialog">{'Add Signal'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Share Details about the relationship with {contact.FirstName}. <br />
          Any professional/Personal feedback can help
        </DialogContentText>
        <div>
          {!byContact && (
            <SingleSfContactSearchBox
              onSelected={selectByContact}
              key={'by-contact-search-sf'}
              label={'By Contact'}
              labelMin={'By Contact'}
            />
          )}
          {byContact && <div>By Contact: {_.get(byContact, 'name')}</div>}
          <FormControl fullWidth>
            <InputLabel id="signal-type-selector-input-label">Type</InputLabel>
            <Select
              labelId="signal-type-selector-label"
              id="signal-type-selector"
              value={_.get(signalDetails, 'Type__c')}
              onChange={handleTypeChange}
              input={<Input />}
              menu_props={menu_props}
              fullWidth
            >
              {signalTypes.map(({ fullName, label }) => (
                <MenuItem key={fullName} value={fullName}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isOpinion() && (
            <>
              <FormControl fullWidth>
                <InputLabel id="signal-direction-selector-input-label">Direction</InputLabel>
                <Select
                  labelId="signal-direction-selector-label"
                  id="signal-direction-selector"
                  value={_.get(signalDetails, 'Direction__c')}
                  onChange={handleDirectionChange}
                  input={<Input />}
                  menu_props={menu_props}
                  fullWidth
                >
                  {_.concat(
                    SIGNAL_DIRECTIONS.map((d) => (
                      <MenuItem key={d} value={d}>
                        {d}
                      </MenuItem>
                    )),
                  )}
                </Select>
              </FormControl>
              <Autocomplete
                style={{ width: '80%' }}
                getOptionLabel={(option) => _.get(option, 'label', '')}
                renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                options={SKILLS}
                value={_.get(signalDetails, 'Skill__c')}
                autoComplete
                multiple
                onChange={handleSkillChange}
                selectOnFocus={false}
                freeSolo={false}
                renderInput={(params) => <TextField {...params} label={'Skill'} />}
              />
            </>
          )}
          <TextField
            id="signal-details"
            type="text"
            label="Details"
            value={_.get(signalDetails, 'Details__c')}
            onChange={handleDetailsChange}
            variant="outlined"
            multiline
            fullWidth
            rows={3}
            style={{ marginTop: '24px' }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={createSignal} color="primary" disabled={disableSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const SignalDialog = withUserState(_SignalDialog);
