import { Button, Link, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import ramzorKnow from '../static/ramzor/green.svg';
import ramzorPortco from '../static/ramzor/grey.svg';
import ramzorLow from '../static/ramzor/orange.svg';
import ramzorCheck from '../static/ramzor/yellow.svg';
import tamnuniSvg from '../static/tamnuni.svg';
import { SalesforceIcon } from '../widgets';
import { SignalDialog } from '../widgets/create-signal';
import { salesforce_signal_link, salesforce_task_link } from './misc';
import { ALEPH_ACCOUNT_ID } from './sf-consts';

export function getName(contact) {
  if (!!contact.name) {
    return contact.name;
  }
  const p = _.get(contact, 'profile.profile');
  return `${p.firstName} ${p.lastName}`;
}

export function getHeadline(contact) {
  return _.get(contact, 'profile.profile.headline');
}

export function getPublicId(contact) {
  return contact.public_identifier;
}

export function getSfId(contact) {
  return _.get(contact, 'Id');
}
export function getConnectionStrengthTalent(score) {
  return {
    0: 'None',
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Very high',
  }[score];
}

export function getConnectionStrengthSales(score) {
  return {
    0: 'None',
    1: 'Low (but worth trying)',
    2: 'Medium (but worth trying)',
    3: 'High',
    4: 'Very high',
  }[score];
}

export function ContactSfTasks({ tasks, askedForTasks, loadingTasks }) {
  return (
    <div>
      {_.isArray(tasks) && !_.isEmpty(tasks) && (
        <div>
          {tasks.map(({ Id, Subject, ActivityDate, Owner }) => {
            return (
              <div key={'task-container-' + Id}>
                <Link href={salesforce_task_link(Id)} target="_blank" key={'task-' + Id} style={{ marginRight: '5px' }}>
                  <SalesforceIcon />
                </Link>
                <b>{_.get(Owner, 'FirstName')}</b>: {Subject} {moment(ActivityDate).format('DD MMM YY')}
              </div>
            );
          })}
        </div>
      )}
      {!loadingTasks && askedForTasks && _.isEmpty(tasks) && <b>No History on Salesforce</b>}
    </div>
  );
}

export function isAlephOrPortco({ Current_Account_Type_of_Contact__c, AccountId }) {
  return Current_Account_Type_of_Contact__c === 'Portfolio' || AccountId === ALEPH_ACCOUNT_ID;
}

export function getRelatedTargetPageUrl({ public_identifier, fullName }) {
  return `../#/talent/related-targets/?linkedin_id=${public_identifier}&fullName=${fullName}`;
}

export const getContactEmail = ({ Email, Secondary_Email__c }) => Email || Secondary_Email__c;

export const RamzorIcon = ({ c, size, marginBottom = '0px' }) => {
  const score = _.get(c, 'chanceToConnect', _.get(c, 'Ramzor_Score__c', 'Low likelihood'));
  return (
    <img
      height={`${size}px`}
      src={
        score === 'High likelihood'
          ? ramzorCheck
          : score === 'Need to check'
            ? ramzorCheck
            : score === 'We know the target'
              ? ramzorKnow
              : _.includes(['Low likelihood', 'Not on database'], score)
                ? ramzorLow
                : ramzorPortco
      }
      style={{ marginRight: '8px', verticalAlign: 'text-bottom', marginBottom }}
      alt={''}
    />
  );
};

export const LinkToTamnuni = ({ public_id_linkedin }) => {
  return (
    <div style={{ marginTop: '8px' }}>
      <Link href={`./#/talent/?linkedin_id=${public_id_linkedin}`} target="_blank">
        <img src={tamnuniSvg} height={31} width={36} alt={''} />
      </Link>
    </div>
  );
};

export function getTalentPageUrl({ public_identifier, fullName, ask }) {
  return `../#/talent/?linkedin_id=${public_identifier}&fullName=${fullName}&request_source=ramzor&request_type=${ask}`;
}

export const getRamzorScoreText = (c) => {
  const score = _.get(c, 'chanceToConnect', _.get(c, 'Ramzor_Score__c')) || 'Low likelihood';
  return {
    'High likelihood': 'Need to check', //todo - remove
    'Need to check': 'Need to check',
    'Portfolio employee': 'Portfolio Employee',
    'Low likelihood': 'Low likelihood',
    'We know the target': `Aleph knows ${_.get(c, 'FirstName')}`,
    'Not on database': 'Low likelihood',
    'logged out': '',
  }[score];
};

export const CreateSignal = ({ Id, FirstName, byContact }) => {
  const toggleDialog = () => setShowDialog(!showDialog);
  const [showDialog, setShowDialog] = useState(false);
  const [lastReq, setLastReq] = useState('');
  const [newSignal, setNewSignal] = useState('');
  const handleCreatedSignal = (s) => {
    setNewSignal(s);
  };
  const hide_message = () => setLastReq('');

  return (
    <div>
      <Button size={'small'} color={'primary'} variant={'outlined'} onClick={toggleDialog}>
        Signal
      </Button>
      <SignalDialog
        showDialog={showDialog}
        toggleDialog={toggleDialog}
        contact={{ Id, FirstName }}
        source={'Talent Connect'}
        subType={'Talent Referral'}
        byContact={byContact}
        setLastReq={setLastReq}
        handleCreatedSignal={handleCreatedSignal}
      />
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
          <Link href={salesforce_signal_link(_.get(newSignal, 'Id'))} target="_blank" style={{ marginRight: '2px' }}>
            <SalesforceIcon fontSize="small" />
          </Link>
          {lastReq}
        </Alert>
      </Snackbar>
    </div>
  );
};
