import {
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import likeClicked from '../../static/focus/like_click.svg';
import likeHover from '../../static/focus/like_hover.svg';
import like from '../../static/focus/like.svg';
import dislikeClicked from '../../static/focus/unlike_click.svg';
import dislikeHover from '../../static/focus/unlike_hover.svg';
import dislike from '../../static/focus/unlike.svg';
import warningSVG from '../../static/focus/warning.svg';
import { getUserAccountName, isTamnuniUser, useAPI, withUserState } from '../../userContext';
import { getPeriodText } from '../../utils/dates';
import { addQueryParamIfNeeded } from '../../utils/misc';
import { PortcoFavicon } from '../../widgets/portco-favicons';
import { ConnectorButtonTooltip, ConnectorTooltip } from '../../widgets/tooltips';
import { get_portco_connectors_full, mark_relevance, submit_connector_feedback } from './api';
import { UserFeedback } from './feedback';
import { ConnectorTamnuniComponent, TamnuniCycleEnded } from './portcos-tamnuni-components';
import { ConnectorScoreFull, getReachoutId, UseAlephNetwork } from './utils';

const StyledTableRow = styled(TableRow)(() => ({
  border: '0',
}));

const cell_styles = {
  border: '0',
};

const getScoreText = (chance_to_connect) => {
  switch (chance_to_connect) {
    case 'good chance':
      return 'high';
    case 'some chance':
      return 'some';
    case 'long shot':
      return 'slim';
    default:
      return null;
  }
};

const ConnectorTableCell = styled(TableCell)(() => ({
  ...cell_styles,
  fontFamily: 'Merriweather Sans',
  fontStyle: 'normal',
  fontWeight: '300',
  width: window.innerWidth > 1440 ? '70%' : '60%',
  paddingLeft: '32px',
}));

const ButtonsTableCell = styled(TableCell)(() => ({
  ...cell_styles,
  textAlign: 'right',
  paddingRight: '32px',
}));

const ConnectorFeedbackModal = ({ connector, showConnectorFeedback, setShowConnectorFeedback, target, relevance }) => {
  const api = useAPI();
  const handleClose = () => {
    setShowConnectorFeedback(false);
    relevance === 'sent tamnuni' && addQueryParamIfNeeded('post_creation', 'true');
  };
  const submitConnectorFeedback = async () => {
    setShowConnectorFeedback(false);
    await submit_connector_feedback({ connector, target, comments }, api);
  };

  const [comments, set_comments] = useState('');
  const handle_comments = (e) => set_comments(e.target.value);

  return (
    <Modal open={showConnectorFeedback} onClose={handleClose}>
      <div style={{ outline: 'none' }}>
        <div
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '30%' }}
        >
          <Paper style={{ padding: 16, borderRadius: '22px', border: 'none' }}>
            <div style={{ textAlign: 'right', marginRight: '12px 12px', color: '#00A394' }}>
              <Close onClick={handleClose} />
            </div>
            <div style={{ marginLeft: '40px', marginBottom: '40px', marginRight: '14px', marginTop: '15px' }}>
              <div
                style={{
                  marginBottom: '18px',
                  fontFamily: 'Merriweather Sans',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  textAlign: 'left',
                }}
              >
                Why {relevance !== 'irrelevant' ? 'is' : "isn't"} {_.get(connector, 'connector_name')} relevant?
              </div>
              <div style={{ marginBottom: '18px' }}>
                <TextField
                  placeholder={'Anything that can help us improve our algorithm'}
                  value={comments}
                  onChange={handle_comments}
                  multiline
                  minRows={4}
                  type={'text'}
                  style={{
                    width: '90%',
                    backgroundColor: 'white',
                    border: '1px solid rgba(0, 0, 0, 0.20)',
                    borderRadius: '3px',
                    color: 'rgba(0, 0, 0, 0.3)',
                    fontFamily: 'Merriweather Sans',
                    fontWeight: 300,
                    fontSize: '13px',
                    lineHeight: '16px',
                    padding: '4px 14px 14px 14px',
                  }}
                  InputProps={{
                    style: {
                      fontFamily: 'Merriweather Sans',
                      fontWeight: 300,
                      fontSize: '13px',
                      lineHeight: '20px',
                    },
                    disableUnderline: true,
                  }}
                />
              </div>
              <Button
                variant="outlined"
                style={{
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  color: 'white',
                  backgroundColor: '#00A394',
                  border: '1px solid #00A394',
                  borderRadius: '18px',
                  marginRight: '8px',
                  height: '30px',
                  fontSize: '15px',
                  lineHeight: '18px',
                  padding: '3px 10px 3px 10px',
                  width: '100px',
                }}
                onClick={submitConnectorFeedback}
              >
                Submit
              </Button>

              <Button
                variant="outlined"
                style={{
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  borderRadius: '18px',
                  marginRight: '8px',
                  height: '30px',
                  fontSize: '15px',
                  lineHeight: '18px',
                  padding: '3px 10px 3px 10px',
                  width: '100px',
                  color: '#00A394',
                  backgroundColor: 'transparent',
                  border: '1px solid #00A394',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    </Modal>
  );
};

const ReachoutDraftButton = ({ handler, clicked, clickedText, defaultText }) => {
  const [hover, setHover] = useState(false);
  return (
    <Button
      onClick={handler}
      className="draftButton"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        textTransform: 'none',
        paddingLeft: '14px',
        paddingTop: '5px',
        paddingRight: '14px',
        height: '32px',
        borderRadius: '22px',
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '15px',
        borderWidth: '1px',
        fontStyle: 'normal',
        color: hover ? '#00A394' : clicked ? 'white' : '#00A394',
        backgroundColor: hover ? 'rgba(0,163,148,0.12)' : clicked ? '#00A394' : 'transparent',
        border: hover ? '1.06739px solid #00A394' : '1px solid #00A394',
        marginRight: '8px',
        lineHeight: '18px',
      }}
    >
      {' '}
      {clicked ? clickedText : defaultText}
    </Button>
  );
};
const DraftToConnector = ({ relevance, connector, target, userState, setRelevance, setShowConnectorFeedback }) => {
  const api = useAPI();

  const { connector_name: connectorFullName, chance_to_connect_v3: chance_to_connect } = connector;
  const { name: targetFullName, public_identifier: targetPublicId, FirstName: targetFirstName } = target;
  const connectorFirstName = connectorFullName.split(' ').shift();
  const targetLinkedin = `https://www.linkedin.com/in/${targetPublicId}`;
  const sharedCompanies = _.get(connector, 'relationships', []).map(({ shared_company }) => shared_company);

  const reachoutId = getReachoutId({ connector, target, userState });

  const getDraftText = async () => {
    return `Hey ${connectorFirstName}!\nI saw you worked with ${targetFullName} ${targetLinkedin} at ${sharedCompanies.join(' & ')}.\nDid you know each other? Any feedback you can share? Asking because ${targetFirstName} may be a good match for ${getUserAccountName(userState)}`;
  };

  const copyText = async () => {
    chance_to_connect === 'long shot' &&
      !_.includes(['relevant', 'copied reachout draft'], relevance) &&
      setShowConnectorFeedback(true);
    const text = await getDraftText();

    await navigator.clipboard.writeText(text);
    setRelevance(relevance === 'copied reachout draft' ? undefined : 'copied reachout draft');
    await mark_relevance({ connector, target, relevance: 'copied reachout draft', reachoutId }, api);
  };

  return (
    <ReachoutDraftButton
      clicked={relevance === 'copied reachout draft'}
      handler={copyText}
      clickedText={'Copied'}
      defaultText={'Copy Draft'}
    />
  );
};

const RelevanceButton = ({ markRelevant, isPrimary, title, srcDefault, srcClicked, srcHover, marginRight = '0px' }) => {
  const [hover, setHover] = useState(false);
  return (
    <Link onClick={markRelevant} style={{ marginRight }}>
      <ConnectorButtonTooltip title={title} placement={'top'}>
        <span onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <img
            height="32px"
            src={hover && !isPrimary ? srcHover : isPrimary ? srcClicked : srcDefault}
            style={{ verticalAlign: 'middle' }}
            alt={''}
          />
        </span>
      </ConnectorButtonTooltip>
    </Link>
  );
};

const RelationshipComponent = ({ relationship, connector }) => {
  const {
    shared_company,
    shared_tenures_titles_connector,
    shared_tenures_titles_colleague,
    shared_months_tenure,
    israel_related_employees_count_start_year_tenure,
    end_date_tenure,
    start_date_tenure,
  } = relationship;
  return (
    <div
      key={'shared_company-' + shared_company + 'connector_public_id-' + _.get(connector, 'connector_public_id')}
      style={{
        fontFamily: 'Merriweather Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '25px',
        width: '100%',
      }}
    >
      <p style={{ margin: '10px 0 10px 0' }}>
        {' '}
        <b>
          <Link href={'https://www.linkedin.com/in/' + _.get(connector, 'connector_public_id')} target="_blank">
            {_.get(connector, 'connector_name')}
          </Link>{' '}
        </b>{' '}
        {_.get(connector, 'source') === 'Nemp' ? `(${_.get(connector, 'signaling_contact_name')}'s contact) ` : ''}was{' '}
        {shared_tenures_titles_connector.join(' / ')}
        <br /> <b> {_.get(connector, 'FirstName')} </b> was {shared_tenures_titles_colleague.join(' / ')}
        <br />
        <b>@{shared_company}</b> • Israel • {getPeriodText(shared_months_tenure, true)} • Started in{' '}
        {moment(start_date_tenure).format('YYYY')} w/{israel_related_employees_count_start_year_tenure} employees
      </p>
    </div>
  );
};

const ConnectorScoreWithRelationship = ({ connector }) => {
  return (
    <div style={{ display: 'flex', marginTop: '2px', alignItems: 'flex-start' }}>
      <ConnectorTooltip
        title={`${_.capitalize(getScoreText(_.get(connector, 'chance_to_connect_to_display')))} likelihood of success`}
        placement={'top'}
      >
        <div style={{ marginRight: '12px', lineHeight: '25px', marginTop: '10px' }}>
          <ConnectorScoreFull chance_to_connect={_.get(connector, 'chance_to_connect_to_display')} />
        </div>
      </ConnectorTooltip>
      <div>
        {connector.relationships.map((relationship, i) => (
          <RelationshipComponent
            relationship={relationship}
            connector={connector}
            key={`relationship-component-${i}-${_.get(connector, 'connector_public_id')}`}
          />
        ))}
      </div>
    </div>
  );
};

const _Connector = ({
  connector,
  backgroundColor,
  target,
  userState,
  hasActiveReachouts,
  isExecuting,
  onSendingTamnuni,
}) => {
  const api = useAPI();
  const [relevance, setRelevance] = useState();
  const [showConnectorFeedback, setShowConnectorFeedback] = useState(false);
  const markRelevant = async () => {
    _.get(connector, 'chance_to_connect_v3') === 'long shot' &&
      !_.includes(['relevant', 'copied reachout draft'], relevance) &&
      setShowConnectorFeedback(true);
    setRelevance(_.includes(['copied reachout draft', 'relevant'], relevance) ? undefined : 'relevant');
    await mark_relevance({ connector, target, relevance: 'relevant' }, api);
  };
  const markIrrelevant = async () => {
    setShowConnectorFeedback(relevance !== 'irrelevant');
    setRelevance(relevance === 'irrelevant' ? undefined : 'irrelevant');
    await mark_relevance({ connector, target, relevance: 'irrelevant' }, api);
  };

  return (
    <StyledTableRow
      key={'connector-row-' + _.get(connector, 'connector_public_id')}
      style={{ backgroundColor: backgroundColor }}
    >
      <ConnectorTableCell>
        <ConnectorScoreWithRelationship connector={connector} />
      </ConnectorTableCell>
      <ButtonsTableCell>
        {isTamnuniUser(userState) ? (
          <>
            {_.get(connector, 'has_reachout') && !_.get(connector, 'is_active_reachout') ? (
              <TamnuniCycleEnded />
            ) : (
              <ConnectorTamnuniComponent
                relevance={relevance}
                connector={connector}
                target={target}
                userState={userState}
                setRelevance={setRelevance}
                setShowConnectorFeedback={setShowConnectorFeedback}
                hasActiveReachouts={hasActiveReachouts}
                isExecuting={isExecuting}
                onSendingTamnuni={onSendingTamnuni}
              />
            )}
          </>
        ) : (
          <DraftToConnector
            relevance={relevance}
            connector={connector}
            target={target}
            userState={userState}
            setRelevance={setRelevance}
            setShowConnectorFeedback={setShowConnectorFeedback}
          />
        )}
        {!_.get(connector, 'has_reachout') && (
          <>
            <RelevanceButton
              markRelevant={markRelevant}
              isPrimary={_.includes(['copied reachout draft', 'relevant'], relevance)}
              title={'Relevant connector'}
              srcDefault={like}
              srcHover={likeHover}
              srcClicked={likeClicked}
              marginRight={'8px'}
            />
            <RelevanceButton
              markRelevant={markIrrelevant}
              isPrimary={_.includes(['irrelevant'], relevance)}
              title={'Not relevant'}
              srcClicked={dislikeClicked}
              srcDefault={dislike}
              srcHover={dislikeHover}
            />
          </>
        )}
        <ConnectorFeedbackModal
          connector={connector}
          relevance={relevance}
          showConnectorFeedback={showConnectorFeedback}
          setShowConnectorFeedback={setShowConnectorFeedback}
          target={target}
        />
      </ButtonsTableCell>
    </StyledTableRow>
  );
};

const Connector = withUserState(_Connector);

const ConnectorsTable = ({ connectors, target, hasActiveReachouts }) => {
  const [isExecuting, setIsExecuting] = useState(false);
  const onSendingTamnuni = (status) => setIsExecuting(status);

  return (
    <TableContainer component={'div'}>
      <Table sx={{ overflowX: 'auto', tableLayout: 'fixed' }} aria-label="customized table">
        <TableBody>
          {connectors.map((connector, index) => (
            <Connector
              key={'connector-' + connector.connector_public_id}
              connector={connector}
              backgroundColor={index % 2 === 1 ? 'white' : '#F9F9F9'}
              target={target}
              hasActiveReachouts={hasActiveReachouts}
              isExecuting={isExecuting}
              onSendingTamnuni={onSendingTamnuni}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ConnectorsGroupTitle = ({ connectors }) => {
  return (
    <div style={{ marginBottom: '11px', color: 'rgba(0, 0, 0, 0.55)' }}>
      <b
        style={{
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        {connectors.length} Connectors
      </b>
      <span
        style={{
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 100,
        }}
      >
        {' '}
        with {getScoreText(_.get(connectors, '0.chance_to_connect_to_display'))} likelihood of successful reachout
      </span>
    </div>
  );
};
const LeadHeader = ({ userState, fullName, connectors, public_identifier }) => {
  return (
    <div style={{ marginBottom: '11px' }}>
      <span
        style={{
          marginRight: '10px',
        }}
      >
        <PortcoFavicon portcoName={getUserAccountName(userState)} width={28} height={28} />
      </span>
      <span style={{ fontSize: '20px', fontFamily: 'Inter', color: 'rgba(0, 0, 0, 0.65)', fontWeight: 400 }}>
        <Link href={`https://www.linkedin.com/in/${public_identifier}/`} target={'_blank'} style={{ fontWeight: 700 }}>
          {fullName}
        </Link>{' '}
        has {_.uniqBy(connectors, 'connector_public_id').length} connectors from {getUserAccountName(userState)}
      </span>
    </div>
  );
};
const DashboardUrl = () => {
  const FOCUS_DASHBOARD_URL = 'https://airtable.com/appVoPRvZdEpJz3FG/pagqajSecrdGjlHgN';
  return (
    <div style={{ marginBottom: '20px' }}>
      <Link
        href={FOCUS_DASHBOARD_URL}
        target={'_blank'}
        style={{ fontSize: '15px', fontWeight: 600, fontFamily: 'Inter', color: '#00A394' }}
      >
        « View All Reachouts
      </Link>
    </div>
  );
};

const TamnuniInProgress = ({ connector }) => {
  return (
    <>
      <div
        style={{
          marginBottom: '10px',
          fontSize: '20px',
          color: 'rgba(0, 0, 0, 0.65)',
          fontWeight: 700,
          fontFamily: 'Inter',
        }}
      >
        Tamnuni sent - Awaiting response
      </div>
      <div
        style={{
          fontFamily: 'Merriweather Sans',
          fontStyle: 'normal',
          fontWeight: '300',
          padding: '16px 16px 16px 32px',
          border: '1px solid rgba(0, 0, 0, 0.15)',
          marginBottom: '8px',
        }}
      >
        <div style={{ width: '80%' }}>
          <ConnectorScoreWithRelationship connector={connector} />
        </div>
      </div>
      <div
        style={{
          fontSize: '14px',
          fontWeight: 'normal',
          color: 'rgba(0,0,0,0.7)',
          backgroundColor: 'rgba(244,134,54,0.1)',
          height: '32px',
          paddingLeft: '7px',
          display: 'flex', // Enable Flexbox
          alignItems: 'center', // Align items vertically in the center
        }}
      >
        <img src={warningSVG} style={{ height: '14px', marginRight: '8px' }} alt={''} /> {/* Adjust height as needed */}
        Wait for response before sending another Tamnuni
      </div>
    </>
  );
};

const _FocusFull = ({ userState }) => {
  const api = useAPI();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [connectors, setConnectors] = useState([]);
  const [connectorsGoodChance, setConnectorsGoodChance] = useState([]);
  const [connectorsSomeChance, setConnectorsSomeChance] = useState([]);
  const [connectorsLongShot, setConnectorsLongShot] = useState([]);
  const [activeReachouts, setActiveReachouts] = useState([]);
  const [pastReachouts, setPastReachouts] = useState([]);

  const [target, setTarget] = useState({});
  const [loading, setLoading] = useState(true);

  const [showFeedback, setFeedback] = useState(false);
  const toggleFeedback = () => setFeedback(!showFeedback);

  const public_identifier = urlParams.get('linkedin_id');
  const entityUrn = urlParams.get('entityUrn');
  const post_creation = urlParams.get('post_creation');
  const fullName = urlParams.get('fullName');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Update the screen width on resize
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine the width based on the screen size
  const dynamicWidth = screenWidth > 1600 ? '80%' : screenWidth > 1440 ? '85%' : '90%';
  useEffect(() => {
    if (!public_identifier) {
      return;
    }
    const fetchResults = async () => {
      try {
        setLoading(true);
        const res = await get_portco_connectors_full({ public_identifier, entityUrn, post_creation }, api);
        setConnectors(res.connectors);
        setTarget(res.target);
      } finally {
        setLoading(false);
      }
    };
    fetchResults();
  }, [public_identifier, api]);

  useEffect(() => {
    // Partition connectors into active and past reachouts
    const [activeReachoutsTemp, nonActiveConnectors] = _.partition(connectors, (c) => _.get(c, 'is_active_reachout'));
    const [pastReachoutsTemp, chanceBasedConnectors] = _.partition(nonActiveConnectors, (c) =>
      _.get(c, 'has_reachout'),
    );

    // Further categorize the remaining connectors by chance
    const groupedByChance = _.groupBy(chanceBasedConnectors, 'chance_to_connect_to_display');

    setConnectorsGoodChance(groupedByChance['good chance'] || []);
    setConnectorsSomeChance(groupedByChance['some chance'] || []);
    setConnectorsLongShot(groupedByChance['long shot'] || []);
    setActiveReachouts(activeReachoutsTemp);
    setPastReachouts(pastReachoutsTemp);
  }, [connectors]);

  return (
    <>
      {!loading && (
        <div style={{ width: dynamicWidth, margin: 'auto' }}>
          <DashboardUrl />
          <LeadHeader
            userState={userState}
            fullName={fullName}
            connectors={connectors}
            public_identifier={public_identifier}
          />
          {
            //currently we only allow one active reachout
            !_.isEmpty(activeReachouts) && (
              <div style={{ marginTop: '50px' }}>
                <TamnuniInProgress connector={_.head(activeReachouts)} />
              </div>
            )
          }
          {!_.isEmpty(pastReachouts) && (
            <div style={{ marginTop: '50px' }}>
              <div style={{ marginBottom: '11px', color: 'rgba(0, 0, 0, 0.55)', fontWeight: 700, fontFamily: 'Inter' }}>
                {' '}
                Past Reachouts{' '}
              </div>
              <ConnectorsTable connectors={pastReachouts} target={target} />
            </div>
          )}
          {!_.isEmpty(connectorsGoodChance) && (
            <div style={{ marginTop: '50px' }}>
              <ConnectorsGroupTitle connectors={connectorsGoodChance} />
              <ConnectorsTable
                connectors={connectorsGoodChance}
                target={target}
                hasActiveReachouts={!_.isEmpty(activeReachouts)}
              />
            </div>
          )}
          {!_.isEmpty(connectorsSomeChance) && (
            <div style={{ marginTop: '50px' }}>
              <ConnectorsGroupTitle connectors={connectorsSomeChance} />
              <ConnectorsTable
                connectors={connectorsSomeChance}
                target={target}
                hasActiveReachouts={!_.isEmpty(activeReachouts)}
              />
            </div>
          )}
          {!_.isEmpty(connectorsLongShot) && (
            <div style={{ marginTop: '50px' }}>
              <ConnectorsGroupTitle connectors={connectorsLongShot} />
              <ConnectorsTable
                connectors={connectorsLongShot}
                target={target}
                hasActiveReachouts={!_.isEmpty(activeReachouts)}
              />
            </div>
          )}
          <div style={{ marginTop: '10px', marginBottom: '100px' }}>
            {showFeedback ? (
              <UserFeedback
                fullName={fullName}
                public_identifier={public_identifier}
                placeholder={'Write connector name or anything else we need to know'}
              />
            ) : (
              <span
                style={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                }}
              >
                {' '}
                Did we miss a connector you know about? <Link onClick={toggleFeedback}>Submit Feedback</Link>
              </span>
            )}
          </div>
          <UseAlephNetwork target={target} fullName={fullName} loading={loading} />
        </div>
      )}
    </>
  );
};

export const FocusFull = withUserState(_FocusFull);
