import React from 'react';

export const Tutorial = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <video
        width="90%"
        height="500"
        preload={true}
        autoplay={true}
        playsinline={true}
        src="https://aleph.vc/wp-content/uploads/aleph-connect-tutorial-final-f65a03a08022e1aed946519ad5bf6f.mp4"
        controls="controls"
      ></video>
    </div>
  );
};
