import { Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDebounce } from 'use-lodash-debounce';

import { AccountFinderByIndustries, ContactFinderByTitle } from '../intro/contacts';
import { ContactQueryBuilder, IndustryQueryBuilder } from '../widgets/contact-query-builder';

export const AdvancedSearch = () => {
  const [titleLike, setTitleLike] = useState([]);
  const [titleNotLike, setTitleNotLike] = useState([]);
  const [headCountFrom, setHeadCountFrom] = useState(11);
  const [headCountTo, setHeadCountTo] = useState(500);
  const [useTitleNotLike, setUseTitleNotLike] = useState(false);
  const [lastReq, setLastReq] = useState('');
  const [reqCount, setReqCount] = useState(0);

  const dTitleLike = useDebounce(titleLike, 1000);
  const dTitleNotLike = useDebounce(titleNotLike, 1000);
  const dHeadCountFrom = useDebounce(headCountFrom, 1000);
  const dHeadCountTo = useDebounce(headCountTo, 1000);

  const on_request = (e) => {
    if (_.isUndefined(e)) {
      setLastReq('Request Sent Successfully');
      setTitleLike([]);
      setTitleNotLike([]);
      setUseTitleNotLike(false);
      setHeadCountTo(500);
      setHeadCountFrom(11);
      setReqCount(reqCount + 1);
    } else {
      setLastReq('There was an error sending the request, please try again...');
    }
  };
  const hide_message = () => setLastReq('');

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h1">Search Aleph Network</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Grid item xs={12} sm={12} md={10} lg={8} xl={6} style={{ textAlign: 'center' }}>
          <ContactQueryBuilder
            key={reqCount}
            onTitleLikeChange={setTitleLike}
            onTitleNotLikeChange={setTitleNotLike}
            headCountFrom={headCountFrom}
            setHeadCountFrom={setHeadCountFrom}
            headCountTo={headCountTo}
            setHeadCountTo={setHeadCountTo}
            setUseTitleNotLike={setUseTitleNotLike}
            useTitleNotLike={useTitleNotLike}
          />
        </Grid>
      </Grid>
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('Success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>

      <Grid container justifyContent="center">
        {!_.isEmpty(dTitleLike) && (
          <ContactFinderByTitle
            titleLike={dTitleLike}
            titleNotLike={dTitleNotLike}
            useTitleNotLike={useTitleNotLike}
            headCountFrom={dHeadCountFrom}
            headCountTo={dHeadCountTo}
            on_request={on_request}
          />
        )}
      </Grid>
    </>
  );
};

export const IndustrySearch = () => {
  const [industries, setIndustries] = useState([]);
  const [headCountFrom, setHeadCountFrom] = useState(11);
  const [headCountTo, setHeadCountTo] = useState(500);
  const [lastReq, setLastReq] = useState('');
  const [reqCount, setReqCount] = useState(0);

  const dIndustries = useDebounce(industries, 1000);
  const dHeadCountFrom = useDebounce(headCountFrom, 1000);
  const dHeadCountTo = useDebounce(headCountTo, 1000);

  const on_request = (e) => {
    if (_.isUndefined(e)) {
      setLastReq('Request Sent Successfully');
      setIndustries([]);
      setHeadCountTo(500);
      setHeadCountFrom(11);
      setReqCount(reqCount + 1);
    } else {
      setLastReq('There was an error sending the request, please try again...');
    }
  };
  const hide_message = () => setLastReq('');

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h1">Search Aleph Network</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Grid item xs={12} sm={12} md={10} lg={8} xl={6} style={{ textAlign: 'center' }}>
          <IndustryQueryBuilder
            key={reqCount}
            onIndustriesChange={setIndustries}
            headCountFrom={headCountFrom}
            setHeadCountFrom={setHeadCountFrom}
            headCountTo={headCountTo}
            setHeadCountTo={setHeadCountTo}
          />
        </Grid>
      </Grid>
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('Success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>

      <Grid container justifyContent="center">
        {!_.isEmpty(dIndustries) && (
          <AccountFinderByIndustries
            industries={dIndustries}
            headCountFrom={dHeadCountFrom}
            headCountTo={dHeadCountTo}
            on_request={on_request}
          />
        )}
      </Grid>
    </>
  );
};
