import { CircularProgress, Grid, Tab, Tabs } from '@material-ui/core';
import Immutable from 'immutable';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useAPI } from '../../userContext';
import { get_all_open_positions, get_potential_position_owners } from '../api';
import { Position } from '../position';
import { createOptionalPositionFilters, getFilteredPositions, PositionsFilters } from '../utils/position-filters';

export const OpenPositions = () => {
  const api = useAPI();

  const [isLoading, setIsLoading] = useState(false);

  const [openPositionsSet, setOpenPositionsSet] = useState(Immutable.Set([]));
  const [openPositions, setOpenPositions] = useState(undefined);
  const [filteredPositions, setFilteredPositions] = useState(undefined);

  const [optionalPositionFilters, setOptionalPositionFilters] = useState({ searchOptions: [] });
  const [positionFilters, setPositionFilters] = useState({ searchOptions: [] });

  const [potentialOwners, setPotentialOwners] = useState([]);
  useEffect(() => {
    setOpenPositions(openPositionsSet.toArray());
  }, [openPositionsSet]);

  const [locations, setLocations] = useState([]);
  useEffect(() => {
    async function fetch() {
      try {
        await get_potential_position_owners(api).then(({ contacts }) => setPotentialOwners(contacts));
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [api]);

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        await get_all_open_positions(api).then(({ positions }) => setOpenPositionsSet(Immutable.Set(positions)));
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [api]);

  useEffect(() => {
    setOptionalPositionFilters(createOptionalPositionFilters(openPositions));
    setLocations(
      _(openPositions)
        .map(({ Location__c }) => Location__c || 'Other')
        .uniq()
        .sortBy(['value'])
        .value(),
    );
  }, [openPositions]);

  useEffect(() => {
    !_.isEmpty(openPositions) &&
      setFilteredPositions(
        getFilteredPositions({
          openPositions,
          positionFilters,
        }),
      );
  }, [openPositions, positionFilters]);

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Grid container style={{ fontFamily: 'Merriweather Sans', width: '90%', margin: 'auto' }}>
        <Grid item md={12} sm={12} xs={12} lg={12} xl={12}>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress size={20} />
              <span style={{ marginLeft: '5px' }}>Loading Positions...</span>
            </div>
          ) : (
            <>
              <Tabs
                value={selectedTab}
                style={{ width: '80%', margin: 'auto', marginBottom: '20px' }}
                onChange={(a, newValue) => {
                  setSelectedTab(newValue);
                }}
                aria-label="tabs"
              >
                <Tab label="Positions" />
              </Tabs>
              {selectedTab === 0 && (
                <div>
                  <div style={{ width: '70%' }}>
                    <PositionsFilters
                      positionFilters={positionFilters}
                      setPositionFilters={setPositionFilters}
                      optionalPositionFilters={optionalPositionFilters}
                      showSlider={false}
                    />
                  </div>
                  <div style={{ marginTop: '40px' }}>
                    {!_.isEmpty(filteredPositions) &&
                      filteredPositions.map((p) => (
                        <Position
                          key={p.Id}
                          position={p}
                          setOpenPositionsSet={setOpenPositionsSet}
                          openPositionsSet={openPositionsSet}
                          locations={locations}
                          view={'All'}
                          potentialOwners={potentialOwners.filter(({ Account__c }) => Account__c === p.Account__c)}
                        />
                      ))}
                  </div>
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
