import _ from 'lodash';

export async function get_potential_introducers({ contactToSearch, source, requestType }, api) {
  return api('contact-intro/results/', {
    method: 'POST',
    body: JSON.stringify({ contactToSearch, source, requestType }),
  });
}

export async function get_potential_introducers_by_account({ account_ids }, api) {
  return api('contact-intro/by-account/', { method: 'POST', body: JSON.stringify({ account_ids }) });
}

export async function get_potential_introducers_by_skiils({ skills }, api) {
  return api('contact-intro/by-skills/', { method: 'POST', body: JSON.stringify({ skills }) });
}

export async function get_aleph_users(api) {
  return api('user/aleph-users/', { method: 'GET' });
}

export async function get_my_candidates(api) {
  return api('candidates/my_candidates/', { method: 'GET' });
}

export async function get_open_requests(params, api) {
  return api('admin/open-requests/', { method: 'POST', body: JSON.stringify(params) });
}

export async function get_talpod_krs(params, api) {
  return api('admin/talpod-krs/', { method: 'POST', body: JSON.stringify(params) });
}

export async function get_missing_hires(params, api) {
  return api('admin/missing-hires/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_candidate(params, api) {
  return api('candidates/update/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_poscan_tamuz_app(params, api) {
  return api('candidates/tamuz_app_update/', { method: 'POST', body: JSON.stringify(params) });
}

export async function get_candidate_details(params, api) {
  return api('candidates/get_details/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_intro(params, api) {
  return api('admin/update-intro/', { method: 'POST', body: JSON.stringify(params) });
}

export async function ping_aleph_facilitator(params, api) {
  return api('contact-intro/ping-facilitator/', { method: 'POST', body: JSON.stringify(params) });
}

export async function get_all_open_positions(api) {
  return api('position/get_open_positions', { method: 'GET' });
}

export async function get_potential_position_owners(api) {
  return api('position/get_potential_position_owners', { method: 'GET' });
}

export async function get_all_job_seekers(api) {
  return api('contact/get_job_seekers/', { method: 'GET' });
}

export async function update_contact(params, api) {
  return api('contact/update/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_relationship(params, api) {
  return api('contact/update_relationship_with_owner/', { method: 'POST', body: JSON.stringify(params) });
}

export async function create_poscans_tamuz_app(params, api) {
  return api('candidates/create_from_tamuz_app', { method: 'POST', body: JSON.stringify(params) });
}

export async function show_contact_history(params, api) {
  return api('contact/show_history', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_position(params, api) {
  return api('position/update_position/', { method: 'POST', body: JSON.stringify(params) });
}

export async function claim_poscan(params, api) {
  return api('candidates/claim_poscan/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_poscan_priority(params, api) {
  return api('candidates/update_priority/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_poscan_status(params, api) {
  return api('candidates/update_status/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_poscan_rejection(params, api) {
  return api('candidates/update_rejection/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_internal_rejection(params, api) {
  return api('candidates/update_internal_rejection/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_portco_interest(params, api) {
  return api('candidates/update_portco_interest/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_portco_feedback(params, api) {
  return api('candidates/update_portco_feedback/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_candidate_feedback(params, api) {
  return api('candidates/update_candidate_feedback/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_poscan_next_feedback_request(params, api) {
  return api('candidates/update_poscan_next_feedback_request/', { method: 'POST', body: JSON.stringify(params) });
}

export async function update_poscan_owner(params, api) {
  return api('candidates/update_owner/', { method: 'POST', body: JSON.stringify(params) });
}

export async function get_related_targets(params, api) {
  return api('contact-intro/get-related-targets/', { method: 'POST', body: JSON.stringify(params) });
}

export async function get_potential_feedback_statuses(api) {
  return api('candidates/feedback_statuses/', { method: 'GET' });
}

export async function get_potential_rejection_reasons(api) {
  return api('candidates/rejection_reasons/', { method: 'GET' });
}

export async function get_internal_rejection_reasons(api) {
  return api('candidates/internal_rejection_reasons/', { method: 'GET' });
}

export async function scrape_contacts(params, api) {
  return api('admin/scrape_contacts/', { method: 'POST', body: JSON.stringify(params) });
}

export async function refresh_ramzor(params, api) {
  return api('admin/refresh_ramzor/', { method: 'POST', body: JSON.stringify(params) });
}

export async function create_nemp_signals(params, api) {
  return api('admin/create_nemp_signals/', { method: 'POST', body: JSON.stringify(params) });
}

export async function ask_portco_feedback(poscanId, setRequested, ownerName) {
  const PORTCO_FEEDBACK__ZAP_URLS = {
    Bar: 'https://hooks.zapier.com/hooks/catch/2976135/bxjcda9/',
    Hadar: 'https://hooks.zapier.com/hooks/catch/2976135/bp949e1/',
  };
  if (_.isEmpty(PORTCO_FEEDBACK__ZAP_URLS[ownerName])) {
    setRequested('no feedback url');
  } else {
    return fetch(PORTCO_FEEDBACK__ZAP_URLS[ownerName], {
      method: 'POST',
      body: JSON.stringify({ poscan_id: poscanId }),
    }).then((response) => {
      setRequested(response.status === 200 ? 'Success' : 'Error');
    });
  }
}

export async function ask_candidate_feedback(poscanId, setRequested, userSfContactId) {
  const CANDIDATE_FEEDBACK_ZAP_URLS = {
    Bar: 'https://hooks.zapier.com/hooks/catch/2976135/bxj3fhv/',
    Hadar: 'https://hooks.zapier.com/hooks/catch/2976135/bp94532/',
  };

  if (_.isEmpty(CANDIDATE_FEEDBACK_ZAP_URLS[userSfContactId])) {
    setRequested('no feedback url');
  } else {
    return fetch(CANDIDATE_FEEDBACK_ZAP_URLS[userSfContactId], {
      method: 'POST',
      body: JSON.stringify({ poscan_id: poscanId }),
    }).then((response) => {
      setRequested(response.status === 200 ? 'Success' : 'Error');
    });
  }
}
