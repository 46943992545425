import { IconButton, Link, ListItem, ListItemIcon, ListItemText, TextField, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { AssignmentInd, MailOutline } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { is_admin, withUserState } from '../userContext';
import { getConnectionStrengthSales } from '../utils/contacts';
import { Battery1, Battery2, Battery3, LinkedIn, SalesforceIcon } from './index';

export const ScoreIconWithoutTooltip = ({ score }) => {
  return (
    <ListItemIcon style={{ minWidth: '38px', height: '32px' }}>
      {_.toNumber(score) === 1 || _.toNumber(score) === 0 || !score ? (
        <Battery1 />
      ) : _.toNumber(score) === 2 ? (
        <Battery2 />
      ) : (
        <Battery3 />
      )}
    </ListItemIcon>
  );
};

const _Contact = ({ id, account, name, title, score, linkedin_profile, userState, score_details }) => {
  const icons = (
    <>
      {linkedin_profile && (
        <ListItemIcon>
          <Link href={linkedin_profile} target="_blank">
            <LinkedIn fontSize="small" />
          </Link>
        </ListItemIcon>
      )}
      {is_admin(userState) && !!id && (
        <ListItemIcon>
          <Link href={`https://aleph.lightning.force.com/lightning/r/Contact/${id}/view`} target="_blank">
            <SalesforceIcon fontSize="small" />
          </Link>
        </ListItemIcon>
      )}
    </>
  );
  return (
    <ListItem>
      <ScoreIconWithoutTooltip score={score} />
      <ListItemText
        primary={
          <>
            {icons}
            {name} - {title}
          </>
        }
        secondary={`Connection Strength: ${getConnectionStrengthSales(score)}`}
      />
    </ListItem>
  );
};

export const Contact = withUserState(_Contact);

const _ContactWithAccount = ({ id, account, name, title, score, linkedin_profile, userState, score_details }) => {
  const icons = (
    <>
      {linkedin_profile && (
        <ListItemIcon>
          <Link href={linkedin_profile} target="_blank">
            <LinkedIn fontSize="small" />
          </Link>
        </ListItemIcon>
      )}
      {is_admin(userState) && !!id && (
        <ListItemIcon>
          <Link href={`https://aleph.lightning.force.com/lightning/r/Contact/${id}/view`} target="_blank">
            <SalesforceIcon fontSize="small" />
          </Link>
        </ListItemIcon>
      )}
    </>
  );
  return (
    <ListItem>
      <ScoreIconWithoutTooltip score={score} />
      <ListItemText
        primary={
          <>
            {icons}
            {name} - {title} @ {account}
          </>
        }
        secondary={`Connection Strength: ${getConnectionStrengthSales(score)}`}
      />
    </ListItem>
  );
};

export const ContactWithAccount = withUserState(_ContactWithAccount);

const _PastContact = ({
  id,
  account,
  name,
  title,
  score,
  linkedin_profile,
  userState,
  score_details,
  start_date,
  end_date,
}) => {
  const icons = (
    <>
      {linkedin_profile && (
        <ListItemIcon>
          <Link href={linkedin_profile} target="_blank">
            <LinkedIn fontSize="small" />
          </Link>
        </ListItemIcon>
      )}
      {is_admin(userState) && !!id && (
        <ListItemIcon>
          <Link href={`https://aleph.lightning.force.com/lightning/r/Contact/${id}/view`} target="_blank">
            <SalesforceIcon fontSize="small" />
          </Link>
        </ListItemIcon>
      )}
    </>
  );
  return (
    <ListItem>
      <ScoreIconWithoutTooltip score={score} />
      <ListItemText
        primary={
          <>
            {icons}
            {name} - was {title} ({moment(start_date).format('Y')} - {moment(end_date).format('Y')})
          </>
        }
        secondary={`Connection Strength: ${getConnectionStrengthSales(score)}`}
      />
    </ListItem>
  );
};

export const PastContact = withUserState(_PastContact);

export const ContactEmail = ({ email }) => {
  const [copiedEmail, setCopied] = useState(false);

  const copyEmail = () => {
    navigator.clipboard.writeText(email);
    setCopied(true);
  };

  return (
    <Tooltip title={copiedEmail ? 'Email was copied to clipboard' : 'Copy Email'}>
      <IconButton size={'small'} onClick={copyEmail}>
        <MailOutline />
      </IconButton>
    </Tooltip>
  );
};

export const OtherContact = ({ other_contact, set_other_contact }) => {
  const handleChange = (e) => set_other_contact(e.target.value);
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <AssignmentInd />
        </ListItemIcon>
        <ListItemText primary="If you know your exact target specify it here" />
      </ListItem>
      <ListItem>
        <TextField
          placeholder="Enter the linkedin URL of the person you would like to reach"
          value={other_contact}
          onChange={handleChange}
          variant="outlined"
          fullWidth
        />
      </ListItem>
    </>
  );
};

export const InteractionSourceSelector = ({ selectedSource, setSelectedSource, optionalSources }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedSource(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '250px',
      },
    },
  };

  return (
    <div>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="source-input-label">Source</InputLabel>
        <Select
          labelId="source-label-id"
          id="interaction-source"
          label={'Source'}
          value={selectedSource}
          onChange={handleChange}
          input={<Input />}
          MenuProps={MenuProps}
          variant={'outlined'}
        >
          {optionalSources.map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
