import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { ArrowDropDown, ArrowRight, LinkedIn, Star } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import Immutable from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { is_admin, is_talpod, useAPI, withUserState } from '../userContext';
import { CreateSignal } from '../utils/contacts';
import { salesforce_contact_link } from '../utils/misc';
import { CANDIDATE_SOURCES, ROLES, SKILL_CATEGORIES, SKILLS } from '../utils/sf-consts';
import { SignalsListRows } from '../utils/signals';
import {
  create_poscans_tamuz_app,
  get_all_open_positions,
  get_candidate_details,
  get_potential_feedback_statuses,
  get_potential_position_owners,
  get_potential_rejection_reasons,
  update_contact,
  update_poscan_next_feedback_request,
} from './api';
import { Poscan } from './poscan';
import { PoscanCart } from './poscanShoppingCart';
import { Position } from './position';
import { createOptionalPositionFilters, getFilteredPositions, PositionsFilters } from './utils/position-filters';

const Role = ({ role }) => {
  return (
    <div style={{ marginLeft: 'auto', width: '80%', fontSize: '16px', fontWeight: '400', lineHeight: '32px' }}>
      <span style={{ marginRight: '23px' }}>
        {_.toUpper(moment(role.Start_Date__c).format('MMM YYYY'))} -{' '}
        {_.isEmpty(role.End_Date__c) ? 'Present' : _.toUpper(moment(role.End_Date__c).format('MMM YYYY'))}
      </span>
      <span style={{ color: '#4D4D4D' }}>{_.get(role, 'role_title')}</span>
    </div>
  );
};
const Tenure = ({ tenure, showOnlyMostRecent }) => {
  return (
    <div style={{ marginBottom: '15px' }}>
      <div>
        {_.get(tenure, 'Account_Name__c')}
        <span style={{ float: 'right' }}>
          {_.get(tenure, 'Account_Employees_Count__c')} emp
          {!!_.get(tenure, 'founded_year') ? ` | Founded ${_.get(tenure, 'founded_year')}` : ''}
        </span>
      </div>
      <hr />
      {showOnlyMostRecent ? (
        <Role role={_.head(tenure.roles)} />
      ) : (
        _.get(tenure, 'roles', []).map((role) => <Role role={role} key={role.Id} />)
      )}
    </div>
  );
};

const SkillSelector = ({
  candidateDetails,
  setCandidateDetails,
  candidateSource,
  setCandidateSource,
  positionFilters,
  setPositionFilters,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menu_props = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  const handleSkillCategoryChange = (event) => {
    const { value } = event.target;
    setCandidateDetails({
      Skill_Category__c: value,
      userChange: true,
      ..._.omit(candidateDetails, ['Skill_Category__c', 'userChange']),
    });
  };
  const handleRoleChange = (event) => {
    const { value } = event.target;
    setCandidateDetails({
      Role__c: value,
      userChange: true,
      ..._.omit(candidateDetails, ['Role__c', 'userChange']),
    });
    setPositionFilters({
      ..._.omit(positionFilters, ['searchOptions']),
      searchOptions: _.concat(positionFilters.searchOptions, { type: 'Role', value }),
    });
  };

  const handleSkillChange = (event, option) => {
    setCandidateDetails({
      Skill__c: option,
      userChange: true,
      ..._.omit(candidateDetails, ['Skill__c', 'userChange']),
    });
    setPositionFilters({
      ..._.omit(positionFilters, ['searchOptions']),
      searchOptions: _.concat(
        positionFilters.searchOptions.filter(({ type }) => type !== 'Main Skill'),
        option.map(({ label }) => ({ type: 'Main Skill', value: label })),
      ),
    });
  };

  const handleSourceChange = (event, option) => {
    const { value } = event.target;
    setCandidateSource(value);
  };

  return (
    <>
      <div>
        <FormControl style={{ width: '80%' }}>
          <InputLabel id="skill-selector-input-label">Skill Category</InputLabel>
          <Select
            labelId="skill-selector-label"
            id="skill-selector"
            value={_.get(candidateDetails, 'Skill_Category__c')}
            onChange={handleSkillCategoryChange}
            input={<Input />}
            menu_props={menu_props}
          >
            {SKILL_CATEGORIES.map(({ label }) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <Autocomplete
          style={{ width: '80%' }}
          getOptionLabel={(option) => _.get(option, 'label', '')}
          renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
          options={SKILLS}
          value={_.get(candidateDetails, 'Skill__c')}
          autoComplete
          multiple
          onChange={handleSkillChange}
          selectOnFocus={false}
          freeSolo={false}
          renderInput={(params) => <TextField {...params} label={'Skill'} />}
        />
      </div>
      <div>
        <FormControl style={{ width: '80%' }}>
          <InputLabel id="role-selector-input-label">Role</InputLabel>
          <Select
            labelId="role-selector-label"
            id="role-selector"
            value={candidateDetails.Role__c}
            onChange={handleRoleChange}
            input={<Input />}
            menu_props={menu_props}
          >
            {ROLES.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControl style={{ width: '80%' }}>
          <InputLabel id="source-selector-input-label">Source</InputLabel>
          <Select
            labelId="source-selector-label"
            id="source-selector"
            value={candidateSource}
            onChange={handleSourceChange}
            input={<Input />}
            menu_props={menu_props}
          >
            {CANDIDATE_SOURCES.map((s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

const _SideMenu = ({
  candidate,
  candidateDetails,
  setCandidateDetails,
  userState,
  candidateSource,
  setCandidateSource,
  positionFilters,
  setPositionFilters,
  setRefreshCandidate,
}) => {
  const api = useAPI();
  const [sfComments, setSfComments] = useState(_.get(candidateDetails, 'Expertise_Summary_and_Takeaways__c'));
  const [candidateEmail, setCandidateEmail] = useState(
    _.get(candidateDetails, 'Secondary_Email__c', _.get(candidateDetails, 'Email')),
  );
  const [candidateMobile, setCandidateMobile] = useState(_.get(candidateDetails, 'MobilePhone'));

  const handleLookingChange = (event) => {
    setCandidateDetails({
      Is_looking_for_a_job__c: event.target.checked,
      userChange: true,
      ..._.omit(candidateDetails, ['Is_looking_for_a_job__c', 'userChange']),
    });
  };

  const handleConfidentialChange = (event) => {
    setCandidateDetails({
      Confidential__c: event.target.checked,
      userChange: true,
      ..._.omit(candidateDetails, ['Confidential__c', 'userChange']),
    });
  };

  const handleCommentsChange = (event) => {
    setSfComments(event.target.value);
  };

  const handleEmailChange = (event) => {
    setCandidateEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setCandidateMobile(event.target.value);
  };

  const handleCommentsBlur = () =>
    setCandidateDetails({
      Expertise_Summary_and_Takeaways__c: sfComments,
      userChange: true,
      ..._.omit(candidateDetails, ['Expertise_Summary_and_Takeaways__c', 'userChange']),
    });

  const handleEmailBlur = () =>
    setCandidateDetails({
      Secondary_Email__c: candidateEmail,
      userChange: true,
      ..._.omit(candidateDetails, ['Secondary_Email__c', 'userChange']),
    });

  const handleMobileBlur = () =>
    setCandidateDetails({
      MobilePhone: candidateMobile,
      userChange: true,
      ..._.omit(candidateDetails, ['MobilePhone', 'userChange']),
    });

  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [addingToDigest, setAdding] = useState(false);
  const [updatingContact, setUpdating] = useState(false);
  const shareWithFounders = async () => {
    const FOUNDER_DIGEST_POSITION_ID = 'a0A4I00000uHmWkUAK';
    setAdding(true);
    await create_poscans_tamuz_app(
      {
        poscans: [
          {
            Position__c: FOUNDER_DIGEST_POSITION_ID,
            Candidate__c: candidate.Id,
            Candidate_Source__c: candidateSource,
            Status__c: 'Applicant to Review',
          },
        ],
      },
      api,
    );
    setAlertText('added to next founders digest');
    setAdding(false);
    setShowAlert(true);

    setRefreshCandidate(true);
  };

  useEffect(() => {
    async function up() {
      const formatted = candidateDetailsToSfFormat({ candidate, candidateDetails });
      try {
        setUpdating(true);
        await update_contact(formatted, api);
        setUpdating(false);
        setAlertText('contact was updated successfully');
        setShowAlert(true);
      } catch (e) {
        setUpdating(false);
        setAlertText(`Ooops, looks like there was an error - ${e.message}`);
        setShowAlert(true);
      }
    }
    !!_.get(candidateDetails, 'userChange', false) && up();
  }, [api, candidate, candidateDetails]);

  return (
    <>
      {updatingContact ? (
        <div style={{ textAlign: 'left' }}>
          <CircularProgress size={20} />
          <span style={{ marginLeft: '5px' }}>Updating contact...</span>
        </div>
      ) : (
        <>
          <div style={{ marginTop: '10px' }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                style={{ width: '60%' }}
                label="Looking"
                checked={candidateDetails.Is_looking_for_a_job__c}
                onChange={handleLookingChange}
              />
            </FormGroup>
          </div>
          <div>
            <TextField
              id="phone-number"
              label="Phone number"
              type={'tel'}
              variant="standard"
              style={{ width: '80%' }}
              value={candidateMobile}
              onChange={handleMobileChange}
              onBlur={handleMobileBlur}
            />
          </div>
          <div>
            <TextField
              id="personal-email"
              label="Email"
              type={'email'}
              variant="standard"
              style={{ width: '80%' }}
              value={candidateEmail}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
            />
          </div>
          {candidateDetails.Is_looking_for_a_job__c && (
            <div style={{ marginTop: '10px' }}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  style={{ width: '60%' }}
                  label="Confidential"
                  checked={candidateDetails.Confidential__c}
                  onChange={handleConfidentialChange}
                />
              </FormGroup>
            </div>
          )}
          <SkillSelector
            candidateDetails={candidateDetails}
            setCandidateDetails={setCandidateDetails}
            candidateSource={candidateSource}
            setCandidateSource={setCandidateSource}
            positionFilters={positionFilters}
            setPositionFilters={setPositionFilters}
          />
          {is_admin(userState) && (
            <TextField
              id="sf-comments"
              type="text"
              label="Expertise summary and takeaways"
              value={sfComments}
              onChange={handleCommentsChange}
              onBlur={handleCommentsBlur}
              variant="outlined"
              multiline
              rows={3}
              style={{ marginTop: '24px' }}
            />
          )}
          {is_talpod(userState) && (
            <div>
              <Tooltip title={'Send on founders digest'}>
                <span>
                  <IconButton
                    onClick={shareWithFounders}
                    color={'primary'}
                    aria-label="Share with founders"
                    disabled={addingToDigest}
                  >
                    <Star />
                  </IconButton>
                  {addingToDigest && (
                    <div>
                      <CircularProgress color="secondary" />
                    </div>
                  )}
                </span>
              </Tooltip>
            </div>
          )}
          {showAlert && (
            <Alert onClose={() => setShowAlert(false)} severity={alertText.includes('success') ? 'success' : 'error'}>
              {alertText}
            </Alert>
          )}
          <div style={{ marginTop: '24px' }}>
            <CreateSignal Id={_.get(candidate, 'Id')} FirstName={_.get(candidate, 'FirstName')} />
          </div>
          {!_.isEmpty(_.get(candidate, 'signals', [])) && (
            <div style={{ width: '100%' }}>
              <SignalsListRows signals={_.get(candidate, 'signals', [])} />
            </div>
          )}
        </>
      )}
    </>
  );
};

const SideMenu = withUserState(_SideMenu);

//todo: desired role? Self_estimated_expertise_level__c	?
const candidateDetailsToSfFormat = ({ candidate, candidateDetails }) => {
  const parsed = {
    Skill__c: _.get(candidateDetails, 'Skill__c', [])
      .map(({ apiName }) => apiName)
      .join(';'),
    ..._.omit(candidateDetails, ['Skill__c', 'Is_looking_for_a_job__c', 'Confidential__c']),
  };

  return {
    Id: _.get(candidate, 'Id'),
    Is_looking_for_a_job__c: _.get(candidateDetails, 'Is_looking_for_a_job__c'),
    Confidential__c: _.get(candidateDetails, 'Confidential__c'),
    ..._.omitBy(parsed, _.isEmpty),
  };
};

const CandidateHeader = ({ fullNameFromUrl, linkedinIdFromUrl, toggleRoles, showAllRoles, candidate }) => {
  return (
    <>
      {_.isEmpty(_.get(candidate, 'Id', '')) && (
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '20px',
          }}
        >
          {' '}
          {fullNameFromUrl}
        </span>
      )}

      {!_.isEmpty(_.get(candidate, 'Id')) && (
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '20px',
          }}
        >
          <a href={salesforce_contact_link(_.get(candidate, 'Id'))} target="_blank">
            {_.get(candidate, 'Name')}
          </a>
        </span>
      )}
      <Link href={`https://www.linkedin.com/in/${linkedinIdFromUrl}`} target="_blank" style={{ marginLeft: '12px' }}>
        <LinkedIn fontSize="small" />
      </Link>
      {!_.isEmpty(_.get(candidate, 'tenures', [])) && (
        <span style={{ float: 'right', fontSize: '16px' }} onClick={toggleRoles}>
          {showAllRoles ? <ArrowDropDown fontSize={'large'} /> : <ArrowRight fontSize={'large'} />}
        </span>
      )}
    </>
  );
};

function getInitialRoleFilters(candidate) {
  return _.isNull(_.get(candidate, 'Role__c'))
    ? false
    : _.includes(['Manager', 'Executive'], _.get(candidate, 'Role__c'))
      ? [
          { type: 'Role', value: 'Manager' },
          { type: 'Role', value: 'Executive' },
        ]
      : { type: 'Role', value: _.get(candidate, 'Role__c') };
}

export const Candidate = () => {
  const api = useAPI();

  const [potentialOwners, setPotentialOwners] = useState([]);

  const [isLoadingCandidate, setIsLoadingCandidate] = useState(false);

  const [shouldRefreshCandidate, setRefreshCandidate] = useState(true);
  const [candidate, setCandidate] = useState(undefined);
  const [candidateDetails, setCandidateDetails] = useState(undefined);
  const [candidateSource, setCandidateSource] = useState('Inbound - Intro and Network');
  const [showAllRoles, setShowRoles] = useState(false);
  const toggleRoles = () => setShowRoles(!showAllRoles);

  const [openPositionsSet, setOpenPositionsSet] = useState(Immutable.Set([]));
  const [openPositions, setOpenPositions] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState(undefined);

  const [optionalPositionFilters, setOptionalPositionFilters] = useState(undefined);
  const [positionFilters, setPositionFilters] = useState({
    searchOptions: [{ type: 'Hide Opportunistic', value: true }],
  });

  const [poscanShoppingCartSet, setPoscanShoppingCartSet] = useState(Immutable.Set([]));
  const [poscanShoppingCart, setPoscanShoppingCart] = useState([]);

  const [potentialRejectionReasons, setPotentialRejectionReasons] = useState([]);

  useEffect(() => {
    const fetchCandidate = async () => {
      await get_candidate_details({ linkedinId: linkedinIdFromUrl, sfId: sfIdFromUrl }, api).then(
        ({ candidate: resCandidate }) => {
          setCandidate(resCandidate);
        },
      );
      setRefreshCandidate(false);
    };
    if (shouldRefreshCandidate) {
      fetchCandidate();
    }
  }, [shouldRefreshCandidate]);

  const handleSendAll = async () => {
    setIsLoadingCandidate(true);
    setRefreshCandidate(true);
    setSelectedTab(1);
  };

  useEffect(() => {
    async function fetch() {
      try {
        await get_potential_position_owners(api).then(({ contacts }) => setPotentialOwners(contacts));
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [api]);

  useEffect(() => {
    setPoscanShoppingCart(poscanShoppingCartSet.toArray());
  }, [poscanShoppingCartSet]);

  useEffect(() => {
    setOpenPositions(
      _.orderBy(openPositionsSet.toArray(), ['High_Priority__c', 'Last_Known_As_Opened__c'], ['desc', 'desc']),
    );
  }, [openPositionsSet]);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const linkedinIdFromUrl = urlParams.get('linkedin_id');
  const sfIdFromUrl = urlParams.get('sf_id');
  const fullNameFromUrl = decodeURIComponent(urlParams.get('fullName'));

  useEffect(() => {
    async function fetch() {
      try {
        await Promise.all([
          get_all_open_positions(api).then(({ positions }) => setOpenPositionsSet(Immutable.Set(positions))),
          get_potential_rejection_reasons(api).then(({ reasons }) => setPotentialRejectionReasons(reasons)),
        ]);
      } catch (e) {
        console.log(e);
      }
    }
    if (!_.isEmpty(sfIdFromUrl)) {
      fetch();
    }
  }, [api, linkedinIdFromUrl, sfIdFromUrl]);

  useEffect(() => {
    !_.isEmpty(openPositions) && setOptionalPositionFilters(createOptionalPositionFilters(openPositions));
  }, [openPositions]);

  useEffect(() => {
    setPositionFilters({
      ..._.pick(optionalPositionFilters, ['headcountMax', 'headcountMin']),
      searchOptions: _.get(positionFilters, 'searchOptions'),
    });
  }, [optionalPositionFilters]);

  useEffect(() => {
    const tempFilteredPositions = getFilteredPositions({
      openPositions,
      positionFilters,
      poscans: _.get(candidate, 'poscans', []),
    });
    setFilteredPositions(tempFilteredPositions);
  }, [candidate, openPositions, positionFilters]);

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (_.isEmpty(candidate)) {
      return;
    }
    const getCandidateSkill = () => {
      const candidateSkill = _.get(candidate, 'Skill__c');
      const mainRoleSkill = _.get(candidate, 'Main_Role__r.Skill__c');
      const chosenSkill = candidateSkill || mainRoleSkill;
      if (!chosenSkill) {
        return [];
      } else {
        return SKILLS.filter(({ apiName }) => _.includes(chosenSkill.split(';'), apiName));
      }
    };

    const candidateSkill = getCandidateSkill();
    setCandidateDetails({
      Is_looking_for_a_job__c: _.get(candidate, 'Is_looking_for_a_job__c'),
      Confidential__c: _.get(candidate, 'Confidential__c'),
      Skill__c: candidateSkill,
      Skill_Category__c: _.isNull(
        _.get(candidate, 'Skill_Category__c', _.get(candidate, 'Main_Role__r.Skill_Category__c')),
      )
        ? ''
        : _.get(candidate, 'Skill_Category__c', _.get(candidate, 'Main_Role__r.Skill_Category__c')),
      Role__c: _.isNull(_.get(candidate, 'Role__c')) ? '' : _.get(candidate, 'Role__c'),
      Expertise_Summary_and_Takeaways__c: _.isNull(_.get(candidate, 'Expertise_Summary_and_Takeaways__c'))
        ? ''
        : _.get(candidate, 'Expertise_Summary_and_Takeaways__c'),
      Secondary_Email__c: _.isNull(_.get(candidate, 'Secondary_Email__c'))
        ? ''
        : _.get(candidate, 'Secondary_Email__c'),
      MobilePhone: _.isNull(_.get(candidate, 'MobilePhone')) ? '' : _.get(candidate, 'MobilePhone'),
      userChange: false,
    });
    if (!_.isEmpty(candidateSkill)) {
      const searchOptions = _.compact(
        _.concat(
          getInitialRoleFilters(candidate),
          candidateSkill.map(({ label }) => ({ type: 'Main Skill', value: label })),
          { type: 'Location', value: 'Israel' },
          { type: 'Hide Opportunistic', value: 'Hide Opportunistic' },
        ),
      );
      setPositionFilters({
        ..._.omit(positionFilters, ['searchOptions']),
        searchOptions,
      });
    }
  }, [candidate]);

  return (
    <>
      <Grid container style={{ fontFamily: 'Merriweather Sans', width: '100%' }}>
        <Grid item md={9} sm={9} xs={9} lg={9} xl={9}>
          {!_.isEmpty(candidate) && (
            <Grid container justifyContent="center">
              <Grid md={2} sm={2} xs={2} lg={2} xl={2} item>
                <CandidateHeader
                  fullNameFromUrl={fullNameFromUrl}
                  linkedinIdFromUrl={linkedinIdFromUrl}
                  toggleRoles={toggleRoles}
                  showAllRoles={showAllRoles}
                  candidate={candidate}
                />
              </Grid>
              <Grid md={5} sm={5} xs={5} lg={5} xl={5} item>
                {!_.isEmpty(_.get(candidate, 'tenures', [])) && (
                  <>
                    <div>
                      {showAllRoles ? (
                        _.get(candidate, 'tenures', []).map((tenure) => (
                          <Tenure tenure={tenure} showOnlyMostRecent={showAllRoles} key={_.get(tenure, 'Account__c')} />
                        ))
                      ) : (
                        <Tenure
                          tenure={_.get(candidate, 'tenures.0')}
                          showOnlyMostRecent={!showAllRoles}
                          key={_.get(candidate, 'tenures.0.Account__c')}
                        />
                      )}
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {isLoadingCandidate || _.isUndefined(candidate) ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress size={20} />
              <span style={{ marginLeft: '5px' }}>Loading Candidate...</span>
            </div>
          ) : (
            <>
              <Tabs
                value={selectedTab}
                style={{ width: '80%', margin: 'auto', marginBottom: '20px' }}
                onChange={(a, newValue) => {
                  setSelectedTab(newValue);
                }}
                aria-label="tabs"
              >
                <Tab label="Positions" />
                {!_.isEmpty(candidate.poscans) && <Tab label="Reviewed" />}
              </Tabs>
              {selectedTab === 0 && (
                <>
                  {!_.isEmpty(openPositions) && !_.isEmpty(positionFilters) && !_.isEmpty(optionalPositionFilters) ? (
                    <div style={{ marginRight: '20px' }}>
                      <div style={{ width: '90%', margin: 'auto' }}>
                        <PositionsFilters
                          positionFilters={positionFilters}
                          setPositionFilters={setPositionFilters}
                          optionalPositionFilters={optionalPositionFilters}
                          showSlider={true}
                        />
                        {!_.isEmpty(filteredPositions) &&
                          filteredPositions.map((p) => (
                            <Position
                              key={p.Id}
                              position={p}
                              candidate={candidate}
                              setOpenPositionsSet={setOpenPositionsSet}
                              openPositionsSet={openPositionsSet}
                              candidateSource={candidateSource}
                              candidateDetails={candidateDetails}
                              setRefreshCandidate={setRefreshCandidate}
                              poscanShoppingCartSet={poscanShoppingCartSet}
                              setPoscanShoppingCartSet={setPoscanShoppingCartSet}
                              view={'Candidate'}
                              potentialOwners={potentialOwners.filter(({ Account__c }) => Account__c === p.Account__c)}
                            />
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <CircularProgress size={20} />
                      <span style={{ marginLeft: '5px' }}>Loading Positions...</span>
                    </div>
                  )}
                </>
              )}
              {selectedTab === 1 && (
                <div style={{ width: '90%', margin: 'auto' }}>
                  {_(candidate.poscans)
                    .orderBy(['CreatedDate'], ['desc'])
                    .map((p) => (
                      <Poscan
                        p={p}
                        key={p.Id}
                        parent={'Candidate'}
                        potentialRejectionReasons={potentialRejectionReasons}
                      />
                    ))
                    .value()}
                </div>
              )}
            </>
          )}
        </Grid>
        {!_.isEmpty(candidate) && !_.isEmpty(candidateDetails) && !_.isEmpty(positionFilters) && (
          <Grid item md={3} sm={3} xs={3} lg={3} xl={3}>
            <SideMenu
              candidate={candidate}
              candidateDetails={candidateDetails}
              setCandidateDetails={setCandidateDetails}
              candidateSource={candidateSource}
              setCandidateSource={setCandidateSource}
              setRefreshCandidate={setRefreshCandidate}
              positionFilters={positionFilters}
              setPositionFilters={setPositionFilters}
            />
          </Grid>
        )}
      </Grid>
      <PoscanCart
        handleSendAll={handleSendAll}
        candidate={candidate}
        setSelectedTab={setSelectedTab}
        poscanShoppingCart={poscanShoppingCart}
        poscanShoppingCartSet={poscanShoppingCartSet}
        setPoscanShoppingCartSet={setPoscanShoppingCartSet}
      />
    </>
  );
};
