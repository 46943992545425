import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';

import { SKILLS } from '../utils/sf-consts';

export const SkillSearchbox = ({ selectedSkills, handleSkillChange }) => {
  return (
    <Autocomplete
      className="searchbox"
      style={{ width: 'calc(80% - 1em)', display: 'inline-flex', marginLeft: '1em' }}
      getOptionLabel={(option) => _.get(option, 'label', '')}
      renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
      options={SKILLS}
      value={selectedSkills}
      autoComplete
      multiple
      onChange={handleSkillChange}
      selectOnFocus={false}
      freeSolo={false}
      renderInput={(params) => <TextField {...params} label={'Skill'} />}
    />
  );
};
