import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { AirportShuttle, KeyboardArrowDown, KeyboardArrowUp, LinkedIn, MailOutline, Send } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import avish from '../../static/avish.png';
import { ReactComponent as gmailLogo } from '../../static/gmail.svg';
import { getUserContactId, getUserFirstName, useAPI, withUserState } from '../../userContext';
import {
  CreateSignal,
  getContactEmail,
  getRelatedTargetPageUrl,
  LinkToTamnuni,
  RamzorIcon,
} from '../../utils/contacts';
import { getPeriodText } from '../../utils/dates';
import {
  ALEPH_IN_PROGRESS_INTRO_STATUSES,
  assignRanking,
  getFilteredIntrosInternal,
  IN_PLAY_INTRO_STATUSES,
  IntroStatus,
  NextUpdate,
  RejectionReasonIntro,
} from '../../utils/intro-utils';
import {
  ACTIVE_POSCAN_REQUEST_STATUSES,
  CreatedDateAfter,
  FAILED_REQUEST_STATUSES,
  getFilteredPoscansInternal,
  getOptionalFiltersInternal,
  MonthsSlider,
  NextPortcoFeedbackRequest,
  NextUpdateAfter,
  NextUpdateBefore,
  OLD_POSCANS_STATUSES,
  PoscanFieldGenericInput,
  RejectionReasonPoscan,
  StatusSelect,
} from '../../utils/poscan-utils';
import { REJECTED_INTRO_STATUSES } from '../../utils/sf-consts';
import { SalesforceIcon } from '../../widgets';
import { ContactEmail } from '../../widgets/contacts';
import {
  ask_candidate_feedback,
  ask_portco_feedback,
  claim_poscan,
  get_internal_rejection_reasons,
  get_open_requests,
  get_potential_feedback_statuses,
  get_potential_position_owners,
  get_potential_rejection_reasons,
  ping_aleph_facilitator,
  update_candidate_feedback,
  update_internal_rejection,
  update_intro,
  update_portco_feedback,
  update_poscan_owner,
  update_poscan_rejection,
} from '../api';
import { PositionOwner } from '../position';

function getDefaultFilters({ poscans }) {
  const earliestStartDate = !_.isEmpty(poscans)
    ? _(poscans)
        .filter(({ Candidate__r }) => !_.isEmpty(_.get(Candidate__r, 'Main_Role__r.Start_Date__c')))
        .map(({ Candidate__r }) => _.get(Candidate__r, 'Main_Role__r.Start_Date__c'))
        .sort()
        .head()
    : undefined;

  const latestStartDate = !_.isEmpty(poscans)
    ? _(poscans)
        .filter(({ Candidate__r }) => !_.isEmpty(_.get(Candidate__r, 'Main_Role__r.Start_Date__c')))
        .map(({ Candidate__r }) => _.get(Candidate__r, 'Main_Role__r.Start_Date__c'))
        .sort()
        .reverse()
        .head()
    : undefined;

  return {
    search: [],
    nextUpdateAfter: moment().subtract(6, 'months').format('yyyy-MM-DDThh:mm'),
    nextUpdateBefore: moment().add(1, 'month').format('yyyy-MM-DDThh:mm'),
    createdDateAfter: moment().subtract(3, 'months').format('yyyy-MM-DDThh:mm'),
    monthsMin: _.isUndefined(latestStartDate) ? 0 : moment().diff(latestStartDate, 'months'),
    monthsMax: _.isUndefined(earliestStartDate) ? 0 : moment().diff(earliestStartDate, 'months'),
  };
}

const _IntroActions = ({
  i,
  newStatus,
  handleNewStatus,
  handleRejectionDetails,
  rejectionDetails,
  statusChanged,
  rejectionReason,
  setRejectionReason,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nextUpdate, setNextUpdate] = useState(
    _.isEmpty(_.get(i, 'Next_Update_Request__c'))
      ? undefined
      : moment(_.get(i, 'Next_Update_Request__c')).format('yyyy-MM-DD'),
  );
  const [showSendButton, setShowButton] = useState(false);
  const handleNextUpdate = (event) => {
    const { value } = event.target;
    setNextUpdate(value);
    setShowButton(true);
  };

  const api = useAPI();
  const [lastReq, setLastReq] = useState('');
  const hide_message = () => setLastReq('');

  const updateIntro = async () => {
    try {
      setIsLoading(true);
      const introUpdate = {
        Id: i.Id,
        Status__c: newStatus,
        Rejection_Details__c: rejectionDetails,
        Next_Update_Request__c: nextUpdate,
      };
      await update_intro(introUpdate, api);
      if (newStatus === 'Declined by Target') {
        const poscanUpdate = {
          poscanId: _.get(i, 'Position_Candidate__c'),
          Rejection_Details__c: rejectionDetails,
          Rejection_Reason__c: rejectionReason,
          extra: _.pick(_.get(i, 'Position_Candidate__r'), [
            'Candidate_LinkedIn__c',
            'Id',
            'Candidate_Name__c',
            'Position_Title__c',
            'Status__c',
          ]),
        };
        await update_poscan_rejection(poscanUpdate, api);
      }
      setLastReq('update sent successfully');
      setIsLoading(false);
    } catch (e) {
      setLastReq('Ooops, looks like there was an error updating the intro. Please try again or contact an EIR');
    }
  };

  return (
    <div>
      <div>
        <IntroStatus newStatus={newStatus} handleNewStatus={handleNewStatus} />
        {statusChanged && _.includes(REJECTED_INTRO_STATUSES, newStatus) && (
          <RejectionReasonIntro
            rejectionReason={rejectionReason}
            setRejectionReason={setRejectionReason}
            newStatus={newStatus}
            rejectionDetails={rejectionDetails}
            handleRejectionDetails={handleRejectionDetails}
          />
        )}
        {i.active && <NextUpdate nextUpdate={nextUpdate} handleNextUpdate={handleNextUpdate} introId={i.Id} />}
      </div>
      {(statusChanged || showSendButton) && (
        <>
          <div>
            <Button
              onClick={updateIntro}
              disabled={isLoading}
              color="primary"
              startIcon={<Send />}
              style={{ paddingTop: '18px' }}
            >
              {' '}
              Send
            </Button>
          </div>
        </>
      )}

      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
    </div>
  );
};
const IntroActions = withUserState(_IntroActions);

const _IntroItem = ({ i, userState }) => {
  const api = useAPI();
  const [newStatus, setNewStatus] = useState(i.Status__c);
  const [statusChanged, setStatusChanged] = useState(false);
  const [rejectionDetails, setRejectionDetails] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [lastReq, setLastReq] = useState('');
  const hide_message = () => setLastReq('');

  const handleRejectionDetails = (e) => {
    setRejectionDetails(e.target.value);
  };
  const handleNewStatus = (s) => {
    setNewStatus(s);
    setStatusChanged(true);
  };

  const pingFacilitator = async () => {
    try {
      await ping_aleph_facilitator(
        {
          introId: _.get(i, 'Id'),
          slackUserId: _.get(i, 'Aleph_Facilitator__r.Slack_Id__c'),
        },
        api,
      );
      setLastReq('Ping was sent successfully');
    } catch (e) {
      setLastReq(`Ooops, looks like there was an error - ${e.message}`);
    }
  };
  const showPingButton =
    _.includes(_.concat(ALEPH_IN_PROGRESS_INTRO_STATUSES, IN_PLAY_INTRO_STATUSES), _.get(i, 'Status__c')) &&
    _.get(i, 'Aleph_Facilitator__r.Id') !== getUserContactId(userState);

  return (
    <>
      <TableRow key={'intro-container' + i.Id} style={{ marginLeft: '20px', border: '1px solid' }}>
        <IntroTableCell>
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              maxWidth: '200px',
            }}
          >
            <div style={{ marginBottom: '10px' }}>
              <Link
                href={`https://aleph.lightning.force.com/lightning/r/Contact/${_.get(i, 'Connector__r.Id')}/view`}
                target="_blank"
              >
                {_.get(i, 'Connector__r.Name')}
              </Link>
            </div>
            <div style={{ marginBottom: '10px' }}>{_.get(i, 'Connector__r.Account.Name')}</div>
            <div
              style={{
                color: '#00A394',
              }}
            >
              {_.get(i, 'Connector__r.Title')}
            </div>
          </div>
          <div>
            <Tooltip title={"Connector's Linkedin"}>
              <Link
                href={_.get(i, 'Connector__r.Linkedin_Profile__c') + '?ampliphy_update=true'}
                target="_blank"
                style={{ marginRight: '4px' }}
              >
                <LinkedIn fontSize="small" />
              </Link>
            </Tooltip>
            <Tooltip title={'Intro url'}>
              <Link
                href={`https://aleph.lightning.force.com/lightning/r/Introduction__c/${_.get(i, 'Id')}/view`}
                target="_blank"
                style={{ marginRight: '2px' }}
              >
                <SalesforceIcon fontSize="small" />
              </Link>
            </Tooltip>
            <ContactEmail email={getContactEmail(_.get(i, 'Connector__r')) || 'No Email'} />
            <Tooltip title="Other requests that we can ask for help with">
              <Link
                href={getRelatedTargetPageUrl({
                  public_identifier: _.get(i, 'Connector__r.linkedin_public_idenfitier__c'),
                  fullName: _.get(i, 'Connector__r.Name'),
                })}
                target={'_blank'}
                style={{ display: 'inline' }}
              >
                <AirportShuttle />
              </Link>
            </Tooltip>
          </div>
        </IntroTableCell>
        <IntroTableCell>{_.get(i, 'Relationship_With_Connector__c')}</IntroTableCell>
        <IntroTableCell>
          <div>{_.get(i, 'Aleph_Facilitator__r.Name')}</div>
          {showPingButton && (
            <div>
              <Button
                size={'small'}
                variant={'contained'}
                color={'primary'}
                onClick={pingFacilitator}
                style={{ marginTop: '8px' }}
              >
                Ping
              </Button>
            </div>
          )}
        </IntroTableCell>
        <IntroTableCell>
          <div>
            {
              <IntroActions
                i={i}
                newStatus={newStatus}
                handleNewStatus={handleNewStatus}
                statusChanged={statusChanged}
                rejectionReason={rejectionReason}
                setRejectionReason={setRejectionReason}
                handleRejectionDetails={handleRejectionDetails}
                rejectionDetails={rejectionDetails}
              />
            }
          </div>
        </IntroTableCell>
        <IntroTableCell>
          {showPingButton && (
            <div style={{ marginBottom: '13px' }}>
              Next Update {moment(i.Next_Update_Request__c).format('DD MMM YY')}
            </div>
          )}
          <div style={{ marginBottom: '13px' }}>Matched {moment(i.CreatedDate).format('DD MMM YY')}</div>
          <div>Updated {moment(i.Last_Status_Update__c).format('DD MMM YY')}</div>
        </IntroTableCell>
      </TableRow>
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
    </>
  );
};

const IntroItem = withUserState(_IntroItem);

function getPoscansBySelectedTab({ selectedTab, poscans }) {
  if (selectedTab === 0) {
    //new requests
    return _(poscans)
      .filter(({ intros, Status__c }) => _.isEmpty(intros) && _.includes(ACTIVE_POSCAN_REQUEST_STATUSES, Status__c))
      .orderBy(
        ['Priority__c', 'Request_Type__c', 'CreatedDate', 'ramzorRank', 'predictedEndDate'],
        ['desc', 'desc', 'desc', 'asc', 'asc'],
      )
      .value();
  } else if (selectedTab === 1) {
    //active requests
    return _(poscans)
      .filter(({ intros, Status__c }) => !_.isEmpty(intros) && _.includes(ACTIVE_POSCAN_REQUEST_STATUSES, Status__c))
      .orderBy(['earliestDate', 'earliestIntroScore'], ['asc', 'desc'])
      .value();
  } else if (selectedTab === 2) {
    //FAILED REQUESTS
    return _(poscans)
      .filter(({ Status__c }) => _.includes(FAILED_REQUEST_STATUSES, Status__c))
      .orderBy(['CreatedDate'], ['desc'])
      .value();
  } else if (selectedTab === 3) {
    //OLD REQUESTS
    return _(poscans)
      .filter(({ Status__c }) => _.includes(OLD_POSCANS_STATUSES, Status__c))
      .orderBy(['CreatedDate'], ['desc'])
      .value();
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0',
  maxWidth: '200px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: '0',
}));

const IntroTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px',
}));

const Opportunity = ({ p, source = false, potentialOwners }) => {
  const hasOwner = _.has(p, 'Portco_Owner__r.Name');
  const potentialPositionOwners = potentialOwners.filter(({ Account_Name__c }) => {
    return Account_Name__c === _.get(p, 'Account__c');
  });
  const api = useAPI();

  const [positionDetails, setPositionDetails] = useState();
  const [lastReq, setLastReq] = useState('');
  const hide_message = () => setLastReq('');

  useEffect(() => {
    async function up() {
      const update = {
        poscanId: _.get(p, 'Id'),
        Portco_Owner__c: _.get(positionDetails, 'owner.Contact__c'),
        ..._.omit(positionDetails, ['userChange', 'owner']),
      };
      try {
        await update_poscan_owner(update, api);
        setLastReq('Position owner was updated successfully');
      } catch (e) {
        setLastReq(`Ooops, looks like there was an error - ${e.message}`);
        console.log(e);
      }
    }
    !!_.get(positionDetails, 'userChange', false) && up();
  }, [api, p, positionDetails]);

  return (
    <StyledTableCell align="left">
      <Grid container spacing={2}>
        <Grid
          item
          style={{
            fontStyle: 'normal',
          }}
        >
          <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>{p.Account__c}</div>
          <div style={{ color: '#00A394', fontWeight: 'bold', marginBottom: '10px' }}>
            {!!_.get(p, 'Position_Link__c') ? (
              <Link href={_.get(p, 'Position_Link__c')} target={'_blank'}>
                {_.get(p, 'Position_Title__c')}
              </Link>
            ) : (
              <span>{_.get(p, 'Position_Title__c')}</span>
            )}
          </div>
          <div style={{ color: '#747474', fontSize: '12px', fontWeight: '400' }}>
            <ContactEmail email={_.get(p, 'Portco_Owner__r.Email')} />
            {source !== 'inbound' ? (
              <span>{hasOwner ? `Owner - ${_.get(p, 'Portco_Owner__r.Name')}` : 'No owner'} </span>
            ) : (
              <span>Referrer TBD</span>
            )}
            {!hasOwner && (
              <>
                <PositionOwner
                  positionDetails={positionDetails}
                  setPositionDetails={setPositionDetails}
                  potentialOwners={potentialPositionOwners}
                />
                <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
                  <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
                    {lastReq}
                  </Alert>
                </Snackbar>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </StyledTableCell>
  );
};

const Candidate = ({ p }) => {
  const getGmailUrl = () => {
    return `https://mail.google.com/mail/?authuser=${_.get(p, 'Aleph_Owner__r.Email')}#all/${_.get(p, 'Gmail_Thread_Id__c')}`;
  };
  const lastScrape = _.isEmpty(_.get(p, 'Candidate__r.last_scraper_enrichment_date__c'))
    ? undefined
    : moment(_.get(p, 'Candidate__r.last_scraper_enrichment_date__c'));
  return (
    <StyledTableCell>
      <div style={{ marginBottom: '10px' }}>{_.get(p, 'Candidate_Name__c')}</div>
      {!_.isEmpty(_.get(p, 'Candidate__r.Main_Role__r')) && (
        <>
          <div style={{ color: '#747474', fontSize: '12px', fontWeight: '400' }}>
            <div style={{ marginBottom: '10px' }}>
              {_.get(p, 'Candidate__r.Main_Role__r.Name')} @ {_.get(p, 'Candidate__r.Main_Role__r.Account_Name__c')}
            </div>
            <div style={{ marginBottom: '10px' }}>
              {getPeriodText(moment().diff(moment(_.get(p, 'Candidate__r.Main_Role__r.Start_Date__c')), 'months'))}
              {
                //Since  {moment(_.get(p, 'Candidate__r.Main_Role__r.Start_Date__c')).format('MMM YY')}
              }
            </div>
          </div>
        </>
      )}
      <div>
        <Link
          href={`https://www.linkedin.com/in/${_.get(p, 'Candidate_Public_Linkedin_Id__c')}?ampliphy_update=true`}
          target="_blank"
        >
          <LinkedIn fontSize="small" />
        </Link>
        <Link
          href={`https://aleph.lightning.force.com/lightning/r/Position_Candidate__c/${_.get(p, 'Id')}/view`}
          target="_blank"
          style={{ marginRight: '3px' }}
        >
          <SalesforceIcon fontSize="small" />
        </Link>
        {_.get(p, 'Gmail_Thread_Id__c') ? (
          <Link href={getGmailUrl()} target="_blank" style={{ marginRight: '3px', marginTop: '3px' }}>
            <SvgIcon
              component={gmailLogo}
              style={{ width: '21px', height: '21px' }}
              viewBox={'7.086 7.087 1277.149 924.008'}
            />
          </Link>
        ) : (
          <MailOutline style={{ color: '#747474', fontSize: '21px', fontWeight: '400', marginRight: '3px' }} />
        )}
        {(!lastScrape || moment().diff(lastScrape, 'days') > 30) && (
          <Tooltip title={!!lastScrape ? `scraped: ${lastScrape.format('YYYY-MM-DD')}` : ''}>
            <Link
              href={`https://www.linkedin.com/in/${_.get(p, 'Candidate_Public_Linkedin_Id__c')}?ampliphy_update=true`}
              target="_blank"
            >
              <img src={avish} style={{ width: '18px', height: '18px' }} alt={'Avish'} />
            </Link>
          </Tooltip>
        )}
      </div>
    </StyledTableCell>
  );
};

const Connectors = ({ p, selectedTab, SetShowInactiveIntros, showInactiveIntros }) => {
  return (
    <StyledTableCell>
      {!_.isEmpty(_.get(p, 'intros', [])) && selectedTab !== 0 && (
        <>
          <div>Connectors ({_.get(p, 'intros', []).length})</div>
          <div>
            Show Old
            <Button aria-label="expand row" size="small" onClick={() => SetShowInactiveIntros(!showInactiveIntros)}>
              {showInactiveIntros ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          </div>
        </>
      )}
    </StyledTableCell>
  );
};

const Actions = ({ p }) => {
  return (
    <StyledTableCell align={'center'}>
      <CreateSignal Id={_.get(p, 'Candidate__c')} FirstName={_.get(p, 'Candidate_First_Name__c')} />
    </StyledTableCell>
  );
};

const IntrosRow = ({ p, showInactiveIntros }) => {
  const sortedIntros = _(_.get(p, 'intros', []))
    .orderBy(['Next_Update_Request__c'], ['asc'])
    .value();
  const introsToShow = showInactiveIntros ? sortedIntros : sortedIntros.filter(({ Is_In_Play__c }) => !!Is_In_Play__c);
  return (
    <StyledTableRow key={'row-connectors' + p.Id}>
      <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={'8'}>
        <Table style={{ border: '1px solid' }}>
          <TableHead>
            <TableRow style={{ border: '1px solid' }}>
              <IntroTableCell>Connector</IntroTableCell>
              <IntroTableCell>Relationship with target</IntroTableCell>
              <StyledTableCell>Aleph Facilitator</StyledTableCell>
              <IntroTableCell>Status</IntroTableCell>
              <IntroTableCell>Dates</IntroTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {introsToShow.map((i) => (
              <IntroItem i={i} key={'IntroItem-item' + i.Id} />
            ))}
          </TableBody>
        </Table>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const _AlephOwner = ({ p, source, userState }) => {
  const [disableClaim, setDisableClaim] = useState(false);
  const api = useAPI();
  const userFirstName = _.get(userState, 'aleph_user.payload.given_name');
  const [alephOwner, setAlephOwner] = useState(_.get(p, 'Aleph_Owner__r.FirstName', 'No Owner'));

  const claimPoscan = async () => {
    setDisableClaim(true);
    const isOwner = alephOwner === userFirstName;
    await claim_poscan(
      {
        poscanId: p.Id,
        isOwner,
      },
      api,
    );
    setAlephOwner(isOwner ? 'No Owner' : userFirstName);
    setDisableClaim(false);
  };
  return (
    <StyledTableCell>
      <div style={{ marginBottom: '8px', color: '#747474', fontSize: '12px', fontWeight: '400' }}>{alephOwner}</div>
      <Button size={'small'} disabled={disableClaim} color={'primary'} variant={'outlined'} onClick={claimPoscan}>
        {alephOwner !== userFirstName ? 'Claim' : 'Disclaim'}
      </Button>
      {source === 'Outbound' && <LinkToTamnuni public_id_linkedin={_.get(p, 'Candidate_Public_Linkedin_Id__c')} />}
    </StyledTableCell>
  );
};

const Owner = withUserState(_AlephOwner);

const PoscanNextUpdate = ({ p, selectedTab }) => {
  return (
    <div style={{ color: '#747474', fontSize: '12px', fontWeight: '400' }}>
      <>
        {!_.get(p, 'earliestDate') && selectedTab === 1 ? (
          <span>No Active Intro </span>
        ) : (
          <>
            <span>Next Update: </span>
            {moment(_.get(p, 'earliestDate')).format('DD MMM YY')}
          </>
        )}
      </>
    </div>
  );
};

const Dates = ({ p, source }) => {
  return (
    <div style={{ color: '#747474', fontSize: '12px', fontWeight: '400' }}>
      <span>{source === 'Feedback' ? 'Intro date:' : 'Created:'}</span>
      {moment(source === 'Feedback' ? _.get(p, 'Intro_Date__c') : _.get(p, 'CreatedDate')).format('DD MMM YY')}
    </div>
  );
};

const RequestInfo = ({ p, source, potentialRejectionReasons, internalRejectionReasons }) => {
  const [status, setStatus] = useState(_.get(p, 'Status__c'));

  return (
    <StyledTableCell>
      <>
        {source === 'Feedback' && (
          <div style={{ marginBottom: '10px' }}>
            <NextPortcoFeedbackRequest
              date={moment(_.get(p, 'Next_Portco_Feedback_Request__c')).format('yyyy-MM-DDThh:mm')}
              poscanId={_.get(p, 'Id')}
            />
          </div>
        )}
        <div style={{ marginBottom: '10px' }}>
          <StatusSelect poscan={p} status={status} setStatus={setStatus} />
        </div>
        {source === 'Outbound' && <div>{_.get(p, 'Priority__c')}</div>}
        {status.toLowerCase().includes('reject') && (
          <>
            <div style={{ marginBottom: '12px' }}>
              <RejectionReasonPoscan
                poscan={{
                  ..._.omit(p, 'Status__c'),
                  Status__c: _.get(p, 'Status__c'),
                }}
                width={'80%'}
                potentialRejectionReasons={potentialRejectionReasons}
              />
            </div>
            <div style={{ marginBottom: '12px' }}>
              <PoscanFieldGenericInput
                fieldName={'Rejection Details'}
                fieldApiName={'Rejection_Details__c'}
                poscan={p}
                updateFunc={update_poscan_rejection}
                inputType={'textarea'}
                width={'80%'}
              />
            </div>
          </>
        )}
        {source === 'Outbound' && status === "Couldn't find connection" && (
          <div style={{ marginBottom: '12px' }}>
            <PoscanFieldGenericInput
              fieldName={'Internal Rejection'}
              fieldApiName={'Internal_Rejection_Reasons__c'}
              poscan={p}
              updateFunc={update_internal_rejection}
              inputType={'select'}
              width={'100%'}
              options={internalRejectionReasons}
            />
          </div>
        )}
        <div>
          <Dates p={p} source={source} />
        </div>
      </>
    </StyledTableCell>
  );
};

const FeedbackCell = ({
  p,
  statusFieldApiName,
  feedbackFieldApiName,
  asked,
  ask,
  potentialStatuses,
  updateFunc,
  hide_message,
}) => {
  return (
    <StyledTableCell>
      {!_.isEmpty(potentialStatuses) && (
        <div style={{ marginBottom: '12px' }}>
          <PoscanFieldGenericInput
            fieldName={'Status'}
            fieldApiName={statusFieldApiName}
            poscan={p}
            options={potentialStatuses}
            updateFunc={updateFunc}
            inputType={'select'}
          />
        </div>
      )}
      <div style={{ marginBottom: '16px' }}>
        <PoscanFieldGenericInput
          fieldName={'Feedback'}
          fieldApiName={feedbackFieldApiName}
          poscan={p}
          updateFunc={updateFunc}
          inputType={'textarea'}
        />
      </div>
      <Button
        style={{ marginBottom: '16px' }}
        size={'small'}
        color={'primary'}
        variant={'outlined'}
        onClick={ask}
        disabled={(asked || _.isEmpty(_.get(p, 'Portco_Owner__r'))) && statusFieldApiName.includes('Portco')}
      >
        Ask {statusFieldApiName.includes('Candidate') ? 'Candidate' : 'Portco'}
      </Button>
      <Snackbar open={!!asked} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={asked.includes('Success') ? 'success' : 'error'}>
          {asked}
        </Alert>
      </Snackbar>
    </StyledTableCell>
  );
};

const FeedbackRow = ({ p, potentialStatuses, potentialOwners, potentialRejectionReasons }) => {
  const [portcoFeedbackRequested, setPortcoFeedbackRequested] = useState('');
  const [candidateFeedbackRequested, setCandidateFeedbackRequested] = useState('');
  const askPortcoFeedback = async () => {
    await ask_portco_feedback(_.get(p, 'Id'), setPortcoFeedbackRequested, _.get(p, 'Aleph_Owner__r.FirstName'));
  };
  const askCandidateFeedback = async () => {
    await ask_candidate_feedback(_.get(p, 'Id'), setCandidateFeedbackRequested, _.get(p, 'Aleph_Owner__r.FirstName'));
  };

  return (
    <>
      <StyledTableRow key={'row-info' + p.Id}>
        <Opportunity p={p} potentialOwners={potentialOwners} />
        <Candidate p={p} />
        <RequestInfo p={p} source={'Feedback'} potentialRejectionReasons={potentialRejectionReasons} />
        <FeedbackCell
          p={p}
          asked={portcoFeedbackRequested}
          ask={askPortcoFeedback}
          potentialStatuses={potentialStatuses}
          hide_message={() => setPortcoFeedbackRequested('')}
          statusFieldApiName={'Portco_Feedback_Status__c'}
          feedbackFieldApiName={'Portco_Feedback__c'}
          updateFunc={update_portco_feedback}
        />
        <FeedbackCell
          p={p}
          asked={candidateFeedbackRequested}
          ask={askCandidateFeedback}
          potentialStatuses={potentialStatuses}
          hide_message={() => setCandidateFeedbackRequested('')}
          statusFieldApiName={'Candidate_Feedback_Status__c'}
          feedbackFieldApiName={'Candidate_Feedback__c'}
          updateFunc={update_candidate_feedback}
        />
        <StyledTableCell style={{ color: '#747474', fontSize: '12px', fontWeight: '400' }}>
          {!!_.get(p, 'Comments__c') && (
            <div>
              <b>Additional comments: </b>
              <span>{_.get(p, 'Comments__c')}</span>
            </div>
          )}
        </StyledTableCell>
        <Owner p={p} />
      </StyledTableRow>
    </>
  );
};

const _PoscanRow = ({ p, selectedTab, source, potentialOwners, internalRejectionReasons }) => {
  const [showInactiveIntros, SetShowInactiveIntros] = useState(false);
  return (
    <>
      <StyledTableRow key={'row-info' + p.Id}>
        <Opportunity p={p} source={source} potentialOwners={potentialOwners} />
        <Candidate p={p} source={source} />
        {source === 'Outbound' && selectedTab !== 3 && (
          <StyledTableCell align={'center'}>
            <RamzorIcon c={_.get(p, 'Candidate__r')} size={20} />
            {_.get(p, 'predictedEndDate') && (
              <div>predicted end date: {moment(_.get(p, 'predictedEndDate')).format('MMM YY')}</div>
            )}
          </StyledTableCell>
        )}
        <RequestInfo p={p} source={source} internalRejectionReasons={internalRejectionReasons} />
        <StyledTableCell style={{ color: '#747474', fontSize: '12px', fontWeight: '400' }}>
          {_.get(p, 'Request_Type__c') === 'Intro request' && !!_.get(p, 'personalized_message_for_candidate__c') && (
            <div>
              <b>Personal message: </b>
              <span>{_.get(p, 'personalized_message_for_candidate__c')}</span>
            </div>
          )}
          {_.get(p, 'Request_Type__c') === 'Ref check request' && !!_.get(p, 'Reference_Check_Details__c') && (
            <div>
              <b>Request details: </b>
              <span>{_.get(p, 'Reference_Check_Details__c')}</span>
            </div>
          )}
          {!!_.get(p, 'Comments__c') && (
            <div>
              <b>Additional comments: </b>
              <span>{_.get(p, 'Comments__c')}</span>
            </div>
          )}
        </StyledTableCell>
        <Owner p={p} source={source} />
        {source === 'Outbound' && (
          <>
            <StyledTableCell align="center">
              <PoscanNextUpdate p={p} source={source} selectedTab={selectedTab} />
            </StyledTableCell>
            <Connectors
              p={p}
              selectedTab={selectedTab}
              SetShowInactiveIntros={SetShowInactiveIntros}
              showInactiveIntros={showInactiveIntros}
            />
          </>
        )}
        {selectedTab <= 1 && <Actions p={p} source={source} />}
      </StyledTableRow>
      {!_.isEmpty(_.get(p, 'intros', [])) && <IntrosRow p={p} showInactiveIntros={showInactiveIntros} />}
    </>
  );
};

const PoscanRow = withUserState(_PoscanRow);

const OutboundFilters = ({ optionalRequestFilters, intros, setFilters, filters }) => {
  const handleOptionSelection = (event, option) => {
    setFilters({
      ..._.omit(filters, ['search']),
      search: option,
    });
  };
  return (
    <>
      {!_.isEmpty(optionalRequestFilters) && !_.isEmpty(intros) && !_.isUndefined(filters) && (
        <Grid container style={{ paddingTop: '3px', marginBottom: '10px' }} spacing={2} justifyContent="center">
          <Grid item md={8} xl={8} lg={8} style={{ marginRight: '20px' }}>
            <div>
              <Autocomplete
                getOptionLabel={(option) => `${option.value} ${option.count ? `(${option.count})` : ''}`}
                options={optionalRequestFilters}
                groupBy={(option) => option.type}
                value={filters.search}
                autoComplete
                multiple
                onChange={handleOptionSelection}
                selectOnFocus={false}
                freeSolo={false}
                renderInput={(params) => (
                  <TextField className={'searchbox'} variant="outlined" style={{ borderRadius: '32px' }} {...params} />
                )}
              />
            </div>
            <div>
              <MonthsSlider filters={filters} setFilters={setFilters} optionalRequestFilters={optionalRequestFilters} />
            </div>
          </Grid>

          <Grid item md={2} xl={2} lg={2}>
            <div style={{ marginBottom: '8px' }}>
              <NextUpdateAfter filters={filters} setFilters={setFilters} />
            </div>
            <div>
              <NextUpdateBefore filters={filters} setFilters={setFilters} />
            </div>
            <div>
              <CreatedDateAfter filters={filters} setFilters={setFilters} />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const _AwaitingFeedback = ({ loading, poscans, potentialOwners, potentialRejectionReasons, userState }) => {
  const [potentialStatuses, setPotentialStatuses] = useState([]);
  const api = useAPI();

  const [whichPoscans, setWhichPoscans] = useState('My');
  const handleChange = (event) => {
    setWhichPoscans(event.target.value);
  };

  useEffect(() => {
    get_potential_feedback_statuses(api).then(({ statuses }) => setPotentialStatuses(statuses));
  }, [api]);
  const getMyPoscans = () => {
    return poscans.filter(({ Aleph_Owner__r }) => {
      return getUserFirstName(userState) === 'Bar'
        ? _.get(Aleph_Owner__r, 'FirstName') === getUserFirstName(userState)
        : _.get(Aleph_Owner__r, 'FirstName') !== 'Bar';
    });
  };
  const [filteredPoscans, setFilteredPoscans] = useState(getMyPoscans());

  useEffect(() => {
    if (loading) {
      return;
    }
    if (whichPoscans === 'All') {
      setFilteredPoscans(poscans);
    } else {
      setFilteredPoscans(getMyPoscans());
    }
  }, [whichPoscans, loading]);

  return (
    <div style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}>
      {loading && (
        <>
          <CircularProgress size={20} />
          <span style={{ marginLeft: '5px' }}>Loading Candidates...</span>
        </>
      )}
      {!loading && (
        <div>
          <>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="poscan-interest"
                value={whichPoscans}
                name="poscan-interest"
                onChange={handleChange}
              >
                <FormControlLabel value={'My'} control={<Radio />} label="My" />
                <FormControlLabel value={'All'} control={<Radio />} label="All" />
              </RadioGroup>
            </FormControl>
          </>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Opportunity</StyledTableCell>
                  <StyledTableCell>Target</StyledTableCell>
                  <StyledTableCell>Request</StyledTableCell>
                  <StyledTableCell>Portco Feedback</StyledTableCell>
                  <StyledTableCell>Candidate Feedback</StyledTableCell>
                  <StyledTableCell>Comments</StyledTableCell>
                  <StyledTableCell>Aleph Owner</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPoscans.map((p) => (
                  <FeedbackRow
                    p={p}
                    key={'feedback-row-' + p.Id}
                    potentialRejectionReasons={potentialRejectionReasons}
                    potentialStatuses={potentialStatuses}
                    potentialOwners={potentialOwners}
                    api={api}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

const AwaitingFeedback = withUserState(_AwaitingFeedback);

const OutboundTables = ({
  filteredPoscans,
  selectedTab,
  setSelectedTab,
  loadingOutbound,
  potentialOwners,
  internalRejectionReasons,
}) => {
  const getCount = (tab) => getPoscansBySelectedTab({ poscans: filteredPoscans, selectedTab: tab }).length;
  const getCountText = (tab) => {
    const count = getCount(tab);
    return selectedTab === tab || count > 0 ? ` (${loadingOutbound ? 'loading' : count})` : '';
  };
  return (
    <div style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}>
      <>
        {loadingOutbound && (
          <>
            <CircularProgress size={20} />
            <span style={{ marginLeft: '5px' }}>Loading Candidates...</span>
          </>
        )}

        <Tabs
          value={selectedTab}
          style={{ width: '100%', margin: 'auto', marginBottom: '20px' }}
          onChange={(a, newValue) => setSelectedTab(newValue)}
          aria-label="tabs"
        >
          <Tab label={`New ${getCountText(0)}`} />
          <Tab label={`IN Play - Aleph ${getCountText(1)}`} />
          <Tab label={`Failed ${getCountText(2)}`} />
          <Tab label={`Closed ${getCountText(3)}`} />
        </Tabs>
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Opportunity</StyledTableCell>
                  <StyledTableCell>Target</StyledTableCell>
                  {selectedTab !== 2 && selectedTab !== 4 && (
                    <StyledTableCell align={'center'}>Likeliness to connect</StyledTableCell>
                  )}
                  <StyledTableCell>Request</StyledTableCell>
                  {(selectedTab === 2 || selectedTab === 4) && (
                    <>
                      <StyledTableCell>Next Portco Feedback Request</StyledTableCell>
                      <StyledTableCell>Portco Feedback</StyledTableCell>
                      <StyledTableCell>Candidate Feedback</StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>Comments</StyledTableCell>
                  <StyledTableCell>Aleph Owner</StyledTableCell>
                  <StyledTableCell align={'center'}>
                    {' '}
                    {selectedTab === 1 ? 'Next Update' : 'Date Submitted'}
                  </StyledTableCell>
                  {selectedTab !== 0 && selectedTab !== 2 && <StyledTableCell>Connectors</StyledTableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {getPoscansBySelectedTab({
                  poscans: filteredPoscans,
                  selectedTab,
                }).map((p) => (
                  <PoscanRow
                    potentialOwners={potentialOwners}
                    internalRejectionReasons={internalRejectionReasons}
                    p={p}
                    selectedTab={selectedTab}
                    source={'Outbound'}
                    key={'poscan-row-' + p.Id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    </div>
  );
};

const InboundTables = ({ inboundCandidates, selectedTab, loadingInbound, potentialOwners }) => {
  const poscansToReview = inboundCandidates.filter(({ Status__c }) => Status__c === 'Talpod To Review');

  return (
    <>
      <Grid container justifyContent="center">
        <Grid md={8} sm={9} xs={12} lg={8} xl={8} item>
          {loadingInbound && (
            <>
              <CircularProgress size={20} />
              <span style={{ marginLeft: '5px' }}>Loading Candidates...</span>
            </>
          )}
          {!loadingInbound && (
            <>
              <div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Opportunity</StyledTableCell>
                        <StyledTableCell>Candidate</StyledTableCell>
                        <StyledTableCell>Request </StyledTableCell>
                        <StyledTableCell>Aleph Owner</StyledTableCell>
                        <StyledTableCell align={'center'}>Date Created</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {poscansToReview.map((p) => (
                        <PoscanRow
                          p={p}
                          potentialOwners={potentialOwners}
                          selectedTab={selectedTab}
                          source={'Inbound'}
                          key={'poscan-row-' + p.Id}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export const OpenRequests = () => {
  const api = useAPI();
  const [filters, setFilters] = useState({
    createdDateAfter: moment().subtract(3, 'months').format('yyyy-MM-DDThh:mm'),
  });
  const [optionalRequestFilters, setOptionalRequestFilters] = useState([]);
  const [potentialRejectionReasons, setPotentialRejectionReasons] = useState([]);
  const [internalRejectionReasons, setInternalRejectionReasons] = useState([]);

  const [inboundCandidates, setInbound] = useState([]);
  const [loadingInbound, setLoadingInbound] = useState(false);

  const [intros, setIntros] = useState([]);
  const [activeIntros, setActiveIntros] = useState([]);
  const [closedIntros, setClosedIntros] = useState([]);
  const [failedIntros, setFailedIntros] = useState([]);

  const [outboundPoscans, setOutboundPoscans] = useState([]);
  const [activePoscans, setActivePoscans] = useState([]);
  const [closedPoscans, setClosedPoscans] = useState([]);
  const [failedPoscans, setFailedPoscans] = useState([]);

  const [feedbackPoscans, setFeedbackPoscans] = useState([]);
  const [loadingFeedback, setLoadingFeedback] = useState(false);

  const [filteredPoscans, setFilteredPoscans] = useState([]);
  const [loadingOutbound, setLoadingOutbound] = useState(false);

  const [selectedType, setSelectedType] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const [potentialOwners, setPotentialOwners] = useState([]);

  useEffect(() => {
    setSelectedTab(0);
  }, [selectedType, api]);

  useEffect(() => {
    async function fetchOutbound() {
      try {
        setLoadingOutbound(true);
        const { intros: ai, poscans: ap } = await get_open_requests(
          {
            onlyActive: selectedTab <= 2,
            type: 'Outbound',
            subType: selectedTab === 3 ? 'Failed' : selectedTab === 4 ? 'Closed' : undefined,
            createdDateAfter: filters.createdDateAfter,
          },
          api,
        );
        if (selectedTab <= 2) {
          setActiveIntros(ai.map(assignRanking));
          setActivePoscans(ap);
        } else if (selectedTab === 3) {
          setFailedIntros(ai.map(assignRanking));
          setFailedPoscans(ap);
        } else if (selectedTab === 4) {
          setClosedIntros(ai.map(assignRanking));
          setClosedPoscans(ap);
        }
        setLoadingOutbound(false);
      } catch (e) {
        console.log(e);
      }
    }

    async function fetchInbound() {
      setLoadingInbound(true);
      get_open_requests(
        {
          onlyActive: true,
          type: 'Inbound',
        },
        api,
      ).then(({ poscans: p }) => {
        setInbound(p);
        setLoadingInbound(false);
      });
    }

    async function fetchFeedback() {
      setLoadingFeedback(true);
      get_open_requests(
        {
          type: 'Feedback',
        },
        api,
      ).then(({ poscans: pc }) => {
        setFeedbackPoscans(
          _(pc)
            .map((p) => ({
              Next_Portco_Feedback_Request__c:
                _.get(p, 'Next_Portco_Feedback_Request__c') ||
                moment(_.get(p, 'Intro_Date__c')).add(7, 'days').format('yyyy-MM-DDThh:mm'),
              ..._.omit(p, ['Next_Portco_Feedback_Request__c']),
            }))
            .orderBy(['Next_Portco_Feedback_Request__c'], ['asc'])
            .value(),
        );
        setLoadingFeedback(false);
      });
    }

    if (selectedType === 0) {
      fetchOutbound();
    } else if (selectedType === 1) {
      fetchInbound();
    } else {
      fetchFeedback();
    }
  }, [selectedType, selectedTab, api, filters.createdDateAfter]);

  useEffect(() => {
    setIntros(_.concat(activeIntros, failedIntros, closedIntros).map(assignRanking));
    setOutboundPoscans(_.concat(activePoscans, failedPoscans, closedPoscans));
  }, [activeIntros, failedIntros, closedIntros, activePoscans, failedPoscans, closedPoscans]);

  const [filteredIntros, setFilteredIntros] = useState([]);
  useEffect(() => {
    if (_.isEmpty(intros) || _.isUndefined(filters)) {
      return;
    }
    setFilteredIntros(getFilteredIntrosInternal({ intros, filters }));
  }, [intros, filters]);

  useEffect(() => {
    if (_.isEmpty(filteredIntros) || _.isUndefined(filters)) {
      return;
    }
    setFilteredPoscans(getFilteredPoscansInternal({ poscans: outboundPoscans, filteredIntros, filters, intros }));
  }, [outboundPoscans, filteredIntros, filters, intros]);

  useEffect(() => {
    setFilters(getDefaultFilters({ intros, poscans: outboundPoscans }));
    setOptionalRequestFilters(getOptionalFiltersInternal({ intros, poscans: outboundPoscans }));
  }, [intros, outboundPoscans]);

  useEffect(() => {
    async function fetchOwners() {
      try {
        await Promise.all([
          get_potential_position_owners(api).then(({ contacts }) => setPotentialOwners(contacts)),
          get_potential_rejection_reasons(api).then(({ reasons }) => setPotentialRejectionReasons(reasons)),
          get_internal_rejection_reasons(api).then(({ reasons }) => setInternalRejectionReasons(reasons)),
        ]);
      } catch (e) {
        console.log(e);
      }
    }
    fetchOwners();
  }, [api]);

  const loadingTab = (tab) =>
    selectedType === tab &&
    ((tab === 0 && loadingOutbound) || (tab === 1 && loadingInbound) || (tab === 2 && loadingFeedback));

  const getTabText = (tab, label, array) =>
    loadingTab(tab)
      ? `${label} (loading)`
      : selectedType === tab || !_.isEmpty(array)
        ? `${label} (${array.length})`
        : label;
  return (
    <>
      <Tabs
        value={selectedType}
        style={{ width: '80%', margin: 'auto', marginBottom: '20px' }}
        onChange={(a, newValue) => setSelectedType(newValue)}
        aria-label="tabs"
      >
        <Tab label={getTabText(0, 'Outbound', outboundPoscans)} />
        <Tab label={getTabText(1, 'Inbound', inboundCandidates)} />
        <Tab label={getTabText(2, 'Awaiting Feedback Last 60 Days', feedbackPoscans)} />
      </Tabs>
      {selectedType === 0 && (
        <>
          <OutboundFilters
            filters={filters}
            setFilters={setFilters}
            optionalRequestFilters={optionalRequestFilters}
            intros={intros}
          />
          <OutboundTables
            loadingOutbound={loadingOutbound}
            filteredPoscans={filteredPoscans}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            potentialOwners={potentialOwners}
            internalRejectionReasons={internalRejectionReasons}
          />
        </>
      )}
      {selectedType === 1 && (
        <>
          {/*
                    <InboundFilters filters={filters}
                                    optionalRequestFilters={optionalRequestFilters} intros={intros}  />
                */}
          <InboundTables
            loadingInbound={loadingInbound}
            potentialOwners={potentialOwners}
            inboundCandidates={inboundCandidates}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </>
      )}
      {
        //awaiting feedback
        selectedType === 2 && (
          <AwaitingFeedback
            loading={loadingFeedback}
            poscans={feedbackPoscans}
            potentialOwners={potentialOwners}
            potentialRejectionReasons={potentialRejectionReasons}
          />
        )
      }
    </>
  );
};
