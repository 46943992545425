import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { arrayToCsv, CsvToArray } from '../../misc/csv-array';
import { getUserAccountName, useAPI, useUserState } from '../../userContext';
import { handle_focus_list } from './api';

const MyDataTable = ({ data, onSelectConnector }) => {
  const columns = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              {columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox onChange={() => onSelectConnector(row.connector_linkedin_url)} />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell key={idx}>{Array.isArray(row[column]) ? row[column].join(', ') : row[column]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const CHANCE_TO_CONNECT_OPTIONS = ['good chance', 'some chance', 'long shot'];

export function FocusList() {
  const api = useAPI();
  const [leads, setLeads] = useState([]);
  const [enrichedLeads, setEnrichedLeads] = useState([]);
  const [topConnectors, setTopConnectors] = useState([]);
  const [selectedConnectors, setSelectedConnectors] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [missingContacts, setMissingContacts] = useState([]);

  const [lastReq, setLastReq] = useState('');
  const [enrichingLeads, setEnrichingLeads] = useState(false);
  const [desiredChanceToConnect, setChanceToConnect] = useState(['good chance', 'some chance', 'long shot']);
  const { userState } = useUserState();

  const hide_message = () => setLastReq('');

  const handleChangeChanceToConnect = (event) => {
    setChanceToConnect(event.target.value);
  };

  const handleConnectorCheck = (connectorUrl) => {
    setSelectedConnectors((current) => {
      if (current.includes(connectorUrl)) {
        return current.filter((url) => url !== connectorUrl);
      } else {
        return [...current, connectorUrl];
      }
    });
  };

  const createList = (type) => {
    const relevantLeads =
      type === 'full'
        ? enrichedLeads
        : enrichedLeads.filter(
            (lead) =>
              lead['connectors Linkedin Urls'] &&
              lead['connectors Linkedin Urls'].some((url) => selectedConnectors.includes(url)),
          );
    const cleanLeands = relevantLeads.map((lead) => ({
      linkedin_profile: lead['linkedin_profile'],
      Name: lead['name'],
      'Focus score': lead['Focus score'],
      Company: lead['current_company'],
      Title: lead['current_title'],
      'Focus url': lead['Focus url'],
      'connectors Linkedin Urls': lead['connectors Linkedin Urls'],
    }));
    arrayToCsv(cleanLeands, `${type === 'full' ? 'all-' : 'top-connectors-'}leads`);
  };

  const exportRelationships = () => {
    arrayToCsv(relationships, 'relationships');
  };

  useEffect(() => {
    async function handle_csv() {
      setEnrichingLeads(true);
      return handle_focus_list({ leads, desiredChanceToConnect }, api).then((res) => {
        setEnrichedLeads(_.get(res, 'enrichedLeads', []));
        setTopConnectors(_.get(res, 'topConnectors', []));
        setRelationships(_.get(res, 'relationships', []));
        setMissingContacts(_.get(res, 'missingContacts', []));
        setEnrichingLeads(false);
        //setLastReq(_.get(res, 'success', false) ? 'successfully enriched leads. check out the enriched csv' : 'error - '+_.get(res, 'error'));
      });
    }
    if (!_.isEmpty(leads)) {
      handle_csv();
    }
  }, [leads]);

  return (
    <Box
      style={{
        width: '50%',
        margin: 'auto',
        padding: '20px',
        backgroundColor: getUserAccountName(userState) === 'Aleph' ? 'red' : 'transparent',
      }}
    >
      <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px' }}>
        Focus List Generator
      </Typography>

      <CsvToArray
        header={'Upload csv from search'}
        subHeader={'Please follow the instructions below'}
        array={leads}
        setArray={setLeads}
      />
      <p>
        <li> make sure you are logged in as the relevant portco user (hack) </li>
        <li>
          {' '}
          note the the code is looking for columns called "linkedin" (case insensitive), "full_name"(case sensitive - if
          no url provided) for the matching{' '}
        </li>
        <li>
          {' '}
          all fields of the input (when not exporting directly from Linkedin will be kept so make sure you edit before
          submitting{' '}
        </li>
        <li>
          {' '}
          please note that we do not validate the results. currently we trust Google to find the right contact based on
          full name, location, title & company. to be on the safe side we might want to use magic spreadsheet for now.{' '}
        </li>
      </p>
      <FormControl style={{ minWidth: 200, marginBottom: 20 }}>
        <InputLabel id="chance-to-connect-label">Chance to Connect</InputLabel>
        <Select
          labelId="chance-to-connect-label"
          id="chance-to-connect-select"
          multiple
          value={desiredChanceToConnect}
          onChange={handleChangeChanceToConnect}
          renderValue={(selected) => selected.join(', ')}
        >
          {CHANCE_TO_CONNECT_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {enrichingLeads && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={20} />
          <span style={{ marginLeft: '5px' }}>enriching leads...</span>
        </div>
      )}
      {!_.isEmpty(missingContacts) && (
        <div>
          <h5>Missing Contacts</h5>
          {missingContacts.map((url) => (
            <div style={{ color: 'red' }}>{url}</div>
          ))}
        </div>
      )}
      {!_.isEmpty(enrichedLeads) && (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={_.partial(createList, 'full')}
            style={{ marginBottom: '20px', marginRight: '20px' }}
          >
            Create Full List
          </Button>
          <Button variant="contained" color="primary" onClick={exportRelationships} style={{ marginBottom: '20px' }}>
            Export Relationships
          </Button>
        </div>
      )}

      {!_.isEmpty(topConnectors) && (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={_.partial(createList, 'top')}
            style={{ marginBottom: '20px', marginRight: '20px' }}
            disabled={_.isEmpty(selectedConnectors)}
          >
            Create Top Connectors List
          </Button>
          <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px' }}>
            Top Connectors
          </Typography>
          <MyDataTable data={topConnectors} onSelectConnector={handleConnectorCheck} showCheckboxes={true} />
        </div>
      )}

      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
    </Box>
  );
}
