import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { Email } from '@material-ui/icons';
import React, { useState } from 'react';
import { withRouter } from 'react-router';

import { useAPI } from '../userContext';

const _FeedbackWidget = ({ location }) => {
  const api = useAPI();
  const [open, setOpen] = useState(false);
  const [done, setDone] = useState(false);
  const [value, setValue] = useState('');
  const handleClose = () => {
    setDone(false);
    setOpen(false);
  };
  const handleOpen = () => setOpen(true);
  const handleChange = (e) => setValue(e.target.value);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = JSON.stringify({ feedback: value, search: location.search, pathname: location.pathname });
    try {
      await api('user/feedback', { method: 'POST', body: body });
      setValue('');
      setDone(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <Button onClick={handleOpen}>
        <Email />
        <Typography>Send Us Feedback</Typography>
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="suggestion-dialog-title" open={open}>
        <DialogTitle id="suggestion-dialog-title">Question? Suggestion? Tell us here!</DialogTitle>
        <DialogContent>
          {done ? (
            <DialogContentText>Thank you!</DialogContentText>
          ) : (
            <>
              <DialogContentText>
                We{' '}
                <span role="img" aria-label="love">
                  ❤️
                </span>{' '}
                feedback! please tell us how we can improve
              </DialogContentText>
              <TextField
                autoFocus
                id="feedback"
                type="text"
                multiline
                fullWidth
                rows={6}
                variant="outlined"
                value={value}
                onChange={handleChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {done ? (
            <Button onClick={handleClose} color="primary">
              Done
            </Button>
          ) : (
            <>
              <Button onClick={handleSubmit} color="primary">
                Submit
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export const FeedbackWidget = withRouter(_FeedbackWidget);
