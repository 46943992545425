import { Button } from '@material-ui/core';
import _ from 'lodash';
import Papa from 'papaparse';
import React, { useState } from 'react';

export function CsvToArray({ header, setArray, subHeader, array }) {
  const [file, setFile] = useState();
  const [showArray, setShowArray] = useState(false);
  const [errors, setErrors] = useState([]);
  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = (string) => {
    var { data, errors } = Papa.parse(string, { header: true });
    setArray(data);
    setErrors(errors);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{header} </h1>
      <h3>{subHeader}</h3>
      <form>
        <input type={'file'} id={'csvFileInput'} accept={'.csv'} onChange={handleOnChange} />

        <Button
          variant={'outlined'}
          disabled={_.isUndefined(file)}
          style={{ marginLeft: '20px' }}
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          IMPORT CSV
        </Button>
      </form>
      {!_.isEmpty(errors) && (
        <div>
          <h3>Found {errors.length} errors in the file. please fix them and try again:</h3>
          {errors.map(({ message, row }, i) => (
            <div key={'error-' + i}>
              row: {row} - {message}
            </div>
          ))}
          <Button variant={'outlined'} style={{ marginTop: '10px' }} onClick={() => setShowArray(!showArray)}>
            Show rows
          </Button>
          {showArray && (
            <div>
              {array.map((row, i) => (
                <div key={i}>
                  {i}: {JSON.stringify(row)}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export const arrayToCsv = (array, fileName) => {
  let csv = Papa.unparse(array);
  var blob = new Blob([csv]);
  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
