import crypto from 'crypto';

import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import React, { useState } from 'react';

import goodChanceStroke from '../../static/focus/good-chance-stroke.svg';
import goodChance from '../../static/focus/good-chance.svg';
import longShotStroke from '../../static/focus/long-shot-stroke.svg';
import longShot from '../../static/focus/long-shot.svg';
import someChanceStroke from '../../static/focus/some-chance-stroke.svg';
import someChance from '../../static/focus/some-chance.svg';
import { getUserAccountName, getUserPublicId, is_fake, withUserState } from '../../userContext';
import { getRamzorScoreText, getTalentPageUrl, RamzorIcon } from '../../utils/contacts';
import { PortcoFavicon } from '../../widgets/portco-favicons';

function hashData(data) {
  const hash = crypto.createHash('sha256');
  hash.update(data);
  return hash.digest('hex');
}

export const getReachoutId = ({ connector, target, userState }) => {
  const dataString =
    _.get(connector, 'connector_public_id') +
    '$' +
    _.get(target, 'public_identifier') +
    '$' +
    getUserPublicId(userState) +
    '$' +
    is_fake(userState);
  return hashData(dataString);
};

export const ConnectorScoreFull = ({ chance_to_connect }) => {
  return (
    <img
      src={
        chance_to_connect === 'good chance' ? goodChance : chance_to_connect === 'some chance' ? someChance : longShot
      }
      alt={''}
    />
  );
};

export const ConnectorScoreMini = ({ score = 'long shot', count = 5 }) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: 19,
        height: 19,
      }}
    >
      <img
        src={score === 'good chance' ? goodChanceStroke : score === 'some chance' ? someChanceStroke : longShotStroke}
        alt={''}
        width="100%"
        height="100%"
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: count < 10 ? '11px' : '9px',
          fontFamily: 'Inter',
          fontWeight: 700,
        }}
      >
        <div
          style={{
            marginBottom: count < 10 ? '1px' : '0px',
            marginTop: count >= 10 ? '1px' : '0px',
            textShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)', // You can adjust these values as needed
          }}
        >
          {Math.min(count, 99)}
        </div>
      </div>
    </div>
  );
};

const TargetActionButton = ({ url, isPrimary, text }) => {
  const [hover, setHover] = useState(false);

  return (
    <Link href={url} target={'_blank'} style={{ display: 'inline', marginRight: '8px' }}>
      <Button
        variant="outlined"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          height: '32px',
          borderRadius: '22px',
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '15px',
          borderWidth: '1px',
          color: isPrimary ? '#EDFFFD' : '#00A394',
          borderColor: isPrimary ? 'transparent' : '#00A394',
          backgroundColor:
            hover && isPrimary ? '#00887B' : isPrimary ? '#00A394' : hover ? 'rgba(0,163,148,0.08)' : 'transparent',
          textTransform: 'none',
          paddingLeft: '14px',
          paddingTop: '6px',
          paddingRight: '14px',
        }}
      >
        {text}
      </Button>
    </Link>
  );
};

const _UseAlephNetwork = ({ target, fullName, userState, loading }) => {
  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <span
          style={{
            marginRight: '6px',
          }}
        >
          <PortcoFavicon portcoName={'Aleph'} width={28} height={28} />
        </span>
        <span style={{ fontSize: '18px' }}>No connectors at {getUserAccountName(userState)}? Try Aleph's Network</span>
      </div>
      <div
        style={{
          backgroundColor: '#F9F9F9',
          height: '105px',
          padding: '32px 32px 32px 32px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '170px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <RamzorIcon c={target} size={36} />
          <span
            style={{
              fontFamily: 'Merriweather Sans',
              fontWeight: 300,
              fontSize: '14px',
              color: 'rgba(0, 0, 0, 0.60)',
            }}
          >
            {getRamzorScoreText(target)}
          </span>
        </div>
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          <TargetActionButton
            url={getTalentPageUrl({
              public_identifier: _.get(target, 'public_identifier'),
              fullName,
              ask: 'Reference Check',
            })}
            isPrimary={_.get(target, 'Ramzor_Score__c') !== 'We know the target' && !loading}
            text={'Ask For Reference'}
          />
          <TargetActionButton
            url={getTalentPageUrl({
              public_identifier: _.get(target, 'public_identifier'),
              fullName,
              ask: 'Intro Request',
            })}
            isPrimary={_.get(target, 'Ramzor_Score__c') === 'We know the target'}
            text={'Request Intro'}
          />
        </div>
      </div>
    </>
  );
};

export const UseAlephNetwork = withUserState(_UseAlephNetwork);
