import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Alert, Autocomplete } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ReactComponent as activeEngagement } from '../../static/active-engagement.svg';
import { ReactComponent as alephReview } from '../../static/aleph-review.svg';
import { ReactComponent as archive } from '../../static/archive.svg';
import { ReactComponent as awaitingFeedback } from '../../static/awaiting-feedback.svg';
import { ReactComponent as inProgressAleph } from '../../static/in-progress-aleph.svg';
import { ReactComponent as LinkedIn } from '../../static/linkedin.svg';
import { ReactComponent as salesforce } from '../../static/salesforce-white.svg';
import { ReactComponent as waitingForContact } from '../../static/waiting-for-contact.svg';
import { is_admin, useAPI, withUserState } from '../../userContext';
import { RamzorIcon } from '../../utils/contacts';
import { assignIntrosScore } from '../../utils/intro-utils';
import {
  assignPoscanGroup,
  assignRamzorRank,
  canCancelRequest,
  getOptionalCandidateFiltersPortcos,
  getPotentialPoscanStatuses,
  isActivePoscan,
  isPortcoRequest,
  PortcoInterest,
  RequestPriorityCheckbox,
  shouldShowPriority,
} from '../../utils/poscan-utils';
import { get_my_candidates, update_portco_feedback, update_poscan_status } from '../api';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0.4px solid #939393',
  padding: '41px 48px 10px 48px',
  verticalAlign: 'top',
}));

const OpportunityTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0.4px solid #939393',
  padding: '41px 0px 10px 0px',
  verticalAlign: 'top',
  width: '280px',
}));

const UpdatesTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0.4px solid #939393',
  padding: '41px 48px 10px 0px',
  verticalAlign: 'top',
}));

const RequestStatusCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0.4px solid #939393',
  padding: '41px 0px 10px 0px',
  verticalAlign: 'top',
  width: '240px',
}));

const ArchiveStatusCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0.4px solid #939393',
  padding: '41px 0px 10px 0px',
  verticalAlign: 'top',
  width: '340px',
}));

const RamzorTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0.4px solid #939393',
  padding: '41px 0px 10px 0px',
  verticalAlign: 'top',
  width: '100px',
}));

const ArchiveRequest = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0.4px solid #939393',
  padding: '41px 0px 0px 0px',
  verticalAlign: 'top',
  width: '25px',
}));

const MostRightTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  width: '78px',
}));

const LinksTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  width: '78px',
  verticalAlign: 'top',
  paddingTop: '41px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: '0',
}));

function enrichPoscans(poscans) {
  return _.isEmpty(poscans)
    ? []
    : _(poscans)
        .map(assignPoscanGroup)
        .map(assignIntrosScore)
        .orderBy(['Priority__c', 'poscanScore', 'CreatedDate'], ['desc', 'desc', 'desc'])
        .value();
}

function getFilteredCandidates({ candidates, candidateFilters }) {
  const candidateNameFilters = _.get(candidateFilters, 'search', [])
    .filter(({ type }) => type === 'Candidate')
    .map(({ value }) => value);
  const positionFilters = _.get(candidateFilters, 'search', [])
    .filter(({ type }) => type === 'Position')
    .map(({ value }) => value);
  const currentCompanyFilters = _.get(candidateFilters, 'search', [])
    .filter(({ type }) => type === 'Current Company')
    .map(({ value }) => value);
  const statusFilters = _.get(candidateFilters, 'search', [])
    .filter(({ type }) => type === 'Status')
    .map(({ value }) => value);
  const ramzorFilters = _.get(candidateFilters, 'search', [])
    .filter(({ type }) => type === 'Likelihood To Connect')
    .map(({ value }) => value);
  const requestTypeFilters = _.get(candidateFilters, 'search', [])
    .filter(({ type }) => type === 'Request Type')
    .map(({ value }) => value);

  return _(candidates)
    .map((p) => ({
      ...p,
      ramzorRank: assignRamzorRank(_.get(p, 'Ramzor_Score__c')),
    }))
    .filter(
      ({
        Candidate_Name__c,
        Position_Title__c,
        Status__c,
        Candidate_Account__c,
        Ramzor_Score__c,
        Request_Type__c,
        introStatusPortco,
        poscanStatusPortco,
      }) => {
        return (
          (_.isEmpty(candidateNameFilters) || _.includes(candidateNameFilters, Candidate_Name__c)) &&
          (_.isEmpty(positionFilters) || _.includes(positionFilters, Position_Title__c)) &&
          (_.isEmpty(currentCompanyFilters) || _.includes(currentCompanyFilters, Candidate_Account__c)) &&
          (_.isEmpty(statusFilters) ||
            !_.isEmpty(_.intersection(statusFilters, [introStatusPortco, poscanStatusPortco]))) &&
          (_.isEmpty(ramzorFilters) || _.includes(ramzorFilters, Ramzor_Score__c)) &&
          (_.isEmpty(requestTypeFilters) || _.includes(requestTypeFilters, Request_Type__c))
        );
      },
    )
    .orderBy(['Priority__c', 'Request_Type__c', 'ramzorRank', 'CreatedDate'], ['desc', 'desc', 'asc', 'desc'])
    .value();
}

const Opportunity = ({ c }) => {
  return (
    <>
      <div
        style={{
          fontFamily: 'Merriweather Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '20px',
          marginBottom: '7px',
        }}
      >
        {c.Candidate_Name__c}
      </div>
      <div
        style={{
          fontFamily: 'Merriweather Sans',
          fontStyle: 'normal',
          lineHeight: '32px',
          marginBottom: '16px',
          color: '#A6A6A6',
        }}
      >
        For {c.Position_Title__c}
      </div>
      <div
        style={{
          fontFamily: 'Merriweather Sans',
          fontStyle: 'normal',
          lineHeight: '32px',
        }}
      >
        <span style={{ marginRight: '12px', fontSize: '12px', color: '#C4C4C4' }}> Created </span>
        <span style={{ marginRight: '12px', fontSize: '10px', color: '#000000' }}>
          {_.toUpper(moment(c.CreatedDate).format('DD  MMM  YY'))}
        </span>
        <span style={{ marginRight: '12px', fontSize: '12px', color: '#C4C4C4' }}> &#x25CF; </span>
        <span style={{ fontSize: '12px', color: '#000000' }}> {c.Request_Type__c}</span>
      </div>
    </>
  );
};

const RequestStatus = ({ c }) => {
  return (
    <div style={{ maxWidth: isActivePoscan(c) ? '200px' : '300px', marginLeft: '48px' }}>
      <div style={{ display: 'inline-flex' }}>
        {c.introStatusPortco === 'Active engagement with potential connectors' && (
          <SvgIcon component={activeEngagement} style={{ width: 26, height: 27 }} viewBox={'0 0 26 27'} />
        )}
        {c.introStatusPortco === 'Waiting for Review' && (
          <SvgIcon component={alephReview} style={{ width: 18, height: 19 }} viewBox={'0 0 18 19'} />
        )}
        {c.introStatusPortco === 'In Progress' && (
          <SvgIcon component={inProgressAleph} style={{ width: 18, height: 19 }} viewBox={'0 0 18 19'} />
        )}
        {c.introStatusPortco === 'Waiting for candidate' && (
          <SvgIcon component={waitingForContact} style={{ width: 15, height: 15 }} viewBox={'0 0 15 15'} />
        )}
        {!c.introStatusPortco && c.poscanStatusPortco === 'Awaiting Feedback' && (
          <SvgIcon component={awaitingFeedback} style={{ width: 17, height: 19 }} viewBox={'0 0 17 19'} />
        )}

        <span
          style={{
            color: '#7E7E7E',
            marginLeft: '8px',
          }}
        >
          {isActivePoscan(c) ? _.capitalize(c.introStatusPortco || c.poscanStatusPortco) : _.get(c, 'Status__c')}
        </span>
      </div>
      {isPortcoRequest(_.get(c, 'Request_Type__c')) && c.poscanStatusPortco !== 'Awaiting Feedback' && (
        <div style={{ marginTop: '7px', color: '#00A394', fontFamily: 'Merriweather Sans', fontSize: '12px' }}>
          <span style={{ marginRight: '3px', paddingLeft: '25px', fontWeight: '800' }}>
            {_.get(c, 'intros', []).length}
          </span>
          Connection{_.get(c, 'intros', []).length === 1 ? '' : 's'} approached
        </div>
      )}
    </div>
  );
};

const CandidateBackground = ({ c }) => {
  return (
    <div>
      <div
        style={{
          fontFamily: 'Merriweather Sans',
          fontStyle: 'normal',
          fontSize: '16px',
          lineHeight: '32px',
          color: '#4D4D4D',
        }}
      >
        {_.get(c, 'Candidate__r.Main_Role__r.Account_Name__c')}
      </div>
      <div style={{ fontFamily: 'Merriweather Sans', fontStyle: 'normal', lineHeight: '32px', fontSize: '14px' }}>
        <span>{moment(_.get(c, 'Candidate__r.Main_Role__r.Start_Date__c')).year()} - Present</span>
        <span style={{ color: '#4D4D4D', marginLeft: '10px' }}>{_.get(c, 'Candidate__r.Main_Role__r.Name')}</span>
      </div>
    </div>
  );
};

const UpdateStatusButton = ({ poscanUpdate, isLoading }) => {
  return (
    <>
      <Button
        onClick={() => poscanUpdate()}
        size="small"
        variant="outlined"
        disabled={isLoading}
        style={{
          backgroundColor: '#00A394',
          borderRadius: '3px',
          padding: '8px 16px',
          fontFamily: 'Merriweather Sans',
          fontSize: '12px',
          lineHeight: '15px',
          color: '#FFFFFF',
          border: 'none',
          textTransform: 'none',
          width: '70px',
          marginTop: '15px',
        }}
      >
        Done
      </Button>
    </>
  );
};

const CancelRequestButton = ({ showStatusSelect, setShowStatus }) => {
  return (
    <Tooltip title={'Archive Request'}>
      <SvgIcon
        component={archive}
        style={{ width: 20, height: 20 }}
        viewBox={'0 0 20 20'}
        onClick={() => setShowStatus(!showStatusSelect)}
      />
    </Tooltip>
  );
};

const PortcoFeedback = ({ setFeedback, feedback, width = '200px', setUpdated }) => {
  const handleChange = (event) => {
    setFeedback(event.target.value);
    setUpdated(true);
  };

  return (
    <TextField
      label={'Comments/Feedback'}
      placeholder={''}
      multiline
      maxRows={4}
      value={feedback}
      onChange={handleChange}
      variant="standard"
      style={{ width, marginRight: '15px' }}
    />
  );
};

const PortcoStatusSelect = ({ status, poscanId, setStatus, width = '200px', potentialStatuses, setUpdated }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menu_props = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  const handleChange = (event) => {
    console.log(event.target.value);
    setStatus(event.target.value);
    setUpdated(true);
  };

  return (
    <FormControl>
      <InputLabel id={'poscan-status-selector-input-label' + poscanId}>Update</InputLabel>
      <Select
        labelId={'poscan-status-selector-label' + poscanId}
        id={'poscan-status-selector' + poscanId}
        value={status}
        onChange={handleChange}
        input={<Input style={{ width }} />}
        menu_props={menu_props}
      >
        {potentialStatuses.map((v) => (
          <MenuItem key={v} value={v}>
            {_.capitalize(v)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const PoscanUpdate = ({ c, isHovered }) => {
  const [showStatusSelect, setShowStatus] = useState(_.get(c, 'Status__c') === 'Introduced');
  const [feedback, setFeedback] = useState(_.get(c, 'Portco_Feedback__c') || '');
  const [status, setStatus] = useState(_.get(c, 'Status__c'));
  const [isLoading, setIsLoading] = useState(false);
  const [lastReq, setLastReq] = useState('');
  const [updated, setUpdated] = useState(false);
  const potentialStatuses = getPotentialPoscanStatuses(
    _.pick(c, ['Request_Type__c', 'Status__c', 'Portco_Interested__c', 'Candidate_Interested__c']),
  );

  const api = useAPI();
  const poscanUpdate = async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        update_poscan_status(
          {
            poscanId: c.Id,
            Status__c: status || _.get(c, 'Status__c'),
            extra: _.pick(c, ['Candidate_LinkedIn__c', 'Id', 'Candidate_Name__c', 'Position_Title__c', 'Status__c']),
          },
          api,
        ),
        update_portco_feedback(
          {
            poscanId: c.Id,
            Portco_Feedback__c: feedback,
            extra: _.pick(c, ['Candidate_LinkedIn__c', 'Id', 'Candidate_Name__c', 'Position_Title__c']),
          },
          api,
        ),
      ]);
      setLastReq(
        canCancelRequest(_.get(c, 'Status__c')) ? 'Request was archived successfully' : 'update sent successfully',
      );
      setUpdated(false);
      setIsLoading(false);
    } catch (e) {
      setLastReq('Ooops, looks like there was an error updating the candidate. Please try again or contact our team');
    }
  };

  const hide_message = () => {
    setLastReq('');
  };

  return (
    <>
      <UpdatesTableCell>
        <div
          style={{
            color: 'black',
            float: 'left',
            borderRight: '0.4px solid #939393',
            height: '54px',
            marginRight: '48px',
          }}
        ></div>
        {shouldShowPriority(_.get(c, 'Status__c')) && (
          <div style={{ float: 'left', marginRight: '30px' }}>
            <RequestPriorityCheckbox
              poscanId={c.Id}
              Priority__c={c.Priority__c}
              extra={_.pick(c, ['Candidate_LinkedIn__c', 'Id', 'Candidate_Name__c', 'Position_Title__c'])}
            />
          </div>
        )}
        <>
          {showStatusSelect && (
            <span style={{ marginRight: '15px' }}>
              <PortcoStatusSelect
                status={status}
                poscanId={c.Id}
                setStatus={setStatus}
                potentialStatuses={potentialStatuses}
                setUpdated={setUpdated}
              />
            </span>
          )}
          {(updated || _.get(c, 'Status__c') === 'Introduced') && (
            <PortcoFeedback feedback={feedback} setFeedback={setFeedback} setUpdated={setUpdated} />
          )}
          {showStatusSelect && updated && !_.isEmpty(status) && (
            <UpdateStatusButton poscanUpdate={poscanUpdate} isLoading={isLoading} />
          )}
          {showStatusSelect && _.get(c, 'Status__c') !== 'Introduced' && (
            <Button
              onClick={() => setShowStatus(!showStatusSelect)}
              size="small"
              disabled={isLoading}
              style={{
                fontFamily: 'Merriweather Sans',
                fontSize: '12px',
                lineHeight: '15px',
                border: 'none',
                color: '#00A394',
                textTransform: 'none',
                width: '70px',
                marginTop: '15px',
              }}
            >
              Cancel
            </Button>
          )}
        </>
        <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
          <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
            {lastReq}
          </Alert>
        </Snackbar>
      </UpdatesTableCell>
      <ArchiveRequest>
        {canCancelRequest(_.get(c, 'Status__c')) && !showStatusSelect && isActivePoscan(c) && isHovered && (
          <CancelRequestButton showStatusSelect={showStatusSelect} setShowStatus={setShowStatus} />
        )}
      </ArchiveRequest>
    </>
  );
};

const showRamzor = (c) =>
  _.includes(['Waiting for Review', 'In Progress', 'Active engagement with potential connectors'], c.introStatusPortco);

const _CandidateRow = ({ c, userState }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledTableRow onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <LinksTableCell>
        <div>
          {is_admin(userState) && (
            <Link href={`https://aleph.lightning.force.com/lightning/r/Contact/${_.get(c, 'Id')}/view`} target="_blank">
              <SvgIcon
                component={salesforce}
                style={{ width: 17.5, height: 14, marginRight: '3px' }}
                viewBox={'0 0 8 10'}
              />
            </Link>
          )}
          <Link href={_.get(c, 'Candidate_LinkedIn__c')} target="_blank">
            <SvgIcon component={LinkedIn} style={{ width: 14, height: 17.5 }} viewBox={'0 0 10 8'} />
          </Link>
        </div>
      </LinksTableCell>
      <OpportunityTableCell>
        <Opportunity c={c} />
      </OpportunityTableCell>
      {/*todo: redesign suggested candidates and make sure it work. currently we don't really use the dsahboard for choosing candidates
        !isPortcoRequest(_.get(c, 'Request_Type__c')) && isActivePoscan(c) && _.get(c, 'Status__c') === 'Sent to account' &&
            <>
                <StyledTableCell>
                    <div style={{ color:'black', float:'left', borderRight:'0.4px solid #939393', height: '54px', marginTop:'40px' }}>
                    </div>
                    <CandidateBackground c={c}/>
                </StyledTableCell>
                <StyledTableCell>
                    <PortcoInterest poscanId ={c.Id} Portco_Interested__c={c.Portco_Interested__c}/>
                </StyledTableCell>
            </>
        */}
      {isActivePoscan(c) && (
        <>
          <RequestStatusCell>
            <div style={{ color: 'black', float: 'left', borderRight: '0.4px solid #939393', height: '54px' }}></div>
            <RequestStatus c={c} />
          </RequestStatusCell>
          <RamzorTableCell>
            {showRamzor(c) && (
              <div align={'center'}>
                <div
                  style={{
                    color: 'black',
                    float: 'left',
                    borderRight: '0.4px solid #939393',
                    height: '54px',
                  }}
                ></div>
                <div style={{ margin: 'auto' }}>
                  <RamzorIcon c={_.get(c, 'Candidate__r')} size={20} />
                </div>
              </div>
            )}
          </RamzorTableCell>
          <PoscanUpdate c={c} isHovered={isHovered} />
          <MostRightTableCell />
        </>
      )}
      {!isActivePoscan(c) && (
        <>
          <ArchiveStatusCell>
            <div
              style={{
                color: 'black',
                float: 'left',
                borderRight: '0.4px solid #939393',
                height: '54px',
                marginRight: '48px',
              }}
            ></div>
            <RequestStatus c={c} />
            <div></div>
          </ArchiveStatusCell>

          <StyledTableCell>{c.Rejection_Details__c}</StyledTableCell>
          <RamzorTableCell>
            {showRamzor(c) && (
              <div align={'center'}>
                <div
                  style={{
                    color: 'black',
                    float: 'left',
                    borderRight: '0.4px solid #939393',
                    height: '54px',
                  }}
                ></div>
                <div style={{ margin: 'auto' }}>
                  <RamzorIcon c={_.get(c, 'Candidate__r')} size={20} />
                </div>
              </div>
            )}
          </RamzorTableCell>
          <MostRightTableCell />
        </>
      )}
    </StyledTableRow>
  );
};

const CandidateRow = withUserState(_CandidateRow);

const CandidateFilters = ({ candidateFilters, setCandidateFilters, optionalCandidateFilters }) => {
  const handleOptionSelection = (event, option) => {
    setCandidateFilters({
      ..._.omit(candidateFilters, ['search']),
      search: option,
    });
  };

  return (
    <>
      {!_.isEmpty(optionalCandidateFilters) && !_.isUndefined(candidateFilters) && (
        <Autocomplete
          getOptionLabel={(option) => option.value || ''}
          options={optionalCandidateFilters}
          groupBy={(option) => option.type}
          value={candidateFilters.search}
          autoComplete
          multiple
          onChange={handleOptionSelection}
          selectOnFocus={false}
          freeSolo={false}
          renderInput={(params) => (
            <TextField
              className={'searchbox'}
              variant="outlined"
              style={{ borderRadius: '32px', marginBottom: '40px' }}
              {...params}
            />
          )}
        />
      )}
    </>
  );
};

const CandidatesTable = ({ candidates, selectedTab }) => {
  return (
    <>
      {!_.isEmpty(candidates) ? (
        <TableContainer component={Paper}>
          <Table sx={{ overflowX: 'auto', tableLayout: 'fixed' }} aria-label="customized table">
            <TableBody>
              {selectedTab === 0 &&
                _(candidates)
                  .orderBy(['poscanScore', 'Last_Status_Update__c'], ['asc', 'desc'])
                  .map((c) => <CandidateRow c={c} key={'CandidateRow-' + c.Id} />)
                  .value()}
              {selectedTab === 1 &&
                _(candidates)
                  .orderBy(['Last_Status_Update__c'], ['desc'])
                  .map((c) => <CandidateRow c={c} key={'CandidateRow-' + c.Id} />)
                  .value()}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>No active candidates found</div>
      )}
    </>
  );
};

const getCandidatesByTab = ({ selectedTab, myCandidates }) => {
  return selectedTab === 0
    ? myCandidates.filter((c) => {
        return isActivePoscan(c) && _.get(c, 'Status__c') !== 'Sent to account';
      }) //my active candidates from connect request or once that were introduced already
    : //selectedTab === 1 ? myCandidates.filter(isActivePoscan) : //all active candidates
      myCandidates.filter((c) => !isActivePoscan(c)); // archive
};

export const PortcoDashboard = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [myCandidates, setMyCandidates] = useState([]);
  const [optionalCandidateFilters, setOptionalCandidateFilters] = useState([]);
  const [candidateFilters, setCandidateFilters] = useState({ search: [] });
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [candidatesForTab, setCandidatesForTab] = useState(getCandidatesByTab({ selectedTab, myCandidates }));

  useEffect(() => {
    setCandidatesForTab(getCandidatesByTab({ selectedTab, myCandidates }));
  }, [selectedTab, myCandidates]);

  useEffect(() => {
    setFilteredCandidates(getFilteredCandidates({ candidates: candidatesForTab, candidateFilters }));
    setOptionalCandidateFilters(getOptionalCandidateFiltersPortcos({ poscans: candidatesForTab }));
  }, [candidatesForTab, candidateFilters]);

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        await get_my_candidates(api).then(({ poscans }) => {
          const enriched = enrichPoscans(poscans);
          setMyCandidates(enriched);
        });
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [api]);
  const theme = useTheme();
  const xlScreen = useMediaQuery(theme.breakpoints.up(2000));
  const smScreen = useMediaQuery(theme.breakpoints.down(1500));
  const gridWidth = xlScreen ? '80%' : smScreen ? '98%' : '90%';

  return (
    <>
      <Tabs
        value={selectedTab}
        style={{ width: gridWidth, margin: 'auto', marginBottom: '20px' }}
        onChange={(a, newValue) => setSelectedTab(newValue)}
        aria-label="tabs"
      >
        <Tab label={'My Candidates'} />
        {/*<Tab label={'All Candidates'} />*/}
        <Tab label={'Archive'} />
      </Tabs>

      <div style={{ width: gridWidth, margin: 'auto' }}>
        {isLoading && <span>Loading Candidates </span>}
        {!isLoading && !_.isEmpty(myCandidates) && (
          <>
            <CandidateFilters
              optionalCandidateFilters={optionalCandidateFilters}
              setCandidateFilters={setCandidateFilters}
              candidateFilters={candidateFilters}
            />
            <CandidatesTable candidates={filteredCandidates} selectedTab={selectedTab} />
          </>
        )}
      </div>
    </>
  );
};
