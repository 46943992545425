import { Slider, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';

import { SKILL_CATEGORIES, SKILLS } from '../../utils/sf-consts';

export const PositionsFilters = ({ positionFilters, setPositionFilters, optionalPositionFilters, showSlider }) => {
  const handleOptionSelection = (event, option) => {
    setPositionFilters({
      ..._.omit(positionFilters, ['searchOptions']),
      searchOptions: option,
    });
  };

  const handleSliderChange = (event, newValue) => {
    const [headcountMin, headcountMax] = newValue;
    setPositionFilters({
      ..._.omit(positionFilters, ['headcountMin', 'headcountMax']),
      headcountMin,
      headcountMax,
    });
  };

  return (
    <>
      <div>
        <Autocomplete
          getOptionLabel={(option) => option.value}
          options={optionalPositionFilters.searchOptions}
          groupBy={(option) => option.type}
          value={positionFilters.searchOptions}
          autoComplete
          multiple
          onChange={handleOptionSelection}
          selectOnFocus={false}
          freeSolo={false}
          renderInput={(params) => (
            <TextField className={'searchbox'} variant="outlined" style={{ borderRadius: '32px' }} {...params} />
          )}
        />
      </div>
      {showSlider && (
        <FormControl style={{ width: '80%' }}>
          <InputLabel id="headcount-selector">Headcount</InputLabel>
          <Slider
            value={[positionFilters.headcountMin || 0, positionFilters.headcountMax || 0]}
            onChange={handleSliderChange}
            getAriaValueText={(a) => _.toString(a)}
            valueLabelDisplay="auto"
            step={1}
            min={optionalPositionFilters.headcountMin}
            max={optionalPositionFilters.headcountMax}
          />
        </FormControl>
      )}
    </>
  );
};

export function getFilteredPositions({ openPositions, positionFilters, poscans }) {
  const locations = positionFilters.searchOptions.filter(({ type }) => type === 'Location').map(({ value }) => value);
  const companies = positionFilters.searchOptions.filter(({ type }) => type === 'Company').map(({ value }) => value);
  const skillCategories = positionFilters.searchOptions
    .filter(({ type }) => type === 'Skill Category')
    .map(({ value }) => value);
  const roles = positionFilters.searchOptions.filter(({ type }) => type === 'Role').map(({ value }) => value);
  const skills = positionFilters.searchOptions.filter(({ type }) => type === 'Main Skill').map(({ value }) => value);
  const titles = positionFilters.searchOptions.filter(({ type }) => type === 'Title').map(({ value }) => value);
  const rounds = positionFilters.searchOptions.filter(({ type }) => type === 'Round').map(({ value }) => value);
  const hideOpportunistic = !!positionFilters.searchOptions.find(({ type }) => type === 'Hide Opportunistic');
  return openPositions.filter((p) => {
    if (
      !_.isEmpty(poscans) &&
      _.includes(
        poscans.map(({ Position__r }) => _.get(Position__r, 'Id')),
        p.Id,
      )
    ) {
      return false;
    }
    if (!_.isEmpty(locations) && !locations.find((l) => _.includes(_.get(p, 'Location__c') || 'Israel', l))) {
      return false;
    }
    if (hideOpportunistic && _.includes(_.get(p, 'Name', '').toLowerCase(), 'opportunistic')) {
      return false;
    }
    if (
      !_.isEmpty(skills) &&
      (_.isNull(_.get(p, 'Main_skill__c', '')) ||
        !_.find(skills, (s) => _.get(p, 'Main_skill__c', '') === s || _.get(p, 'Main_skill__c', '').includes(`${s}:`)))
    ) {
      return false;
    }
    if (
      !_.isEmpty(skillCategories) &&
      (_.isNull(_.get(p, 'Skill_Category__c', '')) || !skillCategories.includes(_.get(p, 'Skill_Category__c', '')))
    ) {
      return false;
    }
    if (!_.isEmpty(roles) && (_.isNull(_.get(p, 'Role__c', '')) || !roles.includes(_.get(p, 'Role__c', '')))) {
      return false;
    }
    if (!_.isEmpty(companies) && !companies.includes(_.get(p, 'Account_Name__c', ''))) {
      return false;
    }
    if (!_.isEmpty(rounds) && !rounds.includes(_.get(p, 'round', ''))) {
      return false;
    }
    if (!_.isEmpty(titles) && !titles.includes(_.get(p, 'Name', ''))) {
      return false;
    }
    if (
      !_.isNull(_.get(p, 'headcount')) &&
      (positionFilters.headcountMin > _.get(p, 'headcount') || positionFilters.headcountMax < _.get(p, 'headcount'))
    ) {
      return false;
    }
    return true;
  });
}

export const createOptionalPositionFilters = (openPositions) => {
  return {
    searchOptions: _.concat(
      _(openPositions)
        .map(({ Skill_Category__c }) => ({
          type: 'Skill Category',
          value:
            _.get(
              SKILL_CATEGORIES.find(({ apiName }) => Skill_Category__c === apiName),
              'label',
            ) || 'Other',
        }))
        .uniqBy('value')
        .sortBy(['value'])
        .value(),
      _(openPositions)
        .map(({ Role__c }) => ({ type: 'Role', value: Role__c || 'Other' }))
        .uniq()
        .uniqBy('value')
        .sortBy(['value'])
        .value(),
      _(openPositions)
        .map(({ round }) => ({ type: 'Round', value: round }))
        .uniq()
        .uniqBy('value')
        .sortBy(['value'])
        .value(),
      _(openPositions)
        .map(({ Account_Name__c }) => ({ type: 'Company', value: Account_Name__c }))
        .uniqBy('value')
        .sortBy(['value'])
        .value(),
      _(openPositions)
        .map(({ Main_skill__c }) => ({
          type: 'Main Skill',
          value:
            _.get(
              SKILLS.find(({ apiName }) => Main_skill__c === apiName),
              'label',
            ) || 'Other',
        }))
        .uniqBy('value')
        .sortBy(['value'])
        .value(),
      _(openPositions)
        .map(({ Location__c }) => ({ type: 'Location', value: Location__c || 'Other' }))
        .uniqBy('value')
        .sortBy(['value'])
        .value(),
      _(openPositions)
        .map(({ Name }) => ({ type: 'Title', value: Name }))
        .uniq()
        .uniqBy('value')
        .sortBy(['value'])
        .value(),
      { type: 'Hide Opportunistic', value: 'Hide Opportunistic' },
    ),
    headcountMin: _(openPositions)
      .map(({ headcount }) => headcount)
      .compact()
      .min(),
    headcountMax: _(openPositions)
      .map(({ headcount }) => headcount)
      .compact()
      .max(),
  };
};
