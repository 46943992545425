import { Box, Button, CircularProgress, Container, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import request from 'request';

import { getUserEmail, is_fake, useAPI } from '../../userContext';
import { send_focus_mini_feedback } from './api';

export const CONNECTOR_DONT_KNOW_TARGET_FORM_URL = 'https://alephvc.typeform.com/to/aGe5ILLy';
const ZAPIER_WEBHOOK_URL = 'https://hooks.zapier.com/hooks/catch/2976135/39yhwn9/';

export function UserFeedback({ public_identifier, fullName, placeholder }) {
  const [comments, set_comments] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const handle_comments = (e) => set_comments(e.target.value);
  const api = useAPI();

  const submit = async () => {
    await send_focus_mini_feedback({ public_identifier, comments, fullName }, api);
    setSubmitted(true);
  };
  return (
    <>
      {!submitted && (
        <>
          {' '}
          <TextField
            placeholder={placeholder}
            value={comments}
            onChange={handle_comments}
            variant="outlined"
            type={'text'}
            style={{
              fontStyle: 'italic',
              backgroundColor: '#EFEFEF',
              borderRadius: '8px',
              borderColor: 'transparent',
              marginRight: '8px',
              width: '70%',
            }}
            InputProps={{
              style: {
                height: '26px',
                padding: '0',
                borderColor: 'transparent',
              },
            }}
          />
          <Button
            variant="outlined"
            style={{
              borderRadius: '8px',
              fontFamily: 'Inter',
              fontWeight: 600,
              color: '#525252',
              height: '26px',
              padding: '0',
              backgroundColor: 'transparent',
              textTransform: 'none',
              borderColor: '#525252',
            }}
            onClick={submit}
          >
            Submit
          </Button>
        </>
      )}
      {submitted && (
        <div style={{ color: '#525252', fontFamily: 'Inter', fontWeight: 600, fontSize: '14px' }}>
          Thank you for your feedback
        </div>
      )}
    </>
  );
}
export function ConnectorFeedback() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramsObject = Object.fromEntries(params.entries());

  useEffect(() => {
    request
      .post(ZAPIER_WEBHOOK_URL)
      .form(paramsObject)
      .on('response', function (response) {
        console.log(response.statusCode); // Print the response status code if a response was received
      })
      .on('error', function (err) {
        console.log('Error:', err); // Print the error if one occurred
      });

    let targetURLWithParams = new URL(CONNECTOR_DONT_KNOW_TARGET_FORM_URL);
    for (let key in paramsObject) {
      targetURLWithParams.searchParams.append(key, paramsObject[key]);
    }
    window.location.href = targetURLWithParams.href;
  }, [location, paramsObject]);

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    </Container>
  );
}
