// import _ from 'lodash'
import { Button, Divider, Grid, Paper, Snackbar, SvgIcon, Typography } from '@material-ui/core';
import { ContactMail, Done, Email, LinkedIn } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

import { GoogleAuthorize } from '../ext/GoogleAuthorize';
import { ReactComponent as google_svg } from '../static/google.svg';
import { useAPI } from '../userContext';

const GMAIL_CONNECT_CLIENT_ID = '348258034816-ve1qhjr9uta6b4e89e86fop73dk1ldcr.apps.googleusercontent.com';

export const AccountLinkManagement = () => {
  const api = useAPI();
  const [signals, setSignals] = useState([]);
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clicks, setClicks] = useState(0);
  const [show_error, set_show_error] = useState(false);
  const [show_success, set_show_success] = useState(false);

  const hide_error = () => set_show_error(false);
  const hide_success = () => set_show_success(false);

  useEffect(() => {
    const get_signals = async () => {
      setLoading(true);
      const { signals, emails } = await api('user/signals');
      setSignals(signals);
      setEmails(emails);
      setLoading(false);
    };
    get_signals();
  }, [api, clicks]);

  const connect_gmail = async (response) => {
    console.log(response);
    setLoading(true);
    try {
      await api('user/connect-gmail', { method: 'POST', body: JSON.stringify(response) });
      setClicks(clicks + 1);
      set_show_success(true);
    } catch (e) {
      console.error(e);
      set_show_error(true);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Snackbar open={show_error} autoHideDuration={10000} onClose={hide_error}>
        <Alert onClose={hide_error} severity="error">
          Error connecting gmail account, please try again later...
        </Alert>
      </Snackbar>
      <Snackbar open={show_success} autoHideDuration={10000} onClose={hide_success}>
        <Alert onClose={hide_success} severity="success">
          Account connected succesfully! Thank you!
        </Alert>
      </Snackbar>

      <Grid item sm={8} lg={6} xs={12}>
        <Paper elevation={1} style={{ padding: '3rem' }}>
          <Typography variant="h4" style={{ marginBottom: '0.5em' }}>
            Expand Your Network
          </Typography>
          <Typography variant="body2" style={{ fontSize: '1rem', lineHeight: '1.6rem', marginBottom: '0.8rem' }}>
            Aleph Connect uses trusted networks to generate warm intros and effective outreach for portfolio companies.
          </Typography>
          <Typography variant="body2" style={{ fontSize: '1rem', lineHeight: '1.6rem', marginBottom: '0.8rem' }}>
            Please connect your gmail account to add your network to Aleph Connect and make it even stronger.
          </Typography>
          <Typography variant="body2" style={{ fontSize: '1rem', lineHeight: '1.6rem', marginBottom: '0.8rem' }}>
            We only extract names, email addresses and timestamps, to ID relevant relationships, and use this
            information solely for making introductions.
          </Typography>
          <Typography variant="body2" style={{ fontSize: '1rem', lineHeight: '1.6rem' }}>
            Your contacts will not be publicly associated with you.
          </Typography>
          <Divider variant="middle" style={{ marginBottom: '0.8rem', marginTop: '1.6rem' }} />
          {loading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            signals
              .filter((s) => s.type === 'Linkedin Connection')
              .map((s) => (
                <Grid item xs={12} key={s}>
                  <Typography style={{ display: 'flex', alignItems: 'center', lineHeight: '2em' }}>
                    <LinkedIn color="primary" fontSize="inherit" />
                    &nbsp;Found {s.count} Linkedin Contacts
                  </Typography>
                </Grid>
              ))
          )}
          {loading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            signals
              .filter((s) => s.type === 'Gmail Contacts')
              .map((s) => (
                <Grid item xs={12} key={s}>
                  <Typography style={{ display: 'flex', alignItems: 'center', lineHeight: '2em' }}>
                    <ContactMail color="primary" fontSize="inherit" />
                    &nbsp;Found {s.count} Gmail Contacts
                  </Typography>
                </Grid>
              ))
          )}
          {loading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            emails.map((e) => (
              <Grid item xs={12} key={e}>
                <Typography style={{ display: 'flex', alignItems: 'center', lineHeight: '2em' }}>
                  <Email color="primary" fontSize="inherit" />
                  &nbsp;{e} connected&nbsp;
                  <Done color="primary" fontSize="inherit" />
                </Typography>
              </Grid>
            ))
          )}
          <Divider variant="middle" style={{ marginTop: '0.8rem' }} />
          <div style={{ marginTop: '2em' }}>
            <GoogleAuthorize
              scope={
                'email profile https://www.googleapis.com/auth/gmail.metadata openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
              }
              clientId={GMAIL_CONNECT_CLIENT_ID}
              buttonText="Connect Gmail Account"
              onSuccess={(response) => connect_gmail(response)}
              online="offline"
              responseType="code"
              prompt="consent"
              render={(renderProps) => (
                <Button
                  variant="contained"
                  onClick={renderProps.onClick}
                  startIcon={<SvgIcon component={google_svg} viewBox="0 0 600 476.6" />}
                >
                  Connect Gmail Account
                </Button>
              )}
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
