import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Apartment, Check, CompareArrows, FileCopy, HighlightOff, Person, Warning } from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import salesforce_png from '../static/salesforce.png';
import { is_admin, is_talpod, useAPI, withUserState } from '../userContext';
import { ROLES, SKILL_CATEGORIES, SKILLS } from '../utils/sf-consts';
import { create_poscans_tamuz_app, update_position } from './api';

const getSfUrl = (position) => `https://aleph.lightning.force.com/lightning/r/Position__c/${position.Id}/view`;

const PositionSideMenu = ({ position, setOpenPositionsSet, openPositionsSet, api }) => {
  return (
    <Grid item md={1} sm={1} xs={1} lg={1} xl={1} container direction={'column'} justifyContent={'space-between'}>
      <Grid item md={6} sm={6} xs={6} lg={6} xl={6} container direction={'column'}>
        <Grid item md={2} sm={2} xs={2} lg={2} xl={2}>
          <Link href={getSfUrl(position)} target="_blank">
            <img height="25px" src={salesforce_png} alt={'salesforce'} />
          </Link>
        </Grid>
        <Grid item md={2} sm={2} xs={2} lg={2} xl={2}>
          {!!_.get(position, 'Link_to_Job__c') && (
            <Link href={_.get(position, 'Link_to_Job__c')} target="_blank">
              <Apartment />
            </Link>
          )}
        </Grid>
      </Grid>
      <Grid item md={2} sm={2} xs={2} lg={2} xl={2}>
        <ClosePosition
          setOpenPositionsSet={setOpenPositionsSet}
          position={position}
          openPositionsSet={openPositionsSet}
          api={api}
        />
      </Grid>
    </Grid>
  );
};

const ClosePositionDialog = ({ showDialog, toggleDialog, setReallyClose }) => {
  const handleClick = (value) => {
    setReallyClose(value);
    toggleDialog();
  };
  return (
    <Dialog
      open={showDialog}
      onClose={toggleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Clicking 'Yes' will close the position and remove it from the list
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick(false)} color="primary">
          No
        </Button>
        <Button onClick={() => handleClick(true)} color="default" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ClosePosition = ({ setOpenPositionsSet, position, openPositionsSet, api }) => {
  const [showDialog, setShowDialog] = useState(false);
  const toggleDialog = () => setShowDialog(!showDialog);
  const [reallyClosePosition, setReallyClose] = useState(false);

  useEffect(() => {
    const closePosition = async () => {
      setOpenPositionsSet(openPositionsSet.remove(position));
      try {
        await update_position(
          {
            Id: position.Id,
            Status__c: 'Close',
          },
          api,
        );
      } catch (e) {
        console.log(e);
      }
    };
    if (reallyClosePosition) {
      closePosition();
    }
  }, [reallyClosePosition]);

  return (
    <>
      <IconButton onClick={toggleDialog} edge={'start'}>
        <HighlightOff />
      </IconButton>
      <ClosePositionDialog toggleDialog={toggleDialog} showDialog={showDialog} setReallyClose={setReallyClose} />
    </>
  );
};

export const PositionOwner = ({ setPositionDetails, positionDetails, potentialOwners }) => {
  const updateOwner = (event, option) => {
    setPositionDetails({
      owner: _.isNull(option) ? { Contact__c: null } : option,
      userChange: true,
      ..._.omit(positionDetails, ['owner', 'userChange']),
    });
  };
  return (
    <Autocomplete
      style={{ width: '200px' }}
      options={_.sortBy(potentialOwners, 'Contact_Name__c')}
      getOptionLabel={(option) => `${_.get(option, 'Contact_Name__c', '')}`}
      value={_.get(positionDetails, 'owner')}
      autoComplete
      size={'small'}
      onChange={updateOwner}
      selectOnFocus={false}
      freeSolo={false}
      renderInput={(params) => <TextField {...params} size={'small'} variant="outlined" label={'Owner'} />}
    />
  );
};

const EditSkill = ({ setPositionDetails, positionDetails }) => {
  const updateSkill = (event, option) => {
    if (!_.isNull(option)) {
      setPositionDetails({
        Main_skill__c: option,
        userChange: true,
        ..._.omit(positionDetails, ['Main_skill__c', 'userChange']),
      });
    }
  };
  return (
    <Autocomplete
      options={SKILLS.map(({ apiName }) => apiName)}
      value={_.get(positionDetails, 'Main_skill__c')}
      autoComplete
      size={'small'}
      onChange={updateSkill}
      selectOnFocus={false}
      freeSolo={false}
      renderInput={(params) => <TextField {...params} size={'small'} variant="outlined" label={'Skill'} />}
    />
  );
};

const EditRole = ({ setPositionDetails, positionDetails }) => {
  const updateRole = (event, option) => {
    if (!_.isNull(option)) {
      setPositionDetails({
        Role__c: option,
        userChange: true,
        ..._.omit(positionDetails, ['Role__c', 'userChange']),
      });
    }
  };
  return (
    <Autocomplete
      options={ROLES}
      value={_.get(positionDetails, 'Role__c')}
      autoComplete
      size={'small'}
      onChange={updateRole}
      selectOnFocus={false}
      freeSolo={false}
      renderInput={(params) => <TextField {...params} size={'small'} variant="outlined" label={'Role'} />}
    />
  );
};

const EditLocation = ({ setPositionDetails, positionDetails, locations }) => {
  const updateLocation = (event, option) => {
    if (!_.isNull(option)) {
      setPositionDetails({
        Location__c: option,
        userChange: true,
        ..._.omit(positionDetails, ['Location__c', 'userChange']),
      });
    }
  };
  return (
    <Autocomplete
      options={locations}
      value={_.get(positionDetails, 'Location__c')}
      autoComplete
      onChange={updateLocation}
      selectOnFocus={false}
      size={'small'}
      freeSolo={true}
      renderInput={(params) => <TextField variant="outlined" {...params} size={'small'} label={'Location'} />}
    />
  );
};

const EditSkillCategory = ({ setPositionDetails, positionDetails }) => {
  const updateSkillCategory = (event, option) => {
    if (!_.isNull(option)) {
      setPositionDetails({
        Skill_Category__c: option,
        userChange: true,
        ..._.omit(positionDetails, ['Skill_Category__c', 'userChange']),
      });
    }
  };
  return (
    <Autocomplete
      options={SKILL_CATEGORIES.map(({ apiName }) => apiName)}
      value={_.get(positionDetails, 'Skill_Category__c')}
      autoComplete
      size={'small'}
      onChange={updateSkillCategory}
      selectOnFocus={false}
      freeSolo={false}
      renderInput={(params) => <TextField {...params} size={'small'} variant="outlined" label={'Skill Category'} />}
    />
  );
};

const Confidential = ({ setPositionDetails, positionDetails, isDisabled }) => {
  const toggleConfidential = () =>
    setPositionDetails({
      Confidential__c: !_.get(positionDetails, 'Confidential__c'),
      userChange: true,
      ..._.omit(positionDetails, ['Confidential__c', 'userChange']),
    });
  return (
    <div>
      <Checkbox
        size={'small'}
        onChange={toggleConfidential}
        checked={_.get(positionDetails, 'Confidential__c')}
        disabled={isDisabled}
      />{' '}
      Confidential
    </div>
  );
};

const Priority = ({ setPositionDetails, positionDetails, isDisabled }) => {
  const toggleHighPriority = () =>
    setPositionDetails({
      High_Priority__c: !_.get(positionDetails, 'High_Priority__c'),
      userChange: true,
      ..._.omit(positionDetails, ['High_Priority__c', 'userChange']),
    });
  return (
    <div>
      <Checkbox
        size={'small'}
        onChange={toggleHighPriority}
        checked={_.get(positionDetails, 'High_Priority__c')}
        disabled={isDisabled}
      />{' '}
      High Priority
    </div>
  );
};

function getPositionTextToShare(position) {
  const positionUrl = _.get(position, 'Link_to_Job__c');
  const positionTitle = _.get(position, 'Name');
  const announced = _.get(position, 'announced');
  const companyWebsite = _.get(position, 'website');
  const oneLiner = _.get(position, 'portco_one_liner');

  const positionNameText = `${['a', 'e', 'i', 'o', 'u'].includes(positionTitle.charAt(0).toLowerCase()) ? 'an' : 'a'} ${positionTitle}`;
  const announcementStatusText = announced === 'Announced' ? '' : '(an unannounced portfolio company) ';
  const accountText = `${_.get(position, 'Account_Name__c')}, ${companyWebsite} ${announcementStatusText}`;
  const res =
    `${accountText} is looking for ${positionNameText} to join their team${positionUrl ? ` ${positionUrl}` : ''}.\n\n` +
    `A bit about the company: ${oneLiner}\n\n Would you like us to pass along your details?\n` +
    (!_.isEmpty(_.get(position, 'Short_Description__c')) ? `\n${_.get(position, 'Short_Description__c')}` : '');
  return res;
}

const OneLiner = ({ position }) => {
  const [copiedOneLiner, setCopied] = useState(false);

  const copyOneLiner = () => {
    const positionText = getPositionTextToShare(position);
    navigator.clipboard.writeText(positionText);
    setCopied(true);
  };

  return (
    <Button
      size={'small'}
      startIcon={<FileCopy />}
      onClick={copyOneLiner}
      style={{ borderRadius: '10px', backgroundColor: 'rgba(0, 163, 148, 0.06)', color: '#00A394', border: 'none' }}
    >
      {copiedOneLiner ? 'Copied to Clipboard' : 'Copy Suggestion'}
    </Button>
  );
};

const _AllPositionsPageView = ({
  position,
  setShowDetails,
  showAllDetails,
  setPositionDetails,
  positionDetails,
  locations,
  userState,
  potentialOwners,
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      onMouseEnter={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
      spacing={2}
    >
      <Grid item md={12} sm={12} xs={12} lg={12} xl={12}>
        <span
          style={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: '#00A394',
            marginRight: '12px',
          }}
        >
          {_.get(position, 'Name')}
        </span>
        <span style={{ fontWeight: 'bold', marginBottom: '10px' }}>
          <span style={{ marginRight: '18px' }}>{_.get(position, 'Account_Name__c')} </span>
          {showAllDetails && <OneLiner position={position} />}
        </span>
      </Grid>
      <Grid item container md={12} sm={12} xs={12} lg={12} xl={12} justifyContent="space-between">
        <Grid item md={3} sm={3} xs={3} lg={2} xl={2}>
          <EditLocation
            setPositionDetails={setPositionDetails}
            positionDetails={positionDetails}
            locations={locations}
          />
        </Grid>
        <Grid item md={3} sm={3} xs={3} lg={2} xl={2}>
          <EditSkill positionDetails={positionDetails} setPositionDetails={setPositionDetails} />
        </Grid>
        <Grid item md={3} sm={3} xs={3} lg={2} xl={2}>
          <EditRole positionDetails={positionDetails} setPositionDetails={setPositionDetails} />
        </Grid>
        <Grid item md={3} sm={3} xs={3} lg={2} xl={2}>
          <EditSkillCategory positionDetails={positionDetails} setPositionDetails={setPositionDetails} />
        </Grid>
      </Grid>
      <Grid md={12} sm={12} xs={12} lg={12} xl={12} item>
        <Confidential positionDetails={positionDetails} setPositionDetails={setPositionDetails} isDisabled={false} />
        <Priority positionDetails={positionDetails} setPositionDetails={setPositionDetails} isDisabled={false} />
        <PositionOwner
          positionDetails={positionDetails}
          setPositionDetails={setPositionDetails}
          potentialOwners={potentialOwners}
        />
      </Grid>

      <Grid md={12} sm={12} xs={12} lg={12} xl={12} item>
        <div style={{ color: '#818181' }}>
          <span style={{ marginRight: '14.5px' }}>
            {' '}
            Created {_.toUpper(moment(_.get(position, 'CreatedDate')).format('DD MMM YY'))}
          </span>
          {!_.isEmpty(_.get(position, 'Last_Known_As_Opened__c')) && (
            <>
              <span style={{ marginRight: '14.5px' }}> • </span>
              <span>
                {moment().diff(moment(_.get(position, 'Last_Known_As_Opened__c')), 'days') > 14 && (
                  <Tooltip title={'Might already be closed'}>
                    <Warning style={{ color: '#FFD700', marginRight: '10px' }} />
                  </Tooltip>
                )}
                Open as of {_.toUpper(moment(_.get(position, 'Last_Known_As_Opened__c')).format('DD MMM YY'))}{' '}
              </span>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const AllPositionsPageView = withUserState(_AllPositionsPageView);

const _CreatePoscanActions = ({
  position,
  candidate,
  candidateSource,
  setPoscanShoppingCartSet,
  positionDetails,
  poscanShoppingCartSet,
  setRefreshCandidate,
  userState,
  api,
}) => {
  const getPoscanFromCart = () =>
    poscanShoppingCartSet.find(
      ({ Position__c, Candidate__c }) => Position__c === position.Id && Candidate__c === candidate.Id,
    );
  const [poscanFromCart, setPoscanFromCart] = useState(getPoscanFromCart());

  const [alertText, setAlertText] = useState('');

  const disableIrrelevant = _.isEmpty(candidateSource) || !!poscanFromCart;
  useEffect(() => {
    setPoscanFromCart(getPoscanFromCart());
  }, [getPoscanFromCart, poscanShoppingCartSet]);

  const addPoscanToCart = (Status__c) => {
    const p = {
      Position__c: position.Id,
      Candidate__c: candidate.Id,
      Candidate_Source__c: candidateSource,
      Status__c,
      Request_Type__c: Status__c === 'Talpod To Review' ? 'Referral' : undefined,
      Portco_Owner__c: _.get(position, 'Owner__c'),
      position,
    };
    setPoscanShoppingCartSet(poscanShoppingCartSet.add(p));
  };
  const [showIrrelevantAlert, setShowAlert] = useState(false);

  const handleIrrelevant = async () => {
    setAlertText("Created a position candidate with 'Irrelevant' status. Check out the 'Reviewed' Tab");
    setShowAlert(true);
    await create_poscans_tamuz_app(
      {
        poscans: [
          {
            Position__c: position.Id,
            Candidate__c: candidate.Id,
            Candidate_Source__c: candidateSource,
            Status__c: 'Talpod found irrelevant',
          },
        ],
      },
      api,
    );
    setRefreshCandidate(true);
  };

  const disableButton = () => !_.get(positionDetails, 'owner');

  return (
    <div>
      <div>
        {is_admin(userState) && !_.get(position, 'Confidential__c') && (
          <Tooltip title={disableButton() ? 'Please update owner' : `Send to ${_.get(candidate, 'FirstName')}`}>
            <span>
              <IconButton
                disabled={disableButton()}
                color={_.get(poscanFromCart, 'Status__c', '') === 'Sent to candidate' ? 'primary' : 'default'}
                onClick={_.partial(addPoscanToCart, 'Sent to candidate')}
                aria-label="Send to candidate"
              >
                <Person />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {is_admin(userState) && !is_talpod(userState) && (
          <Tooltip
            title={
              disableButton() ? 'Please update owner' : `Ask Talpod to suggest to ${_.get(position, 'Account_Name__c')}`
            }
          >
            <span>
              <IconButton
                disabled={disableButton()}
                color={_.get(poscanFromCart, 'Status__c', '') === 'Talpod To Review' ? 'primary' : 'default'}
                onClick={_.partial(addPoscanToCart, 'Talpod To Review')}
                aria-label="Talpod To Review"
              >
                <Apartment />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {is_talpod(userState) && !_.get(position, 'Confidential__c') && (
          <Tooltip title={disableButton() ? 'Please update owner' : 'Introduce'}>
            <span>
              <IconButton
                onClick={_.partial(addPoscanToCart, 'Introduced')}
                aria-label="Introduce"
                disabled={disableButton()}
                color={_.get(poscanFromCart, 'Status__c', '') === 'Introduced' ? 'primary' : 'default'}
              >
                <CompareArrows />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {is_talpod(userState) && (
          <Tooltip title={disableButton() ? 'Please update owner' : `Send to ${_.get(position, 'Account_Name__c')}`}>
            <span>
              <IconButton
                onClick={_.partial(addPoscanToCart, 'Sent to account')}
                disabled={disableButton()}
                color={_.get(poscanFromCart, 'Status__c', '') === 'Sent to account' ? 'primary' : 'default'}
                aria-label="Send to Account"
              >
                <Apartment />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
      {is_talpod(userState) && (
        <div style={{ marginTop: '10px', marginLeft: '14px' }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox size={'small'} />}
              style={{ width: '60%' }}
              disabled={disableIrrelevant}
              label="Irrelevant"
              checked={_.get(poscanFromCart, 'Status__c', '') === 'Talpod found irrelevant'}
              onChange={handleIrrelevant}
            />
          </FormGroup>
        </div>
      )}
      {showIrrelevantAlert && (
        <Alert onClose={() => setShowAlert(false)} severity={'success'}>
          {alertText}
        </Alert>
      )}
    </div>
  );
};

const CreatePoscanActions = withUserState(_CreatePoscanActions);

const _CandidatePageView = ({
  position,
  setShowDetails,
  showAllDetails,
  setPositionDetails,
  positionDetails,
  potentialOwners,
  api,
  candidate,
  candidateSource,
  setPoscanShoppingCartSet,
  userState,
  setRefreshCandidate,
  poscanShoppingCartSet,
}) => {
  return (
    <>
      <Grid
        container
        style={{ lineHeight: '32px' }}
        onMouseEnter={() => setShowDetails(true)}
        onMouseLeave={() => setShowDetails(false)}
        spacing={2}
      >
        <Grid md={4} sm={4} xs={4} lg={4} xl={4} item style={{ textAlign: 'right' }}>
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              color: '#00A394',
              marginBottom: '10px',
            }}
          >
            {_.get(position, 'Name')}
          </div>
          <div>{_.get(position, 'Location__c') || 'Unspecified Location'}</div>
          {is_talpod(userState) && (
            <>
              <Confidential
                positionDetails={positionDetails}
                setPositionDetails={setPositionDetails}
                isDisabled={false}
              />
              <Priority positionDetails={positionDetails} setPositionDetails={setPositionDetails} isDisabled={false} />
            </>
          )}
          {is_admin(userState) && !is_talpod(userState) && (
            <>
              {_.get(position, 'Confidential__c') && (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {' '}
                  <Check style={{ marginRight: '8px' }} /> Confidential
                </div>
              )}
              {_.get(position, 'High_Priority__c') && (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {' '}
                  <Check style={{ marginRight: '8px' }} /> High Priority
                </div>
              )}
            </>
          )}
        </Grid>
        <Grid md={5} sm={5} xs={5} lg={5} xl={5} item>
          <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            <span style={{ marginRight: '18px' }}>
              {_.get(position, 'website') && (
                <Link href={_.get(position, 'website')}>{_.get(position, 'Account_Name__c')}</Link>
              )}
              {!_.get(position, 'website') && <span>{_.get(position, 'Account_Name__c')}</span>}
            </span>
            {showAllDetails && !_.get(position, 'Confidential__c') && <OneLiner position={position} />}
          </div>
          <div style={{ lineHeight: '32px' }}>
            <span style={{ marginRight: '14.5px' }}> {_.get(position, 'Main_skill__c')} </span>
            {!_.isEmpty(_.get(position, 'Skill_Category__c')) && (
              <>
                <span style={{ marginRight: '14.5px' }}> • </span>
                <span>
                  {' '}
                  {_.get(
                    SKILL_CATEGORIES.find(({ apiName }) => _.get(position, 'Skill_Category__c') === apiName),
                    'label',
                  )}{' '}
                </span>
              </>
            )}
          </div>
          <div style={{ color: '#818181' }}>
            <span style={{ marginRight: '14.5px' }}>
              {' '}
              Created {_.toUpper(moment(_.get(position, 'CreatedDate')).format('DD MMM YY'))}
            </span>
            {!_.isEmpty(_.get(position, 'Last_Known_As_Opened__c')) && (
              <>
                <span style={{ marginRight: '14.5px' }}> • </span>
                <span>
                  {moment().diff(moment(_.get(position, 'Last_Known_As_Opened__c')), 'days') > 14 && (
                    <Tooltip title={'Might already be closed'}>
                      <Warning style={{ color: '#FFD700', marginRight: '10px' }} />
                    </Tooltip>
                  )}
                  Open as of {_.toUpper(moment(_.get(position, 'Last_Known_As_Opened__c')).format('DD MMM YY'))}{' '}
                </span>
              </>
            )}
          </div>
        </Grid>
        <Grid md={3} sm={3} xs={3} lg={3} xl={3} item>
          {showAllDetails && (
            <>
              <CreatePoscanActions
                position={position}
                candidate={candidate}
                positionDetails={positionDetails}
                poscanShoppingCartSet={poscanShoppingCartSet}
                setRefreshCandidate={setRefreshCandidate}
                api={api}
                candidateSource={candidateSource}
                setPoscanShoppingCartSet={setPoscanShoppingCartSet}
              />
              {is_talpod(userState) && (
                <PositionOwner
                  positionDetails={positionDetails}
                  setPositionDetails={setPositionDetails}
                  potentialOwners={potentialOwners}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const CandidatePageView = withUserState(_CandidatePageView);

const _Position = ({
  position,
  locations,
  view,
  openPositionsSet,
  setOpenPositionsSet,
  potentialOwners,
  userState,
  candidate,
  candidateSource,
  setPoscanShoppingCartSet,
  poscanShoppingCartSet,
  setRefreshCandidate,
}) => {
  const api = useAPI();
  const [showAllDetails, setShowDetails] = useState(false);

  const [positionDetails, setPositionDetails] = useState({
    ..._.pick(position, [
      'Confidential__c',
      'Location__c',
      'Main_skill__c',
      'High_Priority__c',
      'Role__c',
      'Skill_Category__c',
    ]),
    owner: potentialOwners.find(({ Contact__c }) => _.get(position, 'Owner__c') === Contact__c) || null,
    userChange: false,
  });

  useEffect(() => {
    async function up() {
      const update = {
        Id: position.Id,
        Owner__c: _.get(positionDetails, 'owner.Contact__c'),
        ..._.omit(positionDetails, ['userChange', 'owner']),
      };
      try {
        await update_position(update, api);
      } catch (e) {
        console.log(e);
      }
    }
    !!_.get(positionDetails, 'userChange', false) && up();
  }, [api, position.Id, positionDetails]);

  return (
    <Grid
      container
      style={{
        padding: '20px 20px 20px 20px',
        lineHeight: '32px',
        backgroundColor: showAllDetails ? '#f1f1f1' : 'transparent',
      }}
    >
      <PositionSideMenu
        setOpenPositionsSet={setOpenPositionsSet}
        position={position}
        openPositionsSet={openPositionsSet}
        api={api}
      />
      <Grid item md={10} sm={10} xs={10} lg={10} xl={10}>
        {view === 'All' && (
          <AllPositionsPageView
            position={position}
            setShowDetails={setShowDetails}
            showAllDetails={showAllDetails}
            setPositionDetails={setPositionDetails}
            positionDetails={positionDetails}
            locations={locations}
            potentialOwners={potentialOwners}
          />
        )}
        {view === 'Candidate' && (
          <>
            <CandidatePageView
              position={position}
              setShowDetails={setShowDetails}
              showAllDetails={showAllDetails}
              setPositionDetails={setPositionDetails}
              positionDetails={positionDetails}
              potentialOwners={potentialOwners}
              candidate={candidate}
              poscanShoppingCartSet={poscanShoppingCartSet}
              setRefreshCandidate={setRefreshCandidate}
              api={api}
              candidateSource={candidateSource}
              setPoscanShoppingCartSet={setPoscanShoppingCartSet}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export const Position = withUserState(_Position);
