import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { CsvToArray } from '../misc/csv-array';
import { useAPI } from '../userContext';
import { create_nemp_signals } from './api';

export function Nemp() {
  const api = useAPI();
  const [array, setArray] = useState([]);
  const [result, setResult] = useState();
  const [createingSignals, setCreatingSignals] = useState(false);

  useEffect(() => {
    async function create() {
      setCreatingSignals(true);
      return create_nemp_signals({ rows: array }, api).then((res) => {
        setResult(res);
        setCreatingSignals(false);
      });
    }
    !_.isEmpty(array) && create();
  }, [api, array]);

  return (
    <div style={{ textAlign: 'center' }}>
      <CsvToArray
        header={'NEMP Signals Creator'}
        subHeader={'Please follow the instructions below'}
        array={array}
        setArray={setArray}
      />
      <p style={{ width: '60%', margin: 'auto', textAlign: 'left', marginTop: '8px', marginBottom: '8px' }}>
        <li> make sure you are logged in as an Aleph member (this action is for admins only) </li>
        <li>
          {' '}
          Each csv should only contain signals created by one contact which we recognize by a column called 'Added by'
          (case sensitive) - an email address{' '}
        </li>
        <li> Make sure the above email address is connected to the contact (private or work email)</li>
        <li>
          {' '}
          we are searching/adding contacts based on their linkedin profile which the code search in a column called
          "url"
        </li>
      </p>

      {createingSignals && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={20} />
          <span style={{ marginLeft: '5px' }}>Creating signals and adding contacts...</span>
        </div>
      )}
      {!!_.get(result, 'success') && !createingSignals && (
        <div>
          <h3>
            Created {_.get(result, 'newContacts')} new contacts that are related to {_.get(result, 'addedBy.Name')}.
            here's a summary:
          </h3>
          {_.get(result, 'signalsSummary')}
        </div>
      )}
      {!_.get(result, 'success') && !createingSignals && !_.isEmpty(array) && (
        <div>
          <h3>error creating contacts signals:</h3>
          {_.get(result, 'error')}
        </div>
      )}
    </div>
  );
}
