const DESIRED_SKILLS = [
  'Engineering',
  'Product Management',
  'Design',
  'Data',
  'Marketing and Growth',
  'Sales',
  'Bizdev',
  'Finance and Operations',
  'HR and Recruiting',
  'Customer Facing Roles',
];

const POSITION_TYPES = [
  'Entry Level - less than 2 years',
  'Experienced - 2+ years',
  'Team Leader - managing a team',
  'Director - managing teams of managers',
  'Executive - C-level managers',
];

const ROLES = ['Individual Contributor', 'Manager', 'Executive', 'Consultant'];

const SKILL_CATEGORIES = [
  {
    label: 'Business Intelligence',
    apiName: 'Business Intelligence',
  },
  {
    label: 'Customer Support',
    apiName: 'Customer Support',
  },
  {
    label: 'Design',
    apiName: 'Design',
  },
  {
    label: 'Dev and Data Science',
    apiName: 'Dev and Data Science',
  },
  {
    label: 'Finance and Operations',
    apiName: 'Finance and Operations',
  },
  {
    label: 'Information Tech',
    apiName: 'Information Tech',
  },
  {
    label: 'Investment',
    apiName: 'Investment',
  },
  {
    label: 'Marketing and Growth',
    apiName: 'Marketing and Growth',
  },
  {
    label: 'People and Recruiting',
    apiName: 'People and Recruiting',
  },
  {
    label: 'Product and UX',
    apiName: 'Product and UX',
  },
  {
    label: 'Sales and BD',
    apiName: 'Sales and BD',
  },
  {
    label: 'Other',
    apiName: 'Other',
  },
];

const SKILLS = [
  {
    label: 'Bizdev',
    apiName: 'Bizdev',
  },
  {
    label: 'Bizdev: Corpdev',
    apiName: 'Bizdev: Corpdev',
  },
  {
    label: 'Customer Success',
    apiName: 'Customer Success',
  },
  {
    label: 'Customer Support',
    apiName: 'Customer Support',
  },
  {
    label: 'Data: Marketing Analyst',
    apiName: 'Data: Marketing Analyst',
  },
  {
    label: 'Data: Analyst',
    apiName: 'Data: Analyst',
  },
  {
    label: 'Data: Business Intelligence',
    apiName: 'Data: Business Intelligence',
  },
  {
    label: 'Data: Engineer',
    apiName: 'Data: Engineer',
  },
  {
    label: 'Data: Scientist',
    apiName: 'Data: Scientist',
  },
  {
    label: 'Design',
    apiName: 'Design',
  },
  {
    label: 'Design: Product',
    apiName: 'Design: Product',
  },
  {
    label: 'Design: Marketing',
    apiName: 'Design: Marketing',
  },
  {
    label: 'Design: UI',
    apiName: 'Design: UI',
  },
  {
    label: 'Design: UX',
    apiName: 'Design: UX',
  },
  {
    label: 'Dev',
    apiName: 'Dev',
  },
  {
    label: 'Dev: Algorithms',
    apiName: 'Dev: Algorithms',
  },
  {
    label: 'Dev: Back End',
    apiName: 'Dev: Back End',
  },
  {
    label: 'Dev: Electrical Engineer',
    apiName: 'Dev: Electrical Engineer',
  },
  {
    label: 'Dev: Embedded',
    apiName: 'Dev: Embedded',
  },
  {
    label: 'Dev: Front End',
    apiName: 'Dev: Front End',
  },
  {
    label: 'Dev: Full Stack',
    apiName: 'Dev: Full Stack',
  },
  {
    label: 'Dev: Information Security',
    apiName: 'Dev: Information Security',
  },
  {
    label: 'Dev: Mechanical Engineer',
    apiName: 'Dev: Mechanical Engineer',
  },
  {
    label: 'Dev: Mobile',
    apiName: 'Dev: Mobile',
  },
  {
    label: 'Dev: Reverse Engineering',
    apiName: 'Dev: Reverse Engineering',
  },
  {
    label: 'Dev: Scala',
    apiName: 'Dev: Scala',
  },
  {
    label: 'Dev: Solution Engineer',
    apiName: 'Dev: Solution Engineer',
  },
  {
    label: 'Devops',
    apiName: 'Devops',
  },
  {
    label: 'Finance',
    apiName: 'Finance',
  },
  {
    label: 'Finance: Accounting',
    apiName: 'Finance: Accounting',
  },
  {
    label: 'Growth',
    apiName: 'Growth',
  },
  {
    label: 'Human Resources',
    apiName: 'Human Resources',
  },
  {
    label: 'Human Resources: Talent Acquisition',
    apiName: 'Human Resources: Talent Acquisition',
  },
  {
    label: 'Independent Board Member',
    apiName: 'Independent Board Member',
  },
  {
    label: 'Investment',
    apiName: 'Investment',
  },
  {
    label: 'IT',
    apiName: 'IT',
  },
  {
    label: 'IT: CRM Admin',
    apiName: 'IT: CRM Admin',
  },
  {
    label: 'IT: Security',
    apiName: 'IT: Security',
  },
  {
    label: 'Journalist',
    apiName: 'Journalist',
  },
  {
    label: 'Legal',
    apiName: 'Legal',
  },
  {
    label: 'Machine Learning',
    apiName: 'Machine Learning',
  },
  {
    label: 'Machine Learning: Computer Vision',
    apiName: 'Machine Learning: Computer Vision',
  },
  {
    label: 'Machine Learning: NLP',
    apiName: 'Machine Learning: NLP',
  },
  {
    label: 'Marketing',
    apiName: 'Marketing',
  },
  {
    label: 'Marketing: Branding',
    apiName: 'Marketing: Branding',
  },
  {
    label: 'Marketing: Communications',
    apiName: 'Marketing: Communications',
  },
  {
    label: 'Marketing: Community',
    apiName: 'Marketing: Community',
  },
  {
    label: 'Marketing: Content',
    apiName: 'Marketing: Content',
  },
  {
    label: 'Marketing: Events',
    apiName: 'Marketing: Events',
  },
  {
    label: 'Marketing: Performance',
    apiName: 'Marketing: Performance',
  },
  {
    label: 'Marketing: Product',
    apiName: 'Marketing: Product',
  },
  {
    label: 'Marketing: SEO',
    apiName: 'Marketing: SEO',
  },
  {
    label: 'Operations',
    apiName: 'Operations',
  },
  {
    label: 'Operations: Business',
    apiName: 'Operations: Business',
  },
  {
    label: 'Product',
    apiName: 'Product',
  },
  {
    label: 'Product: B2B',
    apiName: 'Product: B2B',
  },
  {
    label: 'Product: B2C',
    apiName: 'Product: B2C',
  },
  {
    label: 'Project/Program Management',
    apiName: 'Project/Program Management',
  },
  {
    label: 'QA',
    apiName: 'QA',
  },
  {
    label: 'Research',
    apiName: 'Research',
  },
  {
    label: 'Risk',
    apiName: 'Risk',
  },
  {
    label: 'Sales',
    apiName: 'Sales',
  },
  {
    label: 'Sales: Direct',
    apiName: 'Sales: Direct',
  },
  {
    label: 'Sales: Inside Sales',
    apiName: 'Sales: Inside Sales',
  },
  {
    label: 'Strategy',
    apiName: 'Strategy',
  },
  {
    label: 'Unknown',
    apiName: 'Unknown',
  },
  {
    label: 'Other',
    apiName: 'Other',
  },
];

const CANDIDATE_SOURCES = [
  'Inbound - Intro and Network',
  'Inbound - jobs.aleph.vc',
  'Inbound - Referral Form',
  'Inbound - Newsletter',
  'Inbound - Cross Portfolio Reference',
  'Outbound - Companies Shutdowns',
  "Outbound - Aleph's network",
];

const POTENTIAL_INTRO_GOALS = [
  'Accelerator/growth program',
  'Business Development',
  'Due Diligence',
  'Finance & Operations',
  'Follow-on Funding',
  'Initial Funding',
  'Intelligence',
  'Media/PR',
  'Office Space',
  'Pair Up',
  'Sales Opportunity',
  'Talent',
  'Other',
];

const POSCAN_STATUSES = [
  'Intro request',
  'Ref check request',
  'Ref check provided',
  "Couldn't find connection",
  'To send to account',
  'Sent to account',
  'Accepted by account',
  'To send to candidate',
  'Sent to candidate',
  'To be introduced',
  'Introduced',
  'Rejected by account',
  'Rejected by Candidate',
  'Not relevant / No longer needed',
  "Candidate didn't answer",
  'Rejected by account after interview',
  'Rejected by candidate after interview',
  'Interview with Aleph personnel',
  'Is/Was Already in Process',
  'Hired',
  'candidate was hired by another company',
  'position was closed',
  'position candidate was closed after no feedback from account',
  'Someone else was hired',
  'Applicant to Review',
  'Talpod found irrelevant',
  'Talpod To Review',
  'On Hold',
];

const POISTION_CANDIDATE_PRIORITIES = ['High', 'Best Effort'];

const ALEPH_ACCOUNT_ID = '00158000004DdxHAAS';

const REJECTION_REASONS_INTRO = [
  {
    label: 'No Relationship',
    apiName: 'No/Weak Relationship',
  },
  {
    label: 'Not Appropriate In This Context',
    apiName: 'Not Appropriate In This Context',
  },
  {
    label: 'Alternative Path Chosen',
    apiName: 'Alternative Path Chosen',
  },
  {
    label: 'No More Need',
    apiName: 'No More Need',
  },
  {
    label: 'Duplicate Request',
    apiName: 'Duplicate Request',
  },
  {
    label: 'Rejected by Portco',
    apiName: 'Rejected by Portco',
  },
  {
    label: 'Weak Relationship',
    apiName: 'Weak Relationship',
  },
];

const REJECTED_INTRO_STATUSES = ["Can't Reach Out", 'Declined_by_Connector', 'Declined by Target'];

//    all intro statuses:
const INTRO_STATUSES = [
  {
    label: 'Request Sent to Target',
    apiName: 'Sent',
    active: true,
    talpodRank: 1,
  },
  {
    label: 'Asked Connector',
    apiName: 'Asked',
    active: true,
    talpodRank: 2,
  },
  {
    label: 'Asked Connector - Pinged',
    apiName: 'Asked Connector - Pinged',
    active: true,
    talpodRank: 2,
  },
  {
    label: 'To review',
    apiName: 'To review',
    active: true,
    talpodRank: 3,
  },
  {
    label: 'Connector Agreed',
    apiName: 'Agreed',
    active: true,
    talpodRank: 3,
  },
  {
    label: 'Connector Agreed - Pinged',
    apiName: 'Connector Agreed - Pinged',
    active: true,
    talpodRank: 3,
  },
  {
    label: 'Checking Internally',
    apiName: 'Checking Internally',
    active: true,
    talpodRank: 4,
  },
  {
    label: 'Checking Internally - Pinged',
    apiName: 'Checking Internally - Pinged',
    active: true,
    talpodRank: 4,
  },

  {
    label: 'Awaiting Info from Portfolio',
    apiName: 'Awaiting Info from Portfolio',
    active: true,
    talpodRank: 5,
  },
  {
    label: 'Deferred',
    apiName: 'Deferred',
    active: true,
    talpodRank: 6,
  },
  {
    label: 'No Response from Target',
    apiName: 'No Response',
    active: false,
    talpodRank: 8,
  },
  {
    label: 'Declined by Connector',
    apiName: 'Declined_by_Connector',
    active: false,
    talpodRank: 9,
  },
  {
    label: 'No Response from Connector',
    apiName: "Didn't Respond",
    active: false,
    talpodRank: 10,
  },

  {
    label: 'Declined by Target',
    apiName: 'Declined by Target',
    active: false,
    talpodRank: 11,
  },
  {
    label: 'Weak relationship with connector',
    apiName: 'Weak relationship with connector',
    active: false,
    talpodRank: 11,
  },
  {
    label: 'No relationship with connector',
    apiName: 'No relationship with connector',
    active: false,
    talpodRank: 12,
  },

  {
    label: "Can't Reach Out",
    apiName: "Can't Reach Out",
    active: false,
    talpodRank: 13,
  },
  {
    label: 'Sent Email to Facilitator',
    apiName: 'Sent Email to Facilitator',
    active: true,
  },
  {
    label: 'Facilitator Agreed',
    apiName: 'Facilitator Agreed',
    active: true,
  },
  {
    label: 'Introduced',
    apiName: 'Introduced',
  },
  {
    label: 'Feedback Requested',
    apiName: 'Feedback Requested',
  },
  {
    label: 'Feedback Provided',
    apiName: 'Feedback Provided',
  },
  {
    label: 'No Feedback',
    apiName: 'No Feedback',
  },
  {
    apiName: 'Declined by Facilitator',
    label: 'Declined by Facilitator',
  },
];

const INTRO_STATUS_API_NAME_TO_LABEL = INTRO_STATUSES.reduce((acc, status) => {
  acc[status.apiName] = status.label;
  return acc;
}, {});

const REJECTION_REASONS_POSCAN_BY_PORTCO = [
  'Candidate expectations not set for position offered',
  'Does not match requirements',
  'No position',
  'Candidate is not looking',
  'Too junior',
  'Too Senior',
  'Unknown - info was not provided by account',
  'Signed with another company',
];

const REJECTION_REASONS_POSCAN_BY_CANDIDATE = [
  {
    label: 'Signed with another company',
    apiName: 'Signed with another company',
  },
  {
    label: 'Candidate is not looking',
    apiName: 'Candidate is not looking',
  },
  {
    label: 'Too junior',
    apiName: 'Too junior',
  },
  {
    label: 'Too Senior',
    apiName: 'Too Senior',
  },
  {
    label: 'Candidate expectations not set for position offered',
    apiName: 'Candidate expectations not set for position offered',
  },
];

const SIGNAL_DIRECTIONS = ['Neutral', 'Positive', 'Negative'];

const POTENTIAL_DATE_RANGES = [
  'YESTERDAY',
  'TODAY',
  'LAST_WEEK',
  'THIS_WEEK',
  'LAST_MONTH',
  'THIS_MONTH',
  'LAST_90_DAYS',
  'LAST_N_DAYS:n',
  'LAST_N_WEEKS:n',
  'LAST_N_MONTHS:n',
  'THIS_QUARTER',
  'LAST_QUARTER',
  'LAST_N_QUARTERS:n',
  'THIS_YEAR',
  'LAST_YEAR',
];

module.exports = {
  DESIRED_SKILLS,
  SKILL_CATEGORIES,
  POSITION_TYPES,
  ROLES,
  SKILLS,
  CANDIDATE_SOURCES,
  POTENTIAL_INTRO_GOALS,
  POSCAN_STATUSES,
  ALEPH_ACCOUNT_ID,
  REJECTION_REASONS_INTRO,
  INTRO_STATUSES,
  INTRO_STATUS_API_NAME_TO_LABEL,
  REJECTED_INTRO_STATUSES,
  REJECTION_REASONS_POSCAN_BY_CANDIDATE,
  REJECTION_REASONS_POSCAN_BY_PORTCO,
  SIGNAL_DIRECTIONS,
  POISTION_CANDIDATE_PRIORITIES,
  POTENTIAL_DATE_RANGES,
};
