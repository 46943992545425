import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import { DataGrid } from '@material-ui/data-grid';
import { Delete, Group, LinkedIn, Send, Snooze } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAdmin, useAPI } from '../userContext';
import { useQuery } from '../utils/misc';
import { INTRO_STATUS_API_NAME_TO_LABEL } from '../utils/sf-consts';
import { CsvDownloadButton, SalesforceIcon } from '../widgets';
import { IntroRequestDialog } from './intro_request_form';

const CHANCE_TO_CONNECT = {
  0: '0 - No relationship',
  1: '1 - Long shot',
  2: '2 - Possible',
  3: '3 - Good chance',
  4: '4 - High chance',
};

const TITLE_SYNONYMS_LIST = [
  ['cto', 'chief technology officer'],
  ['ceo', 'chief executive officer'],
  ['cfo', 'chief financial officer', 'head of finance', 'vp finance', 'vp of finance'],
  ['cpo', 'chief product officer', 'head of product', 'vp product', 'vp of product'],
  ['coo', 'chief operating officer', 'head of operations', 'vp operations', 'vp of operations', 'head operations'],
  ['cmo', 'chief marketing officer', 'head of marketing', 'vp marketing', 'vp of marketing'],
  ['cso', 'chief sales officer', 'head of sales', 'vp sales', 'vp of sales'],
  ['cio', 'chief information officer', 'head of information', 'vp information', 'vp of information'],
  [
    'ciso',
    'chief information security officer',
    'head of information security',
    'vp information security',
    'vp of information security',
  ],
  ['cdo', 'chief data officer', 'head of data', 'vp data', 'vp of data'],
  ['cco', 'chief compliance officer', 'head of compliance', 'vp compliance', 'vp of compliance'],
  [
    'head cx',
    'head of customer experience',
    'vp customer experience',
    'vp of customer experience',
    'vp customer success',
    'vp of customer success',
    'head of customer success',
  ],
  ['vp customer support', 'vp of customer support', 'head of customer support', 'head customer support'],
];
// now make a mapping for each synonym to all other synonyms
const TITLE_SYNONYMS = {};
TITLE_SYNONYMS_LIST.forEach((synonyms) => {
  synonyms.forEach((synonym) => {
    TITLE_SYNONYMS[synonym] = synonyms.filter((s) => s !== synonym);
  });
});

const keywords_to_regexps = (keywores_text) => {
  const keywords = (keywores_text || '')
    .split(',')
    .map((x) => x.trim())
    .filter((x) => !_.isEmpty(x));
  const keywords_with_synonyms = keywords.map((kw) => [kw, ...(TITLE_SYNONYMS[kw.toLowerCase()] || [])]).flat();
  const keywords_regexps = keywords_with_synonyms.map((kw) => [kw, new RegExp(`\\b${kw}\\b`, 'ig')]);
  return keywords_regexps;
};

const useAlephUsers = () => {
  const api = useAPI();
  const [users, set_users] = React.useState({});
  useEffect(() => {
    const read_users = async () => {
      const { users } = await api('user/aleph-users/', { method: 'GET' });
      set_users(_.keyBy(users, 'Id'));
    };
    read_users();
  }, [api]);
  return users;
};

const prioritize_account = ({ account_kind }) => {
  if (account_kind === 'potential customer') return 0;
  if (account_kind.match(/^lookalike customer:/)) return 1;
  if (account_kind === 'profile match - both') return 2;
  if (account_kind === 'profile match - keywords') return 3;
  if (account_kind === 'profile match - industry') return 4;
  return 2;
};
const prioritize_contact = ({ contact_kind, recent_role, recent_relationship_update }) => {
  if (contact_kind === 'exact_title_match') return 0;
  if (contact_kind === 'executives') return 1;
  if (contact_kind === 'board_members') return 2;
  if (contact_kind === 'managers') return 3;
  if (contact_kind === 'vc_partners') return 4;
  if (contact_kind === 'employees') return 5;
};
const prioritize_timing = ({ recent_role, recent_relationship_update }) => {
  if (recent_role) return 0;
  if (recent_relationship_update) return 1;
  return 2;
};

export const SalesRecommendationsTable = ({ sales_recommendations, loading, sales_target, update_status }) => {
  const users = useAlephUsers();
  const is_admin = useAdmin();
  const min_employees = _.get(sales_target, 'Target_Company_Size_Min__c', 0) || 0;
  const max_employees = _.get(sales_target, 'Target_Company_Size_Max__c', Infinity) || Infinity;
  const industries = (_.get(sales_target, 'Industries__c', '') || '')
    .split(',')
    .map((x) => x.trim().toLowerCase())
    .filter((x) => x);
  const keywords_regexps = keywords_to_regexps(sales_target.Target_Persona_Keywords__c);
  const name_with_link = ({ value, row }) => (
    <>
      <a href={row.linkedin_profile} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
      <a href={row.linkedin_profile} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '0.5rem' }}>
        <LinkedIn color="primary" fontSize="inherit" />
      </a>
      <a
        href={`https://aleph.lightning.force.com/lightning/r/Account/${row.id}/view`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: '0.5rem' }}
      >
        <SalesforceIcon fontSize="small" />
      </a>
    </>
  );
  const AccountWithLinks = ({ value, row }) => (
    <>
      <a href={row.website} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
      <a href={row.account_linkedin} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '0.5rem' }}>
        <LinkedIn color="primary" fontSize="inherit" />
      </a>
      <a
        href={`https://aleph.lightning.force.com/lightning/r/Account/${row.account_id}/view`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: '0.5rem' }}
      >
        <SalesforceIcon fontSize="small" />
      </a>
    </>
  );
  const pre_cell =
    (formatter) =>
    ({ value, row }) => (
      <pre style={{ lineHeight: '1rem', height: '199px', margin: '0px' }}>{formatter({ value, row })}</pre>
    );

  const sf_account_link = ({ value, row }) => `https://aleph.lightning.force.com/lightning/r/Account/${value}/view`;
  const sf_contact_link = ({ value, row }) => `https://aleph.lightning.force.com/lightning/r/Contact/${value}/view`;

  const why_this_person = ({ value, row }) => {
    const reasons = [];
    if (value === 'executives') {
      reasons.push('Executive at the Company');
    }
    if (value === 'managers') {
      reasons.push('Manager at the Company');
    }
    if (value === 'employees') {
      reasons.push('Employee at the Company');
    }
    /* if (value === 'exact_title_match') { */
    const title_match = keywords_regexps.filter(([, kw_re]) => row.title.match(kw_re)).map(([kw]) => kw);
    if (!_.isEmpty(title_match)) {
      reasons.push(`His Title (${row.title}) matches keyword ${_.uniq(title_match).join(', ')}`);
    }
    /* } */
    if (value === 'board_members') {
      reasons.push('Board Member at the Company');
    }
    if (value === 'vc_partners') {
      reasons.push('A Partner at a VC that invested in the Company');
    }

    if (row.recent_role) {
      reasons.push('Recently started this role');
    }
    if (row.recent_relationship_update) {
      reasons.push('Recently joined the network');
    }
    return reasons.join('\n');
  };
  const Relationship = ({ relationship_category, score_details }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={handleClick}>rel={relationship_category}</a>
        <Popper open={open} anchorEl={anchorEl}>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper style={{ padding: '0.4rem' }}>
              <pre>{score_details}</pre>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    );
  };
  const paths_text = (value) => {
    const path_sort = (value) => _.sortBy(value, [prioritize_contact, (x) => -x.score, prioritize_timing]);
    const uniq = _.uniqBy(value, 'id');
    const hop_groups = _.groupBy(uniq, (p) => p.n_hops + (p.relationship_category < '3' ? 1 : 0));
    const rows = _.keys(hop_groups).map((hop) => [
      `${hop} hops:\n`,
      ...path_sort(hop_groups[hop]).map(
        (p) =>
          `${_.get(users, p.contact_owner, {}).Slack_Name__c}->${p.name} (${p.title}) ${p.contact_kind} rel.cat=${p.relationship_category} ${p.recent_role ? '| recent role change' : ''} ${p.recent_relationship_update ? '| recent relationship update' : ''}`,
      ),
    ]);
    return rows.flat().join('\n');
  };
  const Path = ({
    contact_owner,
    name,
    title,
    score,
    linkedin_profile,
    score_details,
    contact_kind,
    n_hops,
    relationship_category,
    recent_role,
    recent_relationship_update,
  }) => {
    const extra_hop = relationship_category < '3' ? 1 : 0;
    const extra_hop_str = extra_hop ? '->[?]->' : '->';
    const recent_role_txt = recent_role ? ' | recent role change' : '';
    const recent_relationship_update_txt = recent_relationship_update ? ' | recent relationship update' : '';
    return (
      <span>
        {_.get(users, contact_owner, {}).Slack_Name__c}
        {extra_hop_str}
        {name} ({title}) {contact_kind} | <Relationship {...{ score_details, relationship_category }} />{' '}
        {recent_role_txt}
        {recent_relationship_update_txt}
        <br />
      </span>
    );
  };
  const Paths = ({ value }) => {
    const path_sort = (value) => _.sortBy(value, [prioritize_contact, (x) => -x.score, prioritize_timing]);
    const uniq = _.uniqBy(value, 'id');
    const hop_groups = _.groupBy(uniq, (p) => p.n_hops + (p.relationship_category < '3' ? 1 : 0));
    return (
      <pre style={{ lineHeight: '1rem', height: '199px', margin: '0px', textWrap: 'pretty' }}>
        {_.keys(hop_groups).map((hop) => (
          <span key={hop}>
            <u>{hop} hops:</u>
            <br />
            {path_sort(hop_groups[hop]).map((p) => (
              <Path key={p.id} users={users} {...p} />
            ))}
            <br />
          </span>
        ))}
      </pre>
    );
  };

  const StatusActionButtons = ({ value, row }) => {
    const [open_snackbar, set_open_snackbar] = React.useState(false);
    const [show_dismiss, set_show_dismiss] = React.useState(false);
    const [show_suggest, set_show_suggest] = React.useState(false);
    const [show_snooze, set_show_snooze] = React.useState(false);
    const [show_intro, set_show_intro] = React.useState(false);
    const [menu_anchor, set_menu_anchor] = React.useState(null);
    const { status_id, status, comments } = row.status;
    const onclick_dismiss = () => set_show_dismiss(true);
    const onclick_suggest = () => set_show_suggest(true);
    const onclick_intro = async () => {
      if (_.isEmpty(status_id)) {
        return;
      }
      set_show_intro(true);
    };
    const onclick_snooze = () => set_show_snooze(true);
    const handle_close_suggest = () => {
      set_show_suggest(false);
      set_menu_anchor(null);
    };
    const handle_close_dismiss = () => {
      set_show_dismiss(false);
      set_menu_anchor(null);
    };
    const handle_close_intro = () => {
      set_show_intro(false);
      set_menu_anchor(null);
    };
    const handle_close_snooze = () => {
      set_show_snooze(false);
      set_menu_anchor(null);
    };
    const handle_intro_requested = () => {
      set_open_snackbar(true);
      update_status({ status_id, account_id: row.account_id, status: 'Introduction Requested' });
    };
    const hide_snackbar = (reason) => {
      console.log('snackbar closed', reason);
      if (reason === 'clickaway') {
        return;
      }
      set_open_snackbar(false);
    };
    const handle_open_menu = (event) => {
      set_menu_anchor(event.currentTarget);
    };
    const handle_close_menu = () => {
      set_menu_anchor(null);
    };
    const onclick_update_why_account = () => {
      update_status({
        status_id,
        account_id: row.account_id,
        account_reasons: why_this_account({ value: row.account_kind, row }),
        was_potential_customer: row.account_kind === 'potential customer',
      });
      handle_close_menu();
    };

    return (
      <div style={{ lineHeight: '1.5rem' }}>
        {status_id ? (
          <a
            href={`https://aleph.lightning.force.com/lightning/r/Sales_Recommendation__c/${status_id}/view`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {status}
          </a>
        ) : (
          <span>{status}</span>
        )}
        <br />
        <span>{comments}</span>
        <br />
        <Button
          aria-controls="actions-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handle_open_menu}
        >
          Action
        </Button>
        <Menu
          id="actions-menu"
          anchorEl={menu_anchor}
          keepMounted
          open={Boolean(menu_anchor)}
          onClose={handle_close_menu}
        >
          <MenuItem onClick={onclick_suggest} disabled={status !== 'To Review'}>
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Suggest" />
          </MenuItem>
          <MenuItem onClick={onclick_dismiss} disabled={status !== 'To Review'}>
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Dismiss" />
          </MenuItem>
          <MenuItem onClick={onclick_intro} disabled={_.isEmpty(status_id)}>
            <ListItemIcon>
              <Group fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Create Intro" />
          </MenuItem>
          <MenuItem onClick={onclick_snooze}>
            <ListItemIcon>
              <Snooze fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Snooze" />
          </MenuItem>
          {status === 'Suggested to Portfolio' && (
            <MenuItem onClick={onclick_update_why_account}>
              <ListItemIcon>
                <Snooze fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Refresh relevancy" />
            </MenuItem>
          )}
        </Menu>
        <IntroRequestDialog
          open={show_intro && !_.isEmpty(status_id)}
          account_id={row.account_id}
          account_name={row.account}
          sales_target={sales_target}
          path={paths_text(row.paths)}
          requesting_contact_id={sales_target.Default_Contact__c}
          requesting_account_id={sales_target.Account__c}
          on_submit={handle_intro_requested}
          on_cancel={handle_close_intro}
          sales_recommendation_id={status_id}
          source="Sales Recs"
        />
        <Snackbar
          open={open_snackbar}
          autoHideDuration={10000}
          onClose={hide_snackbar}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <Alert onClose={hide_snackbar} severity={'success'}>
            {`Introduction requested to ${row.account}!`}
          </Alert>
        </Snackbar>
        <Dialog
          open={show_suggest}
          onClose={handle_close_suggest}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const comments = formJson.comments;
              update_status({
                status_id,
                account_id: row.account_id,
                status: 'Suggested to Portfolio',
                comments,
                account_reasons: why_this_account({ value: row.account_kind, row }),
                was_potential_customer: row.account_kind === 'potential customer',
              });
              handle_close_suggest();
            },
          }}
        >
          <DialogTitle>Suggest {row.account} To Portco</DialogTitle>
          <DialogContent>
            <DialogContentText>Please fill in any details / comments</DialogContentText>
            <TextField autoFocus id="name" name="comments" label="Comments" fullWidth variant="standard" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handle_close_suggest}>Cancel</Button>
            <Button type="submit">Suggest</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={show_dismiss}
          onClose={handle_close_dismiss}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const comments = formJson.comments;
              const dismiss_category = formJson.dismiss_category;
              update_status({
                status_id,
                account_id: row.account_id,
                status: 'Dismissed by Aleph',
                comments,
                dismiss_category,
              });
              handle_close_dismiss();
            },
          }}
        >
          <DialogTitle>Dismiss {row.account}</DialogTitle>
          <DialogContent>
            <DialogContentText>Please fill in any details / comments</DialogContentText>
            <TextField
              autoFocus
              id="dismiss_category"
              name="dismiss_category"
              label="Dismiss Reason"
              fullWidth
              variant="standard"
              select
            >
              <MenuItem value="Company not a good match">Company not a good match</MenuItem>
              <MenuItem value="No viable path">No viable path</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            <TextField autoFocus required id="comments" name="comments" label="Comments" fullWidth variant="standard" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handle_close_dismiss}>Cancel</Button>
            <Button type="submit">Dismiss</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={show_snooze}
          onClose={handle_close_snooze}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const snooze_until = formJson.snooze_until;
              update_status({ status_id, account_id: row.account_id, status, snooze_until });
              handle_close_snooze();
            },
          }}
        >
          <DialogTitle>Snooze {row.account}</DialogTitle>
          <DialogContent>
            <DialogContentText>Pick Date / Time</DialogContentText>
            <TextField
              autoFocus
              required
              id="name"
              name="snooze_until"
              label="Snooze until"
              type="datetime-local"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handle_close_snooze}>Cancel</Button>
            <Button type="submit">Snooze</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const action_buttons = ({ value, row }) => {
    return <StatusActionButtons value={value} row={row} />;
  };

  const why_this_account = ({ value, row }) => {
    const reasons = [];
    if (value === 'potential customer') {
      reasons.push('Marked as potential customer');
    }
    if (value.match(/^lookalike customer:/)) {
      reasons.push(`Lookalike customer: ${value.replace(/^lookalike customer:/, '')}`);
    }
    const geos = (_.get(sales_target, 'Target_Geographies__c', '') || '')
      .split(';')
      .map((x) => x.trim().toLowerCase())
      .filter((x) => x);
    const matching_geo = geos.includes((row.account_country || '').toLowerCase());
    if (matching_geo) {
      reasons.push(`Geography matches (${row.account_country})`);
    }
    if (
      row.number_of_employees > min_employees &&
      row.number_of_employees < max_employees &&
      min_employees !== 0 &&
      max_employees !== Infinity
    ) {
      reasons.push(`Company size matches (${min_employees} < ${row.number_of_employees} < ${max_employees})`);
    }
    const matching_industries = _.uniq(
      row.account_tags.filter((tag) => _.some(industries.map((i) => i.includes(tag.toLowerCase())))),
    );
    if (!_.isEmpty(matching_industries)) {
      reasons.push(`Industry matches (${matching_industries.join(', ')})`);
    }
    const description_regexps = keywords_to_regexps(sales_target.Target_Account_Keywords__c);
    const description_match = description_regexps
      .filter(([, kw_re]) => (row.description || '').match(kw_re))
      .map(([kw]) => kw);
    if (!_.isEmpty(description_match)) {
      reasons.push(`Description matches keyword ${_.uniq(description_match).join(', ')}`);
    }
    if (row.qualification_details) {
      reasons.push(row.qualification_details);
    }
    return reasons.join('\n');
  };

  const format_intros = ({ value }) => {
    return (value || [])
      .map(
        ({ Name, Status__c, CreatedDate }) =>
          `${Name} on ${CreatedDate}: ${_.get(INTRO_STATUS_API_NAME_TO_LABEL, Status__c, Status__c)}`,
      )
      .join('\n');
  };

  const render_intros = ({ value }) => (
    <div style={{ lineHeight: '1.5rem', height: '199px' }}>
      {(value || []).map(({ Id, Name, Status__c, CreatedDate }) => (
        <>
          <span>
            <a
              href={`https://aleph.lightning.force.com/lightning/r/Introduction__c/${Id}/view`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Name}
            </a>
            <br />
            {CreatedDate}:<br />
            {_.get(INTRO_STATUS_API_NAME_TO_LABEL, Status__c, Status__c)}
            <br />
          </span>
          <br />
        </>
      ))}
    </div>
  );

  const account_with_why = ({ value, row }) => (
    <pre style={{ lineHeight: '1rem', height: '199px', margin: '0px', textWrap: 'pretty' }}>
      <AccountWithLinks row={row} value={row.account} />
      <br />
      <div style={{ marginTop: '0.5rem', textWrap: 'pretty' }}>{why_this_account({ value, row })}</div>
    </pre>
  );

  const columns = [
    { field: 'status_text', headerName: 'Status', width: 200, sortable: false, renderCell: action_buttons },
    { field: 'account_kind', headerName: 'Account', width: 350, sortable: false, renderCell: account_with_why },
    { field: 'paths', headerName: 'Paths', width: 500, sortable: false, renderCell: Paths },
    { field: 'intros', headerName: 'Past Intros', width: 300, sortable: false, renderCell: render_intros },
    {
      field: 'description',
      headerName: 'Account Description',
      width: 400,
      sortable: true,
      renderCell: pre_cell(({ value }) => value),
    },
    { field: 'name', headerName: 'Contact Person', width: 200, sortable: true, renderCell: name_with_link },
    { field: 'title', headerName: 'Title', width: 200, sortable: true },
    {
      field: 'score',
      headerName: 'Probability',
      width: 150,
      sortable: true,
      valueFormatter: ({ value }) => CHANCE_TO_CONNECT[value],
    },
    {
      field: 'contact_kind',
      headerName: 'Why This Person',
      width: 400,
      sortable: true,
      valueFormatter: why_this_person,
    },
    {
      field: 'account_tags',
      headerName: 'Industries',
      width: 200,
      sortable: true,
      valueFormatter: ({ value }) => value.join(', '),
    },
    { field: 'account_li_industry', headerName: 'Industry (from Linkedin)', width: 200, sortable: true },
    { field: 'number_of_employees', headerName: 'Headcount', width: 200, sortable: true },
    { field: 'account_country', headerName: 'Geography', width: 200, sortable: true },
    {
      field: 'account_best_relationship',
      headerName: 'Best Relationship in Account',
      width: 200,
      sortable: true,
      valueFormatter: ({ value }) => CHANCE_TO_CONNECT[value],
    },
  ];

  const export_data = (data) =>
    data.map((row) => ({
      Account: row.account,
      Website: row.website,
      'Contact Person': row.name,
      'Linkedin Profile': row.linkedin_profile,
      Title: row.title,
      Probability: CHANCE_TO_CONNECT[row.score],
      'Why This Person': why_this_person({ value: row.contact_kind, row }),
      'Relationship Details': is_admin ? row.score_details : undefined,
      'Why This Account': why_this_account({ value: row.account_kind, row }),
      'Account Description': row.description,
      Tags: row.account_tags.join(', '),
      'Linkedin Industry': row.account_li_industry,
      Headcount: row.number_of_employees,
      Geography: row.account_country,
      'Company Linkedin': row.account_linkedin,
      'Best Relationship in Account': CHANCE_TO_CONNECT[row.account_best_relationship],
      'Account in SF': is_admin ? sf_account_link({ value: row.account_id }) : undefined,
      'Contact in SF': is_admin ? sf_contact_link({ value: row.id }) : undefined,
      'Past Intros': format_intros({ value: row.intros }),
    }));
  return (
    <div style={{ height: '800px', width: '100%' }}>
      {' '}
      <CsvDownloadButton data={export_data(sales_recommendations)}>Export to csv</CsvDownloadButton>
      <DataGrid
        rows={loading ? [] : sales_recommendations}
        columns={columns}
        showCellRightBorder={false}
        autoHeight={true}
        getRowId={(row) => `${row.account_id}-${row.id}-${row.cr_id}`}
        loading={loading}
        rowHeight={200}
        disableSelectionOnClick
      />
    </div>
  );
};

const SalesTarget = ({ sales_target }) => {
  return (
    <code style={{ fontSize: '0.8rem' }}>
      <div>
        <u>ICP Definition</u>
      </div>
      <div>
        <b>default contact person:</b> {_.get(sales_target, 'Default_Contact__r.Name')}
      </div>
      <div>
        <b>company size:</b> {sales_target.Target_Company_Size_Min__c} - {sales_target.Target_Company_Size_Max__c}
      </div>
      <div>
        <b>industries:</b> {sales_target.Industries__c}
      </div>
      <div>
        <b>excluded industries:</b> {sales_target.Excluded_Industries__c}
      </div>
      <div>
        <b>account types:</b> {sales_target.Target_Account_Type__c}
      </div>
      <div>
        <b>account keywords:</b> {sales_target.Target_Account_Keywords__c}
      </div>
      <div>
        <b>account anti keywords:</b> {sales_target.Target_Account_Anti_Keywords__c}
      </div>
      <div>
        <b>title keywords:</b> {sales_target.Target_Persona_Keywords__c}
      </div>
      <div>
        <b>excluded title keywords:</b> {sales_target.Target_Persona_Anti_Keywords__c}
      </div>
      <div>
        <b>target role type:</b> {sales_target.Target_Persona_Role__c}
      </div>
      <div>
        <b>geographies:</b> {sales_target.Target_Geographies__c}
      </div>
      <div>
        <b>excluded geographies:</b> {sales_target.Target_Excluded_Geographies__c}
      </div>
    </code>
  );
};

export const SalesRecommendations = () => {
  const q = useQuery().get('q');
  const history = useHistory();

  const is_admin = useAdmin();
  const api = useAPI();
  const [sales_targets, set_sales_targets] = React.useState([]);
  const [sales_target, set_sales_target] = React.useState(q || '');
  const [sales_target_details, set_sales_target_details] = React.useState({});
  const [sales_recommendations, set_sales_recommendations] = React.useState([]);
  const [statuses, set_statuses] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const filter_recs = React.useMemo(
    () => (recs, selectedTab) => {
      const not_snoozed = (rec) => !rec.status.snooze_until || new Date(rec.status.snooze_until) < new Date();
      switch (selectedTab) {
        case 0:
          return recs.filter((rec) => not_snoozed(rec) && rec.status_text === 'To Review');
        case 1:
          return recs.filter((rec) => not_snoozed(rec) && rec.status_text === 'Suggested to Portfolio');
        case 2:
          return recs.filter((rec) => not_snoozed(rec) && rec.status_text === 'Dismissed by Portfolio');
        case 3:
          return recs.filter((rec) => not_snoozed(rec) && rec.status_text === 'Introduction Requested');
        case 4:
          return recs.filter((rec) => !not_snoozed(rec));
        default:
          return recs;
      }
    },
    [],
  );

  useEffect(() => {
    const read_sales_targets = async () => {
      const sales_targets = await api('sales-targets/', { method: 'GET' });
      set_sales_targets(_.sortBy(sales_targets, 'Name'));
    };
    read_sales_targets();
  }, [api]);

  useEffect(() => {
    const read_sales_recommendations = async () => {
      set_loading(true);
      const { recommendations, intros } = await api(`sales-targets/${sales_target}/suggestions`, { method: 'GET' });
      const reqs_by_account = _.groupBy(recommendations, 'account_id');
      for (const recommendation of recommendations) {
        recommendation.intros = intros[recommendation.account_id];
        recommendation.paths = reqs_by_account[recommendation.account_id].map((row) =>
          _.pick(row, [
            'contact_owner',
            'id',
            'cr_id',
            'name',
            'title',
            'score',
            'linkedin_profile',
            'score_details',
            'contact_kind',
            'relationship_category',
            'n_hops',
            'recent_role',
            'recent_relationship_update',
          ]),
        );
      }
      const sorted_sales_recommendations = _.uniqBy(
        _.sortBy(recommendations, [
          prioritize_account,
          prioritize_contact,
          (x) => -x.score,
          prioritize_timing,
          (x) => x.account,
        ]),
        (row) => row.account,
      );
      set_sales_recommendations(sorted_sales_recommendations);
      set_loading(false);
    };
    if (sales_target) {
      read_sales_recommendations();
    }
  }, [sales_target, api]);
  useEffect(() => {
    const read_sales_target_details = async () => {
      const sales_target_details = await api(`sales-targets/${sales_target}`, { method: 'GET' });
      set_sales_target_details(sales_target_details);
    };
    if (sales_target) {
      read_sales_target_details();
    }
  }, [sales_target, api]);

  const sales_recommendations_with_statuses = React.useMemo(
    () =>
      sales_recommendations.map((rec) => {
        rec.status = _.get(statuses, rec.account_id, {
          status: 'To Review',
          comments: '',
          status_id: null,
          snooze_until: null,
        });
        rec.status_text = rec.status.status;
        return rec;
      }),
    [sales_recommendations, statuses],
  );
  const filtered_recs = React.useMemo(
    () => filter_recs(sales_recommendations_with_statuses, selectedTab),
    [sales_recommendations_with_statuses, selectedTab, filter_recs],
  );

  const read_statuses = useCallback(async () => {
    if (sales_target) {
      const statuses = await api(`sales-targets/${sales_target}/statuses`, { method: 'GET' });
      set_statuses(statuses);
    }
  }, [sales_target, api]);

  const update_status = useCallback(
    async ({
      status_id,
      account_id,
      status,
      comments,
      snooze_until,
      dismiss_category,
      account_reasons,
      was_potential_customer,
    }) => {
      const statuses = await api(`sales-targets/${sales_target}/statuses/${account_id}`, {
        method: 'POST',
        body: JSON.stringify({
          status_id,
          status,
          comments,
          snooze_until,
          dismiss_category,
          account_reasons,
          was_potential_customer,
        }),
      });
      set_statuses(statuses);
    },
    [sales_target, api],
  );

  useEffect(() => {
    read_statuses();
  }, [sales_target, api, read_statuses]);

  const set_sales_target_and_update_url = (target) => {
    set_sales_target(target);

    const newUrl = new URL(window.location);
    newUrl.searchParams.set('q', target);
    history.push(newUrl.search);
  };

  const select_sales_target = (event) => {
    set_sales_target_and_update_url(event.target.value);
  };
  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }}>
        <h1>Sales Recommendations</h1>
        <FormControl style={{ width: '40%' }}>
          <InputLabel id="select-sales-target-label"></InputLabel>
          <Select
            labelId="select-sales-target-label"
            id="select-sales-target"
            value={sales_target}
            onChange={select_sales_target}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Use Case / Target
            </MenuItem>
            {sales_targets.map(({ Id, Name, Account__c }) => (
              <MenuItem key={Id} value={Id}>
                {Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {is_admin && (
        <>
          <div style={{ width: '90%', margin: 'auto', paddingTop: '1rem', paddingBottom: '1rem' }}>
            {!_.isEmpty(sales_target_details) && <SalesTarget sales_target={sales_target_details} />}
          </div>
          <Tabs
            value={selectedTab}
            textColor="primary"
            style={{ width: '90%', margin: 'auto', marginBottom: '20px' }}
            onChange={(a, newValue) => setSelectedTab(newValue)}
            aria-label="tabs"
          >
            <Tab label={'To Review'} />
            <Tab label={'Suggested'} />
            <Tab label={'Declined'} />
            <Tab label={'Introduction Requested'} />
            <Tab label={'Snoozed'} />
          </Tabs>
          <div id="sales-recommendations" style={{ width: '90%', margin: 'auto' }}>
            <h2>{loading ? '' : `${filtered_recs.length} `}Recommendations from Aleph's Network</h2>
            <SalesRecommendationsTable
              sales_recommendations={filtered_recs}
              loading={loading}
              sales_target={sales_target_details}
              update_status={update_status}
            />
          </div>
        </>
      )}
    </>
  );
};
