import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Popper,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { LibraryBooks, LinkedIn as LIIcon, PermContactCalendar } from '@material-ui/icons';
import React from 'react';
import { withRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import aleph_svg from '../static/aleph_icon.svg';
import battery1_svg from '../static/battery-01.svg';
import battery2_svg from '../static/battery-02.svg';
import battery3_svg from '../static/battery-03.svg';
import { ReactComponent as salesOpportunitiesSvg } from '../static/sales-opportunities-icon.svg';
import salesforce_svg from '../static/salesforce.svg';
import { FeedbackWidget } from './feedback';
import { InviteWidget } from './invite';

export const SalesforceIcon = (props) => <img height="15px" src={salesforce_svg} alt="salesforce" {...props} />;

export const LinkedIn = (props) => <LIIcon style={{ color: '#0c65c2' }} {...props} />;

export const Battery1 = (props) => <img height="32px" src={battery1_svg} alt="Success probablity: Low" {...props} />;

export const Battery2 = (props) => <img height="32px" src={battery2_svg} alt="Success probablity: Medium" {...props} />;

export const Battery3 = (props) => <img height="32px" src={battery3_svg} alt="Success probablity: High" {...props} />;

export const AlephIcon = (props) => <img height="20px" src={aleph_svg} alt="Success probablity: High" {...props} />;

export const NoRelevantContacts = () => {
  return (
    <Grid container justifyContent="center" style={{ marginBottom: '1em', marginTop: '2em' }}>
      <Grid item sm={6} xs={12}>
        <Card>
          <CardContent>
            <Typography gutterBottom>Sorry - No relevant contacts found</Typography>
            <Typography variant="body2" component="p">
              Reminder - for high priority requests, you can always send an email to{' '}
              <a href="mailto:help@aleph.vc">help@aleph.vc</a> and we will do our best to find a way to connect you.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" href="mailto:help@aleph.vc">
              Request Help
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export const IntroDetails = ({ comments, handle_comments, placeholder, label, rows = 1, backgroundColor = 'none' }) => {
  return (
    <TextField
      label={label}
      placeholder={placeholder}
      multiline
      maxRows={2}
      minRows={rows}
      value={comments}
      onChange={handle_comments}
      variant="outlined"
      fullWidth
      style={{ fontStyle: 'italic', backgroundColor }}
    />
  );
};

export const ExecuteButton = ({ onClick, text, disabled }) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      startIcon={<Icon>play_arrow</Icon>}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export const ScheduleWidget = () => (
  <Button onClick={() => window.Calendly.initPopupWidget({ url: 'https://calendly.com/aleph-sales' })}>
    <PermContactCalendar />
    <Typography>Schedule a Call With Us</Typography>
  </Button>
);

const IntroDashboardLinkWidget = () => (
  <Button component={RouterLink} to="/intro/dashboard">
    <LibraryBooks />
    <Typography>My Requests</Typography>
  </Button>
);

const SalesOpportunitiesLinkWidget = () => (
  <Button component={RouterLink} to="/sales/opportunities">
    <SvgIcon component={salesOpportunitiesSvg} viewBox={'0 0 22 18'} />
    <Typography>My Opportunities</Typography>
  </Button>
);

const _WidgetsGrid = ({ location }) => {
  const pathname = location.pathname;
  const theme = useTheme();
  const xs_screen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container justifyContent="flex-end" direction="row" style={{ marginBottom: '50px', marginTop: '20px' }}>
      {!xs_screen && (
        <>
          {!pathname.includes('/talent') && (
            <>
              <Grid item lg={2} sm={3} xs={6} className="subtoolbar-link">
                <SalesOpportunitiesLinkWidget />
              </Grid>
              <Grid item lg={2} sm={3} xs={6} className="subtoolbar-link">
                <IntroDashboardLinkWidget />
              </Grid>
            </>
          )}
          <Grid item lg={2} sm={3} xs={6} className="subtoolbar-link">
            <FeedbackWidget />
          </Grid>
          <Grid item lg={2} sm={3} xs={6} className="subtoolbar-link">
            <InviteWidget />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const WidgetsGrid = withRouter(_WidgetsGrid);

function isOverflown(element) {
  element.style.whiteSpace = 'nowrap';

  const isOverflowed = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

  // Restore the original white-space value
  element.style.whiteSpace = 'pre-line';

  return isOverflowed;
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const useStyles = makeStyles(() => ({
    root: {
      alignItems: 'flex-start',
      lineHeight: '24px',
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      '& .cellValue': {
        whiteSpace: 'pre-line',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }));
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div ref={wrapper} className={classes.root} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width, marginLeft: 100 }}>
          <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
            <Typography variant="body2" style={{ padding: 8, whiteSpace: 'pre-line' }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </div>
  );
});

export const renderCellExpand = ({ value, colDef, formattedValue }) => (
  <GridCellExpand
    value={formattedValue ? formattedValue : value ? value.toString() : ''}
    width={colDef.computedWidth}
  />
);

const CSV_FILE_TYPE = 'text/csv;charset=utf-8;';

const csvGenerateRow = (row, headerKeys, delimiter) =>
  headerKeys
    .map((fieldName) =>
      typeof row[fieldName] === 'number' ? row[fieldName] : `"${String(row[fieldName]).replace(/"/g, '""')}"`,
    )
    .join(delimiter);

const csvGenerate = (data, headers, delimiter) => {
  const headerKeys = Object.keys(data[0]);
  const columnNames = headers ?? headerKeys;
  const csv = data.map((row) => csvGenerateRow(row, headerKeys, delimiter));

  csv.unshift(columnNames.join(delimiter));

  return csv.join('\r\n');
};

const csvDownload = ({ data, filename = 'export.csv', delimiter = ';', headers }) => {
  const formattedFilename = getFilename(filename);

  if (data.length === 0) {
    triggerCsvDownload(headers ? headers.join(delimiter) : '', formattedFilename);
    return;
  }

  const csvAsString = csvGenerate(data, headers, delimiter);

  triggerCsvDownload(csvAsString, formattedFilename);
};

const triggerCsvDownload = (csvAsString, fileName) => {
  // BOM support for special characters in Excel
  const byteOrderMark = '\ufeff';

  const blob = new Blob([byteOrderMark, csvAsString], {
    type: CSV_FILE_TYPE,
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getFilename = (providedFilename) => {
  return /csv$/i.test(providedFilename) ? providedFilename : `${providedFilename}.csv`;
};

export const CsvDownloadButton = (props) => {
  const { data, filename, delimiter, headers, ...others } = props;

  return (
    <button onClick={() => csvDownload({ data, filename, delimiter, headers })} {...others}>
      {props.children || 'Download Data'}
    </button>
  );
};
