import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ChevronRight, ExpandMore, Favorite, FavoriteBorder, Send, Warning } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Immutable from 'immutable';
import _ from 'lodash';
import React, { useState } from 'react';
import CsvDownloadButton from 'react-json-to-csv';
import { Link as RouterLink } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useLocalStorage } from '../misc/first_time_popup';
import { IntroRequestButton } from '../sales/intro_request_form';
import { useAdmin, useAPI, useUserState } from '../userContext';
import { clean_account_name, unaccent } from '../utils/misc';
import { IntroDetails, NoRelevantContacts } from '../widgets';
import { search_accounts_batch } from '../widgets/api';
import { Contact, ContactWithAccount, PastContact } from '../widgets/contacts';
import { AlertTooltip, BootstrapTooltip, WhiteCheckbox } from '../widgets/tooltips';
import {
  search_accounts_by_industries,
  search_contacts_by_account_list,
  search_contacts_by_title,
  send_intro_request_batch,
  subscribe_user_to_account,
  unsubscribe_user_from_account,
} from './api';
import { INTRO_STATUSES_TO_HIGH_LEVEL_STATUS } from './dashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
}));

const SubscribeToAccountButton = ({ account_id, show_tooltip }) => {
  const [enabled, set_enabled] = useState(true);
  const { userState, userStateDispatcher } = useUserState();
  const api = useAPI();

  const { subscribed_accounts } = userState;

  const subscribe_to_account = async () => {
    try {
      set_enabled(false);
      const result = await subscribe_user_to_account(account_id, api);
      userStateDispatcher({
        type: 'setAccountSubscriptionList',
        payload: {
          subscribed_accounts: result.subscribed_accounts,
        },
      });
    } finally {
      set_enabled(true);
    }
  };

  const unsubscribe_from_account = async () => {
    try {
      set_enabled(false);
      const result = await unsubscribe_user_from_account(account_id, api);
      userStateDispatcher({
        type: 'setAccountSubscriptionList',
        payload: {
          subscribed_accounts: result.subscribed_accounts,
        },
      });
    } finally {
      set_enabled(true);
    }
  };

  const show_progress = !enabled || _.isUndefined(subscribed_accounts);

  return (
    <BootstrapTooltip
      title={
        !show_progress && subscribed_accounts.has(account_id)
          ? 'click to unfollow'
          : 'get notified when strong contacts join our network'
      }
      open={show_tooltip ? true : undefined}
    >
      {show_progress ? (
        <CircularProgress size="1.5rem" />
      ) : (
        <Button
          variant="contained"
          disableElevation
          onClick={subscribed_accounts.has(account_id) ? unsubscribe_from_account : subscribe_to_account}
          color="secondary"
          edge="end"
          aria-label="follow"
          size="small"
          startIcon={subscribed_accounts.has(account_id) ? <Favorite /> : <FavoriteBorder />}
        >
          {subscribed_accounts.has(account_id) ? 'Wishlist' : 'Wishlist'}
        </Button>
      )}
    </BootstrapTooltip>
  );
};

const SingleAccountList = ({
  account,
  contacts,
  past_employees,
  intros,
  navigatable,
  start_open,
  show_wishlist_tooltip,
  on_request,
}) => {
  const [open, setOpen] = useState(start_open);
  const handleClick = () => setOpen(!open);
  const classes = useStyles();
  const theme = useTheme();
  const xs_screen = useMediaQuery(theme.breakpoints.down('xs'));
  const intros_notice = !_.isEmpty(intros) ? (
    <div>
      <span>
        <Warning style={{ verticalAlign: 'text-bottom' }} /> There are past intros to this account (please check your
        CRM before submitting new requests):
      </span>
      <ul style={{ margin: 'unset', paddingInlineStart: '1rem', lineHeight: '1.5rem' }}>
        {intros.map(({ Id, Name, Status__c, CreatedDate, LastActivityDate }) => (
          <li key={Id}>
            <RouterLink to={`/intro/dashboard?q=${account.name}`}>{Name}</RouterLink> from{' '}
            {(LastActivityDate || CreatedDate || '').slice(0, 10)} [Status:{' '}
            {INTRO_STATUSES_TO_HIGH_LEVEL_STATUS[Status__c]}]
          </li>
        ))}
      </ul>
    </div>
  ) : (
    ''
  );
  // check document.referrer to see if we came from salesforce
  const came_from_salesforce = (document.referrer || '').includes('force');
  const source = came_from_salesforce ? 'Salesforce Plugin' : 'Aleph Connect';
  return (
    <>
      <BootstrapTooltip title={open ? '' : 'Click to open'} placement="left">
        <ListItem button onClick={handleClick} key={account.id}>
          <ListItemIcon>{open ? <ExpandMore /> : <ChevronRight />}</ListItemIcon>
          <ListItemAvatar>
            <Avatar variant="rounded" alt={account.name} src={account.logo_url}>
              {account.name.slice(0, 1)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                {account.name} <Chip label={`${account.contacts.length} contacts`} color="primary" size="small" />
              </span>
            }
            secondary={
              <>
                <span style={{ display: 'block', overflow: 'scroll' }}>
                  <a href={account.website} target="_blank" rel="noopener noreferrer">
                    {account.website}
                  </a>
                </span>
                <span> (search query: {account.matches.join(' / ')})</span>
                {account.reason && (
                  <>
                    <br />
                    <p style={{ marginRight: '7rem' }}>{account.reason}</p>
                  </>
                )}
              </>
            }
          />
          {!xs_screen && (
            <ListItemSecondaryAction>
              <div style={{ width: '240px', textAlign: 'center' }}>
                <SubscribeToAccountButton account_id={account.id} show_tooltip={show_wishlist_tooltip} />{' '}
                <IntroRequestButton
                  account_id={account.id}
                  account_name={account.name}
                  on_submit={on_request}
                  source={source}
                />
              </div>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </BootstrapTooltip>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          subheader={
            contacts.length > 0 ? (
              <ListSubheader disableSticky component="div">
                Found <em>{contacts.length}</em> potential connections.
                {intros_notice}
              </ListSubheader>
            ) : null
          }
        >
          {contacts.length > 0 ? (
            contacts.map((c) => (
              <ListItem key={c.id} role={undefined} dense className={classes.nested}>
                <ListItemText id={c.id} primary={<Contact {...c} />} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText secondary="No reachable contacts found under this account" />
            </ListItem>
          )}
          {past_employees.length > 0 ? (
            <>
              <Divider component="li" />
              <li>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                >
                  Past executives at {account.name}:
                </Typography>
              </li>
              {past_employees.map((c) => (
                <ListItem key={c.id} role={undefined} dense className={classes.nested}>
                  <ListItemText id={c.id} primary={<PastContact {...c} />} />
                </ListItem>
              ))}
            </>
          ) : undefined}
        </List>
      </Collapse>
    </>
  );
};

const search_url = (q) => `https://connect.aleph.vc/#/?q=${encodeURIComponent(q)}`;

function export_contacts(accounts) {
  return _.flatten(
    accounts.map(({ id, contacts, intros, li_id, matches, name, navigators, past_employees, website }) => {
      const search_query = matches[0];
      const best_relationship = _.max(_.flatten([contacts.map((c) => c.score), past_employees.map((c) => c.score)]));
      return _.concat(
        [],
        contacts.map(({ linkedin_profile, name: contact_name, score, role_type, title }) => ({
          search_query,
          account_name: name,
          account_website: website,
          best_relationship,
          contact_type: 'current',
          contact_relationship: score,
          contact_title: title,
          contact_role: role_type,
          contact_name,
          contact_linkedin: linkedin_profile,
          search_in_connect: search_url(name),
        })),
        past_employees.map(({ linkedin_profile, name: contact_name, score, role_type, title }) => ({
          search_query,
          account_name: name,
          account_website: website,
          best_relationship,
          contact_type: 'past',
          contact_relationship: score,
          contact_title: title,
          contact_role: role_type,
          contact_name,
          contact_linkedin: linkedin_profile,
          search_in_connect: search_url(name),
        })),
      );
    }),
  );
}

export const MultiAccountContactList = ({
  contacts_count,
  accounts,
  selected_contacts,
  set_selected_contacts,
  other_contacts_per_account,
  set_other_contact_for_account,
  on_request,
}) => {
  const classes = useStyles();
  const is_admin = useAdmin();
  const toggle_contact = (cid) => () => {
    if (selected_contacts.has(cid)) {
      set_selected_contacts(selected_contacts.remove(cid));
    } else {
      set_selected_contacts(selected_contacts.add(cid));
    }
  };
  // const top_score_per_account = accounts.map(a => _.max(_.concat([0], a.contacts.map(c => parseInt(c.score, 10)))))
  const need_to_show_wishlist_toolip = false; // _.min(top_score_per_account) < 2

  return (
    <div style={{ width: '100%' }}>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            Found <em>{contacts_count}</em> potential connections. Click an account to see all contacts
            {is_admin && (
              <span>
                {' '}
                <CsvDownloadButton data={export_contacts(accounts)}>Export to csv</CsvDownloadButton>
              </span>
            )}
          </ListSubheader>
        }
        className={classes.root}
      >
        {_.sortBy(accounts, (a) => -a.relationship).map((account, idx) => (
          <SingleAccountList
            key={account.id}
            account={account}
            contacts={account.contacts}
            past_employees={account.past_employees}
            navigatable={!_.isEmpty(account.navigators)}
            intros={account.intros}
            selected_contacts={selected_contacts}
            toggle_contact={toggle_contact}
            start_open={accounts.filter((a) => a.contacts.length > 0).length < 2 && account.contacts.length > 0}
            other_contact={other_contacts_per_account.get(account.id, '')}
            set_other_contact_for_account={set_other_contact_for_account}
            show_wishlist_tooltip={need_to_show_wishlist_toolip && idx === 0}
            on_request={on_request}
          />
        ))}
      </List>
    </div>
  );
};

const FlatMultiAccountContactList = ({
  contacts_count,
  contacts,
  selected_contacts,
  set_selected_contacts,
  limit_hit,
}) => {
  const classes = useStyles();
  const toggle_contact = (cid) => () => {
    if (selected_contacts.has(cid)) {
      set_selected_contacts(selected_contacts.remove(cid));
    } else {
      set_selected_contacts(selected_contacts.add(cid));
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <List
        component="nav"
        subheader={
          <ListSubheader component="div">
            {limit_hit ? (
              'Too many results, try narrowing down your search'
            ) : (
              <>
                Found <em>{contacts_count}</em> potential connections. Click an account to see all contacts
              </>
            )}
          </ListSubheader>
        }
        className={classes.root}
      >
        {contacts.map((c) => (
          <BootstrapTooltip title={selected_contacts.has(c.id) ? '' : 'Click to select'} placement="left">
            <ListItem
              key={c.id}
              role={undefined}
              dense
              button
              onClick={toggle_contact(c.id)}
              className={classes.nested}
            >
              <ListItemIcon className="contact-checkbox">
                <Checkbox
                  edge="start"
                  checked={selected_contacts.has(c.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': c.id }}
                />
              </ListItemIcon>
              <ListItemText id={c.id} primary={<ContactWithAccount {...c} />} />
            </ListItem>
          </BootstrapTooltip>
        ))}
      </List>
    </div>
  );
};

const BatchIntroRequestButton = ({ contacts, other_contacts, comments, on_request }) => {
  const api = useAPI();
  const [status, setStatus] = useState('initial');
  const request_intro = async () => {
    setStatus('sending');
    try {
      const accounts = _.groupBy(_.concat([], contacts, other_contacts), (c) => c.matching_account_id);
      const account_id_to_name = {};
      for (const [aid, contacts] of _.toPairs(accounts)) {
        for (const c of contacts) {
          account_id_to_name[aid] = c.account;
        }
      }
      const targets = _.toPairs(accounts).map(([account_id, contacts]) => ({
        account_id,
        account_name: account_id_to_name[account_id],
        contacts,
      }));
      await send_intro_request_batch(targets, comments, api);
      setStatus('success');
      on_request();
    } catch (e) {
      console.log('ERROR', e);
      setStatus('error');
      on_request(e);
    }
  };
  return (
    <Button
      onClick={request_intro}
      disabled={status !== 'initial' || _.isEmpty(comments) || (_.isEmpty(contacts) && _.isEmpty(other_contacts))}
      size="large"
      color="primary"
      variant="contained"
      style={{ width: '100%' }}
      startIcon={<Send />}
    >
      <Typography noWrap>{status === 'initial' ? 'Ask Aleph to Intro' : 'Request Sent'}</Typography>
    </Button>
  );
};

export const IntroRequestArea = ({ selected_contacts_list, selected_other_contacts_list, on_request }) => {
  const [comments, set_comments] = useState('');
  const handle_comments = (e) => set_comments(e.target.value);
  const [tooltip_never_open, set_tooltip_never_open] = useLocalStorage('aleph-connect-reqarea-hide-tooltip', false);
  const [tooltip_open, set_tooltip_open] = useState(tooltip_never_open ? false : true);
  const intro_area_open = selected_other_contacts_list.length + selected_contacts_list.length > 0;
  const close_tooltip = () => set_tooltip_open(false);
  const handle_never_show_toggle = (e) => {
    set_tooltip_never_open(e.target.checked);
  };
  const theme = useTheme();
  const xs_screen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = _.concat(
    [],
    ['intro-req-area'],
    intro_area_open ? ['intro-req-area-active'] : [],
    xs_screen ? ['xs-size'] : [],
  );

  return (
    <Grid container justifyContent="center" className={classes.join(' ')}>
      <AlertTooltip
        title={
          <>
            <Alert
              style={{ alignItems: 'center' }}
              severity="info"
              action={
                <Button style={{ border: '1px solid white' }} color="inherit" size="small" onClick={close_tooltip}>
                  Got it!
                </Button>
              }
            >
              Don’t worry, we will talk to you directly before reaching out to prospects
            </Alert>
            <FormControlLabel
              style={{ height: '0', marginLeft: '7px', marginBottom: '15px', marginTop: '5px' }}
              control={<WhiteCheckbox size="small" checked={tooltip_never_open} onChange={handle_never_show_toggle} />}
              label=<span style={{ fontSize: '0.8em' }}>{"Don't show this again"}</span>
            />
          </>
        }
        open={tooltip_open}
        PopperProps={{ disablePortal: true }}
      >
        <Grid container spacing={3} justifyContent="center" alignItems="center" className="intro-req-area-inner">
          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Avatar
              className="counter-avatar"
              key={`count-${selected_other_contacts_list.length + selected_contacts_list.length}`}
              alt={`${selected_other_contacts_list.length + selected_contacts_list.length}`}
            >
              {selected_other_contacts_list.length + selected_contacts_list.length}
            </Avatar>
          </Grid>
          <Grid item md={6} sm={9} xs={9}>
            <IntroDetails
              comments={comments}
              handle_comments={handle_comments}
              placeholder={'I want to connect with them to...'}
              label={'Give us context to understand the intro request'}
            />
          </Grid>
          <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
            <BatchIntroRequestButton
              on_request={on_request}
              contacts={selected_contacts_list}
              other_contacts={selected_other_contacts_list}
              comments={comments}
            />
          </Grid>
        </Grid>
      </AlertTooltip>
    </Grid>
  );
};
export const BatchContactFinder = ({ unmatchedAccounts, matchedAccounts, exactMatch, on_request }) => {
  const api = useAPI();
  const [loading, setLoading] = useState(false);
  const [contacts_count, setContactsCount] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [not_found, setNotFound] = useState([]);
  const [selected_contacts, set_selected_contacts] = useState(Immutable.Set([]));
  const [other_contacts_per_account, set_other_contacts_per_account] = useState(Immutable.Map());

  const set_other_contact_for_account = (account_id, contact_name) => {
    set_other_contacts_per_account(other_contacts_per_account.set(account_id, contact_name));
  };

  useDeepCompareEffect(() => {
    const find_contacts = async () => {
      setLoading(true);
      try {
        const accounts_to_search = unmatchedAccounts;
        const all_account_names = _.concat(
          accounts_to_search,
          matchedAccounts.map((x) => x.name),
        );
        const search_results = await search_accounts_batch(all_account_names, api);
        const all_accounts = exactMatch
          ? search_results.filter((a) => all_account_names.includes(a.name))
          : search_results;
        // _.cloneDeep(_.concat(matchedAccounts, search_results))
        const { contacts, navigators, past_employees, intros } = await search_contacts_by_account_list(
          all_accounts.map((a) => a.id),
          api,
        );
        for (const a of all_accounts) {
          a.matches = all_account_names.filter(
            (q) =>
              !_.isNull(
                unaccent(clean_account_name(a.name)).match(RegExp(`\\b${unaccent(clean_account_name(q))}\\b`, 'i')),
              ),
          );
          a.contacts = contacts.filter((c) => c.score > 0 && c.account === a.name);
          a.past_employees = past_employees.filter((c) => c.account === a.name);
          a.navigators = navigators.filter((c) => c.account === a.name);
          a.intros = intros.filter((i) => i.Target_Account__c === a.id);
        }
        const found = new Set(_.flatten(all_accounts.map((a) => a.matches)));
        const not_found = accounts_to_search.filter((q) => !found.has(q));
        setContactsCount(contacts.filter((r) => r.score > 0).length);
        setAccounts(all_accounts);
        setNotFound(not_found);
      } finally {
        setLoading(false);
      }
    };
    set_selected_contacts(Immutable.Set([]));
    find_contacts();
  }, [unmatchedAccounts, matchedAccounts]);

  return loading ? (
    <Typography>Searching for connections...</Typography>
  ) : accounts.length > 0 ? (
    <>
      {not_found.length > 0 && (
        <Grid container justifyContent="center">
          <Grid item sm={6} xs={12}>
            <Typography>
              The following company names were not found (try another spelling maybe?): <em>{not_found.join(', ')}</em>
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center" className="contact-list-area">
        <Grid item md={8} sm={9} xs={12}>
          <MultiAccountContactList
            {...{
              contacts_count,
              accounts,
              selected_contacts,
              set_selected_contacts,
              other_contacts_per_account,
              set_other_contact_for_account,
              on_request,
            }}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <NoRelevantContacts />
  );
};

export const ContactFinderByTitle = ({
  titleLike,
  titleNotLike,
  useTitleNotLike,
  headCountFrom,
  headCountTo,
  on_request,
}) => {
  const api = useAPI();
  const [loading, setLoading] = useState(false);
  const [contacts_count, setContactsCount] = useState(0);
  const [selected_contacts, set_selected_contacts] = useState(Immutable.Set([]));
  const [contacts, setContacts] = useState([]);
  const [limit_hit, set_limit_hit] = useState(false);

  useDeepCompareEffect(() => {
    const find_contacts = async () => {
      setLoading(true);
      try {
        const { contacts, limit_hit } = await search_contacts_by_title(
          { titleLike, titleNotLike: useTitleNotLike ? titleNotLike : [], headCountFrom, headCountTo },
          api,
        );
        /*
                const parsedResults = results.map(e=> ({
                    ...e,
                    linkedin_profile:`https://www.linkedin.com/in/${e.public_identifier}`
                }))
                */
        setContactsCount(contacts.length);
        setContacts(contacts);
        set_limit_hit(limit_hit);
      } finally {
        setLoading(false);
      }
    };
    set_selected_contacts(Immutable.Set([]));
    find_contacts();
  }, [titleLike, titleNotLike, useTitleNotLike, headCountFrom, headCountTo]);

  const selected_contacts_list = contacts
    .filter((c) => selected_contacts.has(c.id))
    .map((c) => ({ matching_account_id: c.account_id, ...c }));

  return loading ? (
    <Typography>Searching for connections...</Typography>
  ) : contacts_count > 0 ? (
    <>
      <Grid container justifyContent="center" className="contact-list-area">
        <Grid item md={6} sm={9} xs={12}>
          <FlatMultiAccountContactList
            {...{ contacts, contacts_count, selected_contacts, set_selected_contacts, limit_hit }}
          />
        </Grid>
      </Grid>
      <IntroRequestArea
        selected_contacts_list={selected_contacts_list}
        selected_other_contacts_list={[]}
        on_request={on_request}
      />
    </>
  ) : (
    <NoRelevantContacts />
  );
};

export const AccountFinderByIndustries = ({ industries, headCountFrom, headCountTo, on_request }) => {
  const api = useAPI();
  const [loading, setLoading] = useState(false);
  const [contacts_count, setContactsCount] = useState(0);
  const [selected_contacts, set_selected_contacts] = useState(Immutable.Set([]));
  const [accounts, setAccounts] = useState([]);
  const [other_contacts_per_account, set_other_contacts_per_account] = useState(Immutable.Map());
  const set_other_contact_for_account = (account_id, contact_name) => {
    set_other_contacts_per_account(other_contacts_per_account.set(account_id, contact_name));
  };

  useDeepCompareEffect(() => {
    const find_contacts = async () => {
      setLoading(true);
      try {
        const all_accounts = await search_accounts_by_industries({ industries, headCountFrom, headCountTo }, api);
        const { contacts, navigators, past_employees, intros } = await search_contacts_by_account_list(
          all_accounts.map((a) => a.id),
          api,
        );
        for (const a of all_accounts) {
          // TODO: indicate which industry matched here
          a.matches = industries.filter((q) => a.tags.includes(q));
          a.contacts = contacts.filter((c) => c.score > 0 && c.account === a.name);
          a.past_employees = past_employees.filter((c) => c.account === a.name);
          a.navigators = navigators.filter((c) => c.account === a.name);
          a.intros = intros.filter((i) => i.Target_Account__c === a.id);
        }
        setContactsCount(contacts.filter((r) => r.score > 0).length);
        setAccounts(all_accounts);
      } finally {
        setLoading(false);
      }
    };
    set_selected_contacts(Immutable.Set([]));
    find_contacts();
  }, [industries, headCountFrom, headCountTo]);

  return loading ? (
    <Typography>Searching for connections...</Typography>
  ) : accounts.length > 0 ? (
    <>
      <Grid container justifyContent="center" className="contact-list-area">
        <Grid item md={8} sm={9} xs={12}>
          <MultiAccountContactList
            {...{
              contacts_count,
              accounts,
              selected_contacts,
              set_selected_contacts,
              other_contacts_per_account,
              set_other_contact_for_account,
              on_request,
            }}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <NoRelevantContacts />
  );
};
