import {
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { LinkedIn } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { is_admin, useAPI } from '../userContext';
import { CreateSignal } from '../utils/contacts';
import { getPeriodText } from '../utils/dates';
import { get_related_targets } from './api';

const RelatedTarget = ({ rt, contact, userState }) => {
  const companyText = _.get(rt, 'Account_Name__c');
  const sharedWorkText = `${getPeriodText(_.get(rt, 'overlappingMonths'))} Since ${_.get(rt, 'startYearTogether')}`;
  const currentAccountText = `${_.get(rt, 'Title')} @ ${_.get(rt, 'Currrent_account_of_contact_role__c')}`;
  return (
    <TableRow key={rt.Id}>
      <TableCell>{rt.poscan_account}</TableCell>
      <TableCell>{rt.Position_Title__c}</TableCell>
      <TableCell>{rt.Status__c}</TableCell>
      <TableCell>
        <div style={{ marginBottom: '8px' }}>
          <Link
            href={_.get(rt, 'Candidate_LinkedIn__c') + '?ampliphy_update=true'}
            target="_blank"
            style={{ marginRight: '4px' }}
          >
            <LinkedIn fontSize="small" />
          </Link>
          {_.get(rt, 'Contact_Name__c')}
        </div>
        <CreateSignal
          Id={_.get(rt, 'Candidate__c')}
          FirstName={_.get(rt, 'Candidate_First_Name__c')}
          byContact={contact}
        />
      </TableCell>
      <TableCell>{sharedWorkText}</TableCell>
      <TableCell>{companyText}</TableCell>
      <TableCell>{currentAccountText}</TableCell>
      <TableCell>{rt.Comments__c}</TableCell>
      <TableCell align={'center'}>{moment(rt.CreatedDate).format('DD MMM YYYY')}</TableCell>
    </TableRow>
  );
};

export const ConnectorRelatedTargets = ({}) => {
  const api = useAPI();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [relatedTargets, setRelated] = useState([]);
  const [contact, setContact] = useState();
  const [loadingRelated, setLoading] = useState(true);
  const linkedinIdFromUrl = urlParams.get('linkedin_id');
  useEffect(() => {
    async function fetch() {
      const { contact: resContact, related_targets } = await get_related_targets(
        { public_identifier: linkedinIdFromUrl },
        api,
      );
      setContact(resContact);
      setRelated(related_targets);
      setLoading(false);
    }
    fetch();
  }, [api, linkedinIdFromUrl]);

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Typography variant={'body1'}>
          {_.get(contact, 'name')} {!!_.get(contact, 'headline') && ' - ' + _.get(contact, 'headline')}
        </Typography>
      </div>
      <div style={{ textAlign: 'center' }}>
        {loadingRelated && _.isEmpty(relatedTargets) && <CircularProgress color="secondary" />}
        {!loadingRelated && _.isEmpty(relatedTargets) && <span>No potential targets found</span>}
        {!_.isEmpty(relatedTargets) && (
          <TableContainer component={Paper} style={{ width: '90%', margin: 'auto' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Time Together</TableCell>
                  <TableCell>Shared Account</TableCell>
                  <TableCell>Current Account</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell align={'center'}>Date Submitted</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relatedTargets.map((rt) => (
                  <RelatedTarget rt={rt} contact={contact} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
};
