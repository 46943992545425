import moment from 'moment';

export function getPeriodText(totalMonths, short = false) {
  if (totalMonths < 12) {
    return totalMonths + ' months';
  } else {
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths - years * 12;
    const yearsText = short ? 'yrs' : 'years';
    const yearText = short ? 'yr' : 'year';
    const monthsText = short ? 'mos' : 'months';
    const monthText = short ? 'mo' : 'month';
    return `${years} ${years > 1 ? yearsText : yearText} ${months > 0 ? `and ${months} ${months > 1 ? monthsText : monthText}` : ''}`;
  }
}

export function getDateRangeText({ Start_Date__c, End_Date__c }) {
  const startDate = Start_Date__c ? moment(Start_Date__c).format('MMM YYYY') : '';
  const endDate = End_Date__c ? moment(End_Date__c).format('MMM YYYY') : 'Present';
  return `${startDate} - ${endDate}`;
}
