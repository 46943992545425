import { createTheme, CssBaseline, Typography, useMediaQuery } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAPI } from '../userContext';
import { get_aleph_connections } from './api';

export const DefpodLinkedin = ({}) => {
  const api = useAPI();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const public_identifier = urlParams.get('linkedin_id');
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (!public_identifier) {
      console.error('No public identifier');
      setLoading(false);
      return;
    }
    const fetchResults = async () => {
      try {
        setLoading(true);
        const res = await get_aleph_connections({ public_identifier }, api);
        setConnections(res.connections);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    await fetchResults();
  }, [public_identifier, api]);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading ? (
        <Typography>Searching for connections...</Typography>
      ) : (
        <Typography variant={'subtitle1'} color={'textSecondary'}>
          Connections To Aleph: {connections.map((c) => c.Name).join(', ')}
        </Typography>
      )}
    </ThemeProvider>
  );
};
