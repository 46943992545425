import { Button, Fade, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { ArrowDropUp, HighlightOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import Immutable from 'immutable';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { useAPI } from '../userContext';
import { create_poscans_tamuz_app } from './api';

const PortcoArrow = () => (
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.21213 3.21213C8.32929 3.09497 8.32929 2.90502 8.21213 2.78787L6.30294 0.878679C6.18579 0.761522 5.99584 0.761522 5.87868 0.878679C5.76152 0.995836 5.76152 1.18579 5.87868 1.30294L7.57574 3L5.87868 4.69706C5.76152 4.81421 5.76152 5.00416 5.87868 5.12132C5.99584 5.23848 6.18579 5.23848 6.30294 5.12132L8.21213 3.21213ZM2.62268e-08 3.3L8 3.3L8 2.7L-2.62268e-08 2.7L2.62268e-08 3.3Z"
      fill="black"
    />
  </svg>
);

const CandidateArrow = () => (
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.787868 2.78787C0.670711 2.90502 0.670711 3.09497 0.787868 3.21213L2.69706 5.12132C2.81421 5.23848 3.00416 5.23848 3.12132 5.12132C3.23848 5.00416 3.23848 4.81421 3.12132 4.69706L1.42426 3L3.12132 1.30294C3.23848 1.18579 3.23848 0.995836 3.12132 0.878679C3.00416 0.761522 2.81421 0.761522 2.69706 0.878679L0.787868 2.78787ZM9 2.7L1 2.7L1 3.3L9 3.3L9 2.7Z"
      fill="black"
    />
  </svg>
);

const IntroArrow = () => (
  <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2121 3.21213C12.3293 3.09497 12.3293 2.90503 12.2121 2.78787L10.3029 0.87868C10.1858 0.761522 9.99584 0.761522 9.87868 0.87868C9.76152 0.995837 9.76152 1.18579 9.87868 1.30294L11.5757 3L9.87868 4.69706C9.76152 4.81421 9.76152 5.00416 9.87868 5.12132C9.99584 5.23848 10.1858 5.23848 10.3029 5.12132L12.2121 3.21213ZM0.787868 2.78787C0.670711 2.90503 0.670711 3.09497 0.787868 3.21213L2.69706 5.12132C2.81421 5.23848 3.00416 5.23848 3.12132 5.12132C3.23848 5.00416 3.23848 4.81421 3.12132 4.69706L1.42426 3L3.12132 1.30294C3.23848 1.18579 3.23848 0.995837 3.12132 0.87868C3.00416 0.761522 2.81421 0.761522 2.69706 0.87868L0.787868 2.78787ZM12 2.7L1 2.7V3.3L12 3.3V2.7Z"
      fill="black"
    />
  </svg>
);

const getStatusByAsk = ({ status, logOnly }) => {
  if (logOnly || status === 'Applicant to Review') {
    return status;
  } else {
    switch (status) {
      case 'Sent to candidate':
        return 'To send to candidate';
      case 'Introduced':
        return 'To be introduced';
      case 'Sent to account':
        return 'To send to account';
      default:
        return status;
    }
  }
};

const StyledMenuItem = styled((props) => <MenuItem {...props} />)(({ theme }) => ({
  '& .MuiListItem-root': {
    padding: '5px 12px 5px 12px',
    display: 'block',
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={8}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginBottom: '80px',
    minWidth: '30%',
  },
}));

const CartItem = ({
  poscan,
  candidate,
  poscanShoppingCartSet,
  setPoscanShoppingCartSet,
  setLoggedPoscansSet,
  loggedPoscansSet,
}) => {
  const [isLogged, setIslogged] = useState(loggedPoscansSet.has(poscan));
  const removeFromCart = () => setPoscanShoppingCartSet(poscanShoppingCartSet.remove(poscan));
  const handleLoggingOnly = () => {
    setLoggedPoscansSet(loggedPoscansSet.add(poscan));
    setIslogged(true);
  };

  const LOGGABLE_STATUSES = ['Sent to account', 'Sent to candidate', 'Introduced'];
  const getStatusText = () =>
    _.get(poscan, 'Status__c') === 'Sent to account' ? (
      <PortcoArrow />
    ) : _.get(poscan, 'Status__c') === 'Sent to candidate' ? (
      <CandidateArrow />
    ) : _.get(poscan, 'Status__c') === 'Introduced' ? (
      <IntroArrow />
    ) : _.get(poscan, 'Status__c') === 'Introduced' ? (
      <IntroArrow />
    ) : _.get(poscan, 'Status__c') === 'Talpod found irrelevant' ? (
      '(Irrelevant for)'
    ) : (
      '(Talpod To Review) for'
    );

  return (
    <StyledMenuItem>
      <div style={{ width: '100%' }}>
        <div>
          {_.get(candidate, 'FirstName')} {getStatusText()} {_.get(poscan, 'position.Account_Name__c')}
          <span style={{ float: 'right' }}>
            {LOGGABLE_STATUSES.includes(_.get(poscan, 'Status__c')) && (
              <Button
                onClick={handleLoggingOnly}
                color={'primary'}
                variant={'contained'}
                style={{ borderRadius: '4px', marginRight: '10px' }}
                size={'small'}
                disabled={isLogged}
              >
                Log
              </Button>
            )}
            <HighlightOff onClick={removeFromCart} />
          </span>
        </div>
        <div style={{ fontSize: '13px', marginTop: '12px' }}>{_.get(poscan, 'position.Name')}</div>
      </div>
    </StyledMenuItem>
  );
};

export const PoscanCart = ({
  handleSendAll,
  candidate,
  poscanShoppingCart,
  poscanShoppingCartSet,
  setPoscanShoppingCartSet,
}) => {
  const api = useAPI();
  const [loggedPoscansSet, setLoggedPoscansSet] = useState(Immutable.Set([]));
  const [loggedPoscans, setLoggedPoscans] = useState([]);

  const [showCart, setShowCart] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [creatingPoscans, setCreatingPoscans] = useState(false);
  const toggleShowCart = (event) => {
    setShowCart(!showCart);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setShowCart(false);
  };

  useEffect(() => {
    setLoggedPoscans(loggedPoscansSet.toArray());
  }, [loggedPoscansSet]);

  const sendAllClicked = async () => {
    setCreatingPoscans(true);
    const toSend = poscanShoppingCart.map((p) => ({
      Status__c: getStatusByAsk({ status: p.Status__c, logOnly: _.includes(loggedPoscans, p) }),
      Date_Suggested_to_Account__c: p.Status__c === 'Sent to account' ? new Date() : undefined,
      Date_Suggested_to_Candidate__c: p.Status__c === 'Sent to candidate' ? new Date() : undefined,
      Intro_Date__c: p.Status__c === 'Introduced' ? new Date() : undefined,
      ..._.omit(p, ['position', 'Status__c']),
    }));
    await create_poscans_tamuz_app({ poscans: toSend }, api);
    setPoscanShoppingCartSet(Immutable.Set([]));
    setLoggedPoscansSet(Immutable.Set([]));
    setCreatingPoscans(false);
    setShowSnackBar(true);
    handleSendAll();
  };

  const hasProblematicPoscan = () =>
    _.find(
      poscanShoppingCart,
      ({ Status__c }) =>
        _.includes(['Sent to candidate', 'Introduced'], Status__c) && _.isEmpty(_.get(candidate, 'Secondary_Email__c')),
    );

  const disableButton = () => creatingPoscans || hasProblematicPoscan();
  return (
    <div>
      <Snackbar
        open={showSnackBar && !_.isEmpty(poscanShoppingCart)}
        autoHideDuration={10000}
        onClose={() => setShowSnackBar(false)}
      >
        <Alert onClose={() => setShowSnackBar(false)} severity={'success'}>
          Created {poscanShoppingCart.length} position candidate{poscanShoppingCart.length > 1 && 's'}. Check out the
          'Reviewed' Tab
        </Alert>
      </Snackbar>
      <Snackbar open={hasProblematicPoscan()}>
        <Alert severity={'error'}>Please update email address</Alert>
      </Snackbar>

      {!_.isEmpty(poscanShoppingCart) && (
        <div className={'poscan-shopping-cart-bottom'} style={{ paddingBottom: '5px !important' }}>
          <div className={'poscan-shopping-cart-bottom-inner'}>
            <span>
              {poscanShoppingCart.length - loggedPoscans.length > 0 && (
                <span>
                  {' '}
                  {poscanShoppingCart.length - loggedPoscans.length} draft
                  {poscanShoppingCart.length - loggedPoscans.length > 1 ? 's' : ''}{' '}
                </span>
              )}
              {poscanShoppingCart.length - loggedPoscans.length > 0 && loggedPoscans.length > 0 && <span> & </span>}
              {loggedPoscans.length > 0 && (
                <span>
                  {' '}
                  {loggedPoscans.length} logged position candidate{loggedPoscans.length > 1 ? 's' : ''}{' '}
                </span>
              )}
            </span>
            <div style={{ height: '31px' }}>
              <span style={{ backgroundColor: 'white', borderRadius: '5px 5px 5px 5px', padding: '8px 16px 8px 16px' }}>
                <Button
                  id="show-cart-button"
                  aria-controls="poscan-menu"
                  aria-haspopup="true"
                  aria-expanded={showCart ? 'true' : undefined}
                  variant="text"
                  disableElevation
                  onClick={toggleShowCart}
                  endIcon={<ArrowDropUp />}
                >
                  Review
                </Button>
                <StyledMenu
                  id="poscan-cart-menu"
                  aria-labelledby="show-cart-button"
                  anchorEl={anchorEl}
                  open={showCart}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {poscanShoppingCart.map((poscan) => (
                    <CartItem
                      key={'cart-item-' + _.get(poscan, 'Position__c')}
                      poscan={poscan}
                      candidate={candidate}
                      poscanShoppingCartSet={poscanShoppingCartSet}
                      setPoscanShoppingCartSet={setPoscanShoppingCartSet}
                      loggedPoscansSet={loggedPoscansSet}
                      setLoggedPoscansSet={setLoggedPoscansSet}
                      style={{ marginBottom: '5px' }}
                    />
                  ))}
                </StyledMenu>
              </span>
              <span style={{ marginLeft: '5px' }}>
                <>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    style={{ borderRadius: '5px 5px 5px 5px' }}
                    onClick={sendAllClicked}
                    disabled={disableButton()}
                  >
                    Send all
                  </Button>
                  {<Alert severity="error">Please add an email address</Alert>}
                </>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
