import { Collapse, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export const FirstTimePopup = () => {
  const [never_open, set_never_open] = useLocalStorage('aleph-connect-hide-intro', false);
  const [open, setOpen] = useState(never_open ? false : true);
  const handleClose = () => {
    setOpen(false);
    set_never_open(true);
  };
  return (
    <Grid item xs={12} sm={6}>
      <Collapse in={open}>
        <Alert severity="info" onClose={handleClose} style={{ marginBottom: '3.2rem' }}>
          Welcome to Aleph Connect, a networking tool for our portfolio companies.
          <br />
          Type in a company name and choose relevant network connections from the results.
          <br />
          We will do our best to facilitate an introduction.
        </Alert>
      </Collapse>
    </Grid>
  );
};

export const FirstTimeTitleSearchPopup = () => {
  const [never_open, set_never_open] = useLocalStorage('aleph-connect-hide-title-intro', false);
  const [open, setOpen] = useState(never_open ? false : true);
  const handleClose = () => {
    setOpen(false);
    set_never_open(true);
  };
  return (
    <Grid item xs={12} sm={6}>
      <Collapse in={open}>
        <Alert severity="info" onClose={handleClose} style={{ marginBottom: '3.2rem' }}>
          Welcome to Aleph Connect, a networking tool for our portfolio companies.
          <br />
          Type in job title keywords to search for, and adjust the slider for company sizes.
          <br />
          We will do our best to facilitate an introduction for relevant prospects.
        </Alert>
      </Collapse>
    </Grid>
  );
};
