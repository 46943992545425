import { TextField, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

export const ModeSelector = ({ options, handleSelected, value }) => {
  const theme = useTheme();
  const small_screen = useMediaQuery(theme.breakpoints.down('sm'));
  const xsmall_screen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Autocomplete
      className="searchbox"
      style={{ width: '20%', display: 'inline-flex', borderRight: '1px solid rgba(0,0,0,0.32)' }}
      options={options}
      renderOption={(option) => (
        <Typography noWrap style={{ fontSize: xsmall_screen ? '10px' : undefined }}>
          {small_screen ? option.split(' ')[1] : option}
        </Typography>
      )}
      value={value}
      autoComplete
      disableClearable
      openOnFocus={false}
      onChange={handleSelected}
      selectOnFocus={false}
      disableCloseOnSelect
      disablePortal
      blurOnSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<em>Search for</em>}
          style={{ width: '100%' }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            style: { textOverflow: 'clip', fontSize: xsmall_screen ? '8px' : small_screen ? '0.8rem' : undefined },
          }}
          multiline
          lines={1}
        />
      )}
    />
  );
};
