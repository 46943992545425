import { Link, Modal, Paper, Snackbar, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import vSvg from '../../static/focus/v.svg';
import { getUserAccountName, is_bar, useAPI, useUserState } from '../../userContext';
import { mark_relevance } from './api';
import { getReachoutId } from './utils';

import './tamnuni-button.css';

import { Close } from '@material-ui/icons';

import warningSVG from '../../static/focus/warning_green.svg';
import { getUserFirstName } from '../../userContext';
import { addQueryParamIfNeeded } from '../../utils/misc';
import { SendTamnuniTooltip } from '../../widgets/tooltips';
import { read_slack_user_info, submit_slack_member_id } from './api';

const SendTamnuniButton = ({
  children,
  isPrimary,
  hover,
  setHover,
  buttonClass,
  disabled,
  onClickHandler,
  sendingTamnuni,
  ...rest
}) => {
  return (
    <Button
      onClick={onClickHandler}
      disabled={disabled}
      className={`tamnuniButton ${buttonClass}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        textTransform: 'none',
        paddingLeft: '14px',
        paddingTop: '5px',
        paddingRight: '14px',
        minWidth: '105px',
        height: '32px',
        borderRadius: '22px',
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '15px',
        borderWidth: '1px',
        fontStyle: 'normal',
        color: isPrimary ? '#EDFFFD' : !isPrimary && disabled ? 'rgba(0, 0, 0, 0.25)' : '#00A394',
        borderColor: isPrimary ? 'transparent' : '#00A394',
        backgroundColor:
          hover && isPrimary && !sendingTamnuni
            ? '#00887B'
            : isPrimary
              ? '#00A394'
              : hover
                ? 'rgba(0,163,148,0.12)'
                : 'transparent',
        border: disabled ? '1px solid rgba(0, 0, 0, 0.2)' : hover ? '1.06739px solid #00A394' : '1px solid #00A394',
        marginRight: '8px',
        lineHeight: '18px',
        opacity: disabled ? 0.5 : 1,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

const SendTamnuniButtonWrapper = ({
  text,
  isPrimary,
  handler,
  undoHandler,
  hasActiveReachouts,
  sendingTamnuni,
  disabled = false,
  ...rest
}) => {
  const [hover, setHover] = useState(false);

  const buttonClass = sendingTamnuni ? (isPrimary ? 'tamnuniButton-animate-primary' : 'tamnuniButton-animate') : '';

  return (
    <>
      {disabled ? (
        <SendTamnuniTooltip
          title={hasActiveReachouts ? 'Active reachout in process' : 'Missing a Slack member ID'}
          placement={'top'}
        >
          <span>
            <SendTamnuniButton
              isPrimary={isPrimary}
              hover={hover}
              setHover={setHover}
              buttonClass={buttonClass}
              disabled={disabled}
              onClickHandler={sendingTamnuni ? undoHandler : handler}
              sendingTamnuni={sendingTamnuni}
              {...rest}
            >
              {sendingTamnuni ? 'Undo' : text}
            </SendTamnuniButton>
          </span>
        </SendTamnuniTooltip>
      ) : (
        <SendTamnuniButton
          isPrimary={isPrimary}
          hover={hover}
          setHover={setHover}
          disabled={disabled}
          buttonClass={buttonClass}
          onClickHandler={sendingTamnuni ? undoHandler : handler}
          sendingTamnuni={sendingTamnuni}
          {...rest}
        >
          {sendingTamnuni ? 'Undo' : text}
        </SendTamnuniButton>
      )}
    </>
  );
};

const isValidSlackId = (id) => {
  const pattern = /^[UW][A-Z0-9]{7,11}$/i;
  return pattern.test(id);
};

const ModalSubmitButton = ({ handleSubmit, disabled }) => {
  return (
    <Button
      variant="outlined"
      style={{
        textTransform: 'none',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        color: disabled ? 'rgba(0, 0, 0, 0.25)' : 'white',
        backgroundColor: disabled ? 'transparent' : '#00A394',
        border: disabled ? '1px solid rgba(0, 0, 0, 0.2)' : '1px solid #00A394',
        borderRadius: '18px',
        marginRight: '8px',
        height: '30px',
        fontSize: '15px',
        lineHeight: '18px',
        padding: '3px 10px 3px 10px',
        width: '100px',
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={handleSubmit}
      disabled={disabled}
    >
      Submit
    </Button>
  );
};

export const SlackIdModal = ({
  showSlackIdModal,
  setShowSlackIdModal,
  submitSlackMemberId,
  setSlackMemberId,
  connector,
  readSlackMemberInfo,
  slackMemberId,
}) => {
  const handleClose = () => setShowSlackIdModal(false);
  const [tempSlackMemberId, setTempSlackMemberId] = useState('');
  const [slackUserInfo, setSlackUserInfo] = useState();
  const [error, setError] = useState('');
  const [gettingSlackMemberInfo, setGettingSlackMemberInfo] = useState(false);

  const handleSubmit = () => {
    setSlackMemberId(tempSlackMemberId);
    return submitSlackMemberId(tempSlackMemberId);
  };
  useEffect(() => {
    if (tempSlackMemberId && isValidSlackId(tempSlackMemberId)) {
      setGettingSlackMemberInfo(true);
      readSlackMemberInfo(tempSlackMemberId)
        .then(({ info }) => {
          setSlackUserInfo(info);
          setGettingSlackMemberInfo(false);
          setError(''); // Clear any previous error
        })
        .catch((e) => {
          setError(e.message || 'Failed to fetch user info');
        });
    } else if (!tempSlackMemberId) {
      setError(''); // Clear any previous error
      setSlackUserInfo(undefined);
    } else if (tempSlackMemberId && !isValidSlackId(tempSlackMemberId)) {
      setError('Invalid Member ID');
    }
  }, [tempSlackMemberId]);

  const exactMatch = _.get(connector, 'connector_name') === _.get(slackUserInfo, 'profile.display_name_normalized');

  return (
    <Modal open={showSlackIdModal} onClose={handleClose}>
      <div style={{ outline: 'none' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Paper style={{ padding: 16, borderRadius: '22px', border: 'none', width: '500px', height: '385px' }}>
            <div style={{ textAlign: 'right', marginRight: '12px 12px', color: '#00A394' }}>
              <Close onClick={handleClose} />
            </div>
            <div style={{ marginLeft: '32px', marginBottom: '32px', marginRight: '32px', marginTop: '2px' }}>
              <div
                style={{
                  marginBottom: '18px',
                  fontFamily: 'Merriweather Sans',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  textAlign: 'left',
                }}
              >
                Add a Slack member ID for{' '}
                <Link href={'https://www.linkedin.com/in/' + _.get(connector, 'connector_public_id')} target="_blank">
                  {_.get(connector, 'connector_name')}
                </Link>
              </div>
              <TextField
                value={tempSlackMemberId}
                onChange={(e) => {
                  setTempSlackMemberId(_.toUpper(e.target.value));
                }}
                type={'text'}
                style={{
                  width: '100%',
                  backgroundColor: error
                    ? 'rgba(255, 0, 0, 0.03)'
                    : exactMatch || !slackUserInfo
                      ? 'white'
                      : 'rgba(244, 134, 54, 0.03)',
                  border: error
                    ? '1px solid #F00'
                    : exactMatch || !slackUserInfo
                      ? '1px solid rgba(0, 0, 0, 0.20)'
                      : '1px solid #F48636',
                  borderRadius: '6px',
                  color: 'rgba(0, 0, 0, 0.3)',
                  fontFamily: 'Inter',
                  height: '38px',
                  marginBottom: '4px',
                }}
                inputProps={{
                  maxLength: 11,
                }}
                InputProps={{
                  style: {
                    fontFamily: 'Merriweather Sans',
                    fontWeight: 300,
                    fontSize: '16px',
                    lineHeight: '20px',
                    padding: '4px 4px 4px 10px',
                    letterSpacing: '2.08px',
                  },
                  disableUnderline: true,
                }}
              />
              <div style={{ height: '26px' }}>
                {(slackUserInfo || error) && (
                  <div style={{ color: error ? '#F00' : '#F48636', fontFamily: 'Inter', fontSize: '12px' }}>
                    {error && error}
                    {!exactMatch && !error && (
                      <span>
                        Not an exact match. This is the ID for <b>{slackUserInfo.profile.display_name_normalized}</b>.
                        <br />
                        Proceed with care!
                      </span>
                    )}
                  </div>
                )}
              </div>

              <p
                style={{
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: 300,
                  color: 'rgba(0,0,0,0.4)',
                  marginBottom: '24px',
                  lineHeight: '180%',
                }}
              >
                <b>How to find a Slack member ID</b>
                <br />
                1. Click on a user name within Slack.
                <br />
                2. Click on "View full profile" in the menu that appears.
                <br />
                3. Click the ellipses (three dots).
                <br />
                4. Click on "Copy member ID".
              </p>
              {isValidSlackId(tempSlackMemberId) ? (
                <ModalSubmitButton handleSubmit={handleSubmit} disabled={!!error || gettingSlackMemberInfo} />
              ) : (
                <SendTamnuniTooltip
                  title={
                    "Invalid Slack member ID. It should start with 'U' or 'W' followed by 8 alphanumeric characters."
                  }
                  placement={'top'}
                >
                  <span>
                    {' '}
                    <ModalSubmitButton handleSubmit={handleSubmit} disabled={true} />{' '}
                  </span>
                </SendTamnuniTooltip>
              )}
              <Button
                variant="outlined"
                style={{
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  borderRadius: '18px',
                  marginRight: '8px',
                  height: '30px',
                  fontSize: '15px',
                  lineHeight: '18px',
                  padding: '3px 10px 3px 10px',
                  width: '100px',
                  color: '#00A394',
                  backgroundColor: 'transparent',
                  border: '1px solid #00A394',
                  marginLeft: '8px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    </Modal>
  );
};

export const SendReachoutDialog = ({
  connector,
  show,
  setShow,
  target,
  userState,
  sendingTamnuni,
  isExecuting,
  sendTamnuni,
  undoSendTamnuni,
}) => {
  const handleClose = () => {
    setShow(false);
  };

  const [customMessage, setCustomMessage] = useState('');
  const handleCustomMessage = (e) => setCustomMessage(e.target.value);

  const sharedCompanies = _.get(connector, 'relationships').map((r) => r.shared_company);
  const sharedCompaniesStr =
    sharedCompanies.length > 1
      ? sharedCompanies.slice(0, -1).join(', ') + ' and ' + sharedCompanies.slice(-1)
      : sharedCompanies[0];

  return (
    <Modal open={show} onClose={handleClose}>
      <div style={{ outline: 'none' }}>
        <div
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '500px' }}
        >
          <Paper style={{ padding: 16, borderRadius: '22px', border: 'none' }}>
            <div style={{ textAlign: 'right', marginRight: '12px 12px', color: '#00A394' }}>
              <Close onClick={handleClose} />
            </div>
            <div style={{ marginLeft: '40px', marginBottom: '40px', marginRight: '14px', marginTop: '15px' }}>
              <div
                style={{
                  marginBottom: '18px',
                  fontFamily: 'Merriweather Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  textAlign: 'left',
                  lineHeight: '24.53px',
                }}
              >
                Hey {_.get(connector, 'connector_name')}! 👋 <br />I saw you worked with{' '}
                <Link href={'https://www.linkedin.com/in/' + _.get(target, 'public_identifier')} target="_blank">
                  {_.get(target, 'name')}
                </Link>{' '}
                at {sharedCompaniesStr}.<br />
                We are considering starting a hiring process with {_.get(target, 'FirstName')}.
              </div>
              <div style={{ marginBottom: '18px' }}>
                <TextField
                  placeholder={'Add your custom text here (optional)'}
                  value={customMessage}
                  onChange={handleCustomMessage}
                  multiline
                  minRows={4}
                  type={'text'}
                  style={{
                    width: '90%',
                    backgroundColor: 'white',
                    border: '1px solid rgba(0, 0, 0, 0.20)',
                    borderRadius: '3px',
                    color: 'rgba(0, 0, 0, 0.3)',
                    fontFamily: 'Merriweather Sans',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '16px',
                    padding: '4px 14px 14px 14px',
                  }}
                  InputProps={{
                    style: {
                      fontFamily: 'Merriweather Sans',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '20px',
                    },
                    disableUnderline: true,
                  }}
                />
              </div>
              <div
                style={{
                  marginBottom: '18px',
                  fontFamily: 'Merriweather Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  textAlign: 'left',
                  lineHeight: '24.53px',
                }}
              >
                People approached by someone they know are more likely to respond.
              </div>
              <div
                style={{
                  marginBottom: '18px',
                  fontFamily: 'Merriweather Sans',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  textAlign: 'left',
                  lineHeight: '24.53px',
                }}
              >
                {getUserFirstName(userState)} asked if you can help us reach out?
              </div>

              <SendTamnuniButtonWrapper
                isPrimary
                text={'Send'}
                handler={() => sendTamnuni(customMessage)}
                undoHandler={undoSendTamnuni}
                sendingTamnuni={sendingTamnuni}
                disabled={!sendingTamnuni && isExecuting}
              />

              <Button
                variant="outlined"
                disabled={sendingTamnuni || isExecuting}
                style={{
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  borderRadius: '18px',
                  marginRight: '8px',
                  height: '30px',
                  fontSize: '15px',
                  lineHeight: '18px',
                  padding: '3px 10px 3px 10px',
                  width: '100px',
                  color: !sendingTamnuni && !isExecuting ? '#00A394' : 'rgba(0, 0, 0, 0.25)',
                  border: sendingTamnuni || isExecuting ? '1px solid rgba(0, 0, 0, 0.2)' : '1px solid #00A394',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    </Modal>
  );
};

export const ConnectorTamnuniComponent = ({
  relevance,
  connector,
  target,
  userState,
  setRelevance,
  setShowConnectorFeedback,
  hasActiveReachouts,
  isExecuting,
  onSendingTamnuni,
}) => {
  const api = useAPI();
  const [lastReq, setLastReq] = useState('');
  const [timerId, setTimerId] = useState(null);
  const [showSendReachoutModal, setShowSendReachoutModal] = useState(false);
  const [sendingTamnuni, setSendingTamnuni] = useState(false);

  const [slackMemberId, setSlackMemberId] = useState('');
  const [showSlackMemberModal, setShowSlackMemberModal] = useState(false);

  const submitSlackMemberId = async (slackId) => {
    setShowSlackMemberModal(false);
    await submit_slack_member_id({ slackMemberId: slackId, publicId: _.get(connector, 'connector_public_id') }, api);
  };

  const readSlackMemberInfo = async (slackId) => {
    return read_slack_user_info({ slack_id: slackId, portco_name: getUserAccountName(userState) }, api);
  };

  const hide_message = () => setLastReq('');
  const openSlackMemberModal = () => setShowSlackMemberModal(true);

  const sendTamnuni = (customMessage) => {
    setSendingTamnuni(true);
    onSendingTamnuni(true);
    // Set a timer to call mark_relevance after 5 seconds
    const timer = setTimeout(async () => {
      try {
        setRelevance('sent tamnuni');
        const shouldShowFeedback =
          _.get(connector, 'chance_to_connect_to_display') === 'long shot' &&
          !_.includes(['relevant', 'sent tamnuni'], relevance);
        shouldShowFeedback && setShowConnectorFeedback(true);
        const reachoutId = getReachoutId({ connector, target, userState });
        const res = await mark_relevance(
          { connector, target, relevance: 'sent tamnuni', reachoutId, customMessage },
          api,
        );
        setSendingTamnuni(false);
        setShowSendReachoutModal(false);
        onSendingTamnuni(false);
        if (res.success) {
          setLastReq('Tamnuni was sent successfully!');
          !shouldShowFeedback && addQueryParamIfNeeded('post_creation', 'true');
        } else {
          setLastReq("Failed sending Tamnuni. A bug report was sent to our team - we'll be in touch.");
        }
      } catch (error) {
        console.log(error);
        setLastReq('Failed sending Tamnuni. A bug report was sent to our team. ' + error);
      }
    }, 2000);

    setTimerId(timer);
  };

  const undoSendTamnuni = () => {
    clearTimeout(timerId);
    setSendingTamnuni(false);
    setRelevance(undefined);
    setLastReq('');
    onSendingTamnuni(false);
  };

  const hasSlackId = _.get(connector, 'has_slack_id') || slackMemberId;

  return (
    <>
      {!_.get(connector, 'has_slack_id') && !slackMemberId && (
        <>
          <Link onClick={openSlackMemberModal} style={{ marginRight: '8px', fontFamily: 'Inter', fontSize: '14px' }}>
            <img
              src={warningSVG}
              style={{ height: '14px', marginRight: '8px', position: 'relative', top: '2px' }}
              alt={''}
            />
            Add a Slack member ID
          </Link>
          <SlackIdModal
            showSlackIdModal={showSlackMemberModal}
            setShowSlackIdModal={setShowSlackMemberModal}
            readSlackMemberInfo={readSlackMemberInfo}
            submitSlackMemberId={submitSlackMemberId}
            setSlackMemberId={setSlackMemberId}
            connector={_.pick(connector, ['connector_public_id', 'connector_name'])}
            slackMemberId={slackMemberId}
          />
        </>
      )}
      <SendTamnuniButtonWrapper
        text={'Send Tamnuni'}
        handler={() => setShowSendReachoutModal(true)}
        undoHandler={undoSendTamnuni}
        hasActiveReachouts={hasActiveReachouts}
        sendingTamnuni={sendingTamnuni}
        disabled={hasActiveReachouts || (!hasSlackId && !is_bar(userState)) || (!sendingTamnuni && isExecuting)}
      />
      <SendReachoutDialog
        show={showSendReachoutModal}
        setShow={setShowSendReachoutModal}
        connector={connector}
        target={target}
        userState={userState}
        sendingTamnuni={sendingTamnuni}
        isExecuting={isExecuting}
        sendTamnuni={sendTamnuni}
        undoSendTamnuni={undoSendTamnuni}
      />
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
    </>
  );
};

export const TamnuniCycleEnded = () => {
  return (
    <span
      style={{
        fontSize: '15px',
        color: 'rgba(0, 0, 0, 0.55)',
        fontWeight: 500,
        fontFamily: 'Inter',
      }}
    >
      {' '}
      <img src={vSvg} height={11} width={12} alt={''} /> Cycle Ended
    </span>
  );
};
