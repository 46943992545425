import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import React from 'react';

import { ACTIVE_ALEPH_POSCAN_STATUSES } from './poscan-utils';
import { INTRO_STATUSES, REJECTION_REASONS_INTRO, REJECTION_REASONS_POSCAN_BY_CANDIDATE } from './sf-consts';

export const IN_PLAY_INTRO_STATUSES = [
  'To review',
  'Asked', // label is asked connector
  'Connector Agreed',
  'Sent',
];

export const ALEPH_IN_PROGRESS_INTRO_STATUSES = ['Checking Internally', 'Checking Internally - Pinged'];

//const FAILED_INTRO_STATUSES = ['Can\'t Reach Out', 'Declined by Connector', 'No relationship with connector', 'Weak relationship with connector', 'Deferred']

function getPotentialIntroStatuses(currentStatus) {
  const ALEPH_FACILITATOR_INTRO_STATUSES = [
    {
      label: 'Checking Internally - Pinged',
      apiName: 'Checking Internally - Pinged',
      active: true,
      talpodRank: 4,
    },
    {
      label: 'No relationship with connector',
      apiName: 'No relationship with connector',
      active: false,
      talpodRank: 12,
    },
    {
      label: 'Weak relationship with connector',
      apiName: 'Weak relationship with connector',
      active: false,
      talpodRank: 11,
    },
    {
      label: "Can't Reach Out",
      apiName: "Can't Reach Out",
      active: false,
      talpodRank: 13,
    },
    {
      label: 'To review',
      apiName: 'To review',
      active: true,
      talpodRank: 3,
    },
    {
      label: 'Asked Connector',
      apiName: 'Asked',
      active: true,
      talpodRank: 2,
    },
  ];

  const ASKED_CONNECTOR_INTRO_STATUSES = [
    {
      label: 'Asked Connector',
      apiName: 'Asked',
      active: true,
      talpodRank: 2,
    },
    {
      label: 'Asked Connector - Pinged',
      apiName: 'Asked Connector - Pinged',
      active: true,
    },
    {
      label: 'Connector Agreed',
      apiName: 'Agreed',
      active: true,
      talpodRank: 3,
    },
    {
      label: 'Declined by Connector',
      apiName: 'Declined_by_Connector',
      active: false,
      talpodRank: 9,
    },
    {
      label: 'No Response from Connector',
      apiName: "Didn't Respond",
      active: false,
      talpodRank: 10,
    },
    {
      label: 'Request Sent to Target',
      apiName: 'Sent',
      active: true,
      talpodRank: 1,
    },
    {
      label: 'Declined by Target',
      apiName: 'Declined by Target',
      active: false,
      talpodRank: 11,
    },
    {
      label: 'Introduced',
      apiName: 'Introduced',
    },
  ];

  switch (currentStatus) {
    case 'Checking Internally':
      return ALEPH_FACILITATOR_INTRO_STATUSES;
    case 'Asked Connector - Pinged':
      return ASKED_CONNECTOR_INTRO_STATUSES;
    case 'Asked':
      return ASKED_CONNECTOR_INTRO_STATUSES;
    default:
      return INTRO_STATUSES;
  }
}

export function assignIntrosScore(p) {
  if (!ACTIVE_ALEPH_POSCAN_STATUSES.includes(p.Status__c)) {
    return {
      ...p,
      introScore: 0,
      introStatusPortco: false,
    };
  } else {
    if (_.isEmpty(p.intros)) {
      return {
        ...p,
        introScore: 1,
        introStatusPortco: 'Waiting for Review',
      };
    } else if (_.find(p.intros, ({ Status__c }) => _.includes(ALEPH_IN_PROGRESS_INTRO_STATUSES, Status__c))) {
      return {
        ...p,
        introScore: 2,
        introStatusPortco: 'In Progress',
      };
    } else if (_.find(p.intros, ({ Status__c }) => _.includes(IN_PLAY_INTRO_STATUSES, Status__c))) {
      return {
        ...p,
        introScore: 3,
        introStatusPortco: 'Active engagement with potential connectors',
      };
    } else if (_.find(p.intros, ({ Status__c }) => Status__c === 'Sent')) {
      return {
        ...p,
        introScore: 4,
        introStatusPortco: 'Waiting for candidate',
      };
    }
  }
  // TODO: fix
  return {
    ...p,
    introScore: 0,
    introStatusPortco: false,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '400px',
    },
  },
};

export const IntroStatus = ({ newStatus, handleNewStatus }) => {
  const menuOptions = _(getPotentialIntroStatuses(newStatus))
    .concat(INTRO_STATUSES.find(({ apiName }) => newStatus === apiName))
    .uniqBy('apiName')
    .compact()
    .value();
  return (
    <FormControl style={{ width: '180px', marginBottom: '8px', marginRight: '5px' }}>
      <InputLabel id="intro-status-select-label">Intro Status</InputLabel>
      <Select
        labelId="intro-status-select-label1"
        id="intro-status-select"
        value={newStatus}
        onChange={(event) => handleNewStatus(event.target.value)}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {menuOptions.map(({ label, apiName }) => (
          <MenuItem key={label} value={apiName}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export function assignRanking(i) {
  const rank = INTRO_STATUSES.find(({ apiName }) => apiName === i.Status__c);
  return {
    ...i,
    ..._.pick(rank, ['active', 'talpodRank']),
  };
}

export const RejectionReasonIntro = ({
  rejectionReason,
  setRejectionReason,
  rejectionDetails,
  handleRejectionDetails,
  newStatus,
}) => {
  const optionalReasons =
    newStatus !== 'Declined by Target' ? REJECTION_REASONS_INTRO : REJECTION_REASONS_POSCAN_BY_CANDIDATE;
  return (
    <>
      <FormControl style={{ width: '200px', marginRight: '5px' }}>
        <InputLabel id="rejection-reason-select-label">Reason</InputLabel>
        <Select
          labelId="rejection-reason-select-label1"
          id="rejection-reason-select"
          value={rejectionReason}
          onChange={(event) => setRejectionReason(event.target.value)}
          input={<Input />}
          MenuProps={MenuProps}
        >
          {optionalReasons.map(({ label, apiName }) => (
            <MenuItem key={label} value={apiName}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label={'Rejection Details'}
        placeholder={''}
        multiline
        maxRows={4}
        value={rejectionDetails}
        onChange={handleRejectionDetails}
        style={{ width: '250px' }}
      />
    </>
  );
};

export const NextUpdate = ({ nextUpdate, handleNextUpdate, introId }) => {
  return (
    <FormControl>
      <InputLabel id={'next-update-label-' + introId} />
      <TextField
        id={'datetime-next-update-' + introId}
        label="Next Update"
        type="date"
        value={nextUpdate}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleNextUpdate}
      />
    </FormControl>
  );
};

export function introStatusApiNameToLabel(Status__c) {
  const fullStatus = INTRO_STATUSES.find(({ apiName }) => apiName === Status__c);
  return _.get(fullStatus, 'label');
}

export function getFilteredIntrosInternal({ intros, filters }) {
  const introStatuses = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Intro Status')
    .map(({ value }) => value);
  const facilitators = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Aleph Facilitator')
    .map(({ value }) => value);
  const connectors = _.get(filters, 'search', [])
    .filter(({ type }) => type === 'Connector')
    .map(({ value }) => value);

  return intros.filter(({ Status__c, Connector__r, Aleph_Facilitator__r, Next_Update_Request__c }) => {
    const isReleventIntroStatus =
      _.isEmpty(introStatuses) || _.includes(introStatuses, introStatusApiNameToLabel(Status__c));
    const isRelevantFacilitator =
      _.isEmpty(facilitators) || _.includes(facilitators, _.get(Aleph_Facilitator__r, 'Name'));
    const isRelevantConnector = _.isEmpty(connectors) || _.includes(connectors, _.get(Connector__r, 'Name'));
    return (
      isReleventIntroStatus &&
      isRelevantFacilitator &&
      isRelevantConnector &&
      (_.isEmpty(Next_Update_Request__c) ||
        (Next_Update_Request__c <= filters.nextUpdateBefore && Next_Update_Request__c >= filters.nextUpdateAfter))
    );
  });
}
