import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  Slider,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useDebounce } from 'use-lodash-debounce';

import { useAPI, withUserState } from '../userContext';
import { assignGroup, getConnectorEmail } from '../utils/connectors';
import { AlephIcon, IntroDetails } from '../widgets';
import { MultiAccountSearchBox } from '../widgets/account-searchbox';
import { get_signal_sources, search_accounts_batch } from '../widgets/api';
import { ContactEmail, InteractionSourceSelector, ScoreIconWithoutTooltip } from '../widgets/contacts';
import { ModeSelector } from '../widgets/modeselect';
import { SkillSearchbox } from '../widgets/skill-searchbox';
import { get_aleph_users, get_potential_introducers_by_account, get_potential_introducers_by_skiils } from './api';
import {
  ConnectorAutoCompleteFilter,
  createInitialFiltersCompanyOverview,
  createOptionalEmployeesFilters,
  employeeFilterer,
  MonthsSlider,
} from './utils/connectors-filters';
import { AlephRequestButton } from './utils/intro-request';
import { AlephMemberSelector } from './utils/referrer-actions';
import { ReferrerIcons } from './utils/referrers';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '0',
  borderBottom: '0',
  maxWidth: '200px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: '0',
}));

function useQuery() {
  const loc = useLocation();
  return new URLSearchParams(loc.search);
}

const _Connector = ({ userState, c, alephUsers, comments, selectedSource, InitialSearch }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [lastReq, setLastReq] = useState('');
  const [reqCount, setReqCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState(
    alephUsers.find(({ Slack_Id__c }) => Slack_Id__c === _.get(c, 'owner_slack_id')),
  );

  const { Contact__c: sfid, Linkedin_Profile__c: linkedin_profile, score } = c;

  const on_request = (e) => {
    setIsDisabled(true);
    setReqCount(reqCount + 1);
    if (_.isUndefined(e)) {
      setLastReq('Sent a slack message successfully');
    } else {
      setLastReq('There was an error sending a slack msg. plz contact Tal...');
      console.error(e);
    }
  };

  const endDateText = _.get(c, 'End_Date__c')
    ? `Left on ${moment(_.get(c, 'End_Date__c')).format('MMM YYYY')}`
    : 'Active';

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>
          <ScoreIconWithoutTooltip score={score} />
        </StyledTableCell>
        <StyledTableCell>
          <ReferrerIcons linkedin_profile={linkedin_profile} sfid={sfid} />
          {getConnectorEmail(c) && <ContactEmail email={getConnectorEmail(c)} />}
          <Tooltip title={_.get(c, 'Active__c') ? 'active role' : 'past role'}>
            <AccountCircle style={{ color: _.get(c, 'Active__c') ? 'green' : 'red' }} />
          </Tooltip>
          {assignGroup({ c, userState }) === 'Portcos' && (
            <ListItemIcon style={{ marginLeft: '5px' }}>
              <AlephIcon fontSize="small" />
            </ListItemIcon>
          )}
        </StyledTableCell>
        <StyledTableCell>
          <div style={{ marginBottom: '8px' }}>{_.get(c, 'Contact_Name__c')}</div>
          <div style={{ marginBottom: '8px' }}>{_.get(c, 'Title')}</div>
          <div style={{ marginBottom: '8px' }}>{_.get(c, 'Currrent_account_of_contact_role__c')}</div>
          {InitialSearch === 'By Skill' && _.get(c, 'years') > 0 && (
            <div>{_.round(_.get(c, 'years'), 1)} Years of experience</div>
          )}
        </StyledTableCell>
        {InitialSearch === 'By Company' && (
          <>
            <StyledTableCell>
              <div style={{ marginBottom: '8px' }}>{_.get(c, 'role_title')}</div>
              <div style={{ marginBottom: '8px' }}>{_.get(c, 'Skill__c')}</div>
              <div>{_.get(c, 'Role__c')}</div>
            </StyledTableCell>
            <StyledTableCell align={'center'}>{_.get(c, 'Account_Name__c')}</StyledTableCell>
            <StyledTableCell>
              <div style={{ marginBottom: '8px' }}>{_.get(c, 'overlappingMonths')} Months</div>
              <div>{endDateText}</div>
            </StyledTableCell>
          </>
        )}
        {InitialSearch === 'By Skill' && <StyledTableCell>{_.get(c, 'past_roles')}</StyledTableCell>}
        <StyledTableCell>
          <AlephMemberSelector alephUsers={alephUsers} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </StyledTableCell>
        <StyledTableCell>
          <AlephRequestButton
            connector={c}
            comments={comments}
            selectedSource={selectedSource}
            on_request={on_request}
            setIsDisabled={setIsDisabled}
            selectedUser={selectedUser}
            isDisabled={isDisabled || _.get(selectedUser, 'Name', '') === 'Ampliphy Ampliphius'}
          />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

const Connector = withUserState(_Connector);

const Connectors = ({ connectors, alephUsers, introGoal, comments, selectedSource, InitialSearch }) => {
  const [numberOfConnectorsToShow, setNumberOfConnectorsToShow] = useState(20);
  const orderedConnector = _.orderBy(connectors, ['score', 'overlappingMonths'], ['desc', 'desc']);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow style={{ border: '0pxx' }}>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Connector</StyledTableCell>
            {InitialSearch === 'By Company' && (
              <>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell>Dates</StyledTableCell>
              </>
            )}
            {InitialSearch === 'By Skill' && <StyledTableCell>Past Roles</StyledTableCell>}
            <StyledTableCell>Aleph Contact</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.take(orderedConnector, numberOfConnectorsToShow).map((c) => (
            <Connector
              key={'referrer-' + _.get(c, 'Contact__c') + '-' + _.get(c, 'Account__c')}
              InitialSearch={InitialSearch}
              alephUsers={alephUsers}
              introGoal={introGoal}
              c={c}
              comments={comments}
              selectedSource={selectedSource}
            />
          ))}
        </TableBody>
      </Table>
      {numberOfConnectorsToShow < orderedConnector.length && (
        <Button onClick={() => setNumberOfConnectorsToShow(numberOfConnectorsToShow + 20)} variant={'outlined'}>
          Show More
        </Button>
      )}
    </>
  );
};

export const YearsSlider = ({
  connectorFilters,
  setConnectorFilters,
  optionalConnectorFilters,
  label = 'Years of experience',
}) => {
  const handleSliderChange = (event, newValue) => {
    const [yearsOfExperienceMin, yearsOfExperienceMax] = newValue;
    setConnectorFilters({
      ..._.omit(connectorFilters, ['yearsOfExperienceMin', 'yearsOfExperienceMax']),
      yearsOfExperienceMin,
      yearsOfExperienceMax,
    });
  };
  return (
    <FormControl style={{ width: '80%' }}>
      <InputLabel id="years-selector"> {label} </InputLabel>
      <Slider
        value={[connectorFilters.yearsOfExperienceMin, connectorFilters.yearsOfExperienceMax]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        step={0.5}
        min={_.floor(optionalConnectorFilters.yearsOfExperienceMin)}
        max={_.ceil(optionalConnectorFilters.yearsOfExperienceMax)}
      />
    </FormControl>
  );
};

export const KeyNodesReachouts = () => {
  const api = useAPI();
  const q = useQuery().get('q');
  const [accountList, setAccountList] = useState(q ? [q] : []);
  const [matchedAccountList, setMatchedAccountList] = useState([]);
  const [lastReq, setLastReq] = useState('');
  const [reqCount, setReqCount] = useState(0);

  const [potentialConnectors, setPotentialConnectors] = useState([]);

  const [optionalSources, setOptionalSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState('Layoffs News');

  const [comments, set_comments] = useState('');
  const handle_comments = (e) => set_comments(e.target.value);

  const [optionalConnectorFilters, setOptionalConnectorFilters] = useState();
  const [connectorFilters, setConnectorFilters] = useState();
  const [filteredConnectors, setFilteredConnectors] = useState([]);

  const [searchedSkills, setSearchedSkills] = useState([]);

  useEffect(() => {
    setOptionalConnectorFilters(createOptionalEmployeesFilters(potentialConnectors));
    setConnectorFilters(createInitialFiltersCompanyOverview(potentialConnectors));
  }, [potentialConnectors]);

  useEffect(() => {
    if (_.isEmpty(connectorFilters)) {
      return;
    }
    setFilteredConnectors(
      employeeFilterer({
        connectorFilters,
        potentialConnectors,
      }),
    );
  }, [connectorFilters, potentialConnectors]);

  const [alephUsers, setAlephUsers] = useState([]);
  useEffect(() => {
    async function fetch() {
      try {
        await Promise.all([
          get_aleph_users(api).then(({ users }) => setAlephUsers(users)),
          get_signal_sources(api).then(({ sources }) => setOptionalSources(sources.map(({ fullName }) => fullName))),
        ]);
      } catch (e) {
        console.log(e);
      }
    }
    fetch();
  }, [api]);

  const [loadingConnectors, setLoadingConnectors] = useState(false);

  const onAccountListChange = (lst) => {
    setMatchedAccountList(lst.filter((x) => !!x.id));
    setAccountList(lst.filter((x) => !x.id).map((x) => x.trim()));
    setLastReq('');
  };
  const searchAccountList = useDebounce(accountList, 1000);

  const INITIAL_SEARCH_OPTIONS = ['By Company', 'By Skill'];
  const [InitialSearch, setInitialSearch] = useState('By Company');

  const getDefaultSourceBySearch = () => (InitialSearch === 'By Company' ? 'Layoffs News' : 'Talent Recommendation');
  useEffect(() => {
    setSelectedSource(getDefaultSourceBySearch());
  }, [InitialSearch, getDefaultSourceBySearch]);

  const DEFAULT_LAYOFFS_COMMENTS = '*:exclamation: Layoffs alert - suggest help/collect recommendations:*\n';
  const DEFAULT_TALENT_RECOMMENDATION_COMMENTS =
    ':100: :platypus: :world_map: Top talent mapping - collect recommendations from key nodes.\nPosition:';
  const getDefaultCommentsBySource = () =>
    selectedSource === 'Layoffs News'
      ? DEFAULT_LAYOFFS_COMMENTS
      : selectedSource === 'Talent Recommendation'
        ? DEFAULT_TALENT_RECOMMENDATION_COMMENTS
        : '';

  useEffect(() => {
    set_comments(getDefaultCommentsBySource());
  }, [getDefaultCommentsBySource, selectedSource]);

  useDeepCompareEffect(() => {
    const fetchResults = async () => {
      if (_.isEmpty(_.concat(searchAccountList, matchedAccountList))) {
        return;
      }
      setLoadingConnectors(true);
      try {
        const all_account_names = _.concat(
          searchAccountList,
          matchedAccountList.map((x) => x.name),
        );
        const all_accounts = await search_accounts_batch(all_account_names, api);
        const { connectors } = await get_potential_introducers_by_account(
          { account_ids: all_accounts.map((a) => a.id) },
          api,
        );
        setPotentialConnectors(connectors);
      } finally {
        setLoadingConnectors(false);
      }
    };
    fetchResults();
  }, [searchAccountList, matchedAccountList, api]);

  useDeepCompareEffect(() => {
    const fetchResults = async () => {
      if (_.isEmpty(searchedSkills)) {
        return;
      }
      setLoadingConnectors(true);
      try {
        const { connectors } = await get_potential_introducers_by_skiils(
          { skills: searchedSkills.map(({ apiName }) => apiName) },
          api,
        );
        setPotentialConnectors(connectors);
      } finally {
        setLoadingConnectors(false);
      }
    };
    fetchResults();
  }, [searchedSkills, api]);

  const hide_message = () => setLastReq('');

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h1">Search Aleph Network</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Grid item xs={12} sm={12} md={10} lg={8} xl={6} style={{ textAlign: 'center' }}>
          <div className="searchbox-outer">
            <ModeSelector
              options={INITIAL_SEARCH_OPTIONS}
              handleSelected={(e, value) => setInitialSearch(value)}
              value={InitialSearch}
            />
            {InitialSearch === 'By Company' ? (
              <MultiAccountSearchBox
                onSelected={onAccountListChange}
                key={reqCount}
                initial_search={q}
                label={'Type or paste company names separated by line breaks'}
                labelMin={'Search Accounts'}
              />
            ) : (
              <SkillSearchbox
                handleSkillChange={(e, option) => setSearchedSkills(option)}
                selectedSkills={searchedSkills}
              />
            )}
          </div>
        </Grid>
        {!_.isEmpty(optionalSources) && (
          <Grid
            item
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '1em', marginBottom: '1em' }}
          >
            <Grid item md={2} sm={2} xs={2}>
              <InteractionSourceSelector
                optionalSources={optionalSources}
                selectedSource={selectedSource}
                setSelectedSource={setSelectedSource}
              />
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <IntroDetails
                comments={comments}
                handle_comments={handle_comments}
                placeholder={''}
                label={'context'}
                rows={'3'}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Snackbar open={!_.isEmpty(lastReq)} autoHideDuration={10000} onClose={hide_message}>
        <Alert onClose={hide_message} severity={lastReq.includes('Success') ? 'success' : 'error'}>
          {lastReq}
        </Alert>
      </Snackbar>
      {loadingConnectors && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={20} />
          <span style={{ marginLeft: '5px' }}>Loading Connectors...</span>
        </div>
      )}

      {!_.isEmpty(potentialConnectors) && !loadingConnectors && (
        <>
          <Grid container justifyContent={'center'} style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}>
            <Grid item xs={12} sm={12} md={10} lg={8} xl={6} style={{ textAlign: 'center' }}>
              <Grid
                container
                justifyContent={'center'}
                style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}
              >
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'center' }}>
                  <ConnectorAutoCompleteFilter
                    optionalConnectorFilters={optionalConnectorFilters}
                    setConnectorFilters={setConnectorFilters}
                    connectorFilters={connectorFilters}
                  />
                </Grid>
                {!_.isEmpty(optionalConnectorFilters) && !_.isEmpty(connectorFilters) && (
                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <MonthsSlider
                      connectorFilters={connectorFilters}
                      setConnectorFilters={setConnectorFilters}
                      optionalConnectorFilters={optionalConnectorFilters}
                      label={'Months in role'}
                    />
                    {
                      /*We only show experience when searching by skills*/ !_.isEmpty(
                        _.get(connectorFilters, 'yearsOfExperienceMax'),
                      ) && (
                        <div style={{ marginTop: '15px' }}>
                          <YearsSlider
                            connectorFilters={connectorFilters}
                            setConnectorFilters={setConnectorFilters}
                            optionalConnectorFilters={optionalConnectorFilters}
                            label={'Years of experience'}
                          />
                        </div>
                      )
                    }
                  </Grid>
                )}
              </Grid>
              <div style={{ marginTop: '20px' }}>
                {filteredConnectors.length} / {potentialConnectors.length} Potential Connectors
                {/* todo: add last scraper enrichment date if relevant in this page
                                    <ScrapingPanel contacts={potentialConnectors.map(({Linkedin_Profile__c,last_scraper_enrichment_date__c})=>({linkedin_url:Linkedin_Profile__c,last_scraper_enrichment_date__c}))}/>
                                */}
              </div>
            </Grid>
          </Grid>
          {!_.isEmpty(filteredConnectors) && !loadingConnectors && (
            <Grid container justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10} style={{ textAlign: 'center' }}>
                <Connectors
                  connectors={filteredConnectors}
                  alephUsers={alephUsers}
                  introGoal={'Talent'}
                  comments={comments}
                  selectedSource={selectedSource}
                  InitialSearch={InitialSearch}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
