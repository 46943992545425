import { Button, Checkbox, Grid, Link, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Apartment, FileCopy, LinkedIn } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import salesforce_png from '../static/salesforce.png';
import { is_admin, is_talpod, useAPI, withUserState } from '../userContext';
import { getPotentialPoscanStatuses, PoscanFieldGenericInput, RejectionReasonPoscan } from '../utils/poscan-utils';
import { update_poscan_rejection, update_poscan_tamuz_app } from './api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const PoscanSideMenu = ({ p, parent }) => {
  const sfUrl = `https://aleph.lightning.force.com/lightning/r/Position_Candidate__c/${p.Id}/view`;
  return (
    <Grid item md={1} sm={1} xs={1} lg={1} xl={1} container direction={'column'}>
      <Grid item md={2} sm={2} xs={2} lg={2} xl={2}>
        <Link href={sfUrl} target="_blank">
          <img height="25px" src={salesforce_png} alt={'salesforce'} />
        </Link>
      </Grid>
      {parent === 'Candidate' ? (
        <Grid item md={2} sm={2} xs={2} lg={2} xl={2}>
          {!!_.get(p, 'Position__r.Link_to_Job__c') && (
            <Link href={_.get(p, 'Position__r.Link_to_Job__c')} target="_blank">
              <Apartment />
            </Link>
          )}
        </Grid>
      ) : (
        <Grid item md={2} sm={2} xs={2} lg={2} xl={2}>
          <Link href={_.get(p, 'Candidate_LinkedIn__c')} target="_blank">
            <LinkedIn />
          </Link>
        </Grid>
      )}
      {/* todo: implement
                    <Grid item md={2} sm={2} xs={2} lg={2} xl={2}>
                        <div>
                            <HighlightOff onClick={deletePoscan} />
                        </div>
                    </Grid>
                */}
    </Grid>
  );
};

const _Poscan = ({ p, parent, potentialRejectionReasons, userState }) => {
  const api = useAPI();
  const [poscanDetails, setPoscanDetails] = useState(
    _.pick(p, [
      'Status__c',
      'Id',
      'Rejection_Reason__c',
      'Rejection_Details__c',
      'Candidate_Interested__c',
      'Portco_Interested__c',
    ]),
  );

  const updatePoscanStatus = (event) => {
    const { value } = event.target;
    setPoscanDetails({
      ..._.omit(poscanDetails, ['Status__c']),
      Status__c: value,
      userChange: true,
    });
  };

  const togglePortcoInterest = () =>
    setPoscanDetails({
      ..._.omit(poscanDetails, ['Portco_Interested__c']),
      Portco_Interested__c: !_.get(poscanDetails, 'Portco_Interested__c'),
      userChange: true,
    });

  const toggleCandidateInterest = () =>
    setPoscanDetails({
      ..._.omit(poscanDetails, ['Candidate_Interested__c']),
      Candidate_Interested__c: !_.get(poscanDetails, 'Candidate_Interested__c'),
      userChange: true,
    });

  const [copiedOneLiner, setCopied] = useState(false);
  const textToCopy = `${_.get(p, 'One_Liner__c')}${!_.isEmpty(_.get(p, 'Position_One_Liner__c')) ? `\n${_.get(p, 'Position_One_Liner__c')}` : ''}`;

  useEffect(() => {
    async function up() {
      await update_poscan_tamuz_app(_.omit(poscanDetails, ['userChange']), api);
    }
    !!_.get(poscanDetails, 'userChange', false) && up();
  }, [api, poscanDetails]);
  const copyOneLiner = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  };

  return (
    <Grid
      container
      style={{ width: '100%', padding: '20px 20px 20px 20px', lineHeight: '32px' }}
      justifyContent={'flex-start'}
    >
      <Grid md={3} sm={3} xs={3} lg={3} xl={3} item container style={{ marginRight: '24px', textAlign: 'right' }}>
        <PoscanSideMenu p={p} parent={parent} />
        <Grid item md={10} sm={10} xs={10} lg={10} xl={10}>
          <div
            style={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              color: '#00A394',
              marginBottom: '10px',
            }}
          >
            {parent === 'Candidate' ? _.get(p, 'Position__r.Name') : _.get(p, 'Candidate_Name__c')}
          </div>
          {parent === 'Candidate' && _.get(p, 'Position__r.Confidential__c') && (
            <div>
              <Checkbox checked={true} size={'small'} disabled={true} /> Confidential
            </div>
          )}
          {parent === 'Candidate' && <div>{_.get(p, 'Position__r.Location__c')}</div>}
        </Grid>
      </Grid>
      <Grid md={6} sm={6} xs={6} lg={6} xl={6} item>
        <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
          <span style={{ marginRight: '18px' }}>{_.get(p, 'Account__c')} </span>
          <Button
            size={'small'}
            startIcon={<FileCopy />}
            onClick={copyOneLiner}
            style={{
              borderRadius: '10px',
              backgroundColor: 'rgba(0, 163, 148, 0.06)',
              color: '#00A394',
              border: 'none',
            }}
          >
            {copiedOneLiner ? 'Copied to Clipboard' : 'Copy One Liner'}
          </Button>
        </div>
        <div>
          <FormControl style={{ width: '80%' }}>
            <InputLabel id="poscan-status-selector-input-label">Status</InputLabel>
            <Select
              labelId="poscan-status-selector-label"
              id="poscan-status-selector"
              value={_.get(poscanDetails, 'Status__c')}
              onChange={updatePoscanStatus}
              input={<Input />}
              menu_props={MENU_PROPS}
            >
              {getPotentialPoscanStatuses(
                _.pick(p, ['Status__c', 'Portco_Interested__c', 'Candidate_Interested__c']),
                is_admin(userState),
              ).map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ color: '#818181' }}>
          <span style={{ marginRight: '14.5px' }}> Matched {moment(_.get(p, 'CreatedDate')).format('DD MMM YY')}</span>
          {!_.isEmpty(_.get(p, 'Last_Status_Update__c')) && (
            <>
              <span style={{ marginRight: '14.5px' }}> • </span>
              <span> Updated {moment(_.get(p, 'Last_Status_Update__c')).format('DD MMM YY')} </span>
            </>
          )}
        </div>
        {_.get(poscanDetails, 'Status__c', '').toLowerCase().includes('reject') && (
          <>
            <div>
              <div style={{ marginBottom: '12px' }}>
                <RejectionReasonPoscan
                  poscan={{
                    ..._.omit(p, 'Status__c'),
                    Status__c: _.get(poscanDetails, 'Status__c'),
                  }}
                  width={'80%'}
                  potentialRejectionReasons={potentialRejectionReasons}
                />
              </div>
            </div>
            <div>
              <PoscanFieldGenericInput
                fieldName={'Rejection Details'}
                fieldApiName={'Rejection_Details__c'}
                poscan={p}
                updateFunc={update_poscan_rejection}
                inputType={'textarea'}
                width={'80%'}
              />
            </div>
          </>
        )}
        {
          <div>
            {is_talpod(userState) && (
              <>
                <Checkbox
                  checked={_.get(poscanDetails, 'Portco_Interested__c')}
                  size={'small'}
                  onChange={togglePortcoInterest}
                />{' '}
                Portco Interested
              </>
            )}
            <Checkbox
              checked={_.get(poscanDetails, 'Candidate_Interested__c')}
              size={'small'}
              onChange={toggleCandidateInterest}
            />{' '}
            Candidate Interested
          </div>
        }
      </Grid>
    </Grid>
  );
};

export const Poscan = withUserState(_Poscan);
