import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { People } from '@material-ui/icons';
import React, { useState } from 'react';

import { useAPI } from '../userContext';

export const InviteWidget = () => {
  const api = useAPI();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [comments, setComments] = useState('');
  const [done, setDone] = useState(false);

  const clear = () => {
    setName('');
    setEmail('');
    setRole('');
    setComments('');
    setDone(false);
  };
  const handleClose = () => {
    clear();
    setOpen(false);
  };
  const handleOpen = () => setOpen(true);
  const handleName = (e) => setName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handleRole = (e) => setRole(e.target.value);
  const handleComments = (e) => setComments(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = JSON.stringify({ email, role, name, comments });
    try {
      await api('user/invite', { method: 'POST', body });
      setDone(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Button onClick={handleOpen}>
        <People />
        <Typography>Invite Team Member</Typography>
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="suggestion-dialog-title" open={open}>
        <DialogTitle id="suggestion-dialog-title">Request Access for Team Member</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {done
              ? `Thank you! we will contact ${name} directly with access details`
              : 'Who in your company should have access to this tool?'}
          </DialogContentText>
          {!done && (
            <form id="invite-form" onSubmit={handleSubmit}>
              <TextField
                id="name"
                type="text"
                label="Full Name"
                value={name}
                onChange={handleName}
                fullWidth
                required
              />
              <TextField
                id="email"
                type="email"
                label="Email"
                value={email}
                onChange={handleEmail}
                required
                fullWidth
              />
              <TextField id="role" type="text" label="Role" value={role} onChange={handleRole} required fullWidth />
              <TextField
                id="comments"
                type="text"
                label="Comments (optional)"
                value={comments}
                onChange={handleComments}
                fullWidth
              />
            </form>
          )}
        </DialogContent>
        {done ? (
          <DialogActions>
            <Button onClick={clear} color="primary">
              Invite Another Team Member
            </Button>
            <Button onClick={handleClose} color="primary">
              Done
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button type="submit" form="invite-form" color="primary">
              Submit
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
