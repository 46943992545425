import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useLocation } from 'react-router-dom';

import aleph_png from '../static/aleph.png';
import buffering from '../static/ramzor/buffering.json';
import salesforce_png from '../static/salesforce.png';
import { is_admin, LoginController, useAPI, withUserState } from '../userContext';
import { getRamzorScoreText, getTalentPageUrl, RamzorIcon } from '../utils/contacts';
import { get_ramzor } from './api';

const RamzorWithBuffering = ({ chanceToConnect, loading = false, FirstName }) => {
  const [animate, setAnimate] = useState(false);
  const [hideLottie, setHideLottie] = useState(false);
  useEffect(() => {
    if (loading) return;
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 100);

    const timerLottie = setTimeout(() => {
      setHideLottie(true);
    }, 2000);
    return () => {
      if (timer) clearTimeout(timer); // clean up function in case the component unmounts
      if (timerLottie) clearTimeout(timerLottie); // clean up function in case the component unmounts
    };
  }, [loading]);

  return (
    <>
      <div
        style={{
          position: 'relative',
          alignItems: 'center', // Set the div's position to relative so its children can be absolutely positioned
        }}
      >
        <div
          style={{
            opacity: hideLottie ? 0 : 1, // Fade out the Lottie animation when loading is done
            transition: 'opacity 2s', // Transition for the fade out
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: buffering,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          />
        </div>
        {!loading && (
          <div
            style={{
              position: 'absolute', // Absolutely position the div on top of the Lottie animation
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              transition: 'opacity 1s, transform 1s',
              opacity: animate ? 1 : 0,
            }}
          >
            <RamzorIcon c={{ chanceToConnect }} size={32} marginBottom={'8px'} />
            <span
              style={{
                fontFamily: 'Merriweather Sans',
                fontWeight: 300,
                fontSize: '14px',
                color: 'rgba(0, 0, 0, 0.60)',
                opacity: animate ? 1 : 0,
                transform: animate ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 0.5s, transform 0.5s',
                whiteSpace: 'nowrap', // This will prevent the text from wrapping into a new line
                marginBottom: '8px',
              }}
            >
              {getRamzorScoreText({ chanceToConnect, FirstName })}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const viewAsAdmin = (userState) => is_admin(userState);

const ActionButton = ({ url, isPrimary, text }) => {
  const [hover, setHover] = useState(false);

  return (
    <Link href={url} target={'_blank'} style={{ display: 'inline', marginRight: '8px' }}>
      <Button
        variant="outlined"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          height: '32px',
          borderRadius: '22px',
          fontFamily: 'Inter',
          fontWeight: 700,
          fontSize: '15px',
          borderWidth: '1px',
          color: isPrimary ? '#FFFFFF' : 'rgba(0, 0, 0, 0.64)',
          borderColor: isPrimary ? 'transparent' : 'rgba(0, 0, 0, 0.64)',
          backgroundColor:
            hover && isPrimary ? '#18407E' : isPrimary ? '#0A66C2' : hover ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
          textTransform: 'none',
          paddingLeft: '14px',
          paddingRight: '14px',
        }}
      >
        {text}
      </Button>
    </Link>
  );
};

const _Ramzor = ({ userState }) => {
  const api = useAPI();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const public_identifier = urlParams.get('linkedin_id');
  const source = urlParams.get('source');
  const fullName = urlParams.get('fullName');
  const linkedinLocation = urlParams.get('location');
  const [ramzorScore, setRamzorScore] = useState(undefined);
  const showSfButton = viewAsAdmin(userState) && !_.isEmpty(_.get(ramzorScore, 'sfId')) && source !== 'search'; // && !is_talpod(userState)
  useEffect(() => {
    if (!public_identifier) {
      return;
    }
    const fetchResults = async () => {
      try {
        setLoading(true);
        const res = await get_ramzor({ public_identifier, source, linkedinLocation }, api);
        setRamzorScore(res.result);
      } finally {
        setLoading(false);
      }
    };
    fetchResults();
  }, [public_identifier, source, api, linkedinLocation]);

  return (
    <div style={{ backgroundColor: 'transparent', marginTop: '22px' }}>
      <div style={{ overflow: 'hidden' }}>
        {
          <div style={{ float: 'left' }}>
            {showSfButton && (
              <>
                <div style={{ float: 'left', marginRight: '10px' }}>
                  <Link
                    href={`https://aleph.lightning.force.com/lightning/r/Contact/${_.get(ramzorScore, 'sfId')}/view`}
                    target="_blank"
                  >
                    <img height="32px" src={salesforce_png} alt={'salesforce'} />
                  </Link>
                </div>
                <ActionButton
                  url={`/#/talent/match-positions/?linkedin_id=${public_identifier}&sf_id=${_.get(ramzorScore, 'sfId')}&fullName=${fullName}`}
                  isPrimary={false}
                  text={'Match'}
                />
              </>
            )}
            {viewAsAdmin(userState) === false && !_.isNull(_.get(userState, 'aleph_user')) && (
              <>
                <ActionButton
                  url={getTalentPageUrl({ public_identifier, fullName, ask: 'Reference Check' })}
                  isPrimary={_.get(ramzorScore, 'chanceToConnect') !== 'We know the target' && !loading}
                  text={'Ask For Reference'}
                />
                <ActionButton
                  url={getTalentPageUrl({ public_identifier, fullName, ask: 'Intro Request' })}
                  isPrimary={_.get(ramzorScore, 'chanceToConnect') === 'We know the target'}
                  text={'Request Intro'}
                />
              </>
            )}
          </div>
        }
        <div style={{ float: 'left', display: 'flex', alignItems: 'center' }}>
          {viewAsAdmin(userState) && (
            <Link
              style={{ float: 'left', marginRight: '10px' }}
              href={getTalentPageUrl({ public_identifier, fullName, ask: 'Reference Check' })}
              target={'_blank'}
            >
              <RamzorWithBuffering
                chanceToConnect={_.get(ramzorScore, 'chanceToConnect')}
                FirstName={_.get(ramzorScore, 'FirstName')}
                loading={loading}
              />
            </Link>
          )}
          {!viewAsAdmin(userState) && !_.isNull(_.get(userState, 'aleph_user')) && (
            <RamzorWithBuffering
              FirstName={_.get(ramzorScore, 'FirstName')}
              chanceToConnect={_.get(ramzorScore, 'chanceToConnect')}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const Ramzor = withUserState(_Ramzor);

export const NoRamzorForYou = () => {
  return (
    <div style={{ backgroundColor: 'transparent', marginTop: '22px' }}>
      <div style={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
        <img height="30px" width={'30px'} src={aleph_png} alt={''} style={{ marginRight: '10px' }} />
        <span
          style={{
            fontFamily: 'Inter',
            fontSize: '15px',
            marginRight: '10px',
          }}
        >
          Sign in with Google to Aleph Connect{' '}
        </span>
        <div style={{ verticalAlign: 'bottom' }}>
          <LoginController theme={'outline'} type={'icon'} shape={'square'} size={'small'} />
        </div>
      </div>
    </div>
  );
};
